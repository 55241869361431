import { CloseFullscreenOutlined } from '@mui/icons-material';
import axios from 'axios';
import * as process from "process";
import { toast } from 'react-toastify';



// descrption:- last updated 24-06-22
// descrption:- By Mayur
class BookingServices {
    constructor() {
        this.state = {
            ApibaseURL: process.env.REACT_APP_API_BASE_URL,
            AccessToken: localStorage.getItem('token'),
        }
    }

    async BookAndPay(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/bookings`, data, headers).then((response) => {
                return response
            }).catch(error => {
       
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async PaymentOrder(data) {       
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/users/payments/orders`, data, headers).then((response) => {
                return response
            }).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }
    async PaymentCapture(data) {      
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/users/payments/capture`, data, headers).then((response) => {
                return response
            }).catch(error => {
                return error.response
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async BookingListUserPanel(status) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}bookings/user?status=${status}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }


    async ShowpaymentPageApi({ merchantId, orderId, data }) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`https://securegw-stage.paytm.in/theia/api/v1/showPaymentPage?mid=${merchantId}&orderId=${orderId}`, headers, data).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async BookingServiceProviderAPI(type,status) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
                                                                
            // const response = await axios.get(`${this.state.ApibaseURL}bookings/user?BookingType=${status}&status=&sort_by=&serviceType=${type}`, headers).then((response) => {
                const response = await axios.get(`${this.state.ApibaseURL}bookings/all?BookingType=${status}&status=&sort_by=&serviceType='Service'`, headers).then((response) => {
                return response
              
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async HomecareBookingForServiceProvider(type,status) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
                                                                
            const response = await axios.get(`${this.state.ApibaseURL}homecareBooking?BookingType=${status}&status=&sort_by=&serviceType=${type}`, headers).then((response) => {
                return response
              
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async AmbulanceBookingForServiceProvider(type,status) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
                                                                
            const response = await axios.get(`${this.state.ApibaseURL}ambulances/bookings/user?BookingType=${status}&status=&sort_by=&serviceType=${type}`, headers).then((response) => {
                return response
              
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async AddPrescriptionAPI(reqdata) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}doctors/prescription`,JSON.stringify(reqdata), headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async ServiceProviderIncomeAPI(searchby,service,period,fromdate,todate) {
        try {
            var url="";
            if(period==="Custom"){
            url=`doctors/patients/income?searchBy=${searchby}&service=${service}&period=${period}&from_date=${fromdate}&to_date=${todate}`
            }
            else{
                url=`doctors/patients/income?searchBy=${searchby}&service=${service}&period=${period}`
            }
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}${url}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async bookAndPayForHomecare(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/homecareBooking`, data, headers).then((response) => {
                return response
            }).catch(error => {
       
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async BookAndPayForAmbulance(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/ambulances/bookings`, data, headers).then((response) => {
                return response
            }).catch(error => {
       
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async couponList(service,search_key) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
                                                                
            const response = await axios.get(`${this.state.ApibaseURL}/coupon?search_key=&service_type=${service}&for_user=User`, headers).then((response) => {
                return response
              
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async couponDetailsById(id) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
                                                                
            const response = await axios.get(`${this.state.ApibaseURL}/coupon/${id}`, headers).then((response) => {
                return response
              
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }

    async ValidateCoupon(coupon_code,service_type,amount) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
                                                                
            const response = await axios.get(`${this.state.ApibaseURL}coupon/checkCoupon/${coupon_code}?service_type=${service_type}&amount=${amount}`, headers).then((response) => {
                return response
              
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }


}
export default BookingServices;
