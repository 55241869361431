import APIHandler from '../services/APICore';
import HomepageHeader from '../components/headers/Header';
import SidebarUserPanel from '../components/user/Sidebar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Bookingsdetails from '../components/Bookingsdetails';
import BookingsActivities from '../components/BookingsActivities';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import Sidebarmobile from '../components/user/Sidebarmobile';
import axios from 'axios';


const Bookingssummary = ({ service }) => {
    let { state } = useLocation()    
    const [status, setStatus] = useState("BookingSummary")
    const [apidata, setapidata] = useState([])
    const [eprescription, setepresciption] = useState([])
    const [medicalreport, setmedicalreport] = useState([])
    const [activelog, setactivelog] = useState([])
    const { id } = useParams();
    const [stype,setStype] = useState("")
    const [bookigtypeurl,setbookigtypeurl] = useState("")

    var token = localStorage.getItem('token');
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    var config = {
        headers: {
            'authorization': token
        }
    };

    const getdata = async () => {
        try {            
            const result = await axios.get(`${baseUrl}bookings/${id}`, config)           
            if(result !== null && result !== undefined){               
                setapidata(result?.data.data || {}); // Handle potential undefined result.data
            }           
        } catch (error) {
            console.error("Error fetching booking data:", error);
        }
    };

    const getPrecriptio = async () => {
        try {
            const result = await axios.get(`${baseUrl}bookings/${id}/prescriptions`, config)
            if(result !== null && result !== undefined){
                setepresciption(result?.data.data || []); // Handle potential undefined result.data
            }
        } catch (error) {
            console.error("Error fetching prescriptions:", error);
        }
    };

    const report = async () => {
        try {
            const result = await axios.get(`${baseUrl}bookings/${id}/reports`, config)
            if(result !== null && result !== undefined){
                setmedicalreport(result?.data.data || []); // Handle potential undefined result.data
            }
        } catch (error) {
            console.error("Error fetching reports:", error);
        }
    };

    const log = async () => {
        try {          
            const result = await axios.get(`${baseUrl}bookings/activities/${id}`, config)
            if(result !== null && result !== undefined){
                setactivelog(result?.data.data || []); // Handle potential undefined result.data
            }

        } catch (error) {
            console.error("Error fetching activities:", error);
        }
    };

    useEffect(() => {
        getdata();
        getPrecriptio();
        report();
        log();
        setStype(state?.type || "");
        setbookigtypeurl(localStorage.getItem('activemenu') || "");
    }, []);

    const [isVisible, setIsVisible] = useState(false);

    return (
        <>
            <div className='outerhomepage'>
                {/*Top Navbar Menu*/}
                <HomepageHeader />
                <section className=" paddbottom">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        {/* <SidebarUserPanel />  */}
                        <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                                 {isVisible ? <i class="fa-solid fa-caret-down"></i>: <i class="fa-solid fa-caret-right"></i>}
                              </button>
                            {isVisible && (
                     <div>
                     <Sidebarmobile />
                </div>
      )}

                        <SidebarUserPanel />

                        <div className="right_panel ">
                            <div class="content-wrapper" style={{ marginLeft: 0 }}>

                                <div className='content content-body'>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="content-header">
                                                <h1 class="pull-left">
                                                    Bookings ID: {id}
                                                </h1>
                                                <Link to={bookigtypeurl==="ServiceProvider"? "/serviceprovider-bookings" : "/accounts-bookings"} ><button className='btn btnbtnjoin pull-right'><i className='fa fa-angle-double-left'></i> Back To Bookings</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='spacetop'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="tab">
                                                    <button className={status === "BookingSummary" ? 'active' : ""} aria-current="true" onClick={() => setStatus("BookingSummary")}>Booking Details</button>
                                                    <button className={status === "Activities" ? 'active' : ""} onClick={() => setStatus("Activities")}>Activities</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {status === "BookingSummary" ?
                                        < Bookingsdetails apidata={apidata} />

                                        : <BookingsActivities eprescription={eprescription} medicalreport={medicalreport} activelog={activelog} stype={stype} getPrecriptio={getPrecriptio} report={report}/>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );

}
export default Bookingssummary