import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { HomepageHeader, SidebarUserPanel } from '.';
import moment from 'moment'
import { ProfileAndSlotService } from '../services';
import { ShowProfileType } from '../enums/Enums';
import CheckCircle from "../images/images/check-circle.png"
import userIcon from "../images/adminpanel/UserIcon.svg"
import AdminSidebar from './Admin/AdminSidebar';


const AdminBookingComponent = ({ selfDetails, data, isloading, setStatus, status,type,setType,service }) => {

    const apiFunction = new ProfileAndSlotService();
    const [showAddPrescription, setShowAddPrescription] = useState(false)
    const pagination = useRef();
    const [offset, setOffset] = useState(0);
    const perPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(1);
    // const [serviceProviderId, setServiceProviderId] = useState(0);
    
    const [searchVal, setSearchVal] = useState("");
    const [paginateList, setPaginateList] = useState([])
    const [userData, setUserdata] = useState({
        username: "",
        bio: "",
        profile_pic: "",
        qualification: "",
        specialization: "",
        experience: "",
        consultation_fees: "",
        video_consult: "",
        slots: [],
        patientStories: []
    })

    const filterDataBySearch = () => {
        let dataArr = data.filter(item =>
            item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            item.service_type.toLowerCase().includes(searchVal.toLowerCase())
        )
        setTotalData(dataArr.length)
        if (dataArr.length > 0) {
            const slice = dataArr.slice(offset, offset + perPage);
            setPaginateList(slice);
            setPageCount(Math.ceil(dataArr.length / perPage));
        } else {
            setPaginateList([]);
        }
    }


    // Debounce function
    const debounce = (fn, delay) => {
        let timer;
        return (() => {
            clearTimeout(timer);
            timer = setTimeout(() => fn(), delay);
        })();//ifee-->  Immediately Invoked Function Expression
    };
   
    useEffect(() => {
        if (searchVal) {
            debounce(filterDataBySearch, 1000);
        }
        else {
            loadData();
        }

    }, [searchVal])


    const loadData = () => {
        let dataArr = data
        setTotalData(dataArr.length)
        if (dataArr.length > 0) {
            const slice = dataArr.slice(offset, offset + perPage);
            setPaginateList(slice);
            setPageCount(Math.ceil(dataArr.length / perPage));
        } else {
            setPaginateList([]);
        }
    }

    useEffect(() => {
        loadData()
    }, [number, offset, data])

    const setPage = ({ selected }) => {
        setNumber(selected)
        setOffset(perPage * selected)
    }


    const loadServiceProviderProfile = (userid, serviceType) => {
        if (serviceType === ShowProfileType.type1) {
            apiFunction.LoadDoctorProfileAndSlots(userid).then(result => {
                if (result !== undefined) {

                    if (result.success === true) {
                        setUserdata({
                            username: result.data.doctor.name,
                            bio: result.data.doctor.bio,
                            profile_pic: result.data.doctor.profile_pic,
                            qualification: result.data.doctor.qualification,
                            specialization: result.data.doctor.specialization,
                            experience: result.data.doctor.experience,
                            consultation_fees: result.data.doctor.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }

            })
        } else if (serviceType === ShowProfileType.type2) {
            apiFunction.LoadNursesProfileAndSlots(userid).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.nurse.name,
                            bio: result.data.nurse.bio,
                            profile_pic: result.data.nurse.profile_pic,
                            qualification: result.data.nurse.qualification,
                            specialization: result.data.nurse.specialization,
                            experience: result.data.nurse.experience,
                            consultation_fees: result.data.nurse.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }

            })

        }
        else if (serviceType === ShowProfileType.type3) {
            apiFunction.LoadAmbulancesProfileAndSlots(userid).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.ambulance.name,
                            bio: result.data.ambulance.bio,
                            profile_pic: result.data.ambulance.profile_pic,
                            qualification: result.data.ambulance.qualification,
                            specialization: result.data.ambulance.specialization,
                            experience: result.data.ambulance.experience,
                            consultation_fees: result.data.ambulance.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }
            })
        }

    }



    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            {/* <HomepageHeader /> */}
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <AdminSidebar/>
                        <div className="right_panel table-responsive" style={{ marginTop: "20px" }}>
                            <div className='col-md-12' style={{ marginBottom: "22px" }}>
                                <div class="tab pull-right">
                                    {
                                        service &&
                                        service.map((item) => (
                                            <button onClick={(e) => setType(item.service)}
                                                className={type === item.service ? 'active'
                                                    : ""}>
                                                {item.service}
                                            </button>
                                        ))

                                    }
                                </div>

                            </div>
                            <div>
                                <h2 className='ms-auto-Dashboard'>Dashboard</h2>
                            </div>
                            <p>Total Summary</p>
                            <table>
                                <td className='ms-5'>Open Bookings</td>
                                <td className='ms-5'> Confermed bookings</td>
                                <td className='ms-5'>Compleated Bookings</td>
                                <td className='ms-5'>Revenue</td>
                            </table>
                            {/* <table className="table mytable">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '12.5%' }}>Booking Id</th>
                                        <th style={{ width: '12.5%' }}>Date/Time</th>
                                        <th style={{ width: '12.5%' }}>{selfDetails ? "Name" : "Patient Details"}</th>
                                        <th style={{ width: '12.5%' }}> {selfDetails ? "Service Type" : "For"}</th>
                                        <th style={{ width: '12.5%' }}>Appointment Date</th>
                                        <th style={{ width: '12.5%' }}>Fee</th>
                                        <th style={{ width: '12.5%' }}>View</th>
                                        <th style={{ width: '12.5%' }}></th>
                                    </tr>
                                    {paginateList.length === 0 ? <tr><td colSpan={8}>No records found</td></tr> :
                                        paginateList.map(item =>
                                        (
                                            <tr>
                                                <td>
                                                    <div className="userdtl">
                                                        <div className="userinfo">
                                                            {item.id}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="bookedat">
                                                        <p>{(moment(item.booking_date).utc()).format("DD/MM/YYYY")} </p>
                                                        <span>
                                                            {(moment(item.booking_date).utc()).format("hh:mm a")}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.service_type
                                                    }
                                                </td>
                                                <td>
                                                    <div className="bookedat">
                                                        <p>{(moment(item.appoinment_date).utc()).format("DD/MM/YYYY")} </p>
                                                        <span>
                                                            {(moment(item.appoinment_date).utc()).format("hh:mm a")}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="amount_td">
                                                        <div>
                                                            <p>&#x20B9; {item.total_amount}</p><span>{item.status}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {selfDetails ?
                                                        <button type="button" onClick={() => loadServiceProviderProfile(item.service_provider_id, item.service_type)} class="btn btn-info btn-sm" data-toggle="modal" data-target="#viewServiceProviderDetailsPopup">View</button>
                                                        :
                                                        <button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target="#viewPatientDetailsPopup">View</button>
                                                    }
                                                </td>
                                                <td>
                                                    {selfDetails ? <>
                                                        {status !== "Completed" && <button type="button" class="btn btn-info btn-sm" >Cancel</button>}
                                                    </>

                                                        :
                                                        <button type="button" class="btn btn-info btn-sm" >Acknowledged</button>
                                                    }


                                                </td>
                                            </tr>
                                        )
                                        )}
                                </tbody>
                            </table> */}
                            {/* <>
                                {totalData > 10 &&
                                    <div className='mypagination'>
                                        <ReactPaginate
                                            ref={pagination}
                                            pageCount={pageCount}
                                            pageRangeDisplayed={4}
                                            marginPagesDisplayed={0}
                                            onPageChange={setPage}
                                            activeClassName="active"
                                            containerClassName="pagination_ul"
                                            pageClassName="page-item"
                                            breakClassName="page-item"
                                            nextClassName="page-item"
                                            previousClassName="page-item"
                                            breakLabel="..."
                                            previousLabel={<>Prev</>}
                                            nextLabel={<>Next</>}
                                        />
                                    </div>
                                }
                            </> */}
                        </div>
                    </div>
                </div>
            </section >

            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}
            
            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}
            

        </div >
    )
}

export default AdminBookingComponent;