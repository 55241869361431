import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate,useLocation } from 'react-router'
import { FaqsSections, Footer, HomepageHeader } from '../../components'
import LocationIcon from "../../images/home/map-pin.png"
import SearchIcon from "../../images/home/search.png"
import { Cities as CitiesList, ShowProfileType } from '../../enums/Enums'
import HomecareBanner from "../../images/images/homecare_bnr.png"
import HomeCareServices from '../../services/HomeCareServices'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import CheckImg from "../../images/images/check.png"
import SelectSpeciallityImg from "../../images/howItWorks/hiw1.png"
import ImageNotAvailable from "../../images/images/image_not_available.png"
import AudioVideoImg from "../../images/howItWorks/hiw2.png"
import getDigitalPrescriptionImg from "../../images/howItWorks/hiw3.png"
import SearchServices from '../../services/SearchServices';
import { toast } from 'react-toastify';



const NursingHomeCare = () => {

    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const apifunction = new SearchServices();
    const { locationState, searchKeyState,nurseServiceTypeState, visitTypeState,homecareNameState, pageType } = state;
    const [Cities] = useState(CitiesList)
    const [isloading, setisloading] = useState(false)
    const [searchKey, setSearchkey] = useState("")
    const [searchList, setSearchList] = useState([])
    const [searchagain, setsearchagain] = useState("")

    useEffect(() => {
        getResultsBaseOnSearch(pageType, searchagain, locationState,nurseServiceTypeState, visitTypeState,homecareNameState)
        setisloading(true)
        window.scrollTo(0, 0);
    }, [searchagain])
    


    const getResultsBaseOnSearch = (pageType, searchKey, locationState,nurseServiceType,visitType,homecareName) => {
        setsearchagain(searchKey)
        const user_id = localStorage.getItem("userid")
        // making search url base on search

        let apiurl = "search/homecare?search_key=" + searchKeyState + "&location=" + locationState +
            "&nurse_service_type=" + nurseServiceType +"&homecare_name=" + homecareName + "&visit_type=" + visitType + "&user_id=" + user_id

        apifunction.searchResultFunc(apiurl).then(res => {
            const result = res
            if (result !== undefined) {
                if (result.success === true) {
                    setSearchList(result.data)             
                }
                setisloading(false)
            }
            
        })
    }

   
    const navigateTo = (nurse_user_id,service_provider_id,name,city,homecareServices,experience,degree,profile_pic) => {
        const user_id = localStorage.getItem("userid")
        if (user_id === null || user_id === undefined || user_id === "") {
            toast.error("Please login first")
            return navigate('/login', { state: { from: '/nursing-home-care' } });
           
        }

        return navigate('/nursing-home-care-bing', {
            state: {
                nurseUserId: nurse_user_id,
                service_provider_id: service_provider_id,
                nurseName: name,
                city: city,
                homecareServices: homecareServices,
                qualification: degree,
                specialization:homecareServices,
                profileId: localStorage.getItem("userid"),
                profileType: 'Nurse',
                experience: experience,
                profile_pic: profile_pic,
                visitType: visitTypeState,
                nurseServiveType: nurseServiceTypeState,
            }       

        });
        
    }



    return (
        <div>
            <HomepageHeader />
            <div className="homecare-banner">
                <div className="container mt-5">
                    <div className='col-md-12 '>
                        {/* <div class="searchbox">
                            <div class="locality">
                                <span class="mapicon">
                                    <img src={LocationIcon} />
                                </span>
                                <input type="text" class="form-control" placeholder="Location" value="Bhopal" />
                            </div>
                            <div class="cardSuggetionsLocation">
                                <div class="displayNoneCls">
                                    <ul class="showing-Location">
                                        <li class="useMyLocation">
                                            <img src={SearchIcon} alt="usemylocatioonIcon" />
                                            Use Location
                                        </li>
                                        <li>Bhopal</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="vline"></div>
                            <div class="keyword">
                                <span class="searchicon">
                                    <img src="" />
                                </span>
                                <form><input type="text" class="form-control" placeholder="Enter Name / Service" value="" /></form>
                            </div>
                        </div> */}
                        <h4 type="text" class="text-center" ><span className='home-care-type-text'>City={locationState}, </span><span className='home-care-type-text'>Homecare Name={homecareNameState}, </span><span className='home-care-type-text'>Nurse service Type={nurseServiceTypeState},</span>  <span className='home-care-type-text'>Homecare Visit Type={visitTypeState}</span> </h4>
                    </div>
                    <br />

                    <div className="col-md-6 col-md-offset-3">
                        <h2 className='heaading-type-nursing'>{searchList.length} {nurseServiceTypeState} Nurse available in your area</h2>
                    </div>
                    <div className="col-md-8 col-md-offset-2">
                        <div className='search_wraper paddtop'>
                            {isloading === false ?
                                <>
                                    {searchList.length > 0 ? searchList.map(item => (
                                        <div class="search_list bg_light">
                                            <div class="left_block">
                                                <div class="userIcon_photo">
                                                    {(item.profile_pic)? <img src={item.profile_pic} alt='' className='nursing-img-icon' />:<img src="/images/user.png" alt='' className='nursing-img-icon' />}
                                                </div>
                                                <div class="doctor_bio">
                                                    <h3 class="dr_name">{item.name}</h3>
                                                    <p class="dr_quali">{item.homecare_name}</p>
                                                    <p class="dr_exp">{item.experience} Years</p>
                                                    <p class="dr_address">{item.city} {item.state}</p>
                                                </div>
                                            </div>
                                            <div class="right_block">
                                                <div class="fees">{item.homecareServices ? item.homecareServices.per_visit_charge : 0}</div>
                                                <div class="available"></div>
                                                <button onClick={() => navigateTo(item.id,item.service_provider_id,item.name,item.city,item.homecareServices,item.experience,item.degree,item.profile_pic)} class="btn btn-info">Book Appointment</button>
                                            </div>
                                        </div>
                                    ))
                                        :
                                        "No records found"
                                    }
                                </>

                                :
                                <>
                                    <h1>Loading...</h1>
                                </>
                            }

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    )
}

export default NursingHomeCare
