import React, { useEffect } from 'react'
import { Footer, HomepageHeader } from '../../components'
import about_bnr from "../../images/about/about_bnr.png"
import care from "../../images/about/Care.png"
import trust from "../../images/about/trust.png"
import excellence from "../../images/about/excellence.png"
import ctm1 from "../../images/images/ctm1.png"
import ctm2 from "../../images/images/ctm2.png"
import ctm3 from "../../images/images/ctm3.png"
import linkedinOutlined from "../../images/images/linkedin-outlined.png"
import { useState } from 'react'


const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])




    return (
        <>
            <HomepageHeader />
            {/*Inner Page Banner*/}
            <section className="banner_inner">
                <div className="container">
                    <div className="card_flex">
                        <div className="card_text">
                            <h1>About FastTrack</h1>
                            <h2>We are a community of India's top doctors, caregivers, and ambulance owners working together to make healthcare accessible to all</h2>
                        </div>
                        <div className="card_img">
                            <div className="dr_img"><img src={about_bnr} /></div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Mission & Vision */}
            <section className="mv_sec paddtop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <div className="ab_mvCard">
                                <h3 className="heading2 text-center">Our Mission</h3>
                                <div className="ab_whiteCard">
                                    <p className="para2 mt0">Our mission is to use innovative solutions and latest technology to bring healthcare directly to you, whether you're at home, work, or on-the-go. We believe that medical care should be accessible to everyone, anytime, and anywhere, and we're dedicated to making that a reality with FastTrack.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="ab_mvCard">
                                <h3 className="heading2 text-center">Our Vision</h3>
                                <div className="ab_whiteCard">
                                    <p className="para2 mt0">Our vision it to provide best-quality medical aid which is accessible, cost-effective, and dedicated to patient’s safety and satisfaction. Its our believe that everyone deserves the quality medical care, regardless of their background, financial status, or location.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='mb40'>
                {/* Our values */}
                <section className="values_sec paddtop ">
                    <div className="container">
                        <div className="row">
                            <h3 className="heading2 text-center paddbottom">Our Values</h3>
                            <div className="col-lg-4 col-sm-4">
                                <div className="values_categ ">
                                    <img src={trust} alt="trust" />
                                    <h4>Trust</h4>
                                    <p className="para2">While serving our patients, we not only aim to receive positive feedback but also strive to establish a bond of trust.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-4">
                                <div className="values_categ ">
                                    <img src={excellence} alt />
                                    <h4>Excellence</h4>
                                    <p className="para2">We believe that excellence is achieved through a commitment to ongoing learning, growth, and improvement. </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-4">
                                <div className="values_categ ">
                                    <img src={care} alt />
                                    <h4>Care</h4>
                                    <p className="para2">We believe that true care involves treating the whole person, not just their medical condition.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


              
            </div>
            <Footer />




        </>
    )
}

export default About