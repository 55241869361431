import React, { useEffect, useState } from 'react'
import { FaqsSections, Footer, HomepageHeader } from '../../components'
import slide01 from "../../images/login/login_slide01.svg"
import slide02 from "../../images/login/login_slide02.svg"
import slide03 from "../../images/login/login_slide03.svg"
import OtpInput from "react-otp-input";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { OnboardUser, RegisterForServicePartner } from '../../services';
import HeaderLogin from '../../components/headers/HeaderLogin'

// description:- last updated 20-06-22 Monday
// description:- By Mayur
const OtpScreen = () => {


    const navigate = useNavigate()
    const [inputOtp, setInputOtp] = useState("")
    const [mobileOrEmail, setMobileOrEmail] = useState("")
    const [is_professional, setIs_professional] = useState(false)
    const handleChange = (otp) => setInputOtp(otp)
    const apifunction = new OnboardUser();
    const apifunction2 = new RegisterForServicePartner();
    const [timerCounter, setTimerCounter] = useState(59)
    const [isReadytoResendOtp, setIsReadytoResendOtp] = useState(false)


    useEffect(() => {
        const timer =
            timerCounter > 0 ?
                setInterval(() => setTimerCounter(timerCounter - 1), 1000)
                :
                setIsReadytoResendOtp(true);
        return () => clearInterval(timer);
    }, [timerCounter]);

    const reSendOtpFunc = () => {
        setTimerCounter(59);
        const data = {
            mobile: localStorage.getItem("mobileOrEmail"),
            is_professional: is_professional
        }
        apifunction.LoginFunc(data).then(res => {
          
            if (res !== undefined) {
                var result = res
              
                if (result != null) {

                    localStorage.setItem("mobileOrEmail", mobilenumber)

                    if (result.data.completed_step >= 4) {
                       // setIfShowPasswordField(true)
                        navigate("/validate-otp")
                    } else {
                        navigate("/validate-otp")
                        toast.success(result.message)
                        return true
                    }
                } else {
                    return false
                }
            }
            else {
                return false
            }
        })
        toast.success("We again send a OTP successfully")
        setIsReadytoResendOtp(false);
    }


    const verifyOtp = (e) => {
        e.preventDefault();

        if (inputOtp.length <= 3) {
            return toast.error("Enter four digit otp only")
        } else {
            const data = {
                mobile: localStorage.getItem("mobileOrEmail"),
                otp: inputOtp
            }
            apifunction.OtpVerifyFunc(data).then(res => {
                if (res !== undefined) {
                    var result = res
                    
                    if (result.success === true) {
                          const profile_pic = result.data.user.profile_pic
                          localStorage.setItem("profile_pic", profile_pic)
                        const userid = result.data.user.id
                        const accessToken = result.data.token
                        localStorage.setItem('token', accessToken)
                        const mobileNumber = result.data.user.mobile
                        const stepCompleted = result.data.user.completed_step
                        const username = result.data.user.name
                        const useremail = result.data.user.email
                        const userType = result.data.user.user_type
                        const gender = result.data.user.gender
                       
                      

                        // If want to make service partner
                        const fromWhichPartnerPageComes = sessionStorage.getItem("fromWhichPartnerPageComes")
                        const dataforMakePartner = {
                            service: fromWhichPartnerPageComes
                        }
                        if (fromWhichPartnerPageComes)
                            apifunction2.MakeServicePartnerFirstStep(userid, dataforMakePartner).then();
                        

                        if (stepCompleted <= 2) { //user password not given
                            localStorage.setItem("userid", userid)
                            localStorage.setItem("user_mobile", mobileNumber)
                            localStorage.setItem("stepCompleted", "2")
                           // return navigate("/set-password")
                         
                            return navigate("/fill-details")
                        }
                        else if (stepCompleted === 3) { //Details not set fill-details
                           
                            localStorage.setItem("stepCompleted", "3")
                            localStorage.setItem("userid", userid)
                            localStorage.setItem("user_mobile", mobileNumber)
                            localStorage.setItem("user_name", username)
                            localStorage.setItem("user_email", useremail)
                            localStorage.setItem("user_type", userType)
                            localStorage.setItem("gender",gender)
                           // return navigate("/fill-details")
                        }
                        else if (stepCompleted === 4) {   //All done
                           
                            localStorage.setItem("stepCompleted", "4")
                            localStorage.setItem("userid", userid)
                            localStorage.setItem("user_mobile", mobileNumber)
                            localStorage.setItem("user_name", username)
                            localStorage.setItem("user_email", useremail)
                            localStorage.setItem("user_type", userType)
                            localStorage.setItem("user_dob", result.data.user.dob)
                            localStorage.setItem("gender",result.data.user.gender)
                            localStorage.setItem("services", JSON.stringify(result.data.services))
                            if(result.data.user.dob!==undefined && result.data.user.dob!==null &&result.data.user.dob!=="")
                            {
                                if (result.data.services.length > 0)
                                navigate('/providerdashboard')
                             else
                                return navigate("/my-dashboard")
                            }
                            else{
                                return navigate("/doctor-profile")

                            }
                           
                        }
                    }
                }
            })
        }
    }
    return (
        <div className="login_page">
        <HomepageHeader />
        {/* <HeaderLogin/> */}
            <div className="login_left">
                <div className="left_img">
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        {/* Indicators */}
                        <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to={0} className="active" />
                            <li data-target="#myCarousel" data-slide-to={1} />
                            <li data-target="#myCarousel" data-slide-to={2} />
                        </ol>
                        {/* Wrapper for slides */}
                        <div className="carousel-inner">
                            <div className="item active">
                                <img src={slide01} alt style={{ width: '100%' }} />
                            </div>
                            <div className="item">
                                <img src={slide02} alt style={{ width: '100%' }} />
                            </div>
                            <div className="item">
                                <img src={slide03} alt style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="login_right">
                <div className="login_right_content">
                    <form role="form" className="otpform" onSubmit={(e) => verifyOtp(e)}>
                        <h2 className="title">Enter the OTP</h2>
                        <OtpInput
                            inputStyle="paddingOfInput"
                            containerStyle="spaceInputBox"
                            value={inputOtp}
                            onChange={handleChange}
                            numInputs={4}
                            separator={<span>  </span>}
                            isInputSecure={true}
                        />
                        <div className="mt30 text-center">
                            <p className="otp_msg">Please enter the OTP</p>
                            <div className="mt40">
                                <button type='submit' className="btn btn_fill">Submit</button>
                                <div className="resetlink ">Haven't recived any OTP ?
                                    <button type='button' onClick={() => reSendOtpFunc()} disabled={isReadytoResendOtp ? false : true}>
                                        Resend
                                    </button>in <br />
                                    {timerCounter} second</div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default OtpScreen