import React, { useEffect } from 'react'
import { Footer, HomepageHeader } from '../../components'
import { useState } from 'react'
import {Link} from "react-router-dom"
import { useNavigate } from 'react-router'


const Pricing = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])




    return (
        <>
            <HomepageHeader />
            <section className="banner_inner lohwheight">
                <div className="container">
                    <h1>Pricing</h1>
                </div>
            </section>
<section className="pricing py-5">
    <div className="container"> 
    <div className='row'>
        

        <div className='col-lg-9'>
             <div className='pricing-heading-content'>
                  <h4>Teleconsultation</h4>
                  <p>Price from ₹ 300 to ₹ 1000 per consult</p>
                </div> 
        </div>
        <div className='col-lg-3'>
        <div className='pricing-heading-content'>
            <Link class="btn btn_fill pricing" to={"/tele-consultation"}>Book Teleconsultaion</Link>
            </div>
            </div>
    </div>

            <div className='row'>
            <div className='col-lg-9'>
             <div className='pricing-heading-content'>
                  <h4>Home Care Services</h4>
                  <p>Price from ₹ 300 to ₹ 700 per hour</p>
                </div> 
        </div>
        <div className='col-lg-3'>
        <div className='pricing-heading-content'>
            <Link class="btn btn_fill pricing" to={"/homecare"}>Book Home Care</Link>
            </div>
            </div>


            </div>
            <div className='row'>
            <div className='col-lg-9'>
             <div className='pricing-heading-content'>
                  <h4>Ambulance</h4>
                  <p>Price from ₹ 300 per hour</p>
                </div> 
        </div>
        <div className='col-lg-3'>
        <div className='pricing-heading-content'>
            <Link class="btn btn_fill pricing" to={"/ambulance"}>Book Ambulance</Link>
            </div>
            
            </div>
         
    </div>
    </div>
    </section>
         


            <Footer />
        </>
    )
}

export default Pricing  