import React, { useEffect, useState } from 'react'
import { HomepageHeader } from '../../components'
import SidebarUserPanel from '../../components/user/Sidebar'

import moment from 'moment'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
const baseurl = process.env.REACT_APP_API_BASE_URL
import { useParams } from 'react-router-dom';
import { regHtml, regMobile } from '../../helpers/Validate'
import { toast } from 'react-toastify'
import { BookingServices } from '../../services'
import {
    err_10DigitMobile,
    err_NotFullName,
    err_NotMobile,
    HtmlErrorMessage
} from '../../enums/Enums'
import * as process from "process";
import axios from 'axios'
// import PayTmGatewayPage from './PayTmGatewayPage'

const BookingSummaryAmbulance = () => {
    let token = localStorage.getItem('token')
    const [isVisible, setIsVisible] = useState(false);

    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    }
    let { state } = useLocation()
    const location = useLocation()
    const pathName = location.pathname
    const [status, setStatus] = useState("BookingSummary")
    const [apidata, setapidata] = useState([])
    const { id } = useParams();
    const [stype, setStype] = useState("")
    const [bookigtypeurl, setbookigtypeurl] = useState("")

    const getdata = async () => {
        var config = {

            headers: {
                'authorization': token
            }
        };
        const result = await axios.get(`${baseurl}/ambulances/bookings/${id}`, config)
        setapidata(result.data.data)

    }
    useEffect(() => {
        getdata();
        setbookigtypeurl(localStorage.getItem('activemenu'))
    }, [])

    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}

            {/*Top Navbar Menu*/}
            <HomepageHeader />

            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        {/* <SidebarUserPanel /> */}
                        <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                            {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
                        </button>
                        {isVisible && (
                            <div>
                                <Sidebarmobile />
                            </div>
                        )}

                        <SidebarUserPanel />


                        <section class="right_panel">

                            <div class="">
                                <div className='profile_body'>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className='btn btn-info '>Booking summary</div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='text-right'>
                                                <Link to="/" >
                                                    <button className='btn btnbtnjoin pull-right'>
                                                        <i className='fa fa-angle-double-left'></i>
                                                        Back To Bookings
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <h4 className='spacetop'>Booking Id :{apidata.booking_id}</h4>


                                    <div className="row">
                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Service</label> <br />
                                                    <input type="text" class="form-control" value={apidata.service_type} placeholder="" disabled={true} />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Status</label> <br />
                                                    <input type="text" class="form-control" placeholder="" value={apidata.status} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class=" fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label"> Booking Date & Time</label>
                                                    <input type="text" class="form-control" placeholder="" value={(moment(apidata.appointment_date).utc()).format("DD/MM/YYYY") + "," + (moment(apidata.appointment_date).utc()).format("hh:mm a")} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Fee Paid</label> <br />
                                                    <input type="text" class="form-control" placeholder="" value={apidata.paid_amount} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-lg-12"><h4 class="page_title">Service Provider</h4>  </div>
                                        <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.provider_name} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Address</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.service_provider_address} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Driver Name</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.driver_name} disabled={true} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3">
                                            <div class=" fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Driver Phone number</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.mobile} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-lg-12"><h4 class="page_title">User Details</h4>  </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Booked By</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.booked_by} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Address</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.user_address} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.user_email} disabled={true} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.other_mobile} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Booked Ambulance Type</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.ambulance_type} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-lg-12"><h4 class="page_title">Route Info</h4>  </div>
                                        <div className="col-lg-3">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Pick Up location</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.pickup_address} disabled={true} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Drop off Location</label>
                                                    <input type="text" class="form-control" placeholder="" value={apidata.destination_address} disabled={true} />
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </section>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default BookingSummaryAmbulance