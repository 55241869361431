import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

import React, { useEffect, useState } from 'react'
import { HomepageHeader } from '../../components'
import VideoIcon from "../../images/images/vc_icon.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import moment from 'moment'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { regHtml, regMobile } from '../../helpers/Validate'
import { toast } from 'react-toastify'
import { BookingServices } from '../../services'
import {
  err_10DigitMobile,
  err_NotFullName,
  err_NotMobile,
  HtmlErrorMessage
} from '../../enums/Enums'
import * as process from "process";
import { set } from 'date-fns';



const BookAmbulance = () => {
  // Owl carousel Options you manage item count for different screen sizes
  const options = {
    margin: 1,
    className: "owl-theme",
    responsiveClass: true,
    nav: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 100,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      }
    },
  }

  const apiFunction = new BookingServices();

  const { state } = useLocation();
  const navigateto = useNavigate();

  const merchantId = process.env.REACT_APP_PayTM_MID

  const paytmOpenPaymentPageUrl = "https://securegw.paytm.in/theia/api/v1/showPaymentPage"

  const { ambulanceId, service_provider_id, agency_nameState, equipped_withState, advance_charge,
    advance_chargeState, vehicle_modelState, vehicle_numberState, charge_per_kmState,
    pickUp_addressState, drop_addressState, profileType, profileId, ambulance_typeState,
    vehicle_makeState, ratingState, appointmentDateState, appointmentTimeState, ambulace_picState, interior_imageState, exterior_imageState } = state;
  const [payable_amount, setPayable_amount] = useState(advance_chargeState)
  const [isChecked, setIsChecked] = useState(false)
  const [couponList, setCouponList] = useState([])
  const [couponDetails, setCouponDetails] = useState({})
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [bookingType, setBookingType] = useState('New')
  const [bookAndPayForm, setbookAndPayForm] = useState({
    user_id: "",
    booking_date: "",
    service_provider_id: '',
    service_type: "",
    booked_for: "",
    total_amount: 0,
    other_name: "",
    other_mobile: "",
    short_description: "",
    paid_amount: 0,
    isSomeOneElse: false,
    appointment_date: '',
    appointment_time: "",
    profile_pic: "",
    pickup_address: "",
    destination_address: "",
    ambulance_booking_type: "",
    ambulance_type: "",
    ambulance_id: "",
    applied_coupon: "",
    coupon_discount: 0,


  })
  const { user_id, booking_date,
    service_type, booked_for,
    total_amount, other_name, other_mobile, short_description, applied_coupon, coupon_discount,
    appointment_date, appointment_time,
    paid_amount, isSomeOneElse, pickup_address, ambulance_id, ambulance_type, destination_address } = bookAndPayForm;

  /* const onInputChange = e => {
    setbookAndPayForm({
      ...bookAndPayForm, [e.target.name]:
        e.target.name === "other_mobile" ?
          // Check if not number it will return nothing only for mobile
          e.target.value.match(regMobile) ? e.target.value : ""
          : e.target.value
    })
  } */

  useEffect(() => {
    sessionStorage.setItem("notLoggedInBookingFlowUrlDetails", "");
    setbookAndPayForm({
      ...bookAndPayForm,
      user_id: localStorage.getItem('userid'),
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      service_type: profileType,
      booked_for: isSomeOneElse ? "other" : "self",
      total_amount: payable_amount,
      other_name: other_name,
      other_mobile: other_mobile,
      short_description: "",
      paid_amount: 0,
      isSomeOneElse: isSomeOneElse,
      service_provider_id: service_provider_id,
      appointment_date: appointmentDateState,
      appointment_time: appointmentTimeState,
      pickup_address: pickUp_addressState,
      destination_address: drop_addressState,
      ambulance_type: ambulance_typeState,
      ambulance_id: ambulanceId,
      ambulance_booking_type: bookingType,
      applied_coupon: applied_coupon,
      coupon_discount: coupon_discount,
      //booking_type: bookingType,


    })
    getCouponList()
    setCouponDetails({})
  }, [])



  useEffect(() => {
    if (isSomeOneElse === true) {
      setbookAndPayForm({
        ...bookAndPayForm,
        other_name: "",
        other_mobile: "",
      })
    } else {
      setbookAndPayForm({
        ...bookAndPayForm,
        other_name: localStorage.getItem("user_name"),
        other_mobile: localStorage.getItem("user_mobile")
      })
    }
  }, [isSomeOneElse])

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }

  function isObj(val) {
    return typeof val === 'object'
  }


  // For Paytm Stringify input field values
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }


  // Making a custom form by this code
  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)

    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })
    return form
  }

  function goToPaymentPage_Post_Information(details) {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
  }



  const bookAndPay = (e) => {
    e.preventDefault();
    if (!other_name?.trim()) {
      return toast.error(err_NotFullName)
    }
    else if (regHtml.test(other_name?.trim())) {
      return toast.error(HtmlErrorMessage)
    }
    else if (!other_mobile) {
      return toast.error(err_NotMobile)
    }
    else if (payable_amount <= 0) {
      return toast.error("Amount should be greater than 0")
    }
    else if (other_mobile.length !== 10) {
      return toast.error(err_10DigitMobile)
    } else {
      const data = {
        user_id: localStorage.getItem("userid"),
        booking_date: moment(new Date()).format("YYYY-MM-DD"),
        service_type: profileType,
        booked_for: isSomeOneElse ? "other" : "self",
        total_amount: payable_amount,
        other_name: other_name,
        other_mobile: other_mobile,
        remark: short_description,
        paid_amount: 0,
        isSomeOneElse: isSomeOneElse,
        service_provider_id: service_provider_id,
        appointment_date: appointmentDateState,
        appointment_time: appointmentTimeState,
        pickup_address: pickUp_addressState,
        destination_address: drop_addressState,
        ambulance_type: ambulance_typeState,
        ambulance_id: ambulanceId,
        ambulance_booking_type: bookingType,
        applied_coupon: applied_coupon,
        coupon_discount: coupon_discount,

      }

      apiFunction.BookAndPayForAmbulance(data).then(result => {

        if (result !== undefined) {
          if (result.success === true) {
            localStorage.setItem("BookingId", result.data.id)
            const datanew = {
              amount: result.data.total_amount,
              requestfrom: "WEB",
              booking_id: result.data.id
            }
            apiFunction.PaymentOrder(datanew).then(result => {
              if (result !== undefined) {
                if (result.success === true) {

                  const transaction_token = result.data.transaction_token
                  const order_id = result.data.order_id

                  var information = {
                    action: `${paytmOpenPaymentPageUrl}?mid=${merchantId}&orderId=${order_id}`,
                    params: {
                      mid: merchantId,
                      orderId: order_id,
                      txnToken: transaction_token,
                      TXN_AMOUNT: total_amount / 100,
                      CURRENCY: "INR",
                      WEBSITE: "WEBSTAGING"
                    }
                  }

                  localStorage.setItem("paymentAmount", total_amount)
                  // this is function that show's paytm gate way
                  goToPaymentPage_Post_Information(information)
                }
              }
            })
          }

        }
      })

    }
  }

  const getCouponList = () => {
    apiFunction.couponList('Ambulance').then(result => {
      if (result !== undefined) {
        if (result.success) {
          setCouponList(result.data)
        }
      }
    })
  }
  const getCouponById = async (id) => {
    apiFunction.couponDetailsById(id).then(result => {
      if (result !== undefined) {
        if (result.success) {
          setCouponDetails(result.data)
        }
      }
    })
  }

  const handleCouponSelect = async (event) => {
    const selectedCouponId = parseInt(event.target.value);
    // Filter the couponList to find the selected coupon by id
    const selectedCouponDetails = couponList.find((coupon) => coupon.id === selectedCouponId);

    // Set the filtered coupon object in setCouponDetails
    setCouponDetails(selectedCouponDetails);


    if (selectedCouponDetails.minimum_amount > payable_amount) {
      return toast.error(`Minimum amount should be ${selectedCouponDetails.minimum_amount}`)
    }

    //calling api to validate coupon
    apiFunction.ValidateCoupon(selectedCouponDetails.coupon_code, 'Ambulance', payable_amount).then(result => {
      if (result !== undefined) {
        if (result.success) {
          // update total amount after discount total_amount - discount_amount
          setPayable_amount(payable_amount - selectedCouponDetails.total_discount)
          // set the appled coupon and coupon discount in the booking form
          setbookAndPayForm({
            ...bookAndPayForm,
            applied_coupon: selectedCouponDetails.coupon_code,
            coupon_discount: selectedCouponDetails.total_discount
          })
          setSelectedCoupon(selectedCouponId);
          toast.success(result.message)
        } else {
          toast.error(result.message)
        }
      }

    })

  }

  const handleClearCouponSelection = () => {
    // Reset the coupon-related state and update total_amount without the coupon discount
    setSelectedCoupon(null); // Deselect the coupon  
    setPayable_amount(payable_amount + couponDetails.total_discount)
    setbookAndPayForm({
      ...bookAndPayForm,
      applied_coupon: "",
      coupon_discount: 0
    })
    setCouponDetails(null); // Reset the coupon details
  };



  const handleCheckBox = () => {
    const user_id = localStorage.getItem("userid")
    if (!user_id) {
      toast.error("Please Login First")
      navigateto('/login')
    }
    if (isChecked) {
      setIsChecked(false)
    } else {
      setIsChecked(true)
    }
  }

  const [activeImage, setActiveImage] = useState(ambulace_picState)




  return (
    <div>
      <HomepageHeader />
      <section class="paddtop paddbottom">
        <div class=" margin-my-50">
          <div class="booking_flex">
            <div class="right_itme padding-20">
              <div className=''>
                <div className='display-flex '>
                  <div className='als-images'>
                    <img src={activeImage || '/images/ambulance-image-ab.jpg'} className='book-ambulance-image' />

                    <div className='carousel-width'>
                      <OwlCarousel>
                        <div className='item'>
                          <img src={ambulace_picState || '/images/ambulance-image-ab.jpg'} className='book-ambulance-image book-ambulance-img-fixed-width' onClick={() => setActiveImage(ambulace_picState)} />
                        </div>
                        <div className='item'>
                          <img src={interior_imageState || '/images/ambulance-image-ab.jpg'} className='book-ambulance-image book-ambulance-img-fixed-width' onClick={() => setActiveImage(interior_imageState)} />
                        </div>
                        <div className='item'>
                          <img src={exterior_imageState || '/images/ambulance-image-ab.jpg'} className='book-ambulance-image book-ambulance-img-fixed-width' onClick={() => setActiveImage(exterior_imageState)} />
                        </div>

                      </OwlCarousel>
                    </div>

                  </div>
                  <div>
                    <div className='book-address'>
                      <div className='addd-to'>
                        <img src='/images/Screenshot_3-removebg-preview.png' alt='' className='icon-p-up' />
                      </div>
                      <div className='addd-to'>
                        <span className='p-up'>Pick-up address</span> <br />
                        <span className='ad-name'>{pickUp_addressState} </span>
                      </div>
                    </div>
                    <div className='center-line'></div>
                    <div className='book-address'>
                      <div className='addd-to'>
                        <img src='/images/Screenshot_4-removebg-preview.png' alt='' className='icon-p-up' />
                      </div>
                      <div className='addd-to'>
                        <span className='p-up'>Drop-off address</span> <br />
                        <span className='ad-name'>{drop_addressState}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div className='equipped'>
                  <h4 className='p_up_sub_title'>Equipped with</h4>
                  <ul>
                    {equipped_withState.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>


            </div>
            <div class="left_itme ">
              <div className='bg_light padding-3'>
                <h4 class="bk_sub_title pt20"> <img src='/images/bls-removebg-preview.png' className='book-ambulance-image-1000' /> Book Ambulance</h4>
                <hr />
                <div class="user-name-booking">
                  <p class="">Ambulance service company </p>
                  <span>{agency_nameState}</span> <br></br>
                  {/* <span>
                  <p class="dr_quali display-flex">
                    Rating
                    <i class={`fa-solid fa-star ${ratingState >= 1 ? 'yellow' : 'gray'}`}></i>
                    <i class={`fa-solid fa-star ${ratingState >= 2 ? 'yellow' : 'gray'}`}></i>
                    <i class={`fa-solid fa-star ${ratingState >= 3 ? 'yellow' : 'gray'}`}></i>
                    <i class={`fa-solid fa-star ${ratingState >= 4 ? 'yellow' : 'gray'}`}></i>
                    <i class={`fa-solid fa-star ${ratingState >= 5 ? 'yellow' : 'gray'}`}></i>
                    ({20})
                  </p>
                </span> */}
                  <span>{ }</span> <br />

                  <span>{vehicle_makeState}</span> <br />
                  <span>{vehicle_numberState}</span>    <br />

                </div>
                <hr />

                {couponList && couponList.length > 0 && (
                  <>
                    <h5 className='mb-5'>Applicable Coupons</h5>
                    {couponList && couponList.length > 0 && (
                      <div className='under-box'>
                        <h6>Apply Coupon</h6>
                        {couponList.map((item) => (
                          <div key={item.id} className="display-flex-t">
                            <input
                              type="radio"
                              style={{ cursor: 'pointer' }}
                              id={`test${item.id}`}
                              name="radio-group"
                              value={item.id}
                              checked={selectedCoupon === item.id}
                              onChange={handleCouponSelect}
                            />
                            <div className="div-f">
                              <label htmlFor={`test${item.id}`} className="line-height">
                                {item.coupon_code}
                              </label>
                              <p>Save ₹ {item.total_discount}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {selectedCoupon && (
                      <button onClick={handleClearCouponSelection}>Remove selected coupon</button>
                    )}
                  </>
                )}
                <hr />


                <div class="belowbtn">
                  <div class="cons_fee"><span class="consl">Bill Details</span><span class="cons2"></span></div>
                  <div class="cons_fee"><span class="consl">
                    Advance Booking charge</span><span class="cons2">{advance_chargeState}</span></div>

                  <div class="cons_fee"><span class="consl">Ambulance Charge</span><span class="cons2">0</span></div>
                  <div class="cons_fee"><span class="consl">  Coupon Discount</span><span class="cons2">{coupon_discount}</span></div>
                  <div class="cons_fee"><span class="consl">Total Charge</span><span class="cons2">{payable_amount}</span></div>
                  <hr />

                  <p class="">
                    1. Updates will be sent to registered email address
                  </p>
                  <p>
                    {/* <input type="checkbox" /><span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span> */}
                    <input type={'checkbox'} checked={isChecked} onClick={handleCheckBox} /><span> I agree to the <Link to={'/terms-and-conditions'}>Terms and Conditions.</Link></span>
                  </p>
                </div>
                <div>
                  {/* <div class="text-center"><button disabled="" type="button" data-toggle="modal" data-target="#myModal" class="btn btn_fill btn-block">Pay Booking Advance</button></div> */}
                  <div className="text-center"><button disabled={isChecked ? false : true} type="submit" className="btn btn_fill btn-block" onClick={(e) => bookAndPay(e)}>Book &amp;  pay</button></div>
                </div>
              </div>


              {/* <!-- Modal --> */}
              <div class="modal fade" id="myModal" role="dialog">
                <div class="modal-dialog">

                  {/* <!-- Modal content--> */}
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                      <h4 class="modal-title">Congratulations</h4>
                    </div>
                    <div class="modal-body">
                      <p>Your Booking has been Placed</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default btn_fill" data-dismiss="modal">Okay</button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
      </section >
    </div >
  )
}

export default BookAmbulance
