import axios from 'axios';
import * as process from "process";
import { toast } from 'react-toastify';



// Routes define here 
// descrption:- last updated 15-06-22
// descrption:- By Mayur
class HealthIssueListService {
    constructor() {
        this.state = {
            ApibaseURL: process.env.REACT_APP_API_BASE_URL,
            AccessToken: localStorage.getItem('token'),
            Email: localStorage.getItem('Email'),
            ClientID: Number(localStorage.getItem('ClientID')),
            items: []
        }
    }

    async GetListOfDiseaseSpeciality(urls) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}${urls}`, headers)
                return response
            }
            
            
         catch (error) {

        }
    }

    async GetListOfSpeciality(urls) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}${urls}`, headers)
                return response
            }
            
            
         catch (error) {

        }
    }



    async FaqsListFunctions(fqatype) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL + "/faqs/?type="+fqatype}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }


    async searchDoctorsService(search_key,search_keyCity) {
       
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }

           
            if(this.state.AccessToken)
            {
                const response = await axios.get(`${this.state.ApibaseURL + "/search/afterLogin?search_key=" + search_key+"&location="+search_keyCity}`, headers).then((response) => {
                    
                    return response
                }).catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.success === false)
                            return toast.error(error.response.data.message)
                    }
                    else if (error.response.status === 401) {
                        return toast.error("You are not authorized to view this page")
                    }
                    else if (error.response.status === 404) {
                        return toast.error("Url not found")
                    }
                    else if (error.response.status === 500) {
                        return toast.error("Internal server error please try after sometime")
                    }
                });
                return response.data
            }
            else
            {
                const response = await axios.get(`${this.state.ApibaseURL + "/search/doctors?search_key=" + search_key+"&location="+search_keyCity}`, headers).then((response) => {                   
                    return response
                }).catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.success === false)
                            return toast.error(error.response.data.message)
                    }
                    else if (error.response.status === 401) {
                        return toast.error("You are not authorized to view this page")
                    }
                    else if (error.response.status === 404) {
                        return toast.error("Url not found")
                    }
                    else if (error.response.status === 500) {
                        return toast.error("Internal server error please try after sometime")
                    }
                });
                return response.data
            }

        } catch (error) {
            return toast.error('Something went wrong , Please check your internet connection.')
        }
    }



}
export default HealthIssueListService;
