import React, { useState } from 'react'
import { DoctorUploadImageFor } from '../../services/DoctorOnboarding'
import { toast } from 'react-toastify';
import { uploadImageFor } from '../../enums/Enums';
import { UploadImageFor } from '../../services'

const IdentityProof = ({ incrementBtn,decrementBtn,setIdentity}) => {
    const [image,setImage]=useState()
    const inputImage=()=>{
        let selectPhotos = document.getElementById("file-input");
        selectPhotos.click();
        
    }
    console.log(image)
    function checkAndFilterImage(e) {
        const selectedFile = e.target.files[0];
        setImage(selectedFile.name)
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, uploadImageFor.identity).then(result => {
            toast.success(result.message)
            if (result.success === true) {
                const profileData = {
                    profile_pic: result.data.filePath
                }
                setIdentity(profileData.profile_pic)
            }
        })

        
      }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="profile_form1">
                    <div className>
                        <div>
                            <h4 className="mb-30">Identity proof</h4>
                            <h3 className="drprofile_title">Dr. Abhay mishra</h3>
                        </div>
                        <div className="upload_box">
                            {
                                image===undefined?<img id='imagePreview' src="images/upload.png" />:
                                <><h4>File </h4><p style={{'color':'green'}}>{image}</p></>
                            }
                            <p>Please upload your identity proof to insure that the ownership of your profile remains with only you.</p>
                            <input
                            id="file-input"
                            type="file"
                            name="name"
                            onChange={checkAndFilterImage}
                            style={{ display: "none" }}
                            />
                            <button  onClick={inputImage} className="btn btn-theme btnbig radius">Upload</button>
                        </div>
                        <div>
                            <h4 className=" mt30 mb-20">Acceptable documents:</h4>
                            <p>*Adhar card</p>
                            <p>*Driving license</p>
                            <p>*Voter Id</p>
                            <p>*Any other Govt. Id</p>
                            <hr style={{ borderTop: '1px solid #eee' }} />
                            <h4 className="mt30 mb-20">Extra proofs required to be uploaded</h4>
                            <p>1.Medical registration proof</p>
                            <p>2.Establishment proof</p>
                        </div>
                        <div className="info_box_btn">
                            <button type='button' onClick={decrementBtn} className="btnlink">Go back</button>
                            <button type='button' onClick={incrementBtn} className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IdentityProof