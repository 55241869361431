export const Cities = [
    { value: "Bhopal", label: "Bhopal" },
    { value: "Indore", label: "Indore" },
    { value: "Nagpur", label: "Nagpur" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Pune", label: "Pune" },
    { value: "Delhi", label: "Delhi" },
    { value: "Bangalore", label: "Bangalore" }
]

export const ServiceType = [
    { value: "Homecare", label: "Homecare" },
    { value: "Ambulance", label: "Ambulance" },
    { value: "Teleconsultation", label: "Teleconsultation" }
]
export const ServiceTypeObj =
{
    type1: "Teleconsultation",
    type2: "Homecare",
    type3: "Ambulance"
}

// Partner Services Type
export const ShowProfileType =
    { type1: "Doctor", type2: "Nurse", type3: "Ambulance" }

export const uploadImageFor =
{
    disease: "disease",
    doctor_registration: "doctor_registration",
    homecare: "homecare",
    medical_history: "medical_history",
    medical_report: "medical_report",
    prescription: "prescription",
    profile: "profile",
    feedback: "feedback",
    services: "services",
    identity:"identity",
    medical_doc:'medical_doc',
    establishment_doc:'establishment_doc',
    signature_doc:'signature_doc'
}


// Validations Error
export const err_NotFullName = "Please enter full name"
export const err_NotName = "Please enter name"
export const err_10DigitMobile = "Please enter 10 digit mobile number"
export const err_NotMobile = "Please enter mobile number"
export const err_NotEmailAddress = "Please enter email address"
export const err_NotValidEmail = "Please enter valid email address"
export const err_NotAddress = "Please enter address"
export const err_NotHouseNo = "Please enter house no."
export const err_NotStreetColony = "Please enter street/colony/area"
export const err_NotColony = "Please enter colony"
export const err_NotCity = "Please enter city"
export const err_NotPinCode = "Please enter pincode"
export const err_NotSixDigitPinCode = "Please enter six digit pincode"
export const err_NotDistrict = "Please enter district"
export const err_NotState = "Please enter state"
export const err_NotCountry = "Please enter country"
export const err_NotGender = "Please select gender"
export const err_StartWithZeroMobile = "Mobile number should not start from zero"
export const err_NotPassword = "Please enter password"
export const err_NotEmailPassword = "Please enter mobile or email"

export const HtmlErrorMessage = "Html tags are not allowed"