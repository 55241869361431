import HomepageHeader from '../../components/headers/Header';
import SidebarUserPanel from '../../components/user/Sidebar';


const Revenue=()=>{
    return(
        <>
        <div className='outerhomepage'>
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="userpanel_page bg_light">
                    {/* Side bar */}
                    <SidebarUserPanel />

                    <div className="right_panel " style={{ marginTop: "20px" }}>
                        

                    <div className='content content-body'>
                        

                        
                        
                        
                    </div>



                    </div>

                </div>
            </section>





        </div>




        </>
    );
}
export default Revenue;