import React, { useEffect, useState } from 'react'
import { HomepageHeader, SidebarUserPanel } from '../../../components'
import moment from 'moment'
import { toast } from 'react-toastify'
import { ThreeDots } from 'react-loader-spinner';
import axios from 'axios';
import { saveAs } from "file-saver";
import Sidebarmobile from '../../../components/user/Sidebarmobile'
import APICore from '../../../services/APICore';
const token = localStorage.getItem("token")
const baseurl = process.env.REACT_APP_API_BASE_URL


const MyMedicalReports = () => {

  const [show, setShow] = useState(false);
  const [search, setsearch] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [filepath, setfilepath] = useState("")
  const [title, settitle] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [reportdata, setreportdata] = useState([])



  const reports = async () => {   
    setIsLoading(true)
    const result = await APICore.get(`/users/medicalreports?outputFor=User&searchKey=${search}`)
    if (result != null) {
      if (result != undefined) {
        setreportdata(result.data)
        setIsLoading(false)
      }
    }
    setIsLoading(false)
    console.log(result, "e prescription")

  }

  const postmedical = async (e) => {
    e.preventDefault();
    try {
      var data = {
        "report_name": title,
        "attachment": filepath,
      };

      var config = {
        headers: {
          'authorization': token,
          'Content-Type': 'application/json'
        },

      };
      const result = await axios.post(`${baseurl}users/medicalreports`, JSON.stringify(data), config)      
      if (result != null) {
        reports()
        setIsLoading(false)
        toast.success("Medical Report is Uploaded")
        settitle("")
        setfilepath("")
        setShow(false)
      }
    } catch (error) {
      toast.error(error)
    }

  }



  const postmedicalreport = async (e) => {
    setIsLoading(true)

    var config = {
      headers: {
        'authorization': token,
      },
    };
    const selectedFile = e.target.files[0];
    // const selectedFileName = e.target.files[0];
    const formData = new FormData();
    formData.append("files", selectedFile);

    const result = await axios.post(`${baseurl}uploads?for=medical_report`, formData, config)
    if (result != null) {
      setfilepath(result.data.data.filePath)
      setIsLoading(false)
    }

  }
  useEffect(() => {
    reports();
  }, [show, search])

  const saveFile = (link) => {
    saveAs(
      link,
      "report"
    );
  };

  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="outerhomepage">
      {/*Top Navbar Menu*/}
      <HomepageHeader />

      <section className=" paddbottom">
        <div className="">
          <div className="userpanel_page bg_light">

            <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
              {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
            </button>
            {isVisible && (
              <div>
                <Sidebarmobile />
              </div>
            )}

            <SidebarUserPanel />

            <div className="right_panel ">
              <div class="content-wrapper" style={{ marginLeft: 0 }}>
                <div class="content content-body">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="content-header">
                        {/* <h1 class="pull-left">
                          Medical Reports <span onClick={handleShow} className='add_mr' style={{ cursor: 'pointer' }}><i className='fa fa-plus-circle'></i></span>
                        </h1> */}
                      </div>
                    </div>
                    <div className='col-md-2'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <input type="text" className='form-control' value={search} onChange={(e) => setsearch(e.target.value)} placeholder='Search' />
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="row spacetop">
                    {
                      (!isLoading) ?
                        (reportdata != null && reportdata != undefined && reportdata.length > 0) ?
                          reportdata.map((key) => (
                            <div className='col-md-3'>
                              <div className="mr_card heigth-cardaccod">
                                <div className="mr_left_text">
                                  <h5>{key.report_name}</h5>
                                  <p>{(moment(key?.report_date).utc()).format("MMM DD YYYY")}</p>
                                </div>
                                <div className="mr_download">
                                  <a onClick={() => saveFile(key.attachment)}> <i className='fa fa-download'></i></a>
                                </div>
                               {/*  <div className="mr_download">
                                  <a href={key.attachment} target="_blank"> <i className='fa fa-download'></i></a>
                                  </div> */}
                              </div>
                            </div>
                          ))
                          : <p class="marginme">"No Record Found"</p>

                        :
                        <div class="defaultloader">
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#056B66"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          /></div>
                    }



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div
          class={show ? "modal show" : "modal fade"}
          id="modalpriscription"
          tabIndex="-1"
          aria-hidden="false"
        >
          <div class="modal-dialog mydailog">
            <div class="modal-content">
              {/* <!-- Modal header --> */}
              <div class="modal-header">
                <h5 class="modal-title pull-left" id="modalpris">  Add Medical Report </h5>
                <button type="button" onClick={(e) => handleClose(e)} class="btn-close pull-right" data-bs-dismiss="Close" aria-label="Close">&times;</button>
              </div>
              <div class="modal-body">
                <div className='row'>
                  <div class="col-lg-12">
                    <div class="fd_input mb-2">
                      <div class="form-group"><label>Title</label>
                        <input type="text" name="name" class="form-control" placeholder="" value={title} onChange={(e) => settitle(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="fd_input mb-2">
                      <div class="form-group"><label>File/Attachment</label>


                        <input type={'file'} name="name" class="form-control" placeholder="" onChange={(e) => postmedicalreport(e)} />
                      </div>
                    </div>
                  </div>

                </div>


              </div>
              <div class="modal-footer d-flex justify-content-between">
                <button className='btn btn-info' onClick={(e) => postmedical(e)} > Upload</button>
              </div>
            </div>

            {/* <!-- Form END --> */}
          </div>
          {/* <!-- Modal footer --> */}
        </div>
      </section>

    </div>
  );
}
export default MyMedicalReports;