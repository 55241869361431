import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import slide01 from "../../images/login/login_slide01.svg"
import slide02 from "../../images/login/login_slide02.svg"
import slide03 from "../../images/login/login_slide03.svg"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { OnboardUser } from '../../services';
import { HtmlErrorMessage } from '../../enums/Enums';
const regHtml = /<(.|\n)*?>/g;
const regEmailValidation = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


const SetPassword = () => {
    const navigate = useNavigate()
    const apifunction = new OnboardUser();

    const [passwordconfirm, setPasswordconfirm] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [isEmailReadOnly, setIsEmailReadOnly] = useState(false)

    useEffect(() => {
        const localEmail = localStorage.getItem("user_email")
        setIsEmailReadOnly(localEmail ? true : false)
    }, [])




    const setPasswordFunc = (e) => {
        e.preventDefault();

      
        if (!password) {
            return toast.error("Enter password")
        }
        else if (regHtml.test(password?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (!passwordconfirm) {
            return toast.error("Enter confirm password")
        }
        else if (regHtml.test(passwordconfirm?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (passwordconfirm !== password) {
            return toast.error("Confirm password not match with password")
        }
        else {
            const data = {
                password: password,
                userid: localStorage.getItem("userid")//User ID-getting from api firstly saved in storage
            }
            apifunction.SetPassword(data).then(res => {
                if (res !== undefined) {
                    var result = res
                    if (result.success === true) {
                        const userid = result.data.id
                        const mobileNumber = result.data.mobile
                        const stepCompleted = result.data.completed_step
                        const username = result.data.name
                        const useremail = result.data.email
                        const userType = result.data.user_type
                        if (stepCompleted <= 4) {

                            localStorage.setItem("userid", userid)
                            localStorage.setItem("user_mobile", mobileNumber)
                            localStorage.setItem("user_name", username)
                            localStorage.setItem("user_email", useremail)
                            localStorage.setItem("user_type", userType)
                            navigate("/fill-details")
                            toast.success("Password updated")
                        }
                    }
                }
            })

        }
    }


    return (
        <div className="login_page">
            <div className="login_left">
                <div className="left_img">
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        {/* Indicators */}
                        <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to={0} className="active" />
                            <li data-target="#myCarousel" data-slide-to={1} />
                            <li data-target="#myCarousel" data-slide-to={2} />
                        </ol>
                        {/* Wrapper for slides */}
                        <div className="carousel-inner">
                            <div className="item active">
                                <img src={slide01} alt style={{ width: '100%' }} />
                            </div>
                            <div className="item">
                                <img src={slide02} alt style={{ width: '100%' }} />
                            </div>
                            <div className="item">
                                <img src={slide03} alt style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="login_right">
                <div className="login_right_content">
                    <form onSubmit={(e) => setPasswordFunc(e)} role="form" className="signup_form">
                        <div className='row' style={{ display: "flex", justifyContent: "space-between" }}>
                            <h2 className="title">Setup your Password</h2>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password</label>
                            <input type="password" name='password' value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Confirm Password</label>
                            <input type="password" name='passwordconfirm' value={passwordconfirm} onChange={(e) => setPasswordconfirm(e.target.value)} className="form-control" />
                        </div>
                        <div className="mt40">
                            <div className>
                                <button type='submit' className="btn btn_fill  btn-block">Done</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default SetPassword;