import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { HomepageHeader, SidebarUserPanel } from '../../components'
import Sidebarmobile from '../../components/user/Sidebarmobile'

const ServicesProvider = () => {
    const [teleconsultaion, setTeleconsultaion] = useState(0)
    const [homeCare, setHomeCare] = useState(0)
    const [ambulance, setAmbulance] = useState(0)
    let serviceList = JSON.parse(localStorage.getItem('services'))
   
    useEffect(() => {
        if (serviceList !== null && serviceList.length > 0) {
            serviceList.map((val) => {
                if (val.service == 'Doctor') {
                    setTeleconsultaion(val.service)
                } else if (val.service == 'Nurse') {
                    setHomeCare(val.service)
                } else if (val.service == 'Ambulance') {
                    setAmbulance(val.service)
                }
            })
        }
    }, [])

    const [isVisible, setIsVisible] = useState(false);
    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        {/* <SidebarUserPanel /> */}
                        <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                            {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
                        </button>
                        {isVisible && (
                            <div>
                                <Sidebarmobile />
                            </div>
                        )}

                        <SidebarUserPanel />


                        <div className="right_panel table-responsive" style={{ marginTop: "20px" }}>
                            <div className="container">
                                <div className="userpanel_page bg_light" style={{ height: "100rem" }}>

                                    <div style={{ margin: "115px auto" }}>
                                        <h2 className='mb20'>Want to be a</h2>

                                        {
                                            teleconsultaion === 0 && <Link to="/service-provider-teleconsultation" className='btn btn-theme ml-2'>Teleconsultation</Link>
                                        }
                                        {
                                            homeCare === 0 && <Link to="/service-provider-homecare" className='btn btn-theme ml-2'> Homecare Nurse</Link>
                                        }
                                        {
                                            ambulance === 0 && <Link to="/service-provider-teleconsultation" className='btn btn-theme ml-2'>Ambulance</Link>
                                        }
                                    </div>
                                    {/* Side bar */}
                                    {/* <SidebarUserPanel /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className=" paddbottom">
                <div className="container">
                    <div className="userpanel_page bg_light" style={{ height: "100rem" }}>

                        <div style={{ margin: "115px auto" }}>
                            <h2 className='mb20'>Want to be a</h2>

                            {
                                teleconsultaion === 0 && <Link to="/service-provider-teleconsultation" className='btn btn-theme ml-2'>Teleconsultation</Link>
                            }
                            {
                                homeCare === 0 && <Link to="/service-provider-homecare" className='btn btn-theme ml-2'>Homecare Nurse</Link>
                            }
                            {
                                ambulance === 0 && <Link to="/service-provider-teleconsultation" className='btn btn-theme ml-2'>Ambulance</Link>
                            }
                        </div>
                        {/* Side bar */}
                        {/* <SidebarUserPanel /> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ServicesProvider