import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import  AggregatorBookingComponent  from '../../../components/AggregatorComponents/AggregatorBookingComponents'
import { BookingServices } from '../../../services'


const AggregatorBooking = () => {

    const apiFunction = new BookingServices()
    const { state } = useLocation()
    const paymentStatus = state ? state.status
        ? state.status : null : null
    const [bookingDataMain, setBookingDataMain] = useState([])
    const [isloading, setIsLoading] = useState(false)
    const [status, setStatus] = useState("Booked")
    useEffect(() => {
        setIsLoading(true);
        loadBookingsAllBooking();
    }, [status])
    
    useEffect(() => {
        if (paymentStatus === "true") {
            return toast.success("Payment successfully received")
        } else if (paymentStatus === "false") {
            return toast.error("Payment not received error!!")
        }
    }, [paymentStatus])
    const loadBookingsAllBooking = () => {
        apiFunction.BookingListUserPanel(status).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    setBookingDataMain(result.data)
                    setIsLoading(false);
                }
            }
        })
    }
    return (
        <AggregatorBookingComponent setStatus={setStatus} status={status} data={bookingDataMain} selfDetails={true} isloading={isloading} />
    )
}

export default AggregatorBooking