import React, { useEffect } from 'react'
import { Footer, HomepageHeader } from '../../components'
import { useState } from 'react'


const TermsConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])




    return (
        <>
            <HomepageHeader />

            <section className="banner_inner lohwheight">
                <div className="container">
                    <h1>Terms and Conditions</h1>
                </div>
            </section>

            <section class="policy paddtop">
                <div class="container">
                    <h2>Terms & Conditions of use</h2>
                    <p>Fasttrack health care private ltd., its related corporations, subsidiaries and affiliates (collectively referred to herein as &ldquo;<b>fasttrack</b>&ldquo;), is the owner and operator of this website or application. In consideration of fasttrack&rsquo;s acceptance of my registration as a user of the fasttrack mobile application(s) and/or website(s) (the &ldquo;<b>platform</b>&rdquo;), the grant of license by fasttrack for me to use the platform and/or fasttrack&rsquo;s agreement to make available its service options to me, i hereby acknowledge and agree to abide by the following term</p>

                    <p>Please carefully go through these terms and conditions (&ldquo;terms&rdquo;) and the privacy policy available at <a data-fr-linked="true" href="https://fasttrackhealthcare.in/#/privacy-policy">https://fasttrackhealthcare.in/#/privacy-policy</a> (&ldquo;privacy policy&rdquo;) before you decide to access the website or avail the services made available on the website by fasttrack. These terms and the privacy policy together constitute a legal agreement (&ldquo;agreement&rdquo;) between you and fasttrack in connection with your visit to the website and your use of the services (as defined below).</p>

                    <h4>The agreement applies to you whether you are -</h4>

                    <p>A medical practitioner or health care provider (whether an individual professional or an organization) or similar institution wishing to be listed, or already listed, on the website, including designated, authorised associates of such practitioners or institutions (&ldquo;practitioner(s)&rdquo;, or );</p>

                    <p>A client of the practitioner(s) or a member of the public who searches for practitioners on the website anonymously or as a registered user of the services (&ldquo;end-user&rdquo;).</p>
                    <p>All users of the services are together termed as (&ldquo;users&rdquo; or &ldquo;you&rdquo; or &ldquo;your&rdquo;).</p>

                    <p>This agreement applies to those services made available by fasttrack on the website, which are offered free of charge to the users (&ldquo;services&rdquo;), including the following:</p>

                    <p>For practitioners: listing of practitioners and their profiles and contact details, to be made available to the other users and visitors to the website;</p>

                    <p><b>For other users: facility to</b><br/>
                         (i) Create and maintain &lsquo;health accounts&rsquo;,<br/>
                        (ii) Search for practitioners by name, specialty, and geographical area, or any other criteria that may be developed and made available by fasttrack, and<br/>
                         (iii) To make appointments with practitioners.</p>

                    <p>The services may change from time to time, at the sole discretion of fasttrack, and the agreement will apply to your visit to and your use of the website to avail the service, as well as to all information provided by you on the website at any given point in time.</p>

                    <p>This agreement defines the terms and conditions under which you are allowed to use the website and describes the manner in which we shall treat your account while you are registered as a member with us. If you have any questions about any part of the agreement, feel free to contact us at <a data-fr-linked="true" href="mailto:support@fasttrackhealthcare.in">support@fasttrackhealthcare.in</a></p>

                    <p>By downloading or accessing the website to use the services, you irrevocably accept all the conditions stipulated in this agreement, the subscription terms of service and privacy policy, as available on the website, and agree to abide by them. This agreement along with the terms contained in the abovementioned subscription terms of service and privacy policy supersedes all previous oral and written terms and conditions (if any) communicated to you relating to your use of the website to avail the services. By availing any service, you signify your acceptance of the terms of this agreement.</p>

                    <p>We reserve the right to modify or terminate any portion of the agreement for any reason and at any time, and such modifications shall be informed to you in writing you should read the agreement at regular intervals. Your use of the website following any such modification constitutes your agreement to follow and be bound by the agreement so modified.</p>

                    <p>You acknowledge that you will be bound by this agreement for availing any of the services offered by us. If you do not agree with any part of the agreement, please do not use the website or avail any services.</p>

                    <p>Your access to use of the website and the services will be solely at the discretion of fasttrack.</p>

                    <p>The agreement is published in compliance of, and is governed by the provisions of indian law, including but not limited to:</p>

                    <p>The indian contract act, 1872,</p>
                    <p>The (indian) information technology act, 2000, and</p>
                    <p>The rules, regulations, guidelines and clarifications framed there under, including the (indian) information technology (reasonable security practices and procedures and sensitive personal information) rules, 2011 (the &ldquo;spi rules&rdquo;), and the (indian) information technology (intermediaries guidelines) rules, 2011 (the &ldquo;ig rules&rdquo;),singapore personal data protection act, 2102 (the &ldquo;pdpa&rdquo;).</p>
                    <h4>1. Capacity &amp; authority</h4>
                    <p> I warrant and represent that i am of the age of majority, have the legal capacity and is legally entitled to enter into these contractually and legally binding standard terms and conditions (&ldquo;<b>terms</b>&ldquo;) and to make use of the service options offered by fasttrack. Where i use the service options offered by fasttrack on behalf of an organisation or entity, or to secure goods or services for the consumption of another person, i warrant and represent that i am duly authorised by the organisation or entity or such other person to do so, and in such a case, the words &ldquo;i&rdquo;, &ldquo;me&rdquo; or &ldquo;my&rdquo; used in these terms shall include such an organisation or entity or such other person so that these terms, including terms and conditions that limit the liabilities of fasttrack, are also legally binding on such an organisation or entity or such other person.</p>
                    <h4>2. Platform for healthcare services &amp; products</h4>
                    <p> I acknowledge and accept that fasttrack is in the business of :<br/>
                     (a) providing a platform for my access to qualified healthcare or medical professionals, healthcare or medical institutions and/or specialists (&ldquo;<b>healthcare providers</b>&ldquo;) (either in person, by phone, e-mail, tele-conferencing or video-conferencing);
                      (b) facilitating access to selected healthcare or medical related products or services offered by the healthcare providers, business partners of fasttrack or other third parties (&ldquo;<b>third party services</b>&ldquo;) (the services at (a) and (b) collectively referred to as the &ldquo;<b>services</b>&ldquo;); (c) providing information, articles, content and material on topics such as health or medical care, nutrition and care-giving (&ldquo;<b>articles</b>&ldquo;); and (d) offering the sale of medical, nutritional, health, personal care and home care products (&ldquo;<b>products</b>&ldquo;). I have the sole right and responsibility to choose from the service options or products available to me on the platform or based on the recommendation of fasttrack or the healthcare providers. I agree and acknowledge that i am solely responsible for any decision or selection made by me in relation to the service options/solutions, articles or products regardless of whether or not my decision was based on the recommendation of fasttrack or the healthcare providers (&ldquo;recommendation&ldquo;). In this regard i shall at my own cost and expense, verify and check the credentials and reliability of the recommendation, articles or products and fasttrack shall not have any liability in this regard.</p>
                    <h4>3. Provision of services by healthcare providers</h4>
                    <p> I acknowledge and agree that the services are provided subject to these terms. All requests for the provision of the services shall be communicated by me to fasttrack through means as instructed by fasttrack. Fasttrack has no obligation to accept my request for services and shall have full discretion to decline the provision of any of the services to me. In the event that fasttrack accepts my service request, fasttrack shall record the details of the services requested by me and such a record shall constitute final and conclusive evidence of the services requested by me under these terms. While fasttrack endeavours to ensure that the healthcare providers provide prompt and timely services, fasttrack is not responsible for disruptions in the services or any actions of any healthcare providers. In particular, fasttrack is not responsible for, and i agree to indemnify and hold fasttrack harmless from and against, the actions or omissions of any third-party independent service providers. Fasttrack shall not be liable for any cancellation or delay of an appointment, consultation or interview with the healthcare provider(s) and any consequences or events that may arise pursuant to the scheduling of such appointment, consultation or interview with the healthcare provider(s).</p>
                    <h4>4. Responsibility for services</h4>
                    <p> I acknowledge and accept that fasttrack is not responsible, directly or indirectly, for the services received by me or for the acts or omissions of any healthcare providers assigned to me through the fasttrack platform. If an emergency or urgent medical situation arises in my case, i understand that i should immediately contact the relevant medical institutions for emergency medical care.</p>
                    <h4>5. Sale of products</h4>
                    <p> I acknowledge that all risks in products purchased by me shall pass to me upon delivery to the address i have provided for delivery or upon acceptance of the products by myself or my representative.</p>
                    <h4>6. Intelllectual property rights </h4>
                    <p>I acknowledge that the fasttrack website(s), blog(s), mobile application(s) and articles contain material protected by intellectual property rights such as copyright laws, trademarks, and service marks of fasttrack and licensors of fasttrack (collectively, &ldquo;proprietary material&ldquo;), which may include, but is not limited to: knowhow, business methods, databases, proprietary information, text, written works, compilations, software, photos, video, graphics, images, data, music and sound. I agree not to modify, publish, copy, compile, transmit, participate in the transfer or sale of, create derivative works of, or in any way exploit, in whole or in part, any proprietary material without the prior written consent of fasttrack.</p>
                    <h4>7. No medical outcome guarantee</h4>
                    <p>I acknowledge and agree that whilst fasttrack will use its best endeavours to procure the appropriate healthcare provider to address my needs, fasttrack does not guarantee that the articles, products, services or the services rendered by the healthcare provider assigned to me shall be effective to meet my medical needs or other personal requirements.</p>
                    <h4>8. The products,</h4>
                    <p> The products, articles or services may include recommendations, but all decisions in connection with the implementation and acceptance of such recommendations shall be the responsibility of, and made by, me. In connection with how and when services are to be dispensed by fasttrack or the healthcare providers, fasttrack shall be entitled to rely on all of my decisions and approvals.</p>
                    <h4>9. The articles</h4>
                    <p> The articles the sale of products, the performance of the services and/or the provision of services by the healthcare providers do not constitute (i) medical advice or diagnosis; or (ii) a recommendation regarding the treatment of any illness or disability.</p>
                    <h4>10. Accuracy of information</h4>
                    <p> I acknowledge and accept that the satisfactory or adequate performance of the services and/or the services by the healthcare providers are heavily dependent upon me having provided all relevant materials and information and answered fasttrack&rsquo;s questions fully and accurately. Fasttrack has no responsibility to ensure the accuracy or completeness of the information provided by me even if fasttrack had reason to know or should have known of such inaccuracy or incompleteness.</p>
                    <h4>11. Disclaimer</h4>
                    <p> Fasttrack shall not be liable for any loss, damages, costs or expenses incurred by me in connection with or arising from the products, articles, services or the services provided by any healthcare provider. Fasttrack shall not be responsible nor liable for any complications (including without limitation medical complications) or other consequences that may arise from the provision of the products, articles, services or from any services provided by any healthcare provider.</p>
                    <h4>12. Limits on liability. &nbsp;</h4>
                    <p>Without prejudice to the provisions herein, i hereby acknowledge and agree that in respect of all causes of action which might accrue to me under these terms (if any) or in relation to any products, articles, services or the services provided by any healthcare provider, fasttrack&rsquo;s maximum liability (regardless of form of action, whether in contract, tort or otherwise) for all causes of action that takes place during a calendar year shall in no event exceed the total amount of fees paid by me to fasttrack during the same calendar year as when the said causes of action accrue. I hereby acknowledge and agree that in no event shall fasttrack be liable for any loss of income or profits or damages or losses (economic or consequential) whether these arise as direct or indirect losses. I further acknowledge and agree that fasttrack shall not be liable for any consequential, special, incidental or punitive loss, damage or expense even if i have been advised of their possible existence.</p>
                    <h4>13. No warranty</h4>
                    <p> Fasttrack, its subsidiaries, officers, directors, employees, and suppliers provide the products, articles and services &ldquo;as is&rdquo; and without any warranty or condition, express, implied or statutory. Fasttrack, its subsidiaries, officers, directors, employees and suppliers specifically disclaim all implied warranties including warranties of merchantability, performance, fitness for a particular purpose and non-infringement. In addition, no advice or information (oral or written) obtained by me from fasttrack shall create any warranty.</p>
                    <h4>14. Third party services</h4>
                    <p> For providing the services, fasttrack shall from time to time have arrangements/tie-ups with the healthcare providers, business partners of fasttrack or such other third party for the offering of such third party services that fasttrack may from time to time deem fit. These third party services may be availed by me, at my option at my sole cost and expense. I hereby acknowledge and agree that i shall not make any claim against fasttrack in relation to any deficiency or defect in the third party services provided by the healthcare providers, business partners of fasttrack or such other third party offering these third party services.</p>
                    <h4>15. Agreement not to engage providers of services directly </h4>
                    <p>I agree: (a) not to by-pass the use of the platform; and (b) not to solicit and/or engage the services of healthcare providers directly. I acknowledge and agree that any offer made, whether directly or indirectly, and any attempt to solicit and/or engage the services of healthcare providers first introduced to me via the fasttrack platform, without the use of the fasttrack platform, whether or not such attempt is initiated by the healthcare provider or by me, shall be a material breach of this agreement under these terms, and shall thereby render me liable to the provisions of paragraph 23 (liquidated damages).</p>
                    <h4>16. Dispute resolution and release</h4>
                    <p> In the event a dispute arises between me and one or more of the healthcare provider(s), product manufacturers, business partners of fasttrack or such other third party, i hereby release fasttrack (and fasttrack&rsquo;s officers, directors, agents, subsidiaries, joint ventures and employees) from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes. Fasttrack may, for my benefit, try to help me resolve disputes, provided always that, any such assistance is provided only in fasttrack&rsquo;s sole discretion and fasttrack has no obligation to resolve disputes between the foregoing parties. To the extent that fasttrack attempts to resolve a dispute, fasttrack will do so in good faith based solely on fasttrack&rsquo;s policies. Fasttrack will not make judgments regarding legal issues or claims.</p>
                    <h4>17. Information disclosure</h4>
                    <p> I hereby represent and warrant that all information and documentation disclosed by me and/or contained in the membership registration form (including without limitation any medical reports and transcripts), and any subsequent updates thereto, are true and correct and accurate. I further acknowledge and agree that fasttrack shall not be liable for any disclosure that i may make with regard to my medical/confidential/personal/familial information, history or condition or other records as disclosed through direct or mediated means by me to fasttrack (&ldquo;information&rdquo;). I agree that fasttrack shall have the right to disclose the information to the healthcare provider(s), business partners of fasttrack or such other third party and their employees as necessary in discharge of the desired services, in such manner as fasttrack may deem fit. I acknowledge that fasttrack shall not be liable for non-disclosure or incomplete disclosure of any information to the healthcare provider(s), business partners of fasttrack or such other third party and their employees, nor shall fasttrack be liable for the use, non-use or misuse of information by healthcare provider(s), business partners of fasttrack or such other third party and their employees.</p>
                    <h4>18. Information storage</h4>
                    <p> Fasttrack may choose to maintain copies of the information on a portal (of the platform) wherein all such information shall be stored and if so, fasttrack may at its discretion grant me a secured access to the portal created by fasttrack to ensure easy access to the information (&ldquo;portal&ldquo;) and i agree my access to the information on the portal shall be in compliance with and subject to these terms or the separate terms and conditions of use of the portal, if any, which may change from time to time. I also agree that i shall be solely responsible for any misuse of my access (log in rights) on the said portal. I acknowledge that i shall at all times provide updated information to fasttrack and fasttrack shall, based upon such updates, update the information stored on the portal. I further acknowledge and agree that information provided by me may be stored on third party storage facilities which may not be entirely within the control of fasttrack. I hereby give my consent to the storage of information on such third party storage facilities and shall hold harmless and indemnify fasttrack against any and all claims, costs, losses, expenses and demands arising out of or in connection with the storage of the information in such third party storage facilities.</p>
                    <h4>19. Information to and from third parties </h4>
                    <p>I hereby acknowledge that it is necessary for the provision of the services that fasttrack has to obtain information from, or provides information to, third parties such as hospitals about me or the persons on whose behalf i am using the services, and hereby authorise fasttrack to do so, and i hereby agree to hold harmless and indemnify fasttrack from and against any and all claims, costs, losses, expenses and demands arising out of or in connection with the same.</p>
                    <h4>20. Transmission of data</h4>
                    <p> Fasttrack will use commercially reasonable methods to secure the transmission of data over the internet. I acknowledge and agree that no means of transmission over the internet can be 100% secure and i shall hold harmless and indemnify fasttrack against any and all claims, costs, losses, expenses and demands arising out of or in connection with the transmission of information i provide over the internet.</p>
                    <h4>21. Fees and charges</h4>
                    <p> I shall pay fasttrack all charges and fees invoiced to me for the provision of the services within 2 days from receipt of the invoice. Fasttrack shall have the right to modify the fees and charges in relation to the services (or any part thereof) and the third party services that may be available to me through fasttrack&rsquo;s online platform or from the healthcare providers, business partners of fasttrack or such other third party offering these third party services, from time to time, without any requirement of giving any prior notice. I acknowledge and agree that fasttrack shall be entitled to charge and i shall be liable to pay for the services according to fasttrack&rsquo;s standard price list from time to time in force. In the event of any cancellation or rescheduling of any appointment between me and any healthcare providers, i agree that fasttrack shall be entitled to charge and i shall be liable to pay the prevailing fasttrack charges applicable to such cancellation or rescheduling, as notified by fasttrack to me in connection with the setup of the appointment between me and any healthcare providers. If any fees and charges from me are overdue, fasttrack may, at its absolute discretion and without prior notice, terminate these terms or suspend any services or the delivery of services by healthcare providers.</p>
                    <h4>22. Interest on late payment</h4>
                    <p> Late payment fees will be imposed monthly at the rate of 10% of outstanding amount, in respect of the period starting on the due date of payment and ending on the actual date of payment.</p>
                    <h4>23. Liquidated damages.</h4>
                    <p> Without prejudice to any other right of the company at law or in equity, in the event i commit a material breach under these terms pursuant to paragraph 15 (agreement not to engage services of providers directly), i shall be liable to pay to fasttrack immediately upon demand, by way of liquidated damages, in compensation for loss of costs incurred (a) to recruit, train and develop the healthcare providers, (b) for insurance coverage costs in relation to the provision of services, and (c) marketing and securing the supply of fasttrack&rsquo;s healthcare providers, a &ldquo;compensation fee&rdquo; in an amount equal to rs 10,000 for each healthcare provider introduced by fasttrack who provides services to me, without the use of the fasttrack platform.</p>

                    <p><b>24. I irrevocably agree to indemnify,</b> defend and hold harmless fasttrack from and against any claim, action, demand, complaint, liability, judgment, penalty, compounding, loss, costs, damages and expenses (including debt-collection, court costs and legal fees on a full indemnity basis) suffered or incurred by fasttrack arising from or in connection with any breach in my obligations to fasttrack (including, but not limited to, the obligations under paragraph 21 (fees and charges), paragraph 22 (interest on late payment), and paragraph 23 (liquidated damages) ) under these terms.</p>
                    <h4>25. Online payment </h4>
                    <p>I acknowledge and agree that if and when making payment online: (a) i will be taken away from the platform and directed to the payment gateway used by fasttrack, via a secure connection, through which i will provide my credit card or other payment information, so that it may be passed to a third party payment processor for payment; (b) my payments may also be subject to the terms and conditions and privacy policies of such third parties; and (c) fasttrack will have no access to my credit card or account numbers and information when i make a payment online and fasttrack and its affiliates, officers, directors and employees, disclaim all liability in connection with any third party&rsquo;s use or misuse of the same.</p>
                    <h4>26. Change of terms</h4>
                    <p> The availability of products and the services provided by fasttrack and the terms and conditions herein are subject to change by fasttrack, without any requirement of giving prior notice to me. Fasttrack further reserves the right to modify or terminate any service, third party service, product or article (including without limitation their provision thereof) for any reason, without notice, at any time.</p>
                    <h4>27. Term and termination</h4>
                    <p> Fasttrack reserves the right to terminate these terms and its relationship with me without prior notice, at any time.</p>
                    <h4>28. Force majeure</h4>
                    <p> Fasttrack shall not be liable for its failure to perform under these terms if such non-performance results from the occurrence of any force majeure events like acts of god, fire, wars, sabotage, civil unrest, labour unrest, action of statutory authorities or local or central governments, or change in laws, rules and regulations, affecting the performance of fasttrack.</p>
                    <h4>29. Confidentiality</h4>
                    <p> I agree to keep strictly secret and confidential, and shall under no circumstances disclose to any third party or entity, any information obtained by me in the course of receiving the services including the names and particulars of healthcare providers or the parties providing the third party services, unless with the prior written consent of fasttrack.</p>
                    <h4>30. Personal data protection act</h4>
                    <p> I acknowledge and agree that the provision of any products or services by fasttrack to me shall be subject to my giving consent to fasttrack for the collection, use and disclosure of my personal data in accordance with fasttrack&rsquo;s personal data privacy policy (a copy of which is available for review at <a data-fr-linked="true" href="https://fasttrackhealthcare.in/#/privacy-policy">https://fasttrackhealthcare.in/#/privacy-policy</a> and the terms of which i acknowledge to have read and understood). I hereby give my consent for fasttrack to collect, use and disclose my personal data in accordance with fasttrack&rsquo;s personal data privacy policy.</p>
                    <h4>31. Governing law and dispute resolution</h4>
                    <p> These terms are governed by the laws of india. I agree to submit any dispute arising out of or related to these terms, to arbitration in india in accordance with the arbitration rules of the singapore international arbitration centre (&ldquo;siac rules&rdquo;) for the time being in force, which rules are deemed to be incorporated by reference in this clause.</p>
                       <h4>Applicable law and dispute settlement</h4>
                        <ul>
                            <li>You agree that this agreement and any contractual obligation between fasttrack and user will be governed by the laws of india.</li>
                            <li>The users and fasttrack agree that all attempts shall be made to settle any dispute, claim or action arising out of these subscription terms of use through amicable negotiations within 15 (fifteen) days of the claim first being made. If the negotiations fail, the users and fasttrack will attempt to resolve the dispute by a mediator appointed by mutual consent. The venue of such mediation shall be indore.</li>
                            <li>In the case of failure to resolve finally the dispute in the manner set out in clause 11.2 above, within 30 (thirty) days from the date of first raising the claim, the same shall be decided by binding arbitration by a sole arbitrator appointed by fasttrack. The seat of arbitration shall be indore.</li>
                            <li>The courts at indore shall have exclusive jurisdiction over all matters pertaining to this agreement.</li>
                        </ul>
                   
                   <h4>32. Assignment</h4>
                    <p> Fasttrack shall have the right to assign these terms without my prior written consent.</p>
                    <h4>33. Severability.</h4>
                    <p> If any provision, hereunder becomes, in whole or in part, invalid or unenforceable but would be valid or enforceable if some part of that provision was deleted, that provision shall apply with such deletions as may be necessary to make it valid. If any court or tribunal of competent jurisdiction holds any of the provisions, hereunder unlawful or otherwise ineffective, the remainder shall remain in full force and the unlawful or otherwise ineffective provision shall be substituted by a new provision reflecting the intent of the provision so substituted.</p>
                    <h4>34. Notices</h4>
                    <p> Any notices or other information required or authorised by these terms to be given by either party to the other may be given by hand or sent (by pre-paid post, facsimile transmission or comparable means of communication) to the other party at such address (including electronic mail address) as may be notified by the party concerned in writing from time to time.</p>
                    <h4>35. Rights of third parties</h4>
                    <p> These terms is entered into by the parties hereto exclusively for the benefit of the parties hereto. Nothing in these terms shall confer or be deemed to confer any right or benefit on any third party (including any third party identified or introduced by me for the purposes of these terms) and nothing in these terms shall be enforceable or relied upon in any way by any third party against any party hereto. Notwithstanding any provision herein, the parties hereto may at any time and from time to time, by mutual agreement, vary or rescind these terms without the consent of any third party.</p>


                </div>
            </section>


            <Footer />
        </>
    )
}

export default TermsConditions