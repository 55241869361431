import React, { useEffect, useState } from 'react'
import { HomepageHeader } from '../../components'
import VideoIcon from "../../images/images/vc_icon.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import moment from 'moment'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { regHtml, regMobile } from '../../helpers/Validate'
import { toast } from 'react-toastify'
import { BookingServices } from '../../services'
import {
    err_10DigitMobile,
    err_NotFullName,
    err_NotMobile,
    HtmlErrorMessage
} from '../../enums/Enums'
import * as process from "process";
import { set } from 'date-fns'
// import PayTmGatewayPage from './PayTmGatewayPage'

const BookingDetailsFillForm = () => {

    const apiFunction = new BookingServices();

    const { state } = useLocation();
   
    const merchantId = process.env.REACT_APP_PayTM_MID

    const paytmOpenPaymentPageUrl = "https://securegw.paytm.in/theia/api/v1/showPaymentPage"

    const { slotidArr, slotDate, qualification, experience, specialization, slotTime, doctorName, doctorFee, followupFee, profileId, profileType, service_provider_id, profile_pic } = state;

    const [isChecked, setIsChecked] = useState(false)
    const [couponList, setCouponList] = useState([])
    const [couponDetails, setCouponDetails] = useState({})
    const [selectedCoupon, setSelectedCoupon] = useState(null)
    const [payable_amount, setPayable_amount] = useState(doctorFee)
    const [profilepicture, setprofilepicture] = useState('')
    const [allergy, setAllergy] = useState(false)
    const [onMedication, setOnMeditation] = useState(false)
    const [pastMedicalIssue, setPastMedicalIssue] = useState(false)
    const [bookingType, setBookingType] = useState('New')
    const [bookAndPayForm, setbookAndPayForm] = useState({
        user_id: "",
        booking_date: "",
        service_provider_id: '',
        service_type: "",
        booked_for: "",
        total_amount: payable_amount,
        other_name: "",
        other_mobile: "",
        other_gender: "",
        short_description: "",
        paid_amount: 0,
        isSomeOneElse: false,
        appointment_date: '',
        slots: '',
        profile_pic: "",
        applied_coupon: "",
        coupon_discount: 0,

    })
    const { user_id, booking_date,
        service_type, booked_for,
        total_amount, other_name,applied_coupon, coupon_discount,
        other_mobile, other_gender, short_description,
        paid_amount, isSomeOneElse } = bookAndPayForm;

    const onInputChange = e => {
        setbookAndPayForm({
            ...bookAndPayForm, [e.target.name]:
                e.target.name === "other_mobile" ?
                    // Check if not number it will return nothing only for mobile
                    e.target.value.match(regMobile) ? e.target.value : ""
                    : e.target.value
        })
    }
    useEffect(() => {

        if (bookingType === "New") {
            /* setbookAndPayForm({
                ...bookAndPayForm, ["total_amount"]: doctorFee
            }); */
            if(selectedCoupon){
                handleClearCouponSelection()
                }
            setPayable_amount(doctorFee)
        }
        else {
           /*  setbookAndPayForm({
                ...bookAndPayForm, ["total_amount"]: followupFee
            }); */
            if(selectedCoupon){
            handleClearCouponSelection()
            }
            setPayable_amount(followupFee)
            
        }

    }, [bookingType])

    useEffect(() => {
        sessionStorage.setItem("notLoggedInBookingFlowUrlDetails", "");
        setbookAndPayForm({
            ...bookAndPayForm,
            user_id: profileId,
            booking_date: moment(new Date()).format("YYYY-MM-DD"),
            service_type: profileType,
            booked_for: isSomeOneElse ? "other" : "self",
            total_amount: payable_amount,
            other_name: other_name,
            other_mobile: other_mobile,
            other_gender: other_gender,
            short_description: "",
            paid_amount: 0,
            isSomeOneElse: isSomeOneElse,
            service_provider_id: service_provider_id,
            appointment_date: slotDate,
            profile_pic: profile_pic,
            applied_coupon: "",
            coupon_discount: 0,


        });
        getCouponList();
    }, [])
    useEffect(() => { setprofilepicture(profile_pic) }, [])



    useEffect(() => {
        if (isSomeOneElse === true) {
            setbookAndPayForm({
                ...bookAndPayForm,
                other_name: "",
                other_mobile: "",
                other_gender: "",
            })
        } else {
            setbookAndPayForm({
                ...bookAndPayForm,
                other_name: localStorage.getItem("user_name"),
                other_mobile: localStorage.getItem("user_mobile"),
                other_gender: localStorage.getItem("gender")
            })
        }
    }, [isSomeOneElse])

    function isDate(val) {
        // Cross realm comptatible
        return Object.prototype.toString.call(val) === '[object Date]'
    }

    function isObj(val) {
        return typeof val === 'object'
    }


    // For Paytm Stringify input field values
    function stringifyValue(val) {
        if (isObj(val) && !isDate(val)) {
            return JSON.stringify(val)
        } else {
            return val
        }
    }


    // Making a custom form by this code
    function buildForm({ action, params }) {
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', action)

        Object.keys(params).forEach(key => {
            const input = document.createElement('input')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', key)
            input.setAttribute('value', stringifyValue(params[key]))
            form.appendChild(input)
        })
        return form
    }

    function goToPaymentPage_Post_Information(details) {
        const form = buildForm(details)
        document.body.appendChild(form)
        form.submit()
        form.remove()
    }



    const bookAndPay = (e) => {
        e.preventDefault();
        if (!other_name?.trim()) {
            return toast.error(err_NotFullName)
        }
        else if (regHtml.test(other_name?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (!other_mobile) {
            return toast.error(err_NotMobile)
        }
        else if(payable_amount <= 0){
            return toast.error("Amount should be greater than 0")
        }
        else if (other_mobile.length !== 10) {
            return toast.error(err_10DigitMobile)
        } else {
            const data = {
                user_id: profileId,
                booking_date: moment(new Date()).format("YYYY-MM-DD"),
                service_type: profileType,
                booked_for: isSomeOneElse ? "other" : "self",
                total_amount: payable_amount,
                other_name: other_name,
                other_mobile: other_mobile,
                other_gender: other_gender,
                remark: short_description,
                paid_amount: 0,
                isSomeOneElse: isSomeOneElse,
                service_provider_id: service_provider_id,
                appointment_date: slotDate,
                slots: slotidArr,
                allergy: allergy,
                past_medical_issue: pastMedicalIssue,
                on_medication: onMedication,
                booking_type: bookingType,
                applied_coupon: applied_coupon,
                coupon_discount: coupon_discount,

            }
           
            apiFunction.BookAndPay(data).then(result => {             
                if (result !== undefined) {
                    if (result.success === true) {
                        localStorage.setItem("BookingId", result.data.id)

                        const datanew = {
                            amount: payable_amount,
                            requestfrom: "WEB",
                            booking_id: result.data.id
                        }                      
                        apiFunction.PaymentOrder(datanew).then(result => {
                            if (result !== undefined) {
                                if (result.success === true) {

                                    const transaction_token = result.data.transaction_token
                                    const order_id = result.data.order_id

                                    var information = {
                                        action: `${paytmOpenPaymentPageUrl}?mid=${merchantId}&orderId=${order_id}`,
                                        params: {
                                            mid: merchantId,
                                            orderId: order_id,
                                            txnToken: transaction_token,
                                            TXN_AMOUNT: total_amount / 100,
                                            CURRENCY: "INR",
                                            WEBSITE: "DEFAULT"
                                            //WEBSITE: "WEBSTAGING"
                                        }
                                    }

                                    localStorage.setItem("paymentAmount", total_amount)
                                    // this is function that show's paytm gate way
                                    goToPaymentPage_Post_Information(information)
                                }
                            }
                        })
                    }

                }
            })
        }
    }
    // handle medical history
    const handleMedicalHistory = (medicalAttribute) => {
        let checkbox = document.getElementById(medicalAttribute)
        if (checkbox.checked != true) {           
            checkbox.checked = true
        } else {           
            checkbox.checked = false
        }
    }
    const handleCheckBox = () => {
        if (isChecked) {
            setIsChecked(false)
        } else {
            setIsChecked(true)
        }
    }

    const getCouponList = () => {
        apiFunction.couponList('Doctor').then(result => {
            if (result !== undefined) {
                if (result.success) {
                    setCouponList(result.data)
                }
            }
        })
    }
    /* const getCouponById = async (id) => {
        apiFunction.couponDetailsById(id).then(result => {
            if (result !== undefined) {
                if (result.success) {
                    setCouponDetails(result.data)
                }
            }
        })
    } */

    const handleCouponSelect = async (event) => {
        const selectedCouponId = parseInt(event.target.value);
        // Filter the couponList to find the selected coupon by id
        const selectedCouponDetails = couponList.find((coupon) => coupon.id === selectedCouponId);

        // Set the filtered coupon object in setCouponDetails
        setCouponDetails(selectedCouponDetails);


        if (selectedCouponDetails.minimum_amount > payable_amount) {
           return toast.error(`Minimum amount should be ${selectedCouponDetails.minimum_amount}`)
        }

        //calling api to validate coupon
        apiFunction.ValidateCoupon(selectedCouponDetails.coupon_code, 'Doctor', payable_amount).then(result => {
            if (result !== undefined) {
                if (result.success) {
                    // update total amount after discount total_amount - discount_amount
                   setPayable_amount((payable_amount - selectedCouponDetails.total_discount))
                    //seeting coupon_discount and applied_coupon in state
                    setbookAndPayForm({
                        ...bookAndPayForm,
                        ["applied_coupon"]: selectedCouponDetails.coupon_code,
                        ["coupon_discount"]: selectedCouponDetails.total_discount
                    })
                    setSelectedCoupon(selectedCouponId);
                    toast.success(result.message)
                } else {
                    toast.error(result.message)
                }
            }

        })
        
    }

    const handleClearCouponSelection = () => {
        // Reset the coupon-related state and update total_amount without the coupon discount
        setSelectedCoupon(null); // Deselect the coupon  
        setPayable_amount((payable_amount + couponDetails.total_discount)) // Update total_amount without the coupon discount
        setbookAndPayForm({
            ...bookAndPayForm,
            ["applied_coupon"]: "", // Reset the applied_coupon
            ["coupon_discount"]: 0, // Reset the coupon_discount
        });
        setCouponDetails(null); // Reset the coupon details
    };


    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className="paddtop paddbottom">
                <div className="">
                    <div className="booking_flex">
                        <div className="left_itme">
                            <div className="bkvideo_consult bg_light">
                                <div className="vc_header">
                                    <h4><img src={VideoIcon} />&nbsp;&nbsp;Video Consultation</h4>
                                </div>

                                <div className="vc_datetime">
                                    <div className="date">
                                        <h4>On {moment(slotDate).format('MMMM DD,YYYY')}</h4>
                                        <Link
                                            to="/show-profile-and-slots"
                                            state={{ userid: profileId, searchType: profileType }}>
                                            Change date &amp; time
                                        </Link>
                                    </div>
                                    <div className="time">
                                        At {slotTime}
                                    </div>
                                </div>
                                <div className="bk_drblock">
                                    <div className="dr_photo">
                                        <div className="doctor_img">
                                            <div className="dr_photo">
                                                <img src={(profilepicture) ? (profilepicture) : userIcon} alt="service-provider" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="doctor_bio">
                                        <h3 className="dr_name">{doctorName}</h3>
                                        <p className="dr_quali">{qualification}</p>
                                        <p className="dr_exp">{specialization[0].type}</p>
                                        <p className="dr_exp">{experience} years</p>

                                    </div>
                                </div>
                            </div>
                            <Link
                                to="/show-profile-and-slots"
                                state={{ userid: profileId, searchType: profileType }}
                                className="goback mt20">
                                <i className="fa fa-arrow-left" />
                                &nbsp; &nbsp;Go Back
                            </Link>
                        </div>
                        <div className="right_itme">
                            <div className="booking_dtl">
                                <h3 className="bk_title">Patient details</h3>
                                <h4 className="bk_sub_title">This video consultation is for:</h4>
                                <div className="vc_for">
                                    <div className="vc_rb">
                                        {/* Radio Buttons */}
                                        <input type="radio"
                                            id="test1"
                                            name="radio-group"
                                            defaultChecked={isSomeOneElse === false ? true : false}
                                            onClick={() =>
                                                setbookAndPayForm({
                                                    ...bookAndPayForm,
                                                    booked_for: "self",
                                                    isSomeOneElse: false
                                                })}
                                        />
                                        <label htmlFor="test1">{localStorage.getItem("user_name")}</label>
                                    </div>
                                    <hr />
                                    <div className="vc_rb">
                                        {/* Radio Buttons */}
                                        <input type="radio"
                                            id="test2"
                                            name="radio-group"
                                            onClick={() =>
                                                setbookAndPayForm({
                                                    ...bookAndPayForm,
                                                    booked_for: "other",
                                                    isSomeOneElse: true
                                                })}
                                            defaultChecked={isSomeOneElse === true ? true : false}
                                        />
                                        <label htmlFor="test2">Someone else</label>
                                    </div>
                                </div>
                                <h4 className="bk_sub_title">Please provide following information </h4>
                                <form onSubmit={(e) => bookAndPay(e)}>
                                    <div className>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Full name *</label>
                                            <input
                                                onChange={(e) => onInputChange(e)}
                                                value={other_name} name="other_name"
                                                type="text" className="form-control"
                                                placeholder="Enter full name"
                                                readOnly={isSomeOneElse ? false : true}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Mobile *</label>
                                            <input
                                                readOnly={isSomeOneElse ? false : true}
                                                type="text"
                                                maxLength={10}
                                                onChange={(e) => onInputChange(e)}
                                                value={other_mobile}
                                                name="other_mobile"
                                                className="form-control"
                                                placeholder="Enter mobile" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Gender *</label>
                                            <select
                                                disabled={!isSomeOneElse}
                                                onChange={(e) => onInputChange(e)}
                                                value={other_gender}
                                                name="other_gender"
                                                className="form-control"
                                            >
                                                <option value="">Select gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>


                                        <div className="form-group">
                                            <label>Medical History</label>
                                            <div className='gap-3'>
                                                <input type="checkbox" id='Allergies' onChange={e => setAllergy(!allergy)} checked={allergy} />
                                                <label className='mx-3-medical'>Allergies </label>
                                                <input type="checkbox" id='Past-Medical-Issue' onChange={e => setPastMedicalIssue(!pastMedicalIssue)} checked={pastMedicalIssue} />
                                                <label className='mx-3-medical'>Past Medical Issue </label>
                                                <input type="checkbox" id='On-Medication' onChange={e => setOnMeditation(!onMedication)} checked={onMedication} />
                                                <label className='mx-3-medical'>On Medication </label>
                                            </div>
                                        </div>
                                        {/* <div className="form-group">
                                            <label>Upload Medical Reports</label>
                                            <div className='gap-3'>
                                                <input type={'file'}/>
                                                
                                            </div>
                                        </div> */}
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Remark </label>
                                            <input
                                                type="text"                                                
                                                onChange={(e) => onInputChange(e)}
                                                value={short_description}
                                                name="short_description"
                                                className="form-control"
                                                placeholder="Enter remarks" />
                                        </div>
                                        <div className="form-group">
                                            <label>Booking Type</label>
                                            <div className='gap-3'>
                                                <input type="radio" name="bookingtype" onClick={e => setBookingType("New")} checked={bookingType === "New"} />
                                                <label className='mx-3-medical'>New </label>
                                                <input type="radio" name="bookingtype" onClick={e => setBookingType("Followup")} checked={bookingType === "Followup"} />
                                                <label className='mx-3-medical'>Followup </label>

                                            </div>
                                        </div>
                                    </div>
                                    
                                    <h4 className="bk_sub_title pt20">Bill details</h4>
                                    <div className="cons_fee">
                                        <span className="consl">
                                            Consultation fee
                                        </span>
                                        <span className="cons2"> &#x20B9;  {payable_amount}</span>
                                    </div>
                                    <hr />
                                    {couponList && couponList.length > 0 && (
                                        <>
                                            <h5 className='mb-5'>Applicable Coupons</h5>
                                            {couponList && couponList.length > 0 && (
                                                <div className='under-box'>
                                                    <h6>Apply Coupon</h6>
                                                    {couponList.map((item) => (
                                                        <div key={item.id} className="display-flex-t">
                                                            <input
                                                                type="radio"
                                                                style={{ cursor: 'pointer' }}
                                                                id={`test${item.id}`}
                                                                name="radio-group"
                                                                value={item.id}
                                                                checked={selectedCoupon === item.id}
                                                                onChange={handleCouponSelect}
                                                            />
                                                            <div className="div-f">
                                                                <label htmlFor={`test${item.id}`} className="line-height">
                                                                    {item.coupon_code}
                                                                </label>
                                                                <p>Save ₹ {item.total_discount}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {selectedCoupon && (
                                                <button onClick={handleClearCouponSelection}>Remove selected coupon</button>
                                            )}
                                        </>
                                    )}
                                    <div className="belowbtn">
                                        <p>
                                            Updates will be sent to registered email address
                                            {/* {(isSomeOneElse === true) ? other_mobile : localStorage.getItem("user_mobile")} */}
                                        </p>
                                        <h5>Undertaking</h5>
                                        <p className='Undertaking'>Teleconsultation provides an accessible, convenient, and cost-effective alternative to in-person consultations. However, it should be noted that teleconsultation may not include physical examination and other diagnostic procedures that are necessary for certain medical conditions.</p>
                                        <p>
                                            <input type={'checkbox'} checked={isChecked} onClick={handleCheckBox} /><span> I agree to the <Link to={'/terms-and-conditions'}>Terms and Conditions.</Link></span>
                                        </p>
                                    </div>
                                    <div>
                                        <div className="text-center"><button disabled={isChecked ? false : true} type="submit" className="btn btn_fill btn-block" onClick={(e) => bookAndPay(e)}>Book &amp;  pay</button></div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BookingDetailsFillForm