import React, { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import VinitaIcon from "../../images/images/vinita.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"

const AdminSidebar = () => {
    const location = useLocation()
    const pathName = location.pathname
    const mymobilenumber = localStorage.getItem("user_mobile")
    const [cssSidebar, setCssSidebar] = useState("subMenusClose")
    const service = JSON.parse(localStorage.getItem("services"))
    const [activePanel, setActivePanel] = useState("Aggregator")

    const changeCss = () => {
        cssSidebar === "subMenusOpen" ? setCssSidebar("subMenusClose") : setCssSidebar("subMenusOpen");
    }
    useEffect(() => {
        if (pathName === "/doctor-Application-details" || pathName === "/homecare-Application-details" || pathName === "/history-my-doctors" || pathName === "/history-my-nurses" || pathName === "/history-my-payments" || pathName === "/history-my-medical-reports" || pathName === "/history-my-feedback-query") {
            setActivePanel("User")
        }
        else {
            setActivePanel("Service")
        }
    }, [])
    const handelToggleBtn = (e) => {
        if (e.target.checked)
            setActivePanel("Aggregator")
        else
            setActivePanel("User")
    }
    return (
        <>
            <div className="left_panel">
                <div className="sidebar">
                    <ul className="sidebar_menu">
                        <li className="myuser">
                            <div className="userdtl">
                                <div className="userpic">
                                    <img src={userIcon} alt />
                                </div>
                                <div className="userinfo">
                                    <h4>Admin</h4>
                                </div>

                            </div>
                        </li>
                        <li className={pathName === "/AdminDashBoard" ? "active" : ""}>
                            <Link to="/AdminDashBoard">
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li onClick={changeCss} className={(pathName === "/doctor-Application-details" || pathName === "/homecare-Application-details" || pathName === "/history-my-doctors" ) ? "active" : ""}>
                            <NavLink to="">
                                <span>Users</span>
                            </NavLink>
                            <div className={cssSidebar}>
                                <ul className='subMenuItems'>
                                    <li>
                                        <Link to="/doctor-Application-details"
                                            className={pathName === "/doctor-Application-details" ? "active" : ""}>
                                            Doctors
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/homecare-Application-details"
                                            className={pathName === "/homecare-Application-details" ? "active" : ""}>
                                            Nurses</Link>
                                    </li>
                                    <li>
                                        <Link to=""
                                            className={pathName === "/accounts-ambulance" ? "active" : ""}>
                                            Ambulances</Link>
                                    </li>


                                </ul>
                            </div>
                        </li>

                        {/* <li className={pathName === "/accounts-bookings" ? "active" : ""}>
                            <Link to="/accounts-bookings">
                                Users
                            </Link>
                        </li> */}
                        <li className={pathName === "/history-my-doctors" ? "active" : ""}>
                            <Link to="/history-my-doctors">
                                <span>Reports</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AdminSidebar;