import { useEffect, useState } from 'react';
import HomepageHeader from '../../components/headers/Header'
import SidebarUserPanel from '../../components/user/Sidebar'
import APICore from '../../services/APICore';
import userIcon from "../../images/adminpanel/UserIcon.svg";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ThreeDots } from 'react-loader-spinner'
import { Link, useNavigate } from 'react-router-dom';

import { OnePatientDetails } from '../../services/MypatientService';
import Sidebarmobile from '../../components/user/Sidebarmobile';

const ServiceProvider = () => {
    const [doctorData, setDoctorData] = useState([])
    const [nurseData, setNurseData] = useState([])
    const [ambulanceData, setAmbulanceData] = useState([])
    const [isLoaded, setIsLoaded] = useState(true)
    const navigateTo = useNavigate()

    const cardata = async () => {
        const result = await APICore.get(`/users/Doctor/list`)
        if (result != null) {
            if (result != undefined) {
                setDoctorData(result.data)
              
            }
        }
       
    }

    const getNurseData = async () => {
        const result = await APICore.get(`/users/Nurse/list`)
        if (result != null) {
            if (result != undefined) {
                setNurseData(result.data)
            }
        }
       
    }

    const getAmbulanceData = async () => {
        const result = await APICore.get(`/users/Ambulance/list`)
        if (result != null) {
            if (result != undefined) {
                setAmbulanceData(result.data)
            }
        }
    
    }



    const handleViewDoctorProfile = (id,service) => {
        let userId = localStorage.getItem('userid')
        if (id !== null) {
            try {
                OnePatientDetails(userId, id,service)
                    .then((res) => {                        
                        if (res.success === true) {
                            navigateTo('/my-patient-profile', { state: { profileData: res.data } })
                        }
                    })
                    .catch();
            } catch (error) { }
        }
    }

    useEffect(() => { 
        cardata();
        getNurseData();
        getAmbulanceData();  
    }, [])
    const [isVisible, setIsVisible] = useState(false);

    return (
        <>
            <div className="outerhomepage">
                {/*Top Navbar Menu*/}
                <HomepageHeader />

                <section className=" paddbottom">
                    <div className="">
                        <div className="userpanel_page bg_light">

                            {/* Side bar */}
                            {/* <SidebarUserPanel /> */}
                            <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                                {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
                            </button>
                            {isVisible && (
                                <div>
                                    <Sidebarmobile />
                                </div>
                            )}

                            <SidebarUserPanel />

                            <div className="right_panel ">
                                <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                    {isLoaded ?
                                        <div class="content content-body">
                                            <div class="row">
                                                <div class="col-md-8">
                                                    <div class="content-header">
                                                        <h1 class="pull-left">
                                                            Service Provider
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <input type="text" className='form-control' placeholder='Search' />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <select className='form-control'>
                                                                <option>Sort by Date</option>
                                                                <option>Sort by Booking ID</option>
                                                                <option>Sort by Doctor</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row spacetop">
                                                {/* My Doctors */}
                                                <div className='col-lg-12 '><h4 class="page_title">My Doctors</h4>   </div>
                                                {(doctorData != null && doctorData != undefined && doctorData.length > 0) ?
                                                    (doctorData != null && doctorData != undefined) && doctorData.map((key) => (
                                                        <div className='col-lg-3'>
                                                            <div class="db_box">
                                                                <div className='db_body'>
                                                                    <div class="sv_center">
                                                                        <div className='sv_photo'><img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt /></div>
                                                                        <div className='info_text'>
                                                                            <h5>{key.name}</h5>
                                                                            <p>{key.headline}, {key.city}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='db_footer'>
                                                                    <div className='linkbtn'><a onClick={() => handleViewDoctorProfile(key.doctor_user_id,'Doctor')}>Profile </a></div>
                                                                    <div className='linkbtn'><Link to="/show-profile-and-slots"
                                                                        state={{
                                                                            userid: key.doctor_user_id,
                                                                            searchType: "Doctor"
                                                                        }}>Book Again </Link></div>
                                                                </div>
                                                            </div>
                                                        </div>)) : <p className='marginme'>"No Record Found"</p>}


                                                {/* My Nurses */}
                                                <div className="col-lg-12">
                                                    <h4 className="page_title">My Nurses</h4>
                                                    {nurseData != null && nurseData.length > 0 ? (
                                                        nurseData.map((key) => (
                                                            <div className="col-lg-3">
                                                                <div className="db_box">
                                                                    <div className="db_body">
                                                                        <div className="sv_center">
                                                                            <div className="sv_photo">
                                                                                <img
                                                                                    src={
                                                                                        key.profile_pic !== null && key.profile_pic !== "null"
                                                                                            ? key.profile_pic
                                                                                            : userIcon
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info_text">
                                                                                <h5>{key.name}</h5>
                                                                                <p>
                                                                                 {key.city}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_footer">
                                                                        <div className="linkbtn">
                                                                            <a onClick={() => handleViewDoctorProfile(key.doctor_user_id,'Nurse')}>
                                                                                Profile
                                                                            </a>
                                                                        </div>
                                                                        <div className="linkbtn">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/homecare",
                                                                                  
                                                                                }}
                                                                            >
                                                                                Book Again
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p className="marginme">"No Record Found"</p>
                                                    )}
                                                </div>


                                                {/*My Ambulance Providers */}
                                                <div className="col-lg-12">
                                                    <h4 className="page_title">My Ambulances</h4>
                                                    {ambulanceData != null && ambulanceData.length > 0 ? (
                                                        ambulanceData.map((key) => (
                                                            <div className="col-lg-3" key={key.agency_name}>
                                                                <div className="db_box">
                                                                    <div className="db_body">
                                                                        <div className="sv_center">
                                                                            <div className="sv_photo">
                                                                                <img
                                                                                    src={
                                                                                        key.profile_pic !== null && key.profile_pic !== "null"
                                                                                            ? key.profile_pic
                                                                                            : userIcon
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="info_text">
                                                                                <h5>{key.name}</h5>
                                                                                <p>
                                                                                    {key.city}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_footer">
                                                                        <div className="linkbtn">
                                                                            <a onClick={() => handleViewDoctorProfile(key.doctor_user_id,'Ambulance')}>
                                                                                Profile
                                                                            </a>
                                                                        </div>
                                                                        <div className="linkbtn">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/ambulance",
                                                                                }}
                                                                            >
                                                                                Book Again
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p className="marginme">"No Record Found"</p>
                                                    )}
                                                </div>




                                            </div>


                                        </div> :
                                        <div class="defaultloader">
                                            <ThreeDots
                                                height="80"
                                                width="80"
                                                radius="9"
                                                color="#056B66"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            /></div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>




        </>
    );
}
export default ServiceProvider;