

export const Change_to_12HourFormat = (time) => {

    var timeNew = time;
    var getOnlyhour = Number(timeNew.substr(0, 2))

    //Check Am or Pm
    let suffix = getOnlyhour >= 12 ? " PM" : " AM"
    // change in 24 hour to 12 hour 

    // if time More than 12 then 12 Minus from time 
    let hourStr = getOnlyhour <= 12 ? getOnlyhour : getOnlyhour - 12

    hourStr = hourStr > 9 ? hourStr : '0' + hourStr

    let finalTime = hourStr + timeNew.substr(2, 3) + suffix
    return finalTime
}
