import React, { useEffect, useState } from 'react'

const DaysGettingStatrtedPatient = ({ incrementBtn, decrementBtn, setDay, setStart_time, setEnd_time ,addAvail }) => {
  const [colorId,setColorId]=useState()
  let days=['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
  const selectDays=(val,index)=>{
    setDay(val)
    setColorId(index)
  }
  const handleClick=()=>{
    incrementBtn();
    addAvail();
    
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="profile_form1">
          <div className="row">
            <div className="col-lg-10">
              <h3 className="drprofile_title ">Days</h3>
              <div className="daylist mb-30">
                {
                  days.map((val,ind)=>{
                    return (
                      <button className={colorId===ind?"btn margin-10px dat-selection":"btn margin-10px btn-primary"}key={ind} onClick={(e)=>selectDays(val,ind)}>{val}</button>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10"><label>Session 1</label></div>
            <div className="col-lg-5">
              <div className="form-group ">
                <select className="form-control" onChange={(e)=>setStart_time(e.target.value)}>
                  <option>From</option>
                  <option>2</option>
                </select>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="form-group ">
                <select className="form-control" onChange={(e)=>setEnd_time(e.target.value)}>
                  <option>To</option>
                  <option>2</option>
                </select>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-10"><label>Session 2</label></div>
            <div className="col-lg-5">
              <div className="form-group ">
                <select className="form-control">
                  <option>From</option>
                  <option>2</option>
                </select>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="form-group ">
                <select className="form-control">
                  <option>To</option>
                  <option>2</option>
                </select>
              </div>
            </div>
          </div> */}
          <p style={{ color: '#999DA1', marginBottom: 30 }}><i className="fa fa-plus" /> Add timings for remaining days</p>
          <div className="info_box_btn ">
            <button type='button' onClick={decrementBtn} className="btnlink">Go back</button>
            <button type='button' onClick={handleClick} className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
          </div>
        </div>
      </div>
    </div>


  )
}

export default DaysGettingStatrtedPatient