import Table from 'react-bootstrap/Table';
import moment from 'moment/moment';
import { saveAs } from "file-saver";
import { BookingServices } from '../services';
import React, { Component, PropTypes, useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import { useParams } from 'react-router-dom';
import Eprecriptiondownload from '../pages/E-prescription/downloadeprescription';
import { toast } from 'react-toastify';
import axios from 'axios';
const token = localStorage.getItem("token")
const baseurl = process.env.REACT_APP_API_BASE_URL
import CircularProgress from '@mui/material/CircularProgress';
import { set } from 'date-fns';

const BookingsActivities = ({ eprescription, medicalreport, activelog, stype, getPrecriptio, report }) => {
  const apiFunction = new BookingServices()
  const [prescription, setPrescription] = useState("")
  const [value, setValue] = useState(RichTextEditor.createValueFromString(prescription, 'markdown'));
  const { id } = useParams();
  const [UserType, setUserType] = useState("")
  const [precriptionData, setPriscriptionData] = useState(null)
  const [prescriptionid, setPrescriptionid] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [filepath, setfilepath] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [reportFormData, setReportFormData] = useState({
    report_name: "",
    report_type: "",
    report_date: "",
    attachment: "",
    booking_id: id
  });

  const OnInputReportChange = (e) => {
    setReportFormData({ ...reportFormData, [e.target.name]: e.target.value });
  }



  const handleClose = () => {
    window.$("#modalmedicalreport").modal("hide")
  }
  const handleShow = () => {
    window.$("#modalmedicalreport").modal("show")
  }

  useEffect(() => {
    setUserType(localStorage.getItem("user_type"))
    console.log("Activities ", activelog)
    setPriscriptionData(eprescription)
  }, [])



  const AddPrescription = (e) => {
    if (prescription === "") {
      toast.error("Please Enter Prescription")
      return
    }
    setIsLoading(true)
    var data = {
      "prescription": prescription,
      "user_id": localStorage.getItem("userid"),
      "booking_id": id
    }

    apiFunction.AddPrescriptionAPI(data).then(result => {

      if (result !== undefined) {
        if (result.success === true) {
          console.log(result.data, "----prescription---")
          setIsLoading(false)
          getPrecriptio()
          window.$("#modalepriscription").modal("hide")
          setPrescription("")
          setValue(RichTextEditor.createValueFromString("", 'markdown'))

        }
        else {
          setIsLoading(false)
        }
      }
      else {
        setIsLoading(false)
      }
    })
  }

  const handleChange = (v) => {
    setValue(v);
    //setPrescription(v.toString("markdown"))
    let html1 = v.toString("html")
    //remove /n from html
    let html = html1.replace(/(\r\n|\n|\r)/gm, "");
    //remove &nbsp; from html
    let html2 = html.replace(/&nbsp;/g, "");
    setPrescription(html2)

  }
  const postmedical = async (e) => {
    try {
       reportFormData.attachment = filepath;
     if (reportFormData.report_name === "") {
        toast.error("Please Enter Title")
        return
      }
      else if (reportFormData.report_type === "") {
        toast.error("Please Select Report Type")
        return
      }
      else if (reportFormData.report_date === "") {
        toast.error("Please Select Report Date")
        return
      }
      else if (reportFormData.attachment === "") {
        toast.error("Please Select Attachment")
        return
      }
      setIsLoaded(false)      

      var config = {
        headers: {
          'authorization': token,
          'Content-Type': 'application/json'
        },

      };
      const result = await axios.post(`${baseurl}users/medicalreports`, JSON.stringify(reportFormData), config)
    
      if (result !== undefined) {
        setIsLoaded(true)
        toast.success("Medical Report is Uploaded")
        setReportFormData({
          report_name: "",
          report_type: "",
          report_date: "",
          attachment: "",
          booking_id: id
        })        
        setfilepath("")
        window.$("#modalmedicalreport").modal("hide")
        report()
      }
    } catch (error) {
      toast.error(error)
    }

  }

  const postmedicalreport = async (e) => {
    setIsLoaded(false)

    var config = {
      headers: {
        'authorization': token,
      },
    };
    const selectedFile = e.target.files[0];
    // const selectedFileName = e.target.files[0];
    const formData = new FormData();
    formData.append("files", selectedFile);

    if ((selectedFile.size / 1000) > 200) {
      toast.error("Size should be less than 200 KB")
    }
    else {
      const result = await axios.post(`${baseurl}uploads?for=medical_report`, formData, config)
      console.log(result.data.data, "result")
      if (result != null) {
        setfilepath(result.data.data.filePath)
        setIsLoaded(true)
      }

    }


  }

  const reportList = [
    { value: "Arthroscopy", label: "Arthroscopy" },
    { value: "Bronchoscopy", label: "Bronchoscopy" },
    { value: "Clinical Laboratory", label: "Clinical Laboratory" },
    { value: "Colonoscopy", label: "Colonoscopy" },
    { value: "Cystoscopy", label: "Cystoscopy" },
    { value: "Cytology", label: "Cytology" },
    { value: "Electrocardiogram (ECG)", label: "Electrocardiogram (ECG)" },
    { value: "Endoscopy", label: "Endoscopy" },
    { value: "Gastroscopy", label: "Gastroscopy" },
    { value: "Hysteroscopy", label: "Hysteroscopy" },
    { value: "Histopathology", label: "Histopathology" },
    { value: "Laparoscopy", label: "Laparoscopy" },
    { value: "Laryngoscopy", label: "Laryngoscopy" },
    { value: "Ophthalmoscopy", label: "Ophthalmoscopy" },
    { value: "Otoscopy", label: "Otoscopy" },
    { value: "Others", label: "Others" },
    { value: "Pathology", label: "Pathology" },
    { value: "Radiology", label: "Radiology" },
    { value: "Sigmoidoscopy", label: "Sigmoidoscopy" },
    { value: "Surgical Pathology", label: "Surgical Pathology" },
    { value: "Ureteroscopy", label: "Ureteroscopy" },
    { value: "Urethroscopy", label: "Urethroscopy" },
    { value: "Urethrocystoscopy", label: "Urethrocystoscopy" },
    { value: "Vaginoscopy", label: "Vaginoscopy" }
];


  return (

    <>
      <div className='profile_body'>


        <div className="row ">
          <div className="col-lg-12"><h4 class="page_title">E-Prescriptions {stype === "service" ? (<a onClick={() => { window.$("#modalepriscription").modal("show") }} className='ml-1' data-toggle="tooltip" data-placement="right" title='Add Prescriptions'> <i className='fa fa-plus-circle' style={{ cursor: 'pointer' }}></i> <span className='it-small'>*Click here to Add Prescription</span></a>) : ""} </h4>  </div>
          <div className="col-lg-12">
            <div className="dr_table">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Uploaded On</th>
                    <th>Download</th>

                  </tr>
                </thead>
                <tbody>
                  {(eprescription != null && eprescription != undefined && eprescription.length > 0) ?

                    (eprescription != null && eprescription.map((key) => (
                      <tr>
                        <td>E Prescription </td>
                        <td>{(moment(key.created_at).utc()).format("DD/MM/YYYY") + ", " + (moment(key.created_at).utc()).format("hh:mm A")}</td>
                        {/* <td><a onClick={() => setPrescriptionid(key.id)} > Download</a></td> */}
                        <td>
                          <a href={key.prescription_file} target="_blank" download><i className="fa fa-download" aria-hidden="true"></i> Download</a>
                        </td>
                      </tr>


                    ))) : "No Record Found"}


                </tbody>
              </Table>
            </div>

          </div>

        </div>


        <div className="row mt-3">
          <div className="col-lg-12"><h4 class="page_title">Reports {stype === "user" && <a onClick={handleShow} className='add_mr ml-1' style={{ cursor: 'pointer' }} title='Add Reports'> <i className='fa fa-plus-circle'></i><span class="it-small">*Click here to Add Report</span></a>}</h4>  </div>
          <div className="col-lg-12">
            <div className="dr_table">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Report Type</th>
                    <th>Report Date</th>
                    <th>View</th>

                  </tr>
                </thead>
                <tbody>
                  {(medicalreport != null && medicalreport != undefined && medicalreport.length > 0) ?
                    (medicalreport != null && medicalreport.map((key) => (
                      <tr>
                        <td>{key.report_name}</td>
                        <td>{key?.report_type}</td>
                        <td>{(moment(key?.report_date)).format("DD/MM/YYYY")} </td>
                        <td><a href={key.attachment} target="_blank">View</a></td>
                        {/* <td><a onClick={() => saveFile(key.attachment)}> Click to download</a></td> */}
                      </tr>

                    ))) : "No Record Found"}

                </tbody>
              </Table>


            </div>
          </div>
        </div>


        <div className="row mt-3">
          <div className="col-lg-12"><h4 class="page_title">Activity Logs</h4>  </div>
          <div className="col-lg-12">
            <div className="dr_table">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Performed By</th>
                    <th>Log Date Time</th>

                  </tr>
                </thead>
                <tbody>
                  {
                    (activelog != null && activelog != undefined && activelog.length > 0) ?


                      (activelog != null && activelog.map((obj, key) => (
                        <tr>
                          <td>{obj.title}</td>
                          <td>{obj.performed_by}</td>
                          <td>{moment(obj.log_date_time).utc().format("DD/MM/YYYY hh:mm A")}</td>

                        </tr>

                      ))) : "No Record Found"}

                </tbody>
              </Table>


            </div>
          </div>
        </div>

        <div>
          {prescriptionid !== undefined && prescriptionid !== null && prescriptionid !== "" ?
            <div id="capture">
              <Eprecriptiondownload prescriptionid={prescriptionid} setPrescriptionid={setPrescriptionid}></Eprecriptiondownload>
            </div>
            : ""
          }

        </div>




      </div>
      <div class="modal fade" id="modalepriscription" tabIndex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content" >
            {/* <!-- Modal header --> */}
            <div class="modal-header">
              <h5 class="modal-title" id="modalforprofile">
                E-Prescription
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-24px" }}
                onClick={() => { window.$("#modalepriscription").modal("hide") }}
              >&times;</button>

            </div>
            <div class="modal-body">
              {/* <!-- Form START --> */}
              <RichTextEditor
                value={value}
                onChange={handleChange}
              />


            </div>


            <div class="modal-footer d-flex justify-content-between">
              <p></p>
              {/* Conditionally render the loader or "Save" button based on the show variable */}

              {isLoading ? (
                <div className="spinner-border text-primary" role="status">
                  <span style={{ color: 'green' }}>Loading...</span>
                </div>
              ) : (
                <button type="button" className="btn btn-sm btn-primary-soft" onClick={AddPrescription}>
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>



      <div
        class={"modal fade"}
        id="modalmedicalreport"
        tabIndex="-1"
        aria-hidden="false"
      >
        <div class="modal-dialog mydailog">
          <div class="modal-content">
            {/* <!-- Modal header --> */}
            <div class="modal-header">
              <h5 class="modal-title pull-left" id="modalpris">  Add Medical Report </h5>
              <button type="button" onClick={(e) => handleClose(e)} class="btn-close pull-right" data-bs-dismiss="Close" aria-label="Close">&times;</button>
            </div>
            <div class="modal-body">
              <div className='row'>
                <div class="col-lg-6">
                  <div class="fd_input mb-2">
                    <div class="form-group"><label>Title*</label>
                      <input type="text" required name="report_name" class="form-control" placeholder="" onChange={(e) => OnInputReportChange(e)} />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="fd_input mb-2">
                    <div class="form-group">
                      <label for="ReportType">Report Type</label>
                      <select id="report_type" name="report_type" class="form-control" required onChange={e=>OnInputReportChange(e)}>                        
                        <option value="" disabled selected>Select Report Type</option>
                        {reportList.map((key) => (
                          <option value={key.value}>{key.label}</option>
                        ))}                     
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="fd_input mb-2">
                    <div class="form-group"><label>Report Date*</label>
                      <input type="date" required name="report_date" class="form-control" placeholder="" onChange={e=>OnInputReportChange(e)} />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="fd_input mb-2">
                    <div class="form-group"><label>File/Attachment*</label>
                      <input type={'file'} required name="attachment" class="form-control" placeholder="" onChange={(e) => postmedicalreport(e)} />
                      <span style={{ color: "red" }}>Max File Size 200 KB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <button className='btn btn-info' onClick={(e) => postmedical(e)} disabled={!isLoaded}> {!isLoaded && <CircularProgress color="inherit" size={16} />} Upload</button>
            </div>
          </div>

          {/* <!-- Form END --> */}
        </div>
        {/* <!-- Modal footer --> */}
      </div>
    </>


  );

}
export default BookingsActivities