const dashboarddata =
[
    {
        name:"Today 's Booking"

    },
    {
        name:"Pending Booking"

    },
    {
        name:"Completed Booking"

    },
    {
        name:"Conclude Booking"

    },
    {
        name:"My Booking"

    },
    {
        name:"My Nurse / Homecare"

    },

    {
        name:"My Ambulance"

    },
]
export default dashboarddata; 