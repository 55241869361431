import axios from 'axios';
import ApiError from './ApiError';

const DEBUG = Boolean(process.env.REACT_APP_DEBUG === "1");
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = localStorage.getItem("token");

class APIHandler {
  // Get request
  async get(uri, hasToken = true) {
    try {
      const apiResponse = await axios.get(`${baseUrl}${uri}`, getConfig(hasToken));     
      return execute(apiResponse);
    } catch (err) {
      // Handle specific error conditions here if needed
      throw new ApiError(500, 'Something went wrong');
    }
  }
}

// Get Axios config with or without token
function getConfig(hasToken) {
  const config = {
    headers: {}
  };

  if (hasToken) config.headers.authorization = token;

  return config;
}

// Process the API response
function execute(apiResponse) { 
  if (!apiResponse.data.success || apiResponse.status !== 200) {
    if (DEBUG) {
      throw new ApiError(apiResponse.status, apiResponse.data.message);
    }
    // Handle other non-success cases if needed
  }

   return apiResponse.data;
}

export default new APIHandler();
