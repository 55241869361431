import React from 'react'
import { Link } from 'react-router-dom';

const SquareCard = ({ image, title, paragraph, link }) => {
    return (
        <div className="service_card">
            <Link to={link}>
                <div className="service_header">
                    <img src={image} />
                </div>
                <div className="service_footer">
                    <h4>{title}</h4>
                    <p>{paragraph}</p>
                </div>
            </Link>
        </div>
    )
}

export default SquareCard;