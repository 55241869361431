import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { AccountBookingComponent } from '../../../components'
import { BookingServices } from '../../../services'
import axios from 'axios'
import moment from 'moment'
import { ThreeDots } from 'react-loader-spinner'

const token = localStorage.getItem("token")
const baseurl = process.env.REACT_APP_API_BASE_URL


const MyBookingsServiceProvider = () => {

    const apiFunction = new BookingServices()
    const { state } = useLocation()
    const paymentStatus = state ? state.status
        ? state.status : null : null
    const [bookingDataMain, setBookingDataMain] = useState([])
    const [isloading, setIsLoading] = useState(true)
    const [isloadingHomecare, setIsLoadingHomecare] = useState(true)
    const [isloadingAmbulance, setIsLoadingAmbulance] = useState(true)
    const [status, setStatus] = useState("Upcoming")
    const [carddata, setCardData] = useState([])
    const [sortby, setsortby] = useState("")
    const [filterset, setfilterset] = useState([])
    const location = useLocation()
    const [isLoaded, setIsLoaded] = useState(true)
    const [homecareData, setHomecareData] = useState([])
    const [ambulanceData, setAmbulanceData] = useState([])


    useEffect(() => {
        // setStatus(location.state?.status || status)
        // console.log(status, "status")
        setIsLoading(true);
        LoadBookings("Doctor");
        // LoadHomecareBookings("Nurse");
        // LoadAmbulanceBooking("Ambulance");
        
    }, [status])


    useEffect(() => {
        var temp = carddata;
        if (sortby !== null && sortby !== "") {
            if (sortby === "service_provider") {
                temp = temp.sort((a, b) =>
                    a.user_name.localeCompare(b.user_name));
            }
            if (sortby === "service") {
                temp = temp.sort((a, b) =>
                    a.service.localeCompare(b.service));
            }
           
            setCardData(temp)

        }
        else {
            setCardData(temp)
        }
    }, [sortby])

    useEffect(() => {
        if (paymentStatus === "true") {
            return toast.success("Payment successfully received")
        } else if (paymentStatus === "false") {
            return toast.error("Payment not received error!!")
        }
    }, [paymentStatus])


    const LoadBookings = async (type) => {
        setIsLoading(true)
        apiFunction.BookingServiceProviderAPI(type, status,filterset).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    
                    setCardData(result.data.teleconsultationBookings)
                    setHomecareData(result.data.homeVisitBookings)
                    setAmbulanceData(result.data.ambulanceBookings)
                    setfilterset(result.data)
                    setIsLoading(false)
                   
                }
            }
            setIsLoading(false)
        })
    }


    return (

        <AccountBookingComponent sortby={sortby} setsortby={setsortby} setCardData={setCardData} setStatus={setStatus} setHomecareData={setHomecareData} setAmbulanceData={setAmbulanceData} status={status} data={bookingDataMain} selfDetails={true} isloading={isloading}  filterset={filterset} carddata={carddata} homecareData={homecareData} ambulanceData={ambulanceData} type="service" loadcarddata={LoadBookings}/>
    )
}

export default MyBookingsServiceProvider