import React, { useState } from 'react'

const ConsultationFeeGettingStatrtedPatient = ({ incrementBtn, decrementBtn, setConsultation_fees, DoctorRegistrationCall}) => {
  const handleClick=()=>{
    // incrementBtn();
    DoctorRegistrationCall();
    
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="profile_form1">
          <div className="row">
            <div className="col-lg-9">
              <h3 className="drprofile_title">Consultation fee</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="form-group">
                <div className="input-group">
                  <span className="input-group-addon">Rs.</span>
                  <input type="text" className="form-control" placeholder="Type consulatation fee" onChange={(e)=>setConsultation_fees(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="info_box_btn">
            <button type='button' onClick={decrementBtn} className="btnlink">Go back</button>
            <button type='button' className="btn btn-theme btn-xs radius " onClick={handleClick} style={{ width: 70 }}>Next</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default ConsultationFeeGettingStatrtedPatient