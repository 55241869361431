import React from 'react'
import { Link } from 'react-router-dom'
import AboutImage from "../images/images/about_img.png"

const AboutFasttrack = () => {
    return (
        <section className="about_sec">
            <div className="container">
                <div className="about_intro">
                    <div className="about_img">
                        <img src={AboutImage} />
                    </div>
                    <div className="about_content">
                        <h3 className="heading2">
                            We have experts in end to end Teleconsulting,<br />
                            Homecare Nurse and Emergency Services
                        </h3>
                        <p className="para2">You can expect the highest standards in clinic-like consultation through a video call with the doctor. We have designed the process that can give you the most advance level of medical care.</p>
                        <div className="mt40"><Link to="/about" className="btn btn_fill">About FastTrack</Link></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutFasttrack