import React,{useState} from 'react'
import { DoctorUploadImageFor } from '../../services/DoctorOnboarding'
import { toast } from 'react-toastify';
import { uploadImageFor } from '../../enums/Enums';
import { UploadImageFor } from '../../services'

const EstablishmentProof = ({incrementBtn,decrementBtn,setEstablishment_proof}) => {
    const [image,setImage]=useState()
    const inputImage=()=>{
        let selectPhotos = document.getElementById("file-input");
        selectPhotos.click();
        
    }
    function checkAndFilterImage(e) {
        const selectedFile = e.target.files[0];
        setImage(selectedFile.name)
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, uploadImageFor.establishment_doc).then(result => {
            console.log(result)
            toast.success(result.message)
            if (result.success === true) {
                const profileData = {
                    profile_pic: result.data.filePath
                }
                setEstablishment_proof(profileData.profile_pic)
            }
        })
      }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="profile_form1">
                    <div className>
                        <div>
                            <h4 className="mb-30">Establishment proof</h4>
                            <h3 className="drprofile_title">I AM</h3>
                            <div className="radioblock flexcoloum">
                                <p>
                                    <input type="radio" id="test1" name="radio-group" defaultChecked />
                                    <label htmlFor="test1">The owner of establishments</label>
                                </p>
                                <p>
                                    <input type="radio" id="test2" name="radio-group" />
                                    <label htmlFor="test2">Have rented at other establishment</label>
                                </p>
                                <p>
                                    <input type="radio" id="test3" name="radio-group" />
                                    <label htmlFor="test3">Practicing at home</label>
                                </p>
                                <p>
                                    <input type="radio" id="test4" name="radio-group" />
                                    <label htmlFor="test4">Acceptable documents:</label>
                                </p>
                            </div>
                        </div>
                        <div className="upload_box mt30">
                        {
                                image===undefined?<img id='imagePreview' src="images/upload.png" />:
                                <><h4>File </h4><p style={{'color':'green'}}>{image}</p></>
                            }
                            <p>Accepted proof (any one) clinic regisrtation proof/ Waste disposal proof/ Tax reciept</p>
                            <input
                            id="file-input"
                            type="file"
                            name="name"
                            onChange={checkAndFilterImage}
                            style={{ display: "none" }}
                            />
                            <button onClick={inputImage} className="btn btn-theme btnbig radius">Upload</button>
                        </div>
                        <div className="altbtn mt30">
                            <a href="#" className="btnlink">View alternatives proof</a>
                        </div>
                        <div className="info_box_btn">
                            <button type='button' onClick={decrementBtn} className="btnlink">Go back</button>
                            <button type='button' onClick={incrementBtn}  className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EstablishmentProof