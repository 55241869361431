const API_Base_Url=process.env.REACT_APP_API_BASE_URL


export const DoctorAllDetails = async (paramsData) => {
  console.log(`/teleconsultancy/doctor?status=${paramsData}`)
    try {
      const response = await fetch(`${API_Base_Url}/teleconsultancy/doctor?status=${paramsData}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'authorization': localStorage.getItem('token'),
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
      alert("Something went wrong , Please try again later.");
    }
  };
  // Pending doctor details
  export const DoctorPendingDetails = async () => {
    try {
      const response = await fetch(`${API_Base_Url}/teleconsultancy/doctor?type=Pending&status=false`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'authorization': localStorage.getItem('token'),
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
      alert("Something went wrong , Please try again later.");
    }
  };

  export const OneDoctorDetails = async (id) => {
    try {
      const response = await fetch(`${API_Base_Url}/teleconsultancy/doctor/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'authorization': localStorage.getItem('token'),
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
      alert("Something went wrong , Please try again later.");
    }
  };
  // Doctor Approval
  export const DoctorApproval = async (Doctorid,service,status) => {
    
    try {
      console.log(Doctorid,service,status)
      const response = await fetch(`${API_Base_Url}/users/${Doctorid}/services/update?service=${service}&status=${status}&changeFor=Approval`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'authorization': localStorage.getItem('token'),
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
      alert("Something went wrong , Please try again later.");
    }
  };