import axios from 'axios';
import { toast } from 'react-toastify';
const API_Base_Url = process.env.REACT_APP_API_BASE_URL
const UploadImageFor = async (formdata, type) => {
    try {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem("token")
            }
        }

        const response = await axios.post(`${API_Base_Url}uploads?for=${type}`, formdata, headers).then((response) => {
            return response
        }
        ).catch(error => {
            return toast.error(error)

        });
        return response.data
    } catch (error) {
        return toast.error('Something went wrong , Please check your internet connection.')
    }
}

export default UploadImageFor;