import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { regEmailValidation, regHtml, regMobile } from '../../helpers/Validate';
import { ProfileService, UploadImageFor } from '../../services'
import { DoctorRegistered } from '../../services/DoctorOnboarding'
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner'
import classNames from 'classnames';
import axios from 'axios';

import {
    err_10DigitMobile,
    err_NotCity,
    err_NotCountry,
    err_NotDistrict,
    err_NotGender,
    err_NotHouseNo,
    err_NotMobile, err_NotName,
    err_NotSixDigitPinCode, err_NotState,
    err_NotStreetColony,
    err_NotValidEmail, HtmlErrorMessage, uploadImageFor
} from '../../enums/Enums';
import { Label } from '@mui/icons-material';
const baseurl = process.env.REACT_APP_API_BASE_URL

const AmbulanceProfile = () => {
    const token = localStorage.getItem('token')

    const apiFunction = new ProfileService()
    const [stateList, setStateList] = useState([])
    const [allcity, setallcity] = useState([])
    const [cityid, setcityid] = useState("")
    const [applied, setApplied] = useState(false)


    const [ambulanceProfileData, setAmbulanceProfileData] = useState({
        email: '',
        mobile: '',
        state: '',
        city: '1',
        pincode: '',
        address: '',
        owner_name: '',
        agency_name: "",
        own_type: '',
        contact_person: '',
        is_approved: 0,
        dob: '',
       

    })

   /*  let { email,mobile,state,city, pincode, address, owner_name, agency_name, own_type, 
        contact_person } = ambulanceProfileData */


    // setting form data------------------------------------------
    const onInputChange = (e) => {
        setAmbulanceProfileData({ ...ambulanceProfileData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        cityAPI();
    }, [ambulanceProfileData?.state, cityid])

    useEffect(() => {
        loadAmbulanceProfileData()
        stateAPI();
    }, [])

    useEffect(() => {
        stateList.map((key) => {
            if (key.state_name == ambulanceProfileData?.state) {
                setcityid(key.id)

            }

        })
    }, [ambulanceProfileData?.state, cityid])




    const applyForAmbulance = async (e) => {
        e.preventDefault();

         // for checking is profile updated or not
         const profile_pic = localStorage.getItem('profile_pic')
         if (profile_pic === "null" || profile_pic==undefined || profile_pic=="") {
             return toast.error("Please update Personal Details")
         }
        
       if (!ambulanceProfileData?.agency_name) {
          return toast.error('Please Enter Name of Ambulance service provider (ASP)')
       }
       else if (!ambulanceProfileData?.owner_name) {
           return toast.error('Please Enter Name of Owner')
       }
       else if (!ambulanceProfileData?.own_type) {
           return toast.error('Please Select Owner Type')
       }
       else if (!ambulanceProfileData?.contact_person) {
           return toast.error('Please Enter Name of Primary Contact Person')
       }
       else if (ambulanceProfileData?.mobile == '' || ambulanceProfileData?.mobile == null) {
           return toast.error('Please Enter Mobile Number')
       }
       else if (ambulanceProfileData?.email == '' || ambulanceProfileData?.email == null) {
           return toast.error('Please Enter Email')
       }
       else if(!ambulanceProfileData?.address){
              return toast.error('Please Enter Complete Address')
         }
       else if(!ambulanceProfileData?.state){
           return toast.error('Please Select State')
       }
       else if (!ambulanceProfileData?.city) {
           return toast.error(err_NotCity)
       }
       else if (!ambulanceProfileData?.pincode) {
           return toast.error('Please Enter Pin Code')
       }
       else {
           try {

            //    let id = localStorage.getItem('userid')
               const headers = {
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'authorization': token
                   }
               }
               const response = await axios.post(`${baseurl}onboarding/ambulance`, ambulanceProfileData, headers)
               if (response !== null) {
                   return toast.success("Profile successfully updated")
               }
               else {
                   return toast.error(response.data.message)
               }
           } catch (error) {
               toast.error(error)
           }
       }

       }

    const loadAmbulanceProfileData = () => {
        let id = localStorage.getItem('userid')
        console.log(id)
        apiFunction.ambulanceProfile(id).then(result => {
            console.log(result, "data")
            if (result.success === true) {
                if (result.data !== undefined && result.data !== null) {
                    setAmbulanceProfileData(result.data[0])
                    if ((result.data.is_applied_for_ambulance !== undefined && result.data.is_applied_for_ambulance !== null && result.data.is_applied_for_ambulance === 1) || result.data.is_approved === 1) {
                        setApplied(true)
                    }
                }
            }
        })
    }

    console.log(ambulanceProfileData, "ambulanceProfileData")

    const stateAPI = async () => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': token
            }
        }
        const response = await axios.get(`${baseurl}states`, headers)
        setStateList(response.data.data)
        

    }

    const cityAPI = async () => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': token
            }
        }
        const citydata = await axios.get(`${baseurl}states/${cityid}/cities`)
        setallcity(citydata.data.data)

    }

 

    return (
        <form>
            <div class="row">
                <div className='col-lg-12 col-md-12'>
                    <div className='row'>
                        <div class="col-lg-4">
                            <div class="fd_input mb-2">
                                <div class="form-group">
                                    <label >Name of Ambulance service provider (ASP) </label>
                                    <input type="text" name='agency_name' value={ambulanceProfileData?.agency_name} onChange={(e) => onInputChange(e)} class="form-control" placeholder="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="fd_input mb-2">
                                <div class="form-group">
                                    <label>Name of Owner</label>
                                    <input type="text" name='owner_name' value={ambulanceProfileData?.owner_name} onChange={(e) => onInputChange(e)} class="form-control" placeholder="" />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="fd_input mb-2">
                                <div class="form-group" name='own_type' onChange={onInputChange}>
                                    <label>Select Owner Type</label>
                                    <select className='form-control' value={ambulanceProfileData?.own_type} name='own_type' onChange={onInputChange}>
                                        <option>Select owner Type</option>
                                        <option>Individual</option>
                                        <option>Hospital</option>
                                        <option>charitable trust</option>
                                        <option>Government</option>
                                        <option>108 Ambulance</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Name of Primary Contact Person</label>
                                    <input type="text" name='contact_person' value={ambulanceProfileData?.contact_person} onChange={(e) => onInputChange(e)} class="form-control" placeholder="" />
                                </div>
                            </div>


                        </div>
                       

                        <div class="col-lg-4">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Email address</label>
                                    <input type="email" name='email' value={ambulanceProfileData?.email} onChange={(e) => onInputChange(e)} class="form-control" placeholder="" />
                                </div>
                            </div>


                        </div>

                        <div class="col-lg-4">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Telephone Number</label>
                                    <input type="text" value={ambulanceProfileData?.mobile} name='mobile' class="form-control" onChange={(e) => onInputChange(e)} placeholder="" />
                                </div>
                            </div>
                        </div>




                        <div class="col-lg-12">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Complete Address</label>
                                    <input type="text" name='address' value={ambulanceProfileData?.address} class="form-control" onChange={(e) => onInputChange(e)} placeholder="" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class="fd_input mb-2">
                                <div class="form-group aggregrator_decs">
                                    <label for="gen">State</label>
                                    <select class="form-control" name='state' value={ambulanceProfileData?.state} onChange={(e) => onInputChange(e)} >
                                        <option>Select State</option>
                                        {
                                            stateList.map((val, ind) => {
                                                return (
                                                    <option value={val.state_name}>{val.state_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="fd_input mb-2">
                                <div class="form-group aggregrator_decs">
                                    <label for="gen">City</label>
                                    <select class="form-control" name='city'
                                        value={ambulanceProfileData?.city} onChange={(e) => onInputChange(e)} >
                                        <option>Select City</option>
                                        {(allcity.length > 0 && allcity !== null) && allcity.map((key) => (
                                            <option value={key.city_name}>{key.city_name}</option>

                                        ))}


                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="fd_input mb-2">
                                <div class="form-group aggregrator_decs">
                                    <label for="Specialization">Pin Code</label>
                                    <input type='text' class="form-control" value={ambulanceProfileData?.pincode} name='pincode' id="sel1" onChange={(e) => onInputChange(e)} />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

            <div class="save_btn">
                <a href="" class="btn btn_fill btn-xs" onClick={(e) => applyForAmbulance(e)}>Save</a>
            </div>
        </form>
    )
}

export default AmbulanceProfile