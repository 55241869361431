import React, { useEffect, useState } from 'react'
import { AccountBookingComponent, HomepageHeader, SidebarUserPanel } from '../../../components'
import { ServicesProviderService } from '../../../services'


const AccountAmbulance = () => {
    const apiFunction = new ServicesProviderService()
    const [datalist, setDatalist] = useState([])
    const [isloading, setIsLoading] = useState(false)
    const [status, setStatus] = useState("Booked")
    useEffect(() => {
        setIsLoading(true);
        loadBookingsAllBooking();
    }, [status])
    const loadBookingsAllBooking = () => {
        apiFunction.loadPatientsDetails("ambulances", status).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    setDatalist(result.data)
                    setIsLoading(false);
                }
            }
        })
    }
    return (
        <AccountBookingComponent setStatus={setStatus} status={status} data={datalist} selfDetails={true} isloading={isloading}/>
    )
}

export default AccountAmbulance;