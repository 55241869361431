import React, { useEffect } from 'react'
import { Footer, HomepageHeader } from '../../components'
import PatientNeed from "../../images/images/Patient-need.png"
import ImageOne from "../../images/images/dr1.png"
import ImageTwo from "../../images/images/dr2.png"
import ImageThree from "../../images/images/dr3.png"
import CheckTick from "../../images/images/check.png"
import { ServiceTypeObj } from '../../enums/Enums'
import {
    useNavigate
} from 'react-router-dom';
import { toast } from 'react-toastify'

const PartnerWithUs = () => {
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const redirectToLogin = (partnerWithUs) => {
     
        localStorage.setItem("wantTobeServiceProvider", partnerWithUs);
        toast.info("Please login or signup with your account")
        return navigate("/login");
    }
    return (
        <div class="outerhomepage">
            <HomepageHeader />

            {/*Inner Page Banner*/}
            <div className="topbanner_img">
                <img src={PatientNeed} alt="patient-need" />
            </div>
            {/*About*/}
            <div className="about_center paddtop">
                <h3 className="heading2">Partner with Fasttrack</h3>
                <p className="para2">Be a part of India’s fastest growing healthcare community and help us in making quality healthcare accessible for all. Together, let's make a difference in the lives of many.</p>
                {/* <div className="mt40"><a href="#" className="btn btn_fill">Learn more</a></div> */}
            </div>

            <section className="paddbottom">
                <div className="container">
                    <div className="flex_container">
                        <div className="left_wraper">
                            <div className="img_view"><img src={ImageOne} alt="ImageOne" /></div>
                        </div>
                        <div className="right_wraper">
                            <div className="content_wraper">
                                <div className="content_head">
                                    <h3>For Doctors</h3>
                                    <p>Are you a professional and experienced doctor committed to delivering exceptional care to patients? We're searching for individuals like you to join our team and get the chance to serve patients all over India.</p>
                                </div>
                                <div className="content_list">
                                    <p><img src={CheckTick} alt="checkImage" />	Get Patients All over india</p>
                                    <p><img src={CheckTick} alt="checkImage" />	No Signup Fees</p>
                                    <p><img src={CheckTick} alt="checkImage" />	Better Learning Opportunity</p>
                                </div>
                                <div className="mt40"><button className="btn btn_fill" onClick={() => redirectToLogin(ServiceTypeObj.type1)}>Apply</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex_container">
                        <div className="left_wraper">
                            <div className="img_view"><img src={ImageTwo} alt="ImageTwo" /></div>
                        </div>
                        <div className="right_wraper">
                            <div className="content_wraper">
                                <div className="content_head">
                                    <h3>For Home care </h3>
                                    <p>If you are an experienced nurse/caregiver and love to serve exceptional care to patients? We're looking for people like you to join our team and have the opportunity to serve patients in your area or all over India.</p>
                                </div>
                                <div className="content_list">
                                    <p><img src={CheckTick} alt="checkImage" />	Secure</p>
                                    <p><img src={CheckTick} alt="checkImage" />	No Signup Fees</p>
                                    <p><img src={CheckTick} alt="checkImage" />	24/7 Support</p>
                                </div>
                                <div className="mt40"><button className="btn btn_fill" onClick={() => redirectToLogin(ServiceTypeObj.type2)}>Apply</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex_container">
                        <div className="left_wraper">
                            <div className="img_view"><img src={ImageThree} alt="ImageThree" /></div>
                        </div>
                        <div className="right_wraper">
                            <div className="content_wraper">
                                <div className="content_head">
                                    <h3>For Ambulance</h3>
                                    <p>Are you an ambulance driver or an ambulance owner struggling due to a lack of calls? Don't worry, partner with us today and get ready to receive a steady stream of calls to make the situation feasible for you. With Fasttrack, get ready to make a difference in the world of emergency healthcare.</p>
                                </div>
                                <div className="content_list">
                                    <p><img src={CheckTick} alt="checkImage" />	Get More Calls</p>
                                    <p><img src={CheckTick} alt="checkImage" />	No Signup Fees</p>
                                    <p><img src={CheckTick} alt="checkImage" />	Free Quality Check</p>
                                </div>
                                <div className="mt40">
                                    <button className="btn btn_fill" onClick={() => redirectToLogin(ServiceTypeObj.type3)}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='mb20'>&nbsp;</div>
            <Footer />

        </div>
    )
}

export default PartnerWithUs