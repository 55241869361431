import React from 'react'

const EducationReg = ({incrementBtn,decrementBtn,setExperience,setQualification,setCollege,setCompletion}) => {
    return (
        <div className="box box-solid">
            <div className="row">
                <div className="col-md-6">
                    <div className="profile_form1">
                        <div className="row">
                            <div className="col-lg-9">
                                <h3 className="drprofile_title">Educational  Qualification</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label>Degree</label>
                                    <select className="form-control" onChange={(e)=>setQualification(e.target.value)}>
                                        <option>Type &amp; select degree</option>
                                        <option>MBBS</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label>College/Institute</label>
                                    <select className="form-control" onChange={(e)=>setCollege(e.target.value)}>
                                        <option>Type &amp; select college/institute</option>
                                        <option>MGKVP</option>
                                        <option>BHU</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label>Year of completion</label>
                                    <select className="form-control" onChange={(e)=>setCompletion(e.target.value)}>
                                        <option>type year of completion</option>
                                        <option>2017</option>
                                        <option>2018</option>
                                        <option>2018</option>
                                        <option>2019</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label>Year of experience</label>
                                    <select className="form-control" onChange={(e)=>setExperience(e.target.value)}>
                                        <option>type year of experience</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="profile_form1">
                        <div className="row">
                            <div className="col-lg-10">
                                <div className="info_box">
                                    <p>*On Fastrack, doctors arelisted under the relevent specilazation based on ther’s acadmic degrees. bothg,education &amp; years of ezperience, showcase doctor’s exparise and are among the the top 3 things that patients consider while making their decision.</p>
                                </div>
                                <div className="info_box_btn">
                                    <button onClick={decrementBtn} type="button" className="btnlink">Go back</button>
                                    <button onClick={incrementBtn} type="button"  className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EducationReg