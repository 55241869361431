import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import axios from 'axios';
import { Label } from '@mui/icons-material';
const baseurl=process.env.REACT_APP_API_BASE_URL
let token=localStorage.getItem('token')

const Applydoctor = () => {

    const [check, setCheck] = useState(false)
    const [newSpecilization, setNewSpecilizatio] = useState([])
    const [proofType, setProofType] = useState()
    const [proofTitle, setProofTitle] = useState()
    const [proofFile, setProofFile] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [userProfilePicture, setUserProfilePicture] = useState('')
    const [userSignaturePicture, setUserSignaturePicture] = useState('')
    const [isLoadingsig, setIsLoadingsig] = useState()
    const [specilization_id,setspecilization_id] = useState([])
    const [fromtime,setfromtime] = useState("")
    const [totime,settotime] = useState("")
    const [day,setday]= useState()
    const [serviceprovider,setserviceprovider] = useState()
    const [modal, setModal] = useState(false);
    const [userId, setUserId] = useState(0)
    const [proofpath,setproofpath] = useState("")
    const [speclist,setspeclist] = useState([])
    const [avillist,setavillist] = useState([])
    const [doclist,setdoclist] = useState([])
    


    const [doctorProfileData, setDoctorProfileData] = useState({
        name: "",
        headline: '',
        mobile: "",
        notes: '',
        email: "",
        specialization: [],
        city: "",
        bio: '',
        address: "",
        availability: [],
        consultation_fees: "",
        degree: '',
        followup_fees: "",
        service_provider_document: [],
        state: "",
        zipcode: '',
        gender:'',
        experience: '',
        spelization_id:''
    })
   

    const { name, headline, notes, bio,
        mobile, degree, followup_fees,
        gender,
        email,
        specialization,
        city,
        address, availability, service_provider_document,
        consultation_fees,
        experience,spelization_id } = doctorProfileData;
        
    // setting form data------------------------------------------
    const handleinputsdata=(e)=>{
        setDoctorProfileData({...doctorProfileData, [e.target.name]:e.target.value})
    }
    //setting the days
    const AllDaysList = [
        {
            Dayname:'All Day',
            value:7
        },
        {
            Dayname:'Sunday',
            value:0
        },
        {
            Dayname:'Monday',
            value:1
        },
        {
            Dayname:'Tuesday',
            value:2
        },
        {
            Dayname:'Wednesday',
            value:3
        },
        {
            Dayname:'Thursday',
            value:4
        },
        {
            Dayname:'Friday',
            value:5
        },
        {
            Dayname:'Saturday',
            value:6
        }]
    

        
    const toggle = () => {
        setModal(!modal);
    };
    
    const openModalWithHeaderClass = ( id) => {
        
        setUserId(id);
        toggle();
    };
    
    
       


 
    const handleProfileImage = (type) => {
        if (type == 'profile') {
            let inPutClick = document.getElementById('profilePic')
            inPutClick.click()
        } else {
            let inPutClick = document.getElementById('signature')
            inPutClick.click()
        }
    }
    
    
    
    useEffect(() => {
        //loadDoctorProfileData();
        //getavilibility();
    //getspelization();
    //getdocument();
       
    }, [])
    //uploading profile picture--------------------------------------
    const uploadProfilePicture = (e) => {
        setIsLoading(true)
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);       
        UploadImageFor(formData, uploadImageFor.profile).then(result => {
           
            if (result!==null) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    setUserProfilePicture(e.target.result)
                };
                reader.readAsDataURL(selectedFile);

                // document.getElementById("showProfile").innerHTML = '<img src="' + e.target.result + '"/>';
            }
        })
    }
    //uploading signature--------------------------------------------
    const uploadsignaturePicture = (e) => {
        setIsLoadingsig(true)
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
       
        UploadImageFor(formData, uploadImageFor.signature_doc).then(result => {
          
            if (result!==null && result!= undefined) {
                var reader = new FileReader();               
                reader.onload = function (e) {
                    setUserSignaturePicture(e.target.result)
                };
                reader.readAsDataURL(selectedFile);

                // document.getElementById("showProfile").innerHTML = '<img src="' + e.target.result + '"/>';
            }
        })
    }
    const handleUpload = (e) => {
        let a
        if (proofType == 'Identity Proof') {
            a = 'identity'
        } else if (proofType == 'Medical Registration Proof') {
            a = 'medical_doc'
        } else if (proofType == 'Establishment Proof Proof') {
            a = 'establishment_doc'
        }
        if(!a){
            toast.error("Please select document type")
        }
        else {
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, a).then(result => {
            if (result!== null) {
                setproofpath(result.data.filePath)
            }
        })
        }
    }
    //add bottom table data-----------------------------------------------------
    const Adddocumentlist=async(e)=>{
        e.preventDefault();
        if(!proofTitle){
            toast.error("Please select title")
        }
        else if(!proofpath){
            toast.error("Please select file")
        }else{
        var data = {
            "document_type": proofType,
            "title": proofTitle,
            "doc_file_path": proofpath
          };
          
          var config = {
            
            headers: { 
              'authorization': token,
              'Content-Type': 'application/json'
            },
          
          };
        const result=await axios.post(`${baseurl}document`,JSON.stringify(data),config)
        if(result!=null&& result!=undefined){
            
            toast.success("Document Added Sucessfully")
            getdocument()
        }
    }
    }
    //post all form data--------------------------------------------------
    const updateProfileOnsubmit = (e) => {
        
        e.preventDefault();
        if (headline==''|| headline==null) {
            toast.error('Please Enter Hadline')
        } else if (bio==''|| bio==null) {
            return toast.error('Plaese Enter Bio')
        }
        else if (experience==null || experience==undefined|| experience=='') {
            return toast.error('Please Enter Experiance')
        }
        else if (degree==null||degree==undefined||degree.length<=0) {
            return toast.error('Please Enter Degree')
        }
        else if (consultation_fees==null|| consultation_fees==undefined) {
            return toast.error('Please Enter consultation fees')
        }else {
            try {

               
                    DoctorRegistered(doctorProfileData, token)
                        .then((res) => {
                            
                            if (res!=null=== true) {
                                toast.success('Your profile has been completed, Please wait for approval')
                            }
    
                        })
            } catch (error) {
                toast.error(error)
            }
        }
    }
    //adding avibility table-------------------------
    const AddAvibility=async(e)=>{
        e.preventDefault();
        try{
            var data = {
                "day": day,
                "start_time": fromtime,
                "end_time": totime,
                "fee": null,
                "user_service_id":serviceprovider
              };
              
              var config = {
               
                headers: { 
                  'authorization': token,
                  'Content-Type': 'application/json'
                },
              
              };
            const result=await axios.post(`${baseurl}slots/masterslot`,JSON.stringify(data),config)
            if(result!=null){
               
                toast.success("Available Added Sucessfully");
                getavilibility();
            }


        }catch(error){
            toast.error(error)
        }


    }
    //Add specilization---------------------------------------------
    const handleAddSpecilization=async(e)=>{
        e.preventDefault();
        try{
        
        const tempspec=specializationList.filter(key=>(key.id==specilization_id))
       
        const title=tempspec[0].title;
        
        var data = {
            "type": title,
            "specialization_id": specilization_id
          };
        var config = {
           
            headers: { 
              'authorization': token,
              'Content-Type': 'application/json'
            },
          };
        const result=await axios.post(`${baseurl}specialization/user/specs`,JSON.stringify(data),config)
        if(result!== null){
           
            toast.success("Specilization Added Sucessfully")
            getspelization()
            
        }
    }catch(error){
        toast.error(error.response.data.message)
    }
    }
    //get api for spelization---------------------------------------
    const getspelization=async()=>{
        var config = {
           
            headers: { 
              'authorization': token,
            },
            
          };
          
        const result=await axios.get(`${baseurl}specialization/userPerformer`,config)       
        if(result!=null&& result!= undefined){           
            setspeclist(result.data.data)
        }
    }
    //get api for avilibility-------------------------------------------
    const getavilibility=async()=>{
        var config = {
           
            headers: { 
              'authorization': token,
            },
            
          };
          
        const result=await axios.get(`${baseurl}slots`,config)
        if(result!=null&& result!= undefined){       
        
            setavillist(result.data.data)
        }

    }
    //get api for documnet--------------------------------------------
    const getdocument=async()=>{
        var config = {
           
            headers: { 
              'authorization': token,
            },
            
          };
          
        const result=await axios.get(`${baseurl}document`,config)
        if(result!=null&& result!= undefined){            
            setdoclist(result.data.data)
        }

        
    }
    
    return (
        <div className='teleconsultaion_page'>

            <h3 class="mb-2 heading3">Status : Not Approved</h3>
            <form>
                <div class="">
                    <div className='row'>
                        <div class="col-lg-8">
                            <div class="col-lg-12">
                                <div class="fd_input mb-2">
                                    <div class="form-group">
                                        <label >Headline</label>
                                        <input type="text" value={headline} name='headline' class="form-control" placeholder="" onChange={(e)=>handleinputsdata(e)}  />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="fd_input">
                                    <div class="form-group">
                                        <label>Bio</label>
                                        <textarea type="text" name='bio' value={bio} class="form-control breif-details" placeholder="" onChange={(e)=>handleinputsdata(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="fd_input mb-2">
                                <div class="form-group">
                                    {/* <div class="col-lg-6">
                                        <div class="fd_input mb-2">
                                            <div class="form-group profile-photo ">
                                                <div className='profile-photo-sig' id='showProfile' onClick={() => handleProfileImage('profile')}>
                                                    {
                                                        isLoading && <img style={{ 'z-indez': '11111' }} src={userProfilePicture} />
                                                    }
                                                    <p>Select Picture</p>
                                                    </div>
                                                <input id='profilePic' type={'file'} onChange={(e) => uploadProfilePicture(e)} />
                                                <button className='btn btn-info'>Upload</button>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="col-lg-12">
                                        <div class="fd_input mb-2">
                                            <div class="form-group profile-photo">
                                                <div className='profile-photo-sig' onClick={() => handleProfileImage()}>
                                                    {
                                                        isLoadingsig && <img style={{ 'z-indez': '11111' }} src={userSignaturePicture} />
                                                    }
                                                    {(userSignaturePicture=="")?<p>Select Signature</p>:""}</div>
                                                <input id='signature' onChange={(e) => uploadsignaturePicture(e)} type={'file'} />
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div class="col-lg-3">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Total Experience ( in Year )</label>
                                    <input type="text" name='experience' value={experience} class="form-control" placeholder=""  onChange={(e)=>handleinputsdata(e)}/>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Degree</label>
                                    <input type="text" name='degree' value={degree} class="form-control" placeholder="" onChange={(e)=>handleinputsdata(e)}/>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Consultation Fee</label>
                                    <input type="text" name='consultation_fees' value={consultation_fees} class="form-control" placeholder=""onChange={(e)=>handleinputsdata(e)} />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Follow Up Fee</label>
                                    <input type="text" name='followup_fees' value={followup_fees} class="form-control" placeholder="" onChange={(e)=>handleinputsdata(e)} />
                                </div>
                            </div>
                        </div>                       
                        <div class="col-lg-6">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Specialization</label>
                                    <div className='border-spe'>
                                        <div className='handle-specilization'>
                                            <select type="email"  value={specilization_id} onChange={(e) => setspecilization_id(e.target.value)} class="form-control" placeholder="" >
                                               
                                               <option>ab</option>
                                                {/* {
                                                    specializationList.map((val) => {
                                                        return (
                                                            <option value={val.id}>{val.title}</option>
                                                        )
                                                    })
                                                } */}
                                            </select>
                                            <button className='btn btn-info btn-sm' onClick={(e) => handleAddSpecilization(e)}> + Add</button>

                                        </div>
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Specialization</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                {
                                                    
                                                    speclist.length > 0 &&
                                                    speclist.map((val, ind) => {
                                                        return (
                                                            <tr>
                                                                <td>{val.type}</td>
                                                                <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={()=>deletespecification(val.id)}>Remove</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="fd_input">
                                <div class="form-group">
                                    <label>Availability</label>
                                    <div className='border-spe'>
                                        
                                        <div className='availability-profile'>
                                            <select type="email" name='email' class="form-control" placeholder="" value={day} onChange={(e)=>setday(e.target.value)} >
                                                {
                                                    AllDaysList.map((val, ind) => {
                                                        return (
                                                            <option value={val.value}>{val.Dayname}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <Label>From Time</Label>
                                            <input
                                                    type="time"
                                                    step="1"
                                                    value={fromtime}
                                                    className="form-control"
                                                    placeholder="Time"
                                                    onChange={(e)=>setfromtime(e.target.value)}
                                                    
                                                />
                                            <Label>To Time</Label>
                                                <input
                                                    type="time"
                                                    step="1"
                                                    value={totime}
                                                    className="form-control"
                                                    placeholder="Time"
                                                    onChange={(e)=>settotime(e.target.value)}
                                                    
                                                />
                                            <button className='btn btn-info btn-sm' onClick={(e)=>AddAvibility(e)}> + Add</button>

                                        </div>
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Days</th>
                                                    <th scope="col">From Time</th>
                                                    <th scope="col">To Time</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    avillist.length > 0 &&
                                                    avillist.map((val, ind) => {
                                                        return (
                                                            <tr>
                                                                <td>{val.day == 0 ? 'sunday' : val.day == 1 ? 'Monday' : val.day == 2 ? 'Tuesday' : val.day == 3 ? 'Wednesday' : val.day == 4 ? 'Thursday' : val.day == 5 ? 'Friday' : val.day == 6 ? 'Saturday' : ''}</td>
                                                                <td>{val.start_time}</td>
                                                                <td>{val.end_time}</td>
                                                                <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={()=>deleteAvilability(val.id)} >Remove</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                    <div class="col-lg-12">
                    <div class="fd_input">
                        <div class="form-group">
                            <label>Documents</label>
                            <div className='border-spe'>
                                <div className='documents-profile'>
                                    <select onChange={(e) => setProofType(e.target.value)} value={proofType} class="form-control" placeholder="" >
                                        <option>Select Proof</option>
                                        <option value={"Identity Proof"}>Identity Proof</option>
                                        <option value={"Medical Registration Proof"}>Medical Registration Proof</option>
                                        <option value={"Establishment Proof Proof"}>Establishment Proof Proof</option>
                                    </select>
                                    <input type="text" onChange={(e) => setProofTitle(e.target.value)} placeholder='Title' class="form-control" />
                                    <input type="file" onChange={(e) => handleUpload(e)} class="form-control" />
                                    <button className='btn btn-info btn-sm'onClick={(e)=>Adddocumentlist(e)} > + Add</button>

                                </div>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Document Type</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Link</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            doclist.map((val, ind) => {
                                                return (
                                                    <tr>
                                                        <td>{val.document_type}</td>
                                                        <td>{val.title}</td>
                                                        <td>{val.doc_file_path}</td>
                                                        <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={()=> deletedocument(val.id)} >Remove</td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
                
                <hr class="seprator" />
                <div class="save_btn">
                    <button class="btn btn_fill btn-xs" onClick={(e)=>updateProfileOnsubmit(e)}>Save</button>
                </div>
            </form>



           
            <Modal show={modal} onHide={toggle}>
                <Modal.Header
                    onHide={toggle}
                    closeButton
                    className={classNames('modal-colored-header', 'bg-danger')}>
                    <h4 className="modal-title text-light">Delete User</h4>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="mt-0">Are you sure want to delete this item</h5>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggle}>
                        Cancel
                    </Button>{' '}
                    <Button variant="danger" onClick={() => deletespecification(userId)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Applydoctor;