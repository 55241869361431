import React from 'react'
import { Link } from 'react-router-dom'
import { HomepageHeader } from '../../components'
import dashboarddata from './DashBoardData'

const NewDashBoard = () => {
  return (
    <>
    <HomepageHeader />
    <div className='container'>
        <div className='row '>
            {
                dashboarddata.map((val,ind)=>{
                    return(
                        <div className='col-lg-3 ' key={ind}>
<div className='dashboard_box'>
<Link to="" className='text_dashboard'>{val.name}</Link>
</div>
            </div>
                    )
                })
            }

        </div>

    </div>
    
    </>
  )
}

export default NewDashBoard