import { Token } from '@mui/icons-material'
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react'
import { HomepageHeader } from '../../components'
import ConsultationFeeGettingStatrtedPatient from '../../components/homecare_reg/ConsultationFeeGettingStatrtedPatient'
import DaysGettingStatrtedPatient from '../../components/homecare_reg/DaysGettingStatrtedPatient'
import NurseRegistration from '../../components/homecare_reg/NurseRegistration'
import EducationReg from '../../components/homecare_reg/EducationReg'
import EstablishmentProof from '../../components/homecare_reg/EstablishmentProof'
import IdentityProof from '../../components/homecare_reg/IdentityProof'
import LocationGettingStatrtedPatient from '../../components/homecare_reg/LocationGettingStatrtedPatient'
import MedicalRegProof from '../../components/homecare_reg/MedicalRegProof'
import { ProfileService, UploadImageFor } from '../../services'
import {DoctorRegistered} from '../../services/DoctorOnboarding'
import { useNavigate } from 'react-router';
import { HomeCareRegistered } from '../../services/HomeCareOnboarding';

const HomeCareRegistration = () => {
    const [token,setToken]=useState()
    const [stepCount, setStepCount] = useState(1)
    const [gender,setGender]=useState('Male')
    const [city,setCity]=useState('')
    // const [Services,setServices]=useState('')
    const [qualification,setQualification]=useState()
    const [experience,setExperience]=useState('')
    const [College,setCollege]=useState()
    const [completion,setCompletion]=useState('')
    const [identity,setIdentity]=useState()
    const [establishment_proof,setEstablishment_proof]=useState()
    const [medical_registration,setMedical_registration]=useState()
    const [consultation_fees,setConsultation_fees]=useState()
    const [mobile,setMobile]=useState()
    const [email,setEmail]=useState()
    const [address,setAddress]=useState()
    const [bio,setBio]=useState('')
    const [is_video_consultancy,setIs_video_consultancy]=useState('')
    const [availability,setAvailability]=useState([])
    const [day,setDay]=useState()
    const [start_time,setStart_time]=useState()
    const [end_time,setEnd_time]=useState()
    const [fee,setFee]=useState(1234)
    const navigateTo=useNavigate()
    let services=[
        {
            "service_id":1
        }, {
                "service_id": 2
            }
    ]
    const homeCareData={
        gender,city,qualification,experience,College,completion,consultation_fees,bio,is_video_consultancy,
        services,availability,mobile,email,address,identity,establishment_proof,medical_registration
    }
   
    const incrementFunc = () => {
        setStepCount(1 + stepCount)
    }
    const decrementFunc = () => {
        setStepCount(stepCount - 1)
    }

    const apiFunction = new ProfileService()
    const [userProfile, setUserProfile] = useState({})
    const loadUserProfileData = () => {
        apiFunction.LoadProfileData().then(result => {
            if (result.success === true) {
                setUserProfile(result.data)
            }
        })
    }
    useEffect(()=>{
        loadUserProfileData()
        
        setToken(localStorage.getItem('token'))
    },[])
    // Adding Avail
    const addAvail=()=>{
        setAvailability([{day,start_time,end_time,fee}])
    }
    const DoctorRegistrationCall=()=>{
        setAvailability([{day,start_time,end_time,fee}])
        try {
     
            if (token!=null) {
                HomeCareRegistered(homeCareData,token)
                .then((res) => {
                  
                    if(res.success===true){
                        toast.success('Your profile has been compleated, Please wait for approval')
                        navigateTo('/Application-sent')
                    }
                  
                })
                .catch();
            } else if (token.length <= 0) {
              toast.error("Something went wrong ");
            }
          } catch (error) {
          }
      }
    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="content-wrapper marginleft0">
                    {/* Main content */}
                    <section className="content content-body">
                        {/* Main row */}
                        <div className="container">
                            <div className="box box-solid">

                                {/* Progres bar */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ol className="steps">
                                            <li className="step is-complete" data-step={1}>
                                                <span className="step_name">Profile details</span>
                                            </li>
                                            <li className="step is-active" data-step={2}>
                                                <span className="step_name">Profile verification</span>
                                            </li>
                                            <li className="step" data-step={3}>
                                                <span className="step_name">Start getting patients</span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>


                                {/* --- Registration Forms --- */}

                                {stepCount === 1 ?
                                    <NurseRegistration setGender={setGender}  setCity={setCity} decrementBtn={decrementFunc} incrementBtn={incrementFunc} profileData={userProfile} />
                                    : stepCount === 2 ?
                                        <EducationReg setCollege={setCollege} setCompletion={setCompletion} setQualification={setQualification} setExperience={setExperience} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                        : stepCount === 3 ?
                                            <IdentityProof token={token} setIdentity={setIdentity} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                            : stepCount === 4 ?
                                                <MedicalRegProof setMedical_registration={setMedical_registration} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                : stepCount === 5 ?
                                                    <EstablishmentProof setEstablishment_proof={setEstablishment_proof}  decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                    : stepCount === 6 ?
                                                        <LocationGettingStatrtedPatient profileData={userProfile} setAddress={setAddress} mobile={mobile} setMobile={setMobile} email={email} setEmail={setEmail} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                        : stepCount === 7 ?
                                                            <DaysGettingStatrtedPatient setDay={setDay} addAvail={addAvail} setStart_time={setStart_time} setEnd_time={setEnd_time} setAvailability={setAvailability} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                            : stepCount === 8 ?
                                                                <ConsultationFeeGettingStatrtedPatient setConsultation_fees={setConsultation_fees} DoctorRegistrationCall={DoctorRegistrationCall}  setFee={setFee} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                                :
                                                                <>
                                                                </>
                                }
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </section>
                    {/* /.content */}
                </div>
            </section>
        </div>

    )
}

export default HomeCareRegistration