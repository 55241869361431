import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { regEmailValidation, regHtml, regMobile } from '../../helpers/Validate';
import { ProfileService, UploadImageFor } from '../../services'
import { HomeCareRegistered } from '../../services/HomeCareOnboarding'
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner'
import classNames from 'classnames';
import axios from 'axios';

import {
    err_10DigitMobile,
    err_NotCity,
    err_NotCountry,
    err_NotDistrict,
    err_NotGender,
    err_NotHouseNo,
    err_NotMobile, err_NotName,
    err_NotSixDigitPinCode, err_NotState,
    err_NotStreetColony,
    err_NotValidEmail, HtmlErrorMessage, uploadImageFor
} from '../../enums/Enums';
import { DocumentScanner, Label } from '@mui/icons-material';
import { set } from 'date-fns';
import { is } from 'date-fns/locale';
const baseurl = process.env.REACT_APP_API_BASE_URL

const AddAmbulance = ({ userProfileData, hr }) => {
    const token = localStorage.getItem('token')
    const apiFunction = new ProfileService()
    const [check, setCheck] = useState(false)
    const [newSpecilization, setNewSpecilizatio] = useState([])
    const [proofType, setProofType] = useState()
    const [proofTitle, setProofTitle] = useState()
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [cityId, setCityId] = useState()
    const [state, setState] = useState("")
    const [proofFile, setProofFile] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [ambulanceImage, setAmbulanceImage] = useState('')
    const [interiorImage, setInteriorImage] = useState('')
    const [exteriorImage, setExteriorImage] = useState('')
    const [isLoadingsig, setIsLoadingsig] = useState(false)
    const [specilization_id, setspecilization_id] = useState([])
    const [fromtime, setfromtime] = useState("")
    const [totime, settotime] = useState("")
    const [day, setday] = useState(0)
    const [serviceprovider, setserviceprovider] = useState()
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [userId, setUserId] = useState(0)
    const [proofpath, setproofpath] = useState("")

    const [signpath, setsignpath] = useState(null)
    const [availabilitysetlist, setavailabilitysetlist] = useState([])
    const [doccumentsetlists, setdoccumentsetlists] = useState([])
    const [isapplied, setApplied] = useState(false)
    const [item, setItem] = useState(0)
    const [tempalist, setTempaList] = useState([])
    const [tempdlist, setTempdList] = useState([])
    const [docUploaded, setDocUploaded] = useState(true)
    const [equippedWith, setEquippedWith] = useState([])
    const [equipped, setEquipped] = useState([])
    const [eqq, seteqq] = useState("")
    const [approvedAmbulancesList, setApprovedAmbulancesList] = useState([])
    const [newAmbulanceList, setNewAmbulanceList] = useState([])
    const [rejectedAmbulancesList, setRejectedAmbulancesList] = useState([])
    const [search_key, setSearch_key] = useState('')
    const [isPaid, setIsPaid] = useState(true)

    const [ambulanceData, setAmbulanceData] = useState({

        ambulance_type: '',
        vehicle_number: '',
        vehicle_make: '',
        charge_type: 'paid',
        vehicle_model: '',
        flat_charge: '',
        charge_per_km: '',
        advance_charge: '',
        equipped_with: '',
        operational_city: '',
        operational_state: '',
        user_service_id: '',
        availability: [],
        documents: [],
        eqquiped_with: '',
        ambulance_pic: '',
        interior_image: '',
        exterior_image: ''



    })
    let { ambulance_type, vehicle_number, vehicle_make, charge_type, vehicle_model, flat_charge, charge_per_km, advance_charge, equipped_with, operational_city, operational_state, user_service_id, availability, ambulance_pic, interior_image, exterior_image, documents } = ambulanceData


    // setting form data------------------------------------------
    const onInputChange = (e) => {
        setAmbulanceData({ ...ambulanceData, [e.target.name]: e.target.value })
        if (e.target.name == "operational_state") {
            setState(e.target.value)

        }
        if (e.target.value == "paid") {
            setIsPaid(true)
        }
        if (e.target.value == "free") {
            setIsPaid(false)
        }
    }

    const AllDaysList = [
        {
            Dayname: 'All',
            value: -1
        },
        {
            Dayname: 'Sunday',
            value: 0
        },
        {
            Dayname: 'Monday',
            value: 1
        },
        {
            Dayname: 'Tuesday',
            value: 2
        },
        {
            Dayname: 'Wednesday',
            value: 3
        },
        {
            Dayname: 'Thursday',
            value: 4
        },
        {
            Dayname: 'Friday',
            value: 5
        },
        {
            Dayname: 'Saturday',
            value: 6
        }]

    const TimeFrom = [
        { start: '..:..', value: '..:..' },
        { start: '00:00', value: "00:00" },
        { start: '00:30', value: "00:30" },
        { start: '01:00', value: "01:00" },
        { start: '01:30', value: "01:30" },
        { start: '02:00', value: "02:00" },
        { start: '02:30', value: "02:30" },
        { start: '03:00', value: "03:00" },
        { start: '03:30', value: "03:30" },
        { start: '04:00', value: "04:00" },
        { start: '04:30', value: "04:30" },
        { start: '05:00', value: "05:00" },
        { start: '05:30', value: "05:30" },
        { start: '06:00', value: "06:0" },
        { start: '06:30', value: "06:30" },
        { start: '07:00', value: "07:00" },
        { start: '07:30', value: "07:30" },
        { start: '08:00', value: "08:00" },
        { start: '08:30', value: "08:30" },
        { start: '09:00', value: "09:00" },
        { start: '09:30', value: "09:30" },
        { start: '10:00', value: "10:00" },
        { start: '10:30', value: "10:30" },
        { start: '11:00', value: "11:00" },
        { start: '11:30', value: "11:30" },
        { start: '12:00', value: "12:00" },
        { start: '12:30', value: "12:30" },
        { start: '13:00', value: "13:00" },
        { start: '13:30', value: "13:30" },
        { start: '14:00', value: "14:00" },
        { start: '14:30', value: "14:30" },
        { start: '15:00', value: "15:00" },
        { start: '15:30', value: "15:30" },
        { start: '16:00', value: "16:00" },
        { start: '16:30', value: "16:30" },
        { start: '17:00', value: "17:00" },
        { start: '17:30', value: "17:30" },
        { start: '18:00', value: "18:00" },
        { start: '18:30', value: "18:30" },
        { start: '19:00', value: "19:00" },
        { start: '19:30', value: "19:30" },
        { start: '20:00', value: "20:00" },
        { start: '20:30', value: "20:30" },
        { start: '21:00', value: "21:00" },
        { start: '21:30', value: "21:30" },
        { start: '22:00', value: "22:00" },
        { start: '22:30', value: "22:30" },
        { start: '23:00', value: "23:00" },
        { start: '23:30', value: "23:30" }]

    const TimeTo = [
        { start: '..:..', value: '..:..' },
        { start: '00:30', value: "00:30" },
        { start: '01:00', value: "01:00" },
        { start: '01:30', value: "01:30" },
        { start: '02:00', value: "02:00" },
        { start: '02:30', value: "02:30" },
        { start: '03:00', value: "03:00" },
        { start: '03:30', value: "03:30" },
        { start: '04:00', value: "04:00" },
        { start: '04:30', value: "04:30" },
        { start: '05:00', value: "05:00" },
        { start: '05:30', value: "05:30" },
        { start: '06:00', value: "06:0" },
        { start: '06:30', value: "06:30" },
        { start: '07:00', value: "07:00" },
        { start: '07:30', value: "07:30" },
        { start: '08:00', value: "08:00" },
        { start: '08:30', value: "08:30" },
        { start: '09:00', value: "09:00" },
        { start: '09:30', value: "09:30" },
        { start: '10:00', value: "10:00" },
        { start: '10:30', value: "10:30" },
        { start: '11:00', value: "11:00" },
        { start: '11:30', value: "11:30" },
        { start: '12:00', value: "12:00" },
        { start: '12:30', value: "12:30" },
        { start: '13:00', value: "13:00" },
        { start: '13:30', value: "13:30" },
        { start: '14:00', value: "14:00" },
        { start: '14:30', value: "14:30" },
        { start: '15:00', value: "15:00" },
        { start: '15:30', value: "15:30" },
        { start: '16:00', value: "16:00" },
        { start: '16:30', value: "16:30" },
        { start: '17:00', value: "17:00" },
        { start: '17:30', value: "17:30" },
        { start: '18:00', value: "18:00" },
        { start: '18:30', value: "18:30" },
        { start: '19:00', value: "19:00" },
        { start: '19:30', value: "19:30" },
        { start: '20:00', value: "20:00" },
        { start: '20:30', value: "20:30" },
        { start: '21:00', value: "21:00" },
        { start: '21:30', value: "21:30" },
        { start: '22:00', value: "22:00" },
        { start: '22:30', value: "22:30" },
        { start: '23:00', value: "23:00" },
        { start: '23:30', value: "23:30" },
        { start: '00:00', value: '00:00' },]

    const toggle = () => {
        setModal(!modal);
    };

    const openModal = (id) => {

        setItem(id);
        toggle();
    };

    const toggle1 = () => {
        setModal1(!modal);
    };

    const openModal1 = (id) => {

        setItem(id);
        toggle1();
    };


    const toggle2 = () => {
        setModal2(!modal);
    };

    const openModal2 = (id) => {

        setItem(id);
        toggle2();
    };

    const loadAmbulancesListOfServiceProvider = () => {
        let id = localStorage.getItem('userid')
        apiFunction.ambulanceList(search_key).then(result => {
            if (result.success === true) {

                if (result.data !== undefined && result.data !== null) {
                    setAmbulanceData(result.data)
                    setApprovedAmbulancesList(result.data.approvedAmbulances)
                    setNewAmbulanceList(result.data.pendingAmbulances)
                    setRejectedAmbulancesList(result.data.rejectedAmbulances)
                    setserviceprovider(result.data.service_provider_id, "prsefew")
                }



            }
        })
    }


    //delte api for Avibality-------------------------------------------
    const deleteAvilability = async (id) => {
        var config = {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },

        };
        const result = await axios.delete(`${baseurl}ambulances/availablity/${id}`, config)
        if (result != undefined && result != null) {
            toast.success("Avialibility Deleted Sucessfully");
            getavilibility();
        }
    }
    // //delte api for doccument-------------------------------------------
    const deletedocument = async (id) => {
        var config = {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },

        };
        const result = await axios.delete(`${baseurl}ambulancesDocument/${id}`, config)
        if (result != undefined && result != null) {
            toast.success("Document Deleted Sucessfully")
            getdocument()
        }
    }

    useEffect(() => {
        loadAmbulancesListOfServiceProvider(search_key);   
        
    }, [search_key])
    //uploading profile picture--------------------------------------

    const handleUpload = (e) => {
        setDocUploaded(false)


        let a = ""
        if (proofType == 'Identity Proof') {
            a = 'identity'
        } else if (proofType == 'Vehicle Registration Proof') {
            a = 'medical_doc'
        } else if (proofType == 'Establishment Proof') {
            a = 'establishment_doc'
        }
        if (!a) {
            toast.error("Please select document type")
        }
        else {

            const selectedFile = e.target.files[0];
            const selectedFileName = e.target.files[0].name;
            const formData = new FormData();

            formData.append("files", selectedFile, selectedFileName);
            if ((selectedFile.size) / 1000 > 200) {
                toast.error("Size should be less than 200 kb ")
            }
            else {
                UploadImageFor(formData, a).then(result => {
                    if (result !== null && result != undefined) {
                        setproofpath(result.data.filePath)
                        setDocUploaded(true)

                    }

                })

            }



        }
    }

    //set eqquiped_with of eqquiped
    const Setthespeclization = (e) => {
        if (e.target.value !== undefined && e.target.value !== null && e.target.value !== "") {
            const selectedValue = e.target.value;

            if (equipped.includes(selectedValue)) {
                toast.error("Already added");
                return;
            }

            const updatedEquipped = [...equipped, selectedValue];

            setEquipped(updatedEquipped);
           
        } else {
            toast.error("Please select Equipped With");
        }
    };


    useEffect(() => {
        if (equipped.length > 0) {
            settheeqquiped(equipped)
        }
    }, [equipped])

    //to set value in eqquiped_with of eqquiped
    const settheeqquiped = (equipped) => {
        let eqq1 = ""
        var temp = equipped
        for (let i = 0; i < temp.length; i++) {
            if (i === temp.length - 1) {
                eqq1 += temp[i]; // Append the element without the pipe symbol for the last iteration
            } else {
                eqq1 += temp[i] + " | "; // Append the element with the pipe symbol for other iterations
            }
        }
        seteqq(eqq1)
    }

    useEffect(() => {
        if (tempalist != null && tempalist.length > 0) {
            setavailabilitysetlist(tempalist)
            setTempaList([])
        }

    }, [tempalist])
    //set avilibility-----------
    const settheavilibility = async (e) => {
        if (fromtime !== undefined && fromtime !== null && fromtime !== "" && totime !== undefined && totime !== null && totime !== "") {
            if (totime < fromtime) {
                toast.error("From time shuld be less than to time ")
            }
            else {

                if (day == -1) {
                    for (var i = 0; i < 7; i++) {

                        var temp = availabilitysetlist
                        temp.push({
                            "day": i,
                            "start_time": fromtime,
                            "end_time": totime,

                        })
                        setTempaList(temp)

                    }

                }
                else {
                    let temp = availabilitysetlist
                    temp.push({
                        "day": day,
                        "start_time": fromtime,
                        "end_time": totime,

                    })
                    setTempaList(temp)

                }

            }
        }

        else {
            toast.error("Please enter time")
        }

    }
    useEffect(() => {
        if (tempdlist != null && tempdlist.length > 0) {
            setdoccumentsetlists(tempdlist)
            setTempdList([])

        }

    }, [tempdlist])
    // document settings
    const letsdocument = (e) => {

        if (!proofTitle) {
            toast.error("Please enter title")
        }
        else if (proofType === undefined || proofType === null || proofType === "") {
            toast.error("Please select document type")

        }
        else if (!proofpath) {
            toast.error("Please select file")

        }
        else {
            var temp = doccumentsetlists
            temp.push({
                "doc_type": proofType,
                "title": proofTitle,
                "doc_url": proofpath
            })
            setTempdList(temp)

        }


    }

    //delete he eqquiped with
    const deleteeqquiped = (value) => {
        const updatedEquipped = equipped.filter((element) => element !== value);
        setEquipped(updatedEquipped);
    }


    //delete the avilibility
    const deletavil = (start_time) => {

        var indexDel = availabilitysetlist.findIndex((element) => element.start_time == start_time)

        if (indexDel > -1) {
            availabilitysetlist.splice(indexDel, 1);
        }

        setTempaList(availabilitysetlist)
    }

    //delete the document
    const deletdocum = (title) => {


        var indexDel = doccumentsetlists.findIndex((element) => element.title == title)
        if (indexDel > -1) {
            doccumentsetlists.splice(indexDel, 1);
        }

        setTempdList(doccumentsetlists)
    }




    //post all form data--------------------------------------------------

    const addAmbulanceForService = async () => {
        // e.preventDefault();

        if (doccumentsetlists.length > 0) {
            var requiredDocTypes = ['Identity Proof', 'Vehicle Registration Proof'];
            var missingDocTypes = requiredDocTypes.filter(requiredDocType => !doccumentsetlists.some(obj => obj.doc_type === requiredDocType && obj.document_type !== "Establishment Proof"));
        }

        if (ambulance_type === undefined || ambulance_type === null || ambulance_type === "") {
            return toast.error('Please select Ambulance Type')
        }
        else if (vehicle_number === undefined || vehicle_number === null || vehicle_number === "") {
            return toast.error('Please enter Vehicle Number')
        }
        else if (vehicle_make === undefined || vehicle_make === null || vehicle_make === "") {
            return toast.error('Please enter Vehicle Brand')
        }
        else if (vehicle_model === undefined || vehicle_model === null || vehicle_model === "") {
            return toast.error('Please enter Vehicle Modal')
        }
        else if (operational_state === undefined || operational_state === null || operational_state === "") {
            toast.error('Please select Operational State')
        }
        else if (operational_city === undefined || operational_city === null || operational_city === "") {
            return toast.error('Please select Operational City')
        }
        else if(eqq === undefined || eqq === null || eqq === "") {
            toast.error('Please select Equipped With')
        }
       /*  else if (charge_type === undefined || charge_type === null || charge_type === "") {
            return toast.error('Please select Charge Type')
        } */
        /* else if (charge_type === "flat" && (flat_charge === undefined || flat_charge === null || flat_charge === "")) {
            toast.error('Please enter Flat Charge')
        } */
        /*  else if (charge_per_km === undefined || charge_per_km === null || charge_per_km === "") {
             return toast.error('Please enter Charge Per Km')
         } */

        /*  else if (eqq === undefined || eqq === null || eqq === "") {
             toast.error('Please select Equipped With')
         } */

       /*  if (availabilitysetlist.length == 0) {
            return toast.error('Please Enter Availibility')
        } */
        else if (doccumentsetlists[0] === null || doccumentsetlists[0] === undefined || doccumentsetlists[0] === "") {
            return toast.error('Please upload Documents')
        }

        else if (missingDocTypes.length > 0) {
            var missingDocType = missingDocTypes[0];
            return toast.error(`${missingDocType} is required`)

        }
        else {
            var data = {
                "ambulance_type": ambulance_type,
                "vehicle_number": vehicle_number,
                "vehicle_make": vehicle_make,
                "charge_type": charge_type,
                "vehicle_model": vehicle_model,
                "flat_charge": flat_charge,
                "charge_per_km": charge_per_km,
                "advance_charge": advance_charge,
                "operational_city": operational_city,
                "operational_state": operational_state,
                "equipped_with": eqq,
                "availability": availabilitysetlist,
                "documents": doccumentsetlists,
                "ambulance_pic": ambulance_pic,
                "interior_image": interior_image,
                "exterior_image": exterior_image,
            };
            apiFunction.addAmbulance(data).then(result => {
                if (result != null && result !== undefined) {
                    if (result.success) {
                        toast.success('Ambulance has been added. Please wait for approval');
                         document.getElementById("myModal").click()
                        loadAmbulancesListOfServiceProvider();
                    }
                }
            })
        }
    }
    // //adding avibility table-------------------------
    const AddAvibility = async (e) => {
        e.preventDefault();
        if (fromtime !== undefined && fromtime !== null && fromtime !== "" && totime !== undefined && totime !== null && totime !== "") {

            if (totime < fromtime) {
                toast.error("From time shuld be less than to time ")
            }
            else {
                try {
                    if (day == -1) {

                        for (var i = 0; i < 7; i++) {

                            var data = {
                                "day": i,
                                "start_time": fromtime,
                                "end_time": totime,
                                "fee": null,
                                "user_service_id": serviceprovider
                            };

                            var config = {

                                headers: {
                                    'authorization': token,
                                    'Content-Type': 'application/json'
                                },

                            };
                            const result = await axios.post(`${baseurl}ambulances/availablity`, JSON.stringify(data), config)



                        }
                        toast.success("Available Added Sucessfully");
                        getavilibility();
                    }
                    else {
                        var data = {
                            "day": day,
                            "start_time": fromtime,
                            "end_time": totime,
                            "fee": null,
                            "user_service_id": serviceprovider
                        };

                        var config = {

                            headers: {
                                'authorization': token,
                                'Content-Type': 'application/json'
                            },

                        };
                        const result = await axios.post(`${baseurl}ambulances/availablity`, JSON.stringify(data), config)
                        if (result != null) {

                            toast.success("Available Added Sucessfully");
                            getavilibility();
                        }


                    }


                } catch (error) {
                    toast.error(error)
                }

            }

        }
        else {
            toast.error("Please enter time")
        }

    }

    const Adddocumentlist = async (e) => {

        e.preventDefault();
        if (!proofTitle) {
            toast.error("Please enter title")
        }
        else if (proofType === undefined || proofType === null || proofType === "") {
            toast.error("Please select document type")

        }
        else if (!proofpath) {
            toast.error("Please select file")

        }

        else {

            var data = {
                "doc_type": proofType,
                "title": proofTitle,
                "doc_url": proofpath
            };

            var config = {

                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },

            };
            const result = await axios.post(`${baseurl}ambulancesDocument`, JSON.stringify(data), config)
            if (result != null && result != undefined) {

                toast.success("Document Added Sucessfully")
                setProofFile()
                setproofpath()
                getdocument()

            }
        }
    }

    // //get api for avilibility-------------------------------------------
    const getavilibility = async () => {
        var config = {

            headers: {
                'authorization': token,
            },

        };

        const result = await axios.get(`${baseurl}ambulances/availablity/${ambulanceData.ambulace_id}`, config)
        if (result != null && result != undefined) {
            setavailabilitysetlist(result.data.data)
        }

    }
    // //get api for documnet--------------------------------------------
    const getdocument = async () => {
        const user_id = localStorage.getItem('userid')
        var config = {
            headers: {
                'authorization': token,
            },
        };

        const result = await axios.get(`${baseurl}ambulancesDocument/${ambulanceData.ambulace_id}`, config)
        if (result != null && result != undefined) {
            setdoccumentsetlists(result.data.data)
        }


    }

    const handleProfileImage = (type) => {
        if (type == 'profile') {
            let inPutClick = document.getElementById('profilePic')
            inPutClick.click()
        } else if (type == 'interiorImage') {
            let inPutClick = document.getElementById('interiorImage')
            inPutClick.click()
        }
        else if (type == 'exteriorImage') {
            let inPutClick = document.getElementById('exteriorImage')
            inPutClick.click()
        }
    }

    //uploading profile picture--------------------------------------
    const uploadProfilePictur = (e, uploadFor) => {
        setIsLoading(true)
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, uploadImageFor.signature_doc).then(result => {
            if (result !== null) {
                var temp = ambulanceData;
                if (uploadFor === "ambulace_pic") {
                    temp.ambulance_pic = result.data.filePath;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        setAmbulanceImage(e.target.result)
                    };
                    reader.readAsDataURL(selectedFile);
                }
                else if (uploadFor === "interior_image") {
                    temp.interior_image = result.data.filePath;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        setInteriorImage(e.target.result)
                    };
                    reader.readAsDataURL(selectedFile);
                }
                else if (uploadFor == "exterior_image") {
                    temp.exterior_image = result.data.filePath;
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        setExteriorImage(e.target.result)
                    };
                    reader.readAsDataURL(selectedFile);
                }
                setAmbulanceData(temp)



                // document.getElementById("showProfile").innerHTML = '<img src="' + e.target.result + '"/>';
            }
        })
    }


    useEffect(() => {
        stateList.map((key) => {
            if (key.state_name == operational_state) {
                setCityId(key.id)

            }

        })
    }, [state])

    useEffect(() => {
        if (cityId != null) {
            cityAPI()
        }
    }, [cityId])



    const stateAPI = async () => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': token
            }
        }
        const response = await axios.get(`${baseurl}states`, headers)
        setStateList(response.data.data)


    }
    const cityAPI = async () => {
        const headers = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': token
            }
        }
        const citydata = await axios.get(`${baseurl}states/${cityId}/cities`)
        setCityList(citydata.data.data)

    }


    return (
        <div className='profile_body'>

            <div className='margin-mb-30'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div class="searchbox margin-0">



                            <div class="keyword">
                                <span class="searchicon"><img src="" /></span>
                                <form>
                                    <input
                                        type="text"
                                        value={search_key}
                                        onChange={(e) => { setSearch_key(e.target.value) }}
                                        className="form-control"
                                        placeholder="Enter type/city "

                                    />
                                </form>
                            </div>

                        </div>

                    </div>
                    <div className='col-lg-5'>

                    </div>
                    <div className='col-lg-3 text-right '>
                        <button className='btn btn-info' data-toggle="modal" data-target="#myModal" onClick={stateAPI} >Add ambulance</button>
                    </div>
                    {/* <!-- Modal --> */}
                    <div id="myModal" class="modal fade" role="dialog">
                        <div class="modal-dialog modal-lg">

                            {/* <!-- Modal content--> */}
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    <h4 class="modal-title">Add ambulance</h4>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div class="fd_input mb-2">
                                                <div class="form-group" >
                                                    <label>Ambulance Type</label>
                                                    <select className="form-control" name='ambulance_type' value={ambulance_type} onChange={(e) => onInputChange(e)}>
                                                        <option value="">Ambulance Type</option>
                                                        <option value="BLS" selected={ambulance_type === 'Hospital'}>Basic Life Support</option>
                                                        <option value="ALS" selected={ambulance_type === 'Ambulance Type trust'}>Advance Life Support</option>
                                                        <option value="PTS" selected={ambulance_type === 'Government'}>Patient Transport Service</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-3">
                                            <div class="fd_input">
                                                < div class="form-group">
                                                    <label>Vehicle Number</label>
                                                    <input type="text" name="vehicle_number" class="form-control" placeholder="" value={vehicle_number} onChange={(e) => onInputChange(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class="fd_input">
                                                <div class="form-group">
                                                    <label>Vehicle Brand</label>
                                                    <input type="text" name="vehicle_make" class="form-control" placeholder="" value={vehicle_make} onChange={(e) => onInputChange(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class="fd_input">
                                                <div class="form-group">
                                                    <label>Vehicle Model</label>
                                                    <input type="text" name="vehicle_model" class="form-control" placeholder="" value={vehicle_model} onChange={(e) => onInputChange(e)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3">
                                            <div class="fd_input mb-2">
                                                <div class="form-group aggregrator_decs">
                                                    <label for="gen">State</label>
                                                    <select class="form-control" name='operational_state'
                                                        value={operational_state} onChange={(e) => onInputChange(e)} >
                                                        <option>Operational State</option>
                                                        {
                                                            stateList.map((val, ind) => {
                                                                return (
                                                                    <option value={val.state_name}>{val.state_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="fd_input mb-2">
                                                <div class="form-group aggregrator_decs">
                                                    <label for="gen">City</label>
                                                    <select class="form-control" name='operational_city'
                                                        value={operational_city} onChange={(e) => onInputChange(e)} >
                                                        <option>Operational City</option>
                                                        {(cityList.length > 0 && cityList !== null) && cityList.map((key) => (
                                                            <option value={key.city_name}>{key.city_name}</option>

                                                        ))}


                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class="fd_input">
                                                <div className="form-group">
                                                    <label for="charge_type">Charge type</label>
                                                    <input type="text" name="charge_type" class="form-control" disabled value="Paid"/>

                                                    {/* <select name="charge_type" value={charge_type} onChange={(e) => onInputChange(e)}>
                                                        <option value="">Charge Type</option>
                                                        <option value="paid">Paid</option>
                                                        <option value="free">Free</option>
                                                    </select> */}
                                                </div>

                                            </div>
                                        </div>
                                        {isPaid && <>
                                            <div className="col-lg-3">
                                                <div class="fd_input">
                                                    <div class="form-group">
                                                        <label>Flat Charge</label>
                                                        <input type="number" name="flat_charge" class="form-control" placeholder="" value={flat_charge} onChange={(e) => onInputChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div class="fd_input">
                                                    <div class="form-group">
                                                        <label>Charge per km</label>
                                                        <input type="number" name="charge_per_km" class="form-control" placeholder="" value={charge_per_km} onChange={(e) => onInputChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div class="fd_input">
                                                    <div class="form-group">
                                                        <label>Advance Charge</label>
                                                        <input type="number" name="advance_charge" class="form-control" placeholder="" value={advance_charge} onChange={(e) => onInputChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        <div className='col-lg-6' style={{ height: "14vh" }}>

                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-4'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div class="fd_input mb-2">
                                                            <div class="form-group profile-photo ">
                                                                <div className='profile-photo-sig' id='showProfile' onClick={() => handleProfileImage('profile')}>

                                                                    {(ambulanceImage) ? <img style={{ 'z-indez': '11111' }} src={ambulanceImage} alt="Select Ambulance Picture" /> : <p>Select Ambulance Picture</p>}
                                                                </div>
                                                                <input id='profilePic' type={'file'} onChange={(e) => uploadProfilePictur(e, "ambulace_pic")} />
                                                                {/* <button className='btn btn-info'>Upload</button> */}
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className='profile-upload' > <span onClick={() => handleProfileImage('profile')}> Change Picture</span> </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div class="fd_input mb-2">
                                                            <div class="form-group profile-photo ">
                                                                <div className='profile-photo-sig' id='showProfile2' onClick={() => handleProfileImage('interiorImage')}>

                                                                    {(interiorImage) ? <img style={{ 'z-indez': '11111' }} src={interiorImage} alt="Select Interior Picture" /> : <p>Select Interior Picture</p>}
                                                                </div>
                                                                <input id='interiorImage' type={'file'} onChange={(e) => uploadProfilePictur(e, "interior_image")} />
                                                                {/* <button className='btn btn-info'>Upload</button> */}
                                                            </div>

                                                        </div>

                                                    </div>


                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className='profile-upload' > <span onClick={() => handleProfileImage('profile')}> Change Picture</span> </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div class="fd_input mb-2">
                                                            <div class="form-group profile-photo ">
                                                                <div className='profile-photo-sig' id='showProfile3' onClick={() => handleProfileImage('exteriorImage')}>

                                                                    {(exteriorImage) ? <img style={{ 'z-indez': '11111' }} src={exteriorImage} alt="Select Exterior Picture" /> : <p>Select Exterior Picture</p>}
                                                                </div>
                                                                <input id='exteriorImage' type={'file'} onChange={(e) => uploadProfilePictur(e, "exterior_image")} />
                                                                {/* <button className='btn btn-info'>Upload</button> */}
                                                            </div>

                                                        </div>

                                                    </div>


                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className='profile-upload' > <span onClick={() => handleProfileImage('profile')}> Change Picture</span> </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        {/* <div class="col-lg-12">
                                            <div class="fd_input">
                                                <div class="form-group">
                                                    <label>Availability</label>
                                                    <div className='border-spe'>

                                                        <div className='availability-profile'>
                                                            <select type="email" name='email' class="form-control" placeholder="" value={day} onChange={(e) => setday(e.target.value)} >
                                                                {
                                                                    (AllDaysList != null && AllDaysList != undefined) && AllDaysList.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.value}>{val.Dayname}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            
                                                            <select name='timefrom' class="form-control" placeholder="" value={fromtime} onChange={(e) => setfromtime(e.target.value)} >
                                                                {
                                                                    (TimeFrom != null && TimeFrom != undefined) && TimeFrom.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.value}>{val.start}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                            <select name='timefrom' class="form-control" placeholder="" value={totime} onChange={(e) => settotime(e.target.value)} >
                                                                {
                                                                    (TimeTo != null && TimeTo != undefined) && TimeTo.map((val, ind) => {
                                                                        return (
                                                                            <option value={val.value}>{val.start}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                            {
                                                                isapplied ?
                                                                    <button className='btn btn-info btn-sm' onClick={(e) => AddAvibility(e)}> + Add</button>
                                                                    :
                                                                    <button className='btn btn-info btn-sm' onClick={(e) => settheavilibility(e)}> + Add</button>
                                                            }

                                                        </div>
                                                        <table class="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Days</th>
                                                                    <th scope="col">From Time</th>
                                                                    <th scope="col">To Time</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (availabilitysetlist != null && availabilitysetlist != undefined) &&
                                                                    availabilitysetlist.map((val, ind) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{val.day == 0 ? 'Sunday' : val.day == 1 ? 'Monday' : val.day == 2 ? 'Tuesday' : val.day == 3 ? 'Wednesday' : val.day == 4 ? 'Thursday' : val.day == 5 ? 'Friday' : val.day == 6 ? 'Saturday' : val.day == 7 ? "All day" : ''}</td>
                                                                                <td>{val.start_time}</td>
                                                                                <td>{val.end_time}</td>
                                                                                {isapplied ?
                                                                                    <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deleteAvilability(val.id)} >Delete</td>
                                                                                    :
                                                                                    <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deletavil(val.start_time)} >Delete</td>
                                                                                }

                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr /> */}

                                        <div className='col-lg-12'>
                                            <div className='fd_input'>
                                                <div className='truper border-spe'>
                                                    <select class="form-control horizontal-select" name='eqquiped_with' placeholder="" onChange={(e) => Setthespeclization(e)}>
                                                        <option disabled selected>Equipped with</option>
                                                        <option value="Emergency Kit">Emergency Kit</option>
                                                        <option value="Oxygen Cylinder">Oxygen Cylinder</option>
                                                        <option value="Multipara Monitor">Multipara Monitor </option>
                                                    </select>
                                                    {/* {equipped.length} */}
                                                    <div className='rakesh-flex'>
                                                        {equipped.map((val) => {
                                                            return (
                                                                <div>
                                                                    <p className='rakesh-padding rakesh-flex'>
                                                                        {val}
                                                                        <i className="fa-solid fa-xmark" style={{ cursor: "pointer" }} onClick={() => deleteeqquiped(val)}></i>
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <hr />
                                    <div class="col-lg-12">
                                        <div class="col-lg-12">
                                            <div class="fd_input">
                                                <div class="form-group">

                                                    <div className='border-spe'>
                                                        <div className='documents-profile'>
                                                            <select onChange={(e) => setProofType(e.target.value)} value={proofType} class="form-control" placeholder="" >
                                                                <option disabled selected>Select Proof</option>
                                                                <option value={"Identity Proof"}>Identity Proof</option>
                                                                <option value={"Vehicle Registration Proof"}>Vehicle Registration Proof</option>
                                                                <option value={"Establishment Proof"}>Establishment Proof </option>
                                                            </select>
                                                            <input type="text" onChange={(e) => setProofTitle(e.target.value)} placeholder='Title' class="form-control" />
                                                            <input type="file" onChange={(e) => handleUpload(e)} class="form-control" placeholder='max size 200 kb' />


                                                            {
                                                                isapplied ?
                                                                    <button className='btn btn-info btn-sm' onClick={(e) => Adddocumentlist(e)} >{docUploaded ? "+ Add" : <ThreeDots
                                                                        height="30"
                                                                        width="30"
                                                                        radius="9"
                                                                        color="#4fa94d"
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClassName=""
                                                                        visible={true}
                                                                    />} </button>
                                                                    :
                                                                    <button className='btn btn-info btn-sm' onClick={(e) => letsdocument(e)} disabled={!docUploaded}> + Add</button>
                                                            }

                                                        </div>
                                                        <div className='size_validation'>
                                                            <span>&nbsp;</span>
                                                            <span>&nbsp;</span>
                                                            <span> Max Size: 200 KB Allow </span>
                                                            <span>&nbsp;</span>
                                                        </div>
                                                        <table class="table table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Document Type</th>
                                                                    <th scope="col">Title</th>
                                                                    <th scope="col">Link</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (doccumentsetlists != null && doccumentsetlists != undefined) && doccumentsetlists.map((val, ind) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{val.doc_type}</td>
                                                                                <td>{val.title}</td>
                                                                                <td><a href={val.doc_url} target="_blank">View</a></td>
                                                                                {isapplied ?
                                                                                    <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deletedocument(val.id)} >Delete</td>
                                                                                    :
                                                                                    <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deletdocum(val.title)} >Delete</td>
                                                                                }
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    <button type="button" class="btn btn-info" onClick={()=>addAmbulanceForService()}>Save</button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal-save add ambulane --> */}
            <div id="myModal-001" class="modal fade" role="dialog">
                <div class="modal-dialog">

                    {/* <!-- Modal content--> */}
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Congratulation </h4>
                        </div>
                        <div class="modal-body">
                            <p>your request for adding new ambulance has
                                been successfully sent
                                once admin approved your request new
                                ambulance will added into your inventory</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">OKAY</button>
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <h4 className='margin-mb-10 m-top-40px'>Status: Approved Ambulances</h4>
                <table class="table table-striped table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">Ambulance Type</th>
                            <th scope="col">Vehicle number</th>
                            {/* <th scope="col">Availability</th> */}
                            <th scope="col">Operational state/city</th>
                            {/* <th scope="col">Operational hours</th> */}
                            <th scope="col">Charge per KM </th>
                            <th scope="col">Document</th>
                        </tr>
                    </thead>
                    <tbody>
                        {approvedAmbulancesList.length > 0 ? (
                            approvedAmbulancesList.map((key, index) => (
                                <tr key={index}>
                                    <td>{key.ambulance_type}</td>
                                    <td>{key.vehicle_number}</td>
                                    {/* <td>{key.availability}</td> */}
                                    <td>{key.operational_city}</td>
                                    {/* <td>{key.availabilities && key.availabilities.length > 0 ? key.availabilities[0].start_time : ""}</td> */}
                                    <td>{key.charge_per_km}</td>
                                    <td>
                                        {key.documents && key.documents.length > 0 ? (
                                            <a href={key.documents[0].doc_url} target="_blank" rel="noopener noreferrer">
                                                View
                                            </a>
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">No Data Found</td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>

            <div>
                <h4 className='margin-mb-10 m-top-40px'>Status: New ambulance Registration Request</h4>
                <table class="table table-striped table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">Ambulance Type</th>
                            <th scope="col">Vehicle number</th>
                            {/* <th scope="col">Availability</th> */}
                            <th scope="col">Operational state/city</th>
                            {/* <th scope="col">Operational hours</th> */}
                            <th scope="col">Charge per KM </th>
                            <th scope="col">Document</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(newAmbulanceList.length > 0) ? newAmbulanceList.map((key, index) => (
                            <tr>
                                <td>{key.ambulance_type}</td>
                                <td>{key.vehicle_number}</td>
                                {/* <td>{key.availability}</td> */}
                                <td>{key.operational_city}</td>
                                {/* <td>{(key.availabilities && key.availabilities.length > 0) ? key.availabilities[0].start_time : ""}</td> */}
                                <td>{key.charge_per_km}</td>
                                <td><a href={(key.documents && key.documents.length > 0) ? key.documents[0].doc_url : ""}>View</a></td>
                            </tr>
                        )) : <tr><td colSpan="7">No Data Found</td></tr>}
                    </tbody>
                </table>
            </div>

            <div>
                <h4 className='margin-mb-10 m-top-40px'>Status: Rejected Ambulance List </h4>
                <table class="table table-striped table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">Ambulance Type</th>
                            <th scope="col">Vehicle number</th>
                            {/* <th scope="col">Availability</th> */}
                            <th scope="col">Operational state/city</th>
                            {/* <th scope="col">Operational hours</th> */}
                            <th scope="col">Charge per KM </th>
                            <th scope="col">Document</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(rejectedAmbulancesList.length > 0) ? rejectedAmbulancesList.map((key, index) => (
                            <tr>
                                <td>{key.ambulance_type}</td>
                                <td>{key.vehicle_number}</td>
                                {/* <td>{key.availability}</td> */}
                                <td>{key.operational_city}</td>
                                {/* <td>{(key.availabilities && key.availabilities.length > 0) ? key.availabilities[0].start_time : ""}</td> */}
                                <td>{key.charge_per_km}</td>
                                <td><a href={(key.documents && key.documents.length > 0) ? key.documents[0].doc_url : ""}>View</a></td>
                            </tr>
                        )) : <tr><td colSpan="7">No Data Found</td></tr>}

                    </tbody>
                </table>
            </div>



        </div>
    )
}

export default AddAmbulance