import React, { useEffect, useState } from 'react'
import VinitaIcon from "../../../images/images/vinita.png"
import DownloadPng from "../../../images/images/download.png"
import { HomepageHeader, SidebarUserPanel } from '../../../components'
import { ProfileService } from '../../../services'
import moment from 'moment'
import APICore from '../../../services/APICore';
import Invoicedownload from '../../E-prescription/downloadinvoice'
import { color } from '@mui/system'
import Sidebarmobile from '../../../components/user/Sidebarmobile'


const MyPaymentHistory = () => {
  const [myPaymentList, setMyPaymentList] = useState([])
  const [doctor, setdoctor] = useState([])
  const [search, setsearch] = useState('')
  const [sortby, setsortby] = useState('ThisMonth')
  const [status, setstatus] = useState('Success')
  const [download,setDownLoad]=useState(false)
  const [InvoiceData,setInvoiceData]=useState(null)
  const apiFunction = new ProfileService();
  useEffect(() => {
    loadDoctors();

  }, [search, sortby, status])

  const loadDoctors = () => {
    apiFunction.MyDetailsPaymentList(search, sortby, status).then(result => {
      console.log(result.data, "result")
      if (result !== undefined) {
        if (result.success === true) {
          setMyPaymentList(result.data)
        }
      }
    })

  }
  const handleDownload=(data)=>{
    setDownLoad(true)
    setInvoiceData(data)

  }
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="outerhomepage">
      {/*Top Navbar Menu*/}
      <HomepageHeader />

      <section className=" paddbottom">
        <div className="">
          <div className="userpanel_page bg_light">

            {/* Side bar */}
            {/* <SidebarUserPanel /> */}
            <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                                 {isVisible ? <i class="fa-solid fa-caret-down"></i>: <i class="fa-solid fa-caret-right"></i>}
                              </button>
                            {isVisible && (
                     <div>
                     <Sidebarmobile />
                </div>
      )}

                        <SidebarUserPanel />

            <div className="right_panel ">
              <div class="content-wrapper" style={{ marginLeft: 0 }}>
                <div class="content content-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="content-header">
                        <h1 class="pull-left">
                          Transactions
                        </h1>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='row'>
                        <div className='col-md-4'>
                          <input type="text" className='form-control' value={search} onChange={(e) => setsearch(e.target.value)} placeholder='Search' />
                        </div>
                        <div className='col-md-4'>
                          <select className='form-control' value={sortby} onChange={(e) => setsortby(e.target.value)}>
                            <option value={"ThisMonth"}>This Month</option>
                            <option value={"ThisYear"}>This Year</option>
                            <option value={"PreviousMonth"}>Last Month</option>
                            <option value={"ThisQuater"}>This Quater</option>
                          </select>
                        </div>
                        <div className='col-md-4'>
                          <select className='form-control' value={status} onChange={(e) => setstatus(e.target.value)}>
                            <option value={"Success"}>Completed</option>
                            <option value={"Failed"}>Failed/Cancelled</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row spacetop">
                    <div className='col-md-12 '>
                      <div className="dr_table table-responsive bgwhite">
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Service</th>
                              <th>Service Provider</th>
                              <th>Payment Date</th>
                              <th>Payment Amount</th>
                              <th>Status</th>
                              <th>Transaction ID</th>
                              <th>Booking ID</th>
                              <th>Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(myPaymentList.length > 0) ? myPaymentList != null && myPaymentList.map((key, index) => (
                              <tr>
                                <td>
                                  {index + 1}
                                </td>
                                <td>
                                  {key.service_type}
                                </td>
                                <td>
                                  {(key.name != null) && key.name}
                                </td>
                                <td>
                                  {(moment(key.transaction_date).utc()).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  {key.amount}
                                </td>
                                <td>
                                  {key.transaction_status}
                                </td>
                                <td>
                                  {key.transaction_id}
                                </td>
                                <td>{key.booking_id}</td>
                                <td><a onClick={(e)=>handleDownload(key)} style={{cursor:"pointer",color:"blue"}}>Download</a></td>
                              </tr>

                            )) : <tr><td colspan="12"><p class="marginme">"No Record Found"</p></td></tr>}



                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                  {download &&
                      <Invoicedownload invoiceData={InvoiceData} setDownLoad={setDownLoad} />
                  }

                </div>
              </div>


            </div>



          </div>
        </div>
      </section>

    </div>
  )
}

export default MyPaymentHistory