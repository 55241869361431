import React, { useEffect, useState,useRef } from 'react'
import { Footer, HomepageHeader, LocationsList } from '../../components'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LocationIcon from "../../images/home/map-pin.png"
import SearchIcon from "../../images/home/search.png"
import VerifiedImg from "../../images/images/patch-check.png"
import ThumbBg from "../../images/images/thumb_bg.png"
import CalanderDate from "../../images/images/date.png"
import { Cities as CitiesList, ShowProfileType } from '../../enums/Enums'
import SearchServices from '../../services/SearchServices';
import UserIcon60 from "../../images/images/icons-user-60.png"
import { HomePageServices } from '../../services';
import { ClosedCaptionDisabledOutlined } from '@mui/icons-material';


  

const SearchFilterPage = () => {
  
    const { state } = useLocation();
    const navigate = useNavigate();
    // Destructuring for state that send from last page 
    const apifunction = new SearchServices();
    const apifunction2 = new HomePageServices();
    const { locationState, searchKeyState, pageType } = state;
    const [count, setCount] = useState(0);
    const [isSuggestionListShow, setIsSuggestionListShow] = useState(false);

    const [Cities] = useState(CitiesList)
    const [search_keyCity, setsearch_keyCity] = useState("")
    const [searchedCityList, setSearchedCityList] = useState([])
    const [searchKey, setSearchkey] = useState("")
    const [searchList, setSearchList] = useState([])
    const [suggetionSearchList, setSuggetionSearchList] = useState([])
    const [isloading, setisloading] = useState(false)
    const [isloadingSuggetionList, setIsloadingSuggetionList] = useState(false)
    const [ searchagain,setsearchagain]= useState("")

    const [filterVariables, setFilterVariables] = useState({
        videoConsult: false,
        gender: "",
        availability: "",
        consultFee: ""
    })

    const { videoConsult, gender, availability, consultFee } = filterVariables
    
    useEffect(()=>{window.scrollTo(0, 0);       
        getResultsBaseOnSearch(pageType, searchagain, locationState, videoConsult, gender, availability, consultFee)},[searchagain])

    useEffect(() => {
        window.scrollTo(0, 0);       
        getResultsBaseOnSearch(pageType, searchKeyState, locationState, videoConsult, gender, availability, consultFee)

    }, [])   

    // this api will call for the search result
    const getResultsBaseOnSearch = (pageType, searchKey, locationState, videoConsult, gender, availability, consultFee) => {       
        setsearchagain(searchKey)
        // making search url base on search
        var apiurl;
        
        if (pageType === "teleconsultancy") {           
            if(localStorage.getItem('token')){
                apiurl = "search/afterLogin?search_key=" + searchKey + "&location=Bhopal"
            }else{
                apiurl = "search/doctors?search_key=" + searchKey + "&location=Bhopal"
            }
        }
        else if (pageType === "ambulance") {
            searchKey === "Patient Transport Service" ?
                searchKey = "PTS" : searchKey === "Basic Life Support" ?
                    searchKey = "BLS" : searchKey = "ALS"

            apiurl = `ambulances?city=${locationState}&type=${searchKey}`
        } else {
            apiurl = "search/nurses?search_key=" + searchKey +
                "&gender=" + gender +
                "&availability=" + availability + "&consultFee=" + consultFee
        }
        apifunction.searchResultFunc(apiurl).then(res => {           
            const result = res
            if (result !== undefined) {
                if (result.success === true) {
                    setSearchList(result.data)
                    setCount(1)
                }
            }
        })
    }




    const showProfileNavigate = (userid, type) => {
        navigate('/show-profile-and-slots', {
            state: {
                userid: userid,
                searchType: type
            }
        })
    }

    

    // Doctor Profiles Result
    const DoctorSearchResult = ({ userid, imageUser, name, speciality, experience, address, countPaitents, priceRupees, availability, likecount }) => {
        return (
            <div className="search_list bg_light">
                <div className="left_block">
                    {imageUser ?
                        <div className="dr_photo">
                            <img src={imageUser} />
                        </div>
                        :
                        <div className="userIcon_photo">
                            <img src={UserIcon60} />
                        </div>
                    }

                    <div className="doctor_bio">
                        <h3 className="dr_name">{name}</h3>
                        <p className="dr_quali">{speciality}</p>
                        <p className="dr_exp">{experience} Years</p>
                        <p className="dr_address">{address}</p>

                    </div>
                </div>
                <div className="right_block">
                    {priceRupees &&
                        <div className="fees">&#x20B9; {priceRupees}</div>
                    }
                    {availability === 1 ?
                        <>
                            <div className="available"><img src={CalanderDate} alt />Available Today</div>

                            <button onClick={() => showProfileNavigate(userid, ShowProfileType.type1)} className="btn btn-info">Book Appointment</button>
                        </>
                        : <div className="notavailable">Not available Today</div>

                    }
                </div>
            </div>
        )
    }
  
    // Nurses Profiles Result
    const NurseSearchResult = ({ userid, imageUser, name, speciality, experience, address, countPaitents, priceRupees, availability, likecount }) => {
        return (
            <div className="search_list bg_light">
                <div className="left_block">
                    {imageUser ?
                        <div className="dr_photo">
                            <img src={imageUser} />
                        </div>
                        :
                        <div className="userIcon_photo">
                            <img src={UserIcon60} />
                        </div>
                    }
                    <div className="doctor_bio">
                        <h3 className="dr_name">{name}</h3>
                        <p className="dr_quali">{speciality}</p>
                        <p className="dr_exp">{experience}</p>
                        <p className="dr_address">{address}</p>
                        <p className="pt_served"><img src={ThumbBg} />  {countPaitents} paitents served</p>
                    </div>
                </div>
                <div className="right_block">
                    {
                        priceRupees &&
                        <div className="fees">&#x20B9; {priceRupees}</div>
                    }

                    {availability === 1 ?
                        <>
                            <div className="available"><img src={CalanderDate} alt />Available Today</div>
                            <button onClick={() => showProfileNavigate(userid, ShowProfileType.type2)} className="btn btn-info">Book Appointment</button>
                        </>
                        : <div className="notavailable">Not available Today</div>
                    }
                </div>
            </div>
        )
    }

    // Ambulance Profiles Result
    const AmbulanceSearchResult = ({ userid, imageUser, name, address, priceRupees }) => {

        return (
            <div className="search_list bg_light">
                <div className="left_block">
                    <div className="dr_photo">
                        <img src={imageUser ? imageUser : UserIcon60} />
                    </div>
                    <div className="doctor_bio">
                        <h3 className="dr_name">{name}</h3>

                        <p className="dr_address">{address}</p>
                        {/* <p className="pt_served"><img src={ThumbBg} />  750 paitents served</p> */}
                    </div>
                </div>
                <div className="right_block">
                    {
                        priceRupees &&
                        <div className="fees">&#x20B9; {priceRupees}</div>
                    }

                    <div className="available"><img src={CalanderDate} alt />Available Today</div>
                    <button onClick={() => showProfileNavigate(userid, ShowProfileType.type3)} className="btn btn-info">Book Appointment</button>

                </div>
            </div>
        )
    }

    useEffect(() => {
        if (count !== 0) {
            const delayDebounceFn = setTimeout(() => {
                if (searchKey) {
                    return universalSearch(searchKey);
                } else {
                    return setSuggetionSearchList([]);
                }
            }, 300);
            setIsSuggestionListShow(true)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchKey])

    const universalSearch = (searchKey) => {
        setIsloadingSuggetionList(true)
        let type = "";
        if (pageType === "teleconsultancy") {
            type = "Doctor";
        }
        else if (pageType === "ambulance") {
            type = "Nurse";
        } else {
            type = "Ambulance";
        }
        apifunction2.SearchPublicPage(searchKey,type,locationState).then(res => {

            if (res !== undefined) {
                var result = res
                if (result.success === true) {
                    setSuggetionSearchList(result.data)
                    setIsloadingSuggetionList(false)
                }
            }
        })
    }


    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            {/*Inner Page Banner*/}
            <section className="app_search_box">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-8">
                        </div>
                    </div>
                </div>
            </section>
            {/* <GetGeoLocation /> */}

            {/* Searching List*/}
            <section className="searching_page paddtop paddbottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-offset-2">
                            <div className="searching_pnl">
                                <div className="searchbox mt0">
                                    <div className="locality">
                                        <span className="mapicon"><img src={LocationIcon} /></span>
                                        <input type="text" value={search_keyCity} onChange={(e) => setsearch_keyCity(e.target.value)} className="form-control" placeholder="Location" />
                                    </div>
                                    <div className="cardSuggetionsLocationSearchLocation">
                                        <LocationsList search_keyCity={search_keyCity} setsearch_keyCity={setsearch_keyCity} />
                                    </div>
                                    <div className="vline" />
                                    <div className="keyword">
                                        <span className="searchicon"><img src={SearchIcon} /></span>
                                        <input type="text" value={searchagain} onChange={(e) => setsearchagain(e.target.value)} className="form-control" placeholder="Dentist"/>
                                    </div>
                                </div>
                                {isSuggestionListShow ?
                                    <div className="cardSuggetionsMainSearch">
                                        {suggetionSearchList.length > 0 ?
                                            <>
                                                <ul>
                                                    {
                                                        suggetionSearchList.map(item => (
                                                            <li key={item.id + "doctorsNursesAmbulances-list"}>
                                                                {item.type && item.type.toLowerCase() === "specialization"
                                                                    ?
                                                                    <button type='button'
                                                                        onClick={() => {
                                                                            getResultsBaseOnSearch(
                                                                                item.type === "specialization" ? "teleconsultancy_search" : "",
                                                                                item.name,
                                                                                locationState, videoConsult,
                                                                                gender, availability,
                                                                                consultFee,
                                                                                true
                                                                            );
                                                                            setIsSuggestionListShow(false)
                                                                        }
                                                                        }
                                                                    >
                                                                        {item.name}
                                                                        <p className='resultType'>{item.type}</p>
                                                                    </button>
                                                                    :
                                                                    <Link to="/show-profile-and-slots"
                                                                        state={{
                                                                            userid: item.id, searchType:
                                                                                item.type === "Doctor" ? ShowProfileType.type1 :
                                                                                    item.type === "Nurse" ? ShowProfileType.type2 :
                                                                                        item.type === "Ambulance" ? ShowProfileType.type3 : ""
                                                                        }
                                                                        }
                                                                    >
                                                                        {item.name}
                                                                        <p className='resultType'>{item.type}</p>
                                                                    </Link>
                                                                }
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </>
                                            :
                                            <>
                                                {isloadingSuggetionList ?
                                                    <p className={searchKey === "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                                                        Loading...
                                                    </p>
                                                    :
                                                    <p className={searchKey === "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                                                        No Records Found
                                                    </p>
                                                }
                                            </>
                                        }

                                    </div>
                                    : ""
                                }

                                {pageType === "teleconsultancy_search"
                                    ?
                                    <>
                                        <h3 className="heading2 mt40">{searchList.length} Doctor'(s) are available in {search_keyCity}</h3>
                                        <p className="book_title"><img src={VerifiedImg} />  Book verified Doctor</p>
                                    </>
                                    :
                                    pageType === "ambulance"
                                        ?
                                        <>
                                            <h3 className="heading2 mt40">{searchList.length} Ambulance are available in your area with multiple speciality </h3>
                                            <p className="book_title"><img src={VerifiedImg} />  Book verified Ambulance</p>
                                        </>
                                        :
                                        <>
                                            <h3 className="heading2 mt40">{searchList.length} Health Care {(searchList.length >1) ? "Experts are" : "Expert is"} available in your area</h3>
                                            <p className="book_title"><img src={VerifiedImg} />  Book verified Doctor</p>
                                        </>
                                }

                            </div>


                            {/*Doctor Info*/}

                            <div className="search_wraper paddtop">
                                {isloading === false ?
                                    <>
                                        {searchList.length > 0 ? searchList.map(item => (
                                            <>
                                                {pageType === "teleconsultancy"
                                                    ?

                                                    <DoctorSearchResult key={item.id + "searchDoctors"}
                                                        userid={item.id}
                                                        imageUser="" bio={item.bio} name={item.name}
                                                        speciality={item.specialization} experience={item.experience}
                                                        address={item.address} countPaitents={null}
                                                        availability={item.Available}
                                                        priceRupees={item.consultation_fees}
                                                    />
                                                    :
                                                    pageType === "ambulance" ?
                                                        <AmbulanceSearchResult key={item.id + "searchAmbulances"}
                                                            userid={item.id}
                                                            imageUser={item.profile_pic} name={item.agency_name}
                                                            speciality={item.specialization}
                                                            address={item.service_area}
                                                            priceRupees={item.consultation_fees}
                                                        />
                                                        :
                                                        <NurseSearchResult key={item.id + "searchNurses"}
                                                            userid={item.id}
                                                            imageUser="" bio={item.bio} name={item.name}
                                                            speciality={item.specialization}
                                                            experience={item.experience}
                                                            address={item.address} countPaitents={null}
                                                            availability={item.Available}
                                                            priceRupees={item.consultation_fees}
                                                        />
                                                }
                                            </>
                                        ))
                                            :
                                            "No records found"
                                        }
                                    </>

                                    :
                                    <>
                                        <h1>Loading...</h1>
                                    </>
                                }


                                {/* <div className="mypagination">
                                    <ul className="pagination_ul">
                                        <li><a href="#" className="active">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">Next</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        {/*Video Consultation*/}

                        {/* {sessionStorage.getItem("searchForPageType") !== "ambulance" &&
                            <div className="col-lg-4">
                                <div className="video_consult filters">
                                    <div className="vc_header">
                                        <h4>Filters&nbsp;&nbsp;<img src={FilterImage} /></h4>
                                        <div><button type='button' onClick={onClickResetFilter} className="btn btnwhite">Reset</button></div>
                                    </div>
                                    <div className="filter_bx"> */}

                        {/* If teleconsultancy then video Consultatoin Will show */}
                        {/* {sessionStorage.getItem("searchForPageType") === "teleconsultancy"
                                            && <div className="vc_block">
                                                <div className="fil_flex">
                                                    <RadioButtonFilter
                                                        onChange={onChangeFilterCheckbox} checked={isChecked}
                                                        label="Video consultation" name="Video_consultation"
                                                        id="Video_consultation" value="Video_consultation" />

                                                </div>
                                            </div>} */}

                        {/* If Home Care then and teleconsultancy then All Will show */}
                        {/* <div className="vc_block">
                                            <div className="fil_lbl">Gender</div>
                                            <div className="fil_flex">
                                                <RadioButtonFilter
                                                    onChange={onChangeFilterCheckbox} checked={isChecked}
                                                    label="Male" name="gender"
                                                    id="Male" value="Male" />
                                                <RadioButtonFilter
                                                    onChange={onChangeFilterCheckbox} checked={isChecked}
                                                    label="Female" name="gender"
                                                    id="Female" value="Female" />
                                                <RadioButtonFilter
                                                    onChange={onChangeFilterCheckbox} checked={isChecked}
                                                    label="Others" name="gender"
                                                    id="Others" value="Others" />
                                            </div>
                                        </div> */}
                        {/* <div className="vc_block">
                                            <div className="fil_lbl">Availability</div>
                                            <div className="fil_flex">
                                                <div className="fi_checkbox">
                                                    <RadioButtonFilter
                                                        onChange={onChangeFilterCheckbox} checked={isChecked}
                                                        label="Today" name="availability"
                                                        id="Today" value="Today" />
                                                </div>
                                                <div className="fi_checkbox">
                                                    <RadioButtonFilter
                                                        onChange={onChangeFilterCheckbox} checked={isChecked}
                                                        label="Tomorrow" name="availability"
                                                        id="Tomorrow" value="Tomorrow" />
                                                </div>
                                                <div className="fi_checkbox">
                                                    <RadioButtonFilter
                                                        onChange={onChangeFilterCheckbox} checked={isChecked}
                                                        label="Next7days" name="availability"
                                                        id="Next7days" value="Next7days" />
                                                </div>
                                            </div>
                                        </div> */}

                        {/* <div className="vc_block">
                                            <div className="fil_lbl">Consultation fee</div>
                                            <div className="fil_flex">
                                                <RadioButtonFilter
                                                    onChange={onChangeFilterCheckbox} checked={isChecked}
                                                    label="200" name="consultaionfee"
                                                    id="200" value="200" />
                                                <RadioButtonFilter
                                                    onChange={onChangeFilterCheckbox} checked={isChecked}
                                                    label="300" name="consultaionfee"
                                                    id="300" value="300" />
                                                <RadioButtonFilter
                                                    onChange={onChangeFilterCheckbox} checked={isChecked}
                                                    label="400" name="consultaionfee"
                                                    id="400" value="400" />
                                                <RadioButtonFilter
                                                    onChange={onChangeFilterCheckbox} checked={isChecked}
                                                    label="500+" name="consultaionfee"
                                                    id="500+" value="500+" />
                                            </div>
                                        </div> */}
                        {/* </div>
                                </div>
                            </div>
                        } */}
                    </div>
                </div>
            </section>

            <Footer />
        </div>

    )
}

export default SearchFilterPage