import APIHandler from '../services/APICore';
import HomepageHeader from '../components/headers/Header';
import SidebarUserPanel from '../components/user/Sidebar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Bookingsdetails from '../components/Bookingsdetails';
import BookingsActivities from '../components/BookingsActivities';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import Sidebarmobile from '../components/user/Sidebarmobile';
import moment from 'moment/moment';


const BookingsVisitsummary = ({ }) => {

    const [isVisible, setIsVisible] = useState(false);

    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    }
    let { state } = useLocation()
    const location = useLocation()
    const pathName = location.pathname
    const [status, setStatus] = useState("BookingSummary")
    const [apidata, setapidata] = useState([])
    const [eprescription, setepresciption] = useState([])
    const [medicalreport, setmedicalreport] = useState([])
    const [activelog, setactivelog] = useState([])
    const { id } = useParams();
    const [stype, setStype] = useState("")
    const [bookigtypeurl, setbookigtypeurl] = useState("")

    const getdata = async () => {
        const result = await APIHandler.get(`homecareBooking/bookingItem/${id}`)
        setapidata(result.data[0])
      
    }
    useEffect(() => {
        getdata();
        setbookigtypeurl(localStorage.getItem('activemenu'))
    }, [])


    return (
        <>
            <div className='outerhomepage'>
                {/*Top Navbar Menu*/}
                <HomepageHeader />
                <section className=" paddbottom">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        {/* <SidebarUserPanel />  */}
                        <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                            {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
                        </button>
                        {isVisible && (
                            <div>
                                <Sidebarmobile />
                            </div>
                        )}

                        <div class="row">
                            <div class="col-md-12">
                                <div class="content-header">
                                    {/* <h1 class="pull-left">
                                        Bookings ID: {id}
                                    </h1> */}
                                    <Link to={bookigtypeurl === "ServiceProvider" ? "/serviceprovider-bookings" : "/accounts-bookings"} ><button className='btn btnbtnjoin pull-right'><i className='fa fa-angle-double-left'></i> Back To Bookings</button></Link>
                                </div>
                            </div>

                        </div>


                        <div className="right_panel " id='right-tap' style={{ marginLeft: "127px" }}>
                            <div className='spacetop'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div class="tab">
                                            <button className={status === "BookingSummary" ? 'active' : ""} onClick={handleClick} aria-current="true">Booking Summary</button>
                                            {/* <button className={status === "Activities" ? 'active' : ""} onClick={handleClick} >Activities</button> */}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='text-right'>
                                            <Link to="/" ><button className='btn btnbtnjoin pull-right'><i className='fa fa-angle-double-left'></i> Back To Bookings</button></Link>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h4 className='spacetop'>Booking Id :{apidata.booking_id}</h4>
                                </div>

                                <div className='profile_body'>
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Service</label> <br />
                                                    <input type="text" class="form-control" value={apidata.service_type == "Doctor" ? "Teleconsultation" : apidata.service_type == "Nurse" ? "Homecare" : apidata.service_type == "Ambulance" ? "Ambulance" : ""} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Status</label>
                                                    <input type="text" value={(apidata.status)} class="form-control" placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class=" fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Booking Date & Time</label>
                                                    <input type="text" class="form-control" value={(moment(apidata.booking_date).utc()).format("DD/MM/YYYY") + "," + (moment(apidata.booking_date).utc()).format("hh:mm a")} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label text-wrap">Visit Required on/first visit required on</label>
                                                    <input type="text" class="form-control" value={(moment(apidata.scheduled_date).utc()).format("DD/MM/YYYY") + "," + (moment(apidata.scheduled_date).utc()).format("hh:mm a") || ""} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Fee Paid</label> <br />
                                                    <input type="text" class="form-control" value={(apidata.total_amount)} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Totals visits</label> <br />
                                                    <input type="text" class="form-control" value={(apidata.total_visit)} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Visits so far </label> <br />
                                                    <input type="text" class="form-control" value={(apidata.completed_visit)} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2">
                                            <div class="fd_input  mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Visits left</label> <br />
                                                    <input type="text" class="form-control" value={(Number(apidata.total_visit) - Number(apidata.completed_visit)) || 0} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>




                                    <div className='row'>
                                        <div className="col-lg-12"><h4 class="page_title">Service Provider</h4>  </div>
                                        <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                                                    <input type="text" class="form-control" value={apidata.provider_name} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Address</label>
                                                    <input type="text" class="form-control" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Specialization</label>
                                                    <input type="text" class="form-control" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                                                    <input type="text" class="form-control" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div class=" fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                                                    <input type="text" class="form-control" placeholder="" />
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                    <div className="row ">
                                        <div className="col-lg-12"><h4 class="page_title">User Details</h4>  </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Booked By</label>
                                                    <input type="text" class="form-control" value={apidata.booked_by} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Address</label>
                                                    <input type="text" class="form-control" value={apidata.user_address} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                                                    <input type="text" class="form-control" value={apidata.user_email} placeholder="" disabled={true} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                                                    <input type="text" class="form-control" value={apidata.other_mobile} placeholder="" disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Booked For</label>
                                                    <input type="text" class="form-control" value={apidata.booked_for} placeholder="" disabled={true} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                                                    <input type="text" class="form-control" value={apidata.other_name} placeholder="" disabled={true} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-4">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Age</label>
                                                    <input type="text" class="form-control" value={apidata.other_age} placeholder="" disabled={true} />
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="row ">
                                        {/* <div className="col-lg-12">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Medical History(If Any)</label>
                                                    <textarea class="form-control" value={apidata.user_medical_history} id="exampleFormControlTextarea1" rows="3" disabled={true}></textarea>
                                                </div>
                                            </div>


                                        </div> */}
                                        <div className="col-lg-12">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Notes(If Any)</label>
                                                    <textarea class="form-control" value={apidata.notes} id="exampleFormControlTextarea1" v rows="3" ></textarea>
                                                </div>
                                            </div>

                                            <div className="col-lg mb-3">

                                                <div class="fd_input">
                                                    <label for="exampleFormControlInput1" class="form-label">Medical History</label>

                                                </div>
                                                <div className='gap-3'>
                                                    <input type="checkbox" disabled id='Allergies' checked={apidata?.allergy} />
                                                    <label className='mx-3-medical'>Allergies </label>
                                                    <input type="checkbox" disabled id='Past-Medical-Issue' checked={apidata?.pastMedicalIssue} />
                                                    <label className='mx-3-medical'>Past Medical Issue </label>
                                                    <input type="checkbox" disabled id='On-Medication' checked={apidata?.onMedication} />
                                                    <label className='mx-3-medical'>On Medication </label>
                                                </div>
                                            </div>
                                            <div className="col-lg">
                                                <div className='form-group fd_input mt-3'>
                                                    <label for="" class="form-label">Remarks</label>
                                                    <textarea disabled class="form-control" value={apidata?.remarks} v rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>

                        <SidebarUserPanel />

                    </div>
                </section>

            </div>

        </>
    );

}
export default BookingsVisitsummary;