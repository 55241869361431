import React, { useEffect } from 'react'
import Logo from "../../images/home/fasttrackLogo.svg"
import userAdmin from "../../images/adminpanel/userAdmin.jpg"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useNavigate } from 'react-router';
import ProfileService from '../../services/ProfileServices';
import Invoicedownload from '../../pages/E-prescription/downloadinvoice'


const HeaderLogin = () => {
    const navigateTo = useNavigate();
    const location = useLocation()
    const pathName = location.pathname
    const apifunction = new ProfileService();
    let profilePic = localStorage.getItem("profile_pic")
    let userId = localStorage.getItem('userid')
    let token=localStorage.getItem('token')
    let a = navigator.geolocation
    // console.log(a)
    

    
    //  
    return (
        <header>
            <nav className="navbar-default navbar-fixed-top">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#navbar" aria-expanded="false"
                            aria-controls="navbar">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} className="logo-icon" alt="FastTrack Logo" />
                            <span className="logo-text" />
                        </Link>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse navbar-links">
                        <ul className="nav navbar-nav nav-right">
                            <li className={location.pathname === "/tele-consultation" ? "link-li active" : "link-li"} ><NavLink to="/tele-consultation">Teleconsultation</NavLink></li>
                            <li className={location.pathname === "/homecare" ? "link-li active" : "link-li"} ><NavLink to="/homecare">Home Care</NavLink></li>
                            <li className={location.pathname === "/ambulance" ? "link-li active" : "link-li"} ><NavLink to="/ambulance">Ambulance</NavLink></li>
                            <li className={location.pathname === "/contact-us" ? "link-li active" : "link-li"}><NavLink to={"/contact-us"}>Contact Us</NavLink></li>
                          
                           
                           {/* {(localStorage.getItem("token"))?<li className='link-li ml-3'><NavLink><i className='fa fa-cog'></i> </NavLink></li>:""} */}
                        </ul>

                    </div>
                </div>
            </nav>
        </header>
    )
}

export default React.memo(HeaderLogin);

