import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { HomepageHeader, SidebarUserPanel } from '.';
import moment from 'moment'
import { ProfileAndSlotService } from '../services';
import { ShowProfileType } from '../enums/Enums';
import CheckCircle from "../images/images/check-circle.png"
import userIcon from "../images/adminpanel/UserIcon.svg"
import { ActionDoctor, AddPrescription } from '../services/ServiceProviderDoctor';
import { Link, useNavigate } from 'react-router-dom';
import { OnePatientDetails } from '../services/MypatientService';
import { ThreeDots } from 'react-loader-spinner';
import Sidebarmobile from './user/Sidebarmobile';


const MyPatientComponent = ({ selfDetails, data, isloading, setStatus, status, type, setType, service }) => {

    const [paitentList, setpatientList] = useState([])
    const [searchKey, setSearchKey] = useState("")
    setType('Doctor')
    const navigateTo = useNavigate()

    useEffect(() => {
        if (data !== undefined) {
            setpatientList(data)
        }
    }, [data])

    useEffect(()=>{
        var temp=paitentList;
        if(searchKey.length>2){
            temp=temp.filter(x=>x.name.toLowerCase().includes(searchKey.toLowerCase()))
            setpatientList(temp)
        }
        else{
            setpatientList(data)
        }

    },[searchKey])

    const handleViewDoctorProfile = (id,profiletab,service) => {
        console.log(id)
        let userId = localStorage.getItem('userid')
        if (id !== null) {
            try {
                OnePatientDetails(userId, id,service)
                    .then((res) => {
                        console.log(res)
                        if (res.success === true) {
                            navigateTo('/my-patient-profile', { state: { profileData: res.data,Tabstatus: profiletab} })
                        }
                    })
                    .catch();
            } catch (error) { }
        }
    }

    const [isVisible, setIsVisible] = useState(false);

   
    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        {/* <SidebarUserPanel />  */}
                        <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                                 {isVisible ? <i class="fa-solid fa-caret-down"></i>: <i class="fa-solid fa-caret-right"></i>}
                              </button>
                            {isVisible && (
                     <div>
                     <Sidebarmobile />
                </div>
      )}

                        <SidebarUserPanel />
                        {!isloading ?
                            <div className="right_panel">
                                <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                    <div class="content content-body">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="content-header">
                                                    <h1 class="pull-left">
                                                        My Patients
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        {/* <input type="text" className='form-control' placeholder='Search' onChange={e=>setSearchKey(e.target.value)} /> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* for teleconsultancy patients     */}
                                        <div class="row spacetop">
                                            {(paitentList.doctorPatients !== undefined && paitentList.doctorPatients !== null && paitentList.doctorPatients.length > 0) &&
                                                <>
                                                    <div className='col-lg-12 '><h4 class="page_title">Teleconsultation</h4>   </div>
                                                    {
                                                        paitentList.doctorPatients.map((key, ind) => {
                                                            return (
                                                                <div className='col-lg-3'>
                                                                    <div class="db_box">
                                                                        <div className='db_body'>
                                                                            <div class="sv_center">
                                                                                <div className='sv_photo'><img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt /></div>
                                                                                <div className='info_text'>
                                                                                    <h5>{key.name}</h5>
                                                                                    <p>{key.gender}, {key.city}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='db_footer'>
                                                                            <div className='linkbtn'><a onClick={() => handleViewDoctorProfile(key.id,"Profile","Doctor")}>Profile </a></div>
                                                                            <div className='linkbtn'><a onClick={() => handleViewDoctorProfile(key.id,"Bookings","Doctor")}>Bookings</a></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                        </div>
                                        {/* for homecare patients */}
                                        <div class="row spacetop">
                                            {(paitentList.HomecarePatients !== undefined && paitentList.HomecarePatients !== null && paitentList.HomecarePatients.length > 0) &&
                                                <>
                                                    <div className='col-lg-12 '><h4 class="page_title">Homecare</h4>   </div>
                                                    {
                                                        paitentList.HomecarePatients.map((key, ind) => {
                                                            return (
                                                                <div className='col-lg-3'>
                                                                    <div class="db_box">
                                                                        <div className='db_body'>
                                                                            <div class="sv_center">
                                                                                <div className='sv_photo'><img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt /></div>
                                                                                <div className='info_text'>
                                                                                    <h5>{key.name}</h5>
                                                                                    <p>{key.gender}, {key.city}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='db_footer'>
                                                                            <div className='linkbtn'><a onClick={() => handleViewDoctorProfile(key.id,"Profile","Nurse")}>Profile </a></div>
                                                                            <div className='linkbtn'><a onClick={() => handleViewDoctorProfile(key.id,"Bookings","Nurse")}>Bookings</a></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                        </div>

                                        {/* for Ambulance patients */}
                                        <div class="row spacetop">
                                            {(paitentList.AmbulancePatients !== undefined && paitentList.AmbulancePatients !== null && paitentList.AmbulancePatients.length > 0) &&
                                                <>
                                                    <div className='col-lg-12 '><h4 class="page_title">Ambulance</h4>   </div>
                                                    {
                                                        paitentList.AmbulancePatients.map((key, ind) => {
                                                            return (
                                                                <div className='col-lg-3'>
                                                                    <div class="db_box">
                                                                        <div className='db_body'>
                                                                            <div class="sv_center">
                                                                                <div className='sv_photo'><img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt /></div>
                                                                                <div className='info_text'>
                                                                                    <h5>{key.name}</h5>
                                                                                    <p>{key.gender}, {key.city}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='db_footer'>
                                                                            <div className='linkbtn'><a onClick={() => handleViewDoctorProfile(key.id,"Profile","Ambulance")}>Profile </a></div>
                                                                            <div className='linkbtn'><a onClick={() => handleViewDoctorProfile(key.id,"Bookings","Ambulance")}>Bookings</a></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                        </div>


                                    </div>
                                </div>


                            </div>
                            :
                            <div class="defaultloader">
                                <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#056B66"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                /></div>
                        }
                    </div>
                </div>
            </section >

            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}


        </div >
    )
}

export default MyPatientComponent;