import React, { useEffect, useState } from 'react'
import { FaqsSections, Footer, HomepageHeader } from '../../components'
import LocationIcon from "../../images/home/map-pin.png"
import SearchIcon from "../../images/home/search.png"
import HomecareBanner from "../../images/images/homecare_bnr.png"
import HomeCareServices from '../../services/HomeCareServices'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import CheckImg from "../../images/images/check.png"
import SelectSpeciallityImg from "../../images/howItWorks/hiw1.png"
import ImageNotAvailable from "../../images/images/image_not_available.png"
import AudioVideoImg from "../../images/howItWorks/hiw2.png"
import getDigitalPrescriptionImg from "../../images/howItWorks/hiw3.png"
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { ShowProfileType } from '../../enums/Enums'
import LocationsList from '../../components/locations/LocationsList'
import NursingHomeCare from './nursing-home-care'
import axios from 'axios'
import { set } from 'date-fns'

const baseurl = process.env.REACT_APP_API_BASE_URL


const HomeCare = () => {
    const apifunction = new HomeCareServices();
    const [search_keyCity, setsearch_keyCity] = useState("");
    const navigate = useNavigate();

    const [isListVisible, setListVisible] = useState(true);
    const [items, setItems] = useState([])
    const [search_key, setsearch_key] = useState("")
    const [isloading, setisloading] = useState(false)
    const [homecares, setHomecare] = useState([])
    const [physiotherapy, setPhysiotherapy] = useState([])
    const [homecareId, setHomecareId] = useState("")
    const [homecareName, setHomecareName] = useState("")
    const [visitType, setVisitType] = useState("per_visit")
    const [nurseType, setNurseType] = useState("Basic Care")
    const [isedit, setIsEdit] = useState(false)
    const [isloading2, setisloading2] = useState(false)

    useEffect(() => {
        setisloading(true)
        const delayDebounceFn = setTimeout(() => {
            if (search_key) {
                loadSearchList(search_key)
                setisloading(false)
            } else {
                setItems([])
            }
        }, 1000);
        return () => clearTimeout(delayDebounceFn)
    }, [search_key])


    const loadSearchList = (search_key) => {
        apifunction.SearchHomecareNurseList(search_key, search_keyCity).then(res => {
            if (res !== undefined) {
                var result = res
                if (result.success === true) {
                    setItems(result.data)
                    setisloading2(false)

                }
            }
        })
    }

    const options = {
        margin: 1,
        className: "owl-theme",
        responsiveClass: true,
        // nav: true,
        autoplay: false,
        // navText: ["Prev", "Next"],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
            }
        },
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        loadSpeciality();
    }, [])

    const loadSpeciality = async () => {

        const result = await axios.get(`${baseurl}nurse?homecare_type`)

        if (result !== undefined) {
            if (result.data.success) {
                setHomecare(result.data.data.Homecares)
                setPhysiotherapy(result.data.data.Physiotherapists)

            }
        }
        setisloading(false)
    }

    const navigateTo = (Hid, hName) => {
        window.$('#myModal-001').modal('hide');
        return navigate('/nursing-home-care', {
            state: {
                locationState: search_keyCity || localStorage.getItem("SelectedLocation"),
                searchKeyState: search_key,
                nurseServiceTypeState: nurseType || "",
                visitTypeState: visitType ? visitType : "per_visit",
                homecareNameState: homecareName ? homecareName : hName,
                pageType: "homecare"
            }

        })

    }


    const onEnterPress = (e) => {
        e.preventDefault();
        return navigate('/search', { state: { locationState: localStorage.getItem("SelectedLocation"), searchKeyState: search_key, pageType: "homecare" } })
    }
    const onClickConsultNow = (search_key) => {
        return navigate('/search', { state: { locationState: localStorage.getItem("SelectedLocation"), searchKeyState: search_key, pageType: "homecare" } })
    }
    const onClickHomecare = (id, name, providerName) => {
        setHomecareId(id)
        setHomecareName(name)
        setsearch_key(providerName)
    }


    return (
        <div className='outerhomepage'>
            <HomepageHeader />

            {/*Inner Page Banner*/}
            <section className="banner_inner">
                <div className="container">
                    <div className="card_flex">
                        <div className="card_text">
                            <h1>Expert <span className='bold-mail-heading'>H</span>ome <span className='bold-mail-heading'>C</span>are <span className='bold-mail-heading'>S</span>ervices</h1>
                            <h2>Book Verified Home Care Expert</h2>

                            <div className="searchbox">
                                <div className="locality">
                                    <span className="mapicon">
                                        <img src={LocationIcon} />
                                    </span>
                                    <input
                                        type="text"
                                        value={search_keyCity}
                                        onChange={(e) => setsearch_keyCity(e.target.value)}
                                        className="form-control"
                                        placeholder="Location"
                                    />
                                </div>

                                <div className="vline" />
                                <div className="keyword">
                                    <span className="searchicon">
                                        <img src={SearchIcon} />
                                    </span>
                                    <form>
                                        <input
                                            type="text"
                                            value={search_key}
                                            onChange={(e) => { setsearch_key(e.target.value); setIsEdit(true) }}
                                            className="form-control"
                                            placeholder="Enter Name / Service"
                                        />

                                        <div className={"cardSuggetionsLocation"}>
                                            {/* Render Location List */}
                                            <LocationsList
                                                search_keyCity={search_keyCity}
                                                setsearch_keyCity={setsearch_keyCity}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='position-relative'>
                            {(isedit && isListVisible) ?
                                <div id={items.length} className="cardSuggetions">

                                    {items.length > 0 ?
                                        <>
                                            <ul>
                                                {items.map((item) => (

                                                    <li key={item.id + "nurse_list"}>

                                                        <li>
                                                            {item.homecareServices.homecare_type === "Nurse" ? (
                                                                <li
                                                                    data-toggle="modal"
                                                                    data-target="#myModal-001"
                                                                    onClick={() => {
                                                                        onClickHomecare(item.id, item.homecareServices.name, item.name)
                                                                        setListVisible(false)
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                    <p className="resultType">{item.homecareServices.name}</p>
                                                                </li>
                                                            ) : (
                                                                <li onClick={() => {
                                                                    navigateTo(item.id, item.homecareServices.name)
                                                                    setListVisible(false)
                                                                }}>
                                                                    {item.name}
                                                                    <p className="resultType">{item.homecareServices.name}</p>
                                                                </li>
                                                            )}
                                                        </li>


                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                        :
                                        <>
                                            {isloading2 ?
                                                <p className={search_key == "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                                                    Loading...
                                                </p>
                                                :
                                                <p className={search_key == "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                                                    No Records Found
                                                </p>
                                            }
                                        </>
                                    }


                                </div> : ""
                            }
                        </div>
                        <div className="card_img">
                            <div className="dr_img-homecare"><img src={HomecareBanner} /></div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                {/* Specialities Slider*/}
                <section className="specialities_Slider paddtop">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="slider_heading">
                                    <h3 className="left-h">Home Care </h3>
                                </div>
                            </div>
                            <div className="col-md-12 mb-50">
                                {!isloading ?
                                    <OwlCarousel {...options}>
                                        <>
                                            {(homecares != null && homecares.length > 0)
                                                ?
                                                (homecares != null && homecares.map((item) => (
                                                    //how to apply onclick for setting id in homecareId
                                                    //<div type="button" class="" data-toggle="modal" data-target="#myModal-001" onClick={() => onClickHomecare(item.id,item.name)}>
                                                    <div type="button" class="" data-toggle="modal" data-target="#myModal-001" onClick={() => onClickHomecare(item.id, item.name)}>
                                                        <div className="post-slide">
                                                            <div className="white_card text-center">
                                                                <div className="card_img">
                                                                    <img src={item.banner_image ? item.banner_image : ImageNotAvailable} alt="" className="sp_img" />
                                                                </div>
                                                                <div className="card_dtl">
                                                                    <h3 className="card_title">{item.name} </h3>
                                                                    <div className="card_rate"></div>
                                                                    <div className="card_btn">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )))
                                                :
                                                "No records found"
                                            }
                                        </>
                                    </OwlCarousel>
                                    : "Loading..."}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Short term nursing care*/}
                <section className="specialities_Slider paddtop">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="slider_heading">
                                    <h3 className="left-h">Physiotherapy</h3>
                                </div>
                            </div>
                            <div className="col-md-12 mb-50">
                                {!isloading ?
                                    <OwlCarousel {...options}>
                                        <>
                                            {(homecares != null && homecares.length > 0)
                                                ?
                                                (physiotherapy != null && physiotherapy.map((item) => (
                                                    <div class="" onClick={() => navigateTo(item.id, item.name)}>
                                                        <div className="post-slide">
                                                            <div className="white_card text-center">
                                                                <div className="card_img">
                                                                    <img src={item.banner_image ? item.banner_image : ImageNotAvailable} alt="" className="sp_img" />
                                                                </div>
                                                                <div className="card_dtl">
                                                                    <h3 className="card_title">{item.name} </h3>
                                                                    <div className="card_rate"></div>
                                                                    <div className="card_btn">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )))
                                                :
                                                "No records found"
                                            }
                                        </>
                                    </OwlCarousel>
                                    : "Loading..."}
                            </div>
                        </div>
                    </div>
                </section>




                {/* How it works*/}
                <section className="how_it_works bg_light margintop">
                    <div className="container">
                        <div className="paddtop paddbottom">
                            <h3 className="heading2 text-center">How it works</h3>
                            <div className="hiw_section paddbottom">
                                <div className="value_prop">
                                    <div className="value_prop_icon new_csprop_icon"><img src="/images/Care-specialist.png" alt /></div>
                                    <p className="description">Select Home Care specialist </p>
                                </div>
                                <div className="value_prop">
                                    <div className="value_prop_icon new_csprop_icon"><img src="/images/Visit-Type.png" alt /></div>
                                    <p className="description">Select Visit Type</p>
                                </div>
                                <div className="value_prop">
                                    <div className="value_prop_icon new_csprop_icon"><img src="/images/Billing-Details.png" alt /></div>
                                    <p className="description">Billing Details</p>
                                </div>
                                <div className="value_prop">
                                    <div className="value_prop_icon new_csprop_icon"><img src="/images/Screenshot_3.png" alt /></div>
                                    <p className="description">Payment</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <FaqsSections  fqatype="HomeCare"/> */}
                <div class="modal fade" id="myModal-001" role="dialog">
                    <div class="modal-dialog modal-lg">

                        {/* <!-- Modal content--> */}
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">{homecareName}</h4>
                            </div>
                            <div class="modal-body">
                                <h6 className='font-size-heading'>Select Required service for Home care</h6>
                                <div className='display-flex'>
                                    <input type='radio' name='setNurseType' className='mr-5' onClick={e => setNurseType("Basic Care")} checked={nurseType === "Basic Care"} /> <p>Basic Care</p>
                                </div>
                                <div className='display-flex'>
                                    <input type='radio' name='setNurseType' className='mr-5' onClick={e => setNurseType("Nursing Care")} checked={nurseType === "Nursing Care"} /> <p>Nursing Care</p>
                                </div>
                                <div className='display-flex'>
                                    <input type='radio' name='setNurseType' className='mr-5' onClick={e => setNurseType("Advance Nursing Care")} checked={nurseType === "Advance Nursing Care"} /> <p>Advance Nursing Care (ICU Trained) </p>
                                </div>
                                <div className='border-top'>
                                    <h6 className='font-size-heading'>Home Care Visit Type</h6>
                                    <div className='display-flex'>
                                        <input type='radio' name="VisitType" className='mr-5' onClick={e => setVisitType("per_visit")} checked={visitType === "per_visit"} />
                                        <p>Per Visit <br></br> <span>Easy visit maybe have 30 t0 60 min</span></p>
                                    </div>
                                    <div className='display-flex'>
                                        <input type='radio' name="VisitType" className='mr-5' onClick={e => setVisitType("fixed_hour_visit")} checked={visitType === "fixed_hour_visit"} />
                                        <p>Fixed Hours Per day <br></br> <span>for longer requirement for 8hr,10hr,12hr, etc
                                        </span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="btn_fill" onClick={() => navigateTo()}>Continue</div>

                            </div>
                        </div>

                    </div>
                </div>

                {/* MODAL Physioterapy CARD 001 */}
                <div class="modal fade" id="myModal-Back-Pain" role="dialog">
                    <div class="modal-dialog modal-lg">

                        {/* <!-- Modal content--> */}
                        {/* <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">{homecareName}</h4>
                            </div>
                            <div class="modal-body">

                                <div className=''>
                                    <h6 className='font-size-heading'>Physiotherapist Visit Type</h6>
                                    <div className='display-flex'>
                                        <input type='radio' className='mr-5' /> <p>Per Visit <br></br> <span>Easy visit maybe have </span></p>
                                    </div>
                                    <div className='display-flex'>
                                        <input type='radio' className='mr-5' /> <p>Fixed Hours Per day <br></br> <span>for longer requirement for 8hr,10hr,12hr, etc
                                        </span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <Link to="/nursing-home-care" class="btn_fill">Continue</Link>
                            </div>
                        </div> */}

                    </div>
                </div>
                {/* MODAL Physioterapy CARD 001 END */}

                <Footer />
            </div>
        </div>
    )
}

export default HomeCare