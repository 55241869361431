let API_Base_Url=process.env.REACT_APP_API_BASE_URL
let AccessToken=localStorage.getItem('token')
// Doctor Approval
export const AddPrescription = async (reqdata) => {
    
    try {    
      const response = await fetch(`${API_Base_Url}/doctors/prescription`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'authorization': AccessToken,
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(reqdata),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
      alert("Something went wrong , Please try again later.");
    }
  };

  // Doctor Action
  export const ActionDoctor = async (status,id,roomId) => {
    
    try {
      const response = await fetch(`${API_Base_Url}/bookings/changebookingconfirmstatus/${id}?status=${status}&room_id=${roomId}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'authorization': AccessToken,
          // 'Authorization': 'Bearer ' + this.state.AccessToken
        },
        body: JSON.stringify(),
      });
      if (response.status === 401) {
        alert("Your Session has been expired, Please login again.");
      }
      const result = await response.json();
      if (response.ok) {
        return result;
      } else if (response.status === 400) {
        alert(result.errors[0]);
      } else {
      }
    } catch (error) {
      alert("Something went wrong , Please try again later.");
    }
  };