import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import BookingServices from '../../services/BookingServices'

const PaymentSuccessFail = () => {
    const navigate = useNavigate()
    const apiFunction = new BookingServices();
    const { orderid } = useParams()
    const [isPaymentSuccess, setIsPaymentSuccess] = useState("")
    const [secondCount, setSecondCount] = useState(8)
    const paymentAmount = localStorage.getItem("paymentAmount")
    const bookingId = localStorage.getItem("BookingId")
    const remaining_amount = localStorage.getItem("remaining_amount")
    const [isPaymentCaptured, setIsPaymentCaptured] = useState(false)

    useEffect(() => {
       
        if (bookingId && paymentAmount) {
            paymentCapture()
            const interval = setInterval(() => {
                setSecondCount(secondCount => secondCount - 1)
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [paymentAmount, bookingId])

    useEffect(() => {
        localStorage.setItem("remaining_amount", "")
        navigate("/accounts-bookings", { state: { status: isPaymentSuccess } })
        window.location.reload();
    }, [secondCount === 0, isPaymentCaptured])

    const clearData = () => {
        localStorage.setItem("paymentAmount", "")
        localStorage.setItem("remaining_amount", "")
    }

    // Want to confirm Payment this api will tell us Payment confirm or not
    const paymentCapture = () => {       
        const data = {
            order_id: orderid,
            amount: paymentAmount,
            booking_id: bookingId,
            remaining_amount: remaining_amount
        }
        apiFunction.PaymentCapture(data).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    setIsPaymentSuccess("true")
                } else {
                    setIsPaymentSuccess("false")
                }
                
            }
            setIsPaymentCaptured(true)
            clearData()
        }).catch(err => {
           
            setIsPaymentCaptured(true)
            clearData()
        })
    }

    return (
        <div className='paytmStatus'>
            {isPaymentSuccess === "" ?
                <h1 className='text-center'>Confirming Your Payment...</h1>
                :
                isPaymentSuccess === "true" ?
                    <>
                        <h1 className='text-center'>Payment Success</h1>
                        <h4 className='text-center'>Redirecting in {secondCount} seconds </h4>
                    </>
                    :
                    <>
                        <h1 className='text-center'>Payment Failed</h1>
                        <h4 className='text-center'>Redirecting in {secondCount} seconds </h4>
                    </>
            }
        </div>
    )
}

export default PaymentSuccessFail