import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router'
import { HomepageHeader } from '../../components'
import LocationIcon from "../../images/home/map-pin.png"
import SearchIcon from "../../images/home/search.png"
import SearchServices from '../../services/SearchServices';
import { set } from 'date-fns';
import { toast } from 'react-toastify';


const AdvanceSupportAmbulance = () => {

    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const apifunction = new SearchServices();
    const { ambulance_typeState, pickUp_addressState, drop_addressState, isNowState, appointmentDateState, appointmentTimeState, pageType } = state;
    const [isloading, setisloading] = useState(false)
    const [searchKey, setSearchkey] = useState("")
    const [searchList, setSearchList] = useState([])
    const [searchagain, setsearchagain] = useState("")
    const [type, settype] = useState("")


    useEffect(() => {
        loadAmbulanceList(pageType, searchKey, ambulance_typeState, pickUp_addressState, drop_addressState, appointmentDateState, appointmentTimeState)
        setisloading(false)
        window.scrollTo(0, 0);
        if (ambulance_typeState == 'ALS') {
            settype('Advanced Life Support')
        }
        else if (ambulance_typeState == 'BLS') {
            settype('Basic Life Support')
        }
        else if (ambulance_typeState == 'PTS') {
            settype('Patient Transport Service')
        }
    }, [searchagain])



    const loadAmbulanceList = (pageType, searchKey, ambulance_typeState, pickUp_addressState, drop_addressState, appointmentDateState, appointmentTimeState) => {

        setsearchagain(searchKey)
        const user_id = localStorage.getItem("userid")
        // making search url base on search

        let apiurl = "/ambulances?search_key=" + searchKey + "&city=" + pickUp_addressState +
            "&type=" + ambulance_typeState + "&user_id=" + user_id

        apifunction.searchResultFunc(apiurl).then(res => {

            const result = res
            if (result !== undefined) {
                if (result.success === true) {
                    setSearchList(result.data)
                }
                setisloading(false)
            }
        })
    }

    const navigateTo = (id, service_provider_id, agency_name, equipped_with, advance_charge, charge_per_km, vehicle_model, vehicle_number, rating, vehicle_make, ambulace_pic, interior_image, exterior_image) => {

        const user_id = localStorage.getItem("userid")

        if (user_id === null || user_id === undefined || user_id === "") {
            toast.error("Please login first")
            return navigate('/login', { state: { from: '/Advance-support-ambulance' } });

        }


        return navigate('/book-ambulance', {
            state: {
                ambulanceId: id,
                service_provider_id: service_provider_id,
                ambulance_typeState: ambulance_typeState,
                equipped_withState: equipped_with,
                profileId: localStorage.getItem("userid"),
                profileType: 'Ambulance',
                agency_nameState: agency_name,
                pickUp_addressState: pickUp_addressState,
                drop_addressState: drop_addressState,
                advance_chargeState: advance_charge,
                charge_per_kmState: charge_per_km,
                vehicle_modelState: vehicle_model,
                vehicle_numberState: vehicle_number,
                appointmentDateState: appointmentDateState,
                appointmentTimeState: appointmentTimeState,
                ratingState: rating,
                vehicle_makeState: vehicle_make,
                ambulace_picState: ambulace_pic,
                interior_imageState: interior_image,
                exterior_imageState: exterior_image,

            }

        });

    }


    return (
        <div>
            <HomepageHeader />
            <div className="homecare-banner">
                <div className="container mt-5">
                    <div className='col-md-12 text-center'>
                        {/* <div class="searchbox">
                            <div class="locality">
                                <span class="mapicon">
                                    <img src={LocationIcon} />
                                </span>
                                <input type="text" class="form-control" placeholder="Location" value={pickUp_addressState} />
                            </div>
                            <div class="cardSuggetionsLocation">
                                <div class="displayNoneCls">
                                    <ul class="showing-Location">
                                        <li class="useMyLocation">
                                            <img src={SearchIcon} alt="usemylocatioonIcon" />
                                            Use Location
                                        </li>
                                        <li>Bhopal</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="vline"></div>
                            <div class="keyword">
                                <span class="searchicon">
                                    <img src="" />
                                </span>
                                <form><input type="text" class="form-control" placeholder="Enter Name / Service" value={"Ambulance Type="+ambulance_typeState +",Pick-up Address="+pickUp_addressState + ",Drop Address="+drop_addressState} /></form>
                            </div>
                        </div> */}
                        <h4 type="text" class="" >Ambulance Type={ambulance_typeState}, Pick-up Address={pickUp_addressState}, Drop Address= {drop_addressState}</h4>
                    </div>
                    <div className='col-lg-6  col-md-offset-3'>
                        <h2 className='heaading-type-nursing'>{searchList.length} {type} ambulance available in your area</h2>
                    </div>

                    <div className="col-md-7 col-md-offset-3">
                        <div className='search_wraper paddtop'>
                            {isloading === false ?
                                <>
                                    {searchList.length > 0 ? searchList.map(item => (
                                        <div class="search_list bg_light">
                                            <div class="left_block">
                                                <div class="userIcon_photo">
                                                    <img src="/images/two-ambulance.png" alt='' className='nursing-img-icon ambulane-page' />
                                                </div>
                                                <div class="doctor_bio">
                                                    <h3 class="dr_name">{item.agency_name}</h3>
                                                    {/*  <p class="dr_quali display-flex">
                                                        Rating
                                                        <i class={`fa-solid fa-star ${item.rating >= 1 ? 'yellow' : 'gray'}`}></i>
                                                        <i class={`fa-solid fa-star ${item.rating >= 2 ? 'yellow' : 'gray'}`}></i>
                                                        <i class={`fa-solid fa-star ${item.rating >= 3 ? 'yellow' : 'gray'}`}></i>
                                                        <i class={`fa-solid fa-star ${item.rating >= 4 ? 'yellow' : 'gray'}`}></i>
                                                        <i class={`fa-solid fa-star ${item.rating >= 5 ? 'yellow' : 'gray'}`}></i>
                                                        (${item.total_ratings})
                                                    </p> */}


                                                    <p class="dr_exp">
                                                        {item.ambulance_type === 'BLS' ? 'Basic Life Support' : (item.ambulance_type === 'ALS' ? 'Advanced Life Support' : 'Patient Transport Service')}
                                                    </p>

                                                    <p class="dr_address">₹ {item.advance_charge} </p>
                                                </div>
                                            </div>
                                            <div class="right_block">
                                                <div class="fees"></div>
                                                <div class="available"></div>
                                                <button onClick={() => navigateTo(item.id, item.service_provider_id, item.agency_name, item.equipped_with, item.advance_charge, item.charge_per_km, item.vehicle_model, item.vehicle_number, item.rating, item.vehicle_make, item.ambulance_pic, item.interior_image, item.exterior_image)} class="btn btn-info">Book Appointment</button>
                                            </div>
                                        </div>
                                    )) : <div className='text-center'>No data found</div>}
                                </>
                                : <div className='text-center'>Loading...</div>}

                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default AdvanceSupportAmbulance
