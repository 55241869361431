import * as React from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useLocation } from 'react-router';
import Header from '../../components/headers/Header'
import { Link } from 'react-router-dom';

export function getUrlParams(
  url: String = window.location.href): URLSearchParams {
  let urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}

export default function VideoCall() {
  //refresh the page
  //window.location.reload();
  const username = localStorage.getItem("user_name")
  const userid = localStorage.getItem("userid")
  let { state } = useLocation()
  console.log(state)
  const roomID = (state === null || state === undefined || state.roomId === undefined || state.roomId === null) ? '123456' : state.roomId;//getUrlParams().get('roomID') || randomID(5);
  let name = localStorage.getItem('user_name')
  let myMeeting = async (element: HTMLDivElement) => {
    // generate Kit Token
    const appID = 1452110015;
    const serverSecret = "74f6f7d51d09930a9c8474111e330b54";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userid, username);

    //alert(window.location.origin +'/video-call' +'?roomID=' + roomID)
    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      sharedLinks: [
        {
          name: localStorage.getItem('user_name'),
          url:
            window.location.origin +
            '/video-call' + '?roomID=' + roomID,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
    });


  };

  return (
    <div class="outerhomepage">
      <Header />

      <div className='container-full'>
        <div className='row justify-content-md-center'>
          <div className='col-10 col-md-12'>
            <div className='back-btn-se'>
              <Link to="/accounts-bookings" className='transform-xy-trans'><button className='btn btnbtnjoin pull-right '><i className='fa fa-angle-double-left'></i> Back To Bookings</button></   Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="myCallContainer"
        ref={myMeeting}
        style={{ width: '100vw', height: '100vh' }}
      ></div>
    </div>
  );
}