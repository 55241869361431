import React, { useEffect, useState } from 'react'
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import useMyLocation from "../../images/images/useMyLocation.png"
import { Cities as CitiesList } from '../../enums/Enums';

const LocationsList = ({ search_keyCity, setsearch_keyCity }) => {

    const [Cities] = useState(CitiesList)
    const [count, setCount] = useState(0);
    const [searchedCityList, setSearchedCityList] = useState([])


    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyA6KSQ1K4AYunLrJLZzibbZOAMWBSuqIPo");
    // set response language. Defaults to english.
    Geocode.setLanguage("en");

    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("in");

    // set location_type filter . Its optional.
    // google geocoder returns more that one address for given lat/lng.
    // In some case we need one address as response for which google itself provides a location_type filter.
    // So we can easily parse the result for fetching address components
    // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
    // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
    Geocode.setLocationType("ROOFTOP");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();

    const setCityOnSelect = (name = "Bhopal") => {
        setsearch_keyCity(name)
        localStorage.setItem('SelectedLocation', name)
        setCount(count + 1)
    }
    function getCoordintes() {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        function success(pos) {
            var crd = pos.coords;
            var lat = crd.latitude.toString();
            var lng = crd.longitude.toString();
            

            Geocode.fromLatLng(lat, lng).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    let city, state, country;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }

                    /*----- We are setting a city ------ */

                    return setCityOnSelect(city)
                },
                (error) => {
                    //return console.error(error);
                }
            );
        }
        function error(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
             //return toast.error('Please enable location permissions in browser settings')
            //return toast.error(error)
        }
        navigator.geolocation.getCurrentPosition(success, error, options);
        return;
    }

    useEffect(() => {

        setsearch_keyCity("Bhopal")
        localStorage.setItem('SelectedLocation', "Bhopal")
        setCount(count + 1)

    }, [])

    useEffect(() => {
        if (search_keyCity.length === 1)
            setCount(0)
        if (search_keyCity) {
            const aa = Cities.filter(item => item.value.toString().toLowerCase().includes(search_keyCity.toString().toLowerCase()))
            setSearchedCityList(aa)
        } else {
            setSearchedCityList([])
        }
    }, [search_keyCity])

    const loadLocationViaLatitudeLongitude = () => {
        getCoordintes()
       
    }
    useEffect(()=>{
        loadLocationViaLatitudeLongitude()
    },[])
    return (
        <div className={count > 0 ? "displayNoneCls" : ""}>

            {
                searchedCityList.length > 0 ?
                    <>
                        <ul className='showing-Location'>
                            <li className='useMyLocation' onClick={loadLocationViaLatitudeLongitude}>
                            <img src={useMyLocation} alt="usemylocatioonIcon"/> Use  Location
                            </li>
                            {
                                searchedCityList.map(item => (
                                    <li onClick={() => setCityOnSelect(item.value)}>
                                        {item.label}
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                    :
                    <p className={search_keyCity === "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                        No Records Found
                    </p>
            }
        </div>
    )
}

export default LocationsList;