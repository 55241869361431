import React, { useEffect, useState } from 'react'
import { HomepageHeader } from '../../components'
import VideoIcon from "../../images/images/vc_icon.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import moment from 'moment'
import { Change_to_12HourFormat } from '../../helpers/Change_to_12HourFormat'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { regHtml, regMobile } from '../../helpers/Validate'
import { toast } from 'react-toastify'
import { BookingServices } from '../../services'
import {
    err_10DigitMobile,
    err_NotFullName,
    err_NotMobile,
    HtmlErrorMessage
} from '../../enums/Enums'
import * as process from "process";
import axios from 'axios'
// import PayTmGatewayPage from './PayTmGatewayPage'

const NrusingBillingDetails = () => {

    const apiFunction = new BookingServices();
    const baseurl = process.env.REACT_APP_API_BASE_URL
    const token = localStorage.getItem("token")

    const { state } = useLocation();
 
    const merchantId = process.env.REACT_APP_PayTM_MID

    const paytmOpenPaymentPageUrl = "https://securegw.paytm.in/theia/api/v1/showPaymentPage"

    const { qualification, experience, specialization, nurseName, profileId, profileType, service_provider_id, profile_pic, nurseUserId, city, visitType, nurseServiveType } = state;

    const [isChecked, setIsChecked] = useState(false)
    const [couponList, setCouponList] = useState([])
    const [couponDetails, setCouponDetails] = useState({})
    const [selectedCoupon, setSelectedCoupon] = useState(null)
    const [profilepicture, setprofilepicture] = useState('')
    const [allergy, setAllergy] = useState(false)
    const [onMedication, setOnMeditation] = useState(false)
    const [pastMedicalIssue, setPastMedicalIssue] = useState(false)
    const [bookingType, setBookingType] = useState('New')
    const [selectedHours, setSelectedHours] = useState(0);
    const [appointmentTimeList, setAppointmentTimeList] = useState([])
    const [availabilityId, setAvailabilityId] = useState('')
    const [bookAndPayForm, setbookAndPayForm] = useState({
        user_id: "",
        booking_date: "",
        service_provider_id: '',
        service_type: "",
        booked_for: "",
        total_amount: 0,
        other_name: "",
        other_mobile: "",
        other_gender: "",
        short_description: "",
        paid_amount: 0,
        isSomeOneElse: false,
        appointment_date: "",
        profile_pic: "",
        pickup_address: "",
        nurse_user_id: nurseUserId,
        homecare_id: specialization.homecare_id,
        total_visit: 1,
        appointment_time: "",
        nurse_service_type: nurseServiveType || "",
        visit_type: visitType,
        selected_hours: 0,
        availablity_id: "",
        applied_coupon: "",
        coupon_discount: 0

    })   
    const { user_id, booking_date,
        service_type, booked_for,
        total_amount, other_name, other_mobile, other_gender, short_description,applied_coupon, coupon_discount,
        appointment_date, appointment_time, nurse_user_id, nurse_service_type, visit_type,
        paid_amount, isSomeOneElse, pickup_address, homecare_id, total_visit, selected_hours, availablity_id } = bookAndPayForm;

    const onInputChange = e => {

        setbookAndPayForm({
            ...bookAndPayForm, [e.target.name]:
                e.target.name === "other_mobile" ?
                    // Check if not number it will return nothing only for mobile
                    e.target.value.match(regMobile) ? e.target.value : ""
                    : e.target.value
        })

        /* if (e.target.name == "pickup_address") {
            sessionStorage.setItem("pickup_address", e.target.value)
        }
        if (e.target.name == "appointment_date") {
            // getAppointmentTimeList()
             sessionStorage.setItem("appointment_date", e.target.value)
        }
        if (e.target.name == "appointment_time") {
            sessionStorage.setItem("appointment_time", e.target.value)
        } */

    }
    useEffect(() => {
        calculateTotalAmount();
    }, [total_visit, appointment_time]);
    

    useEffect(() => {
        sessionStorage.setItem("notLoggedInBookingFlowUrlDetails", "Vaibhav Chaurasiya is a great man");
        setbookAndPayForm({
            ...bookAndPayForm,
            user_id: localStorage.getItem('userid'),
            booking_date: moment(new Date()).format("YYYY-MM-DD"),
            service_type: profileType,
            booked_for: isSomeOneElse ? "other" : "self",
            total_amount: total_amount,
            other_name: other_name,
            other_mobile: other_mobile,
            other_gender: other_gender,
            short_description: "",
            paid_amount: 0,
            isSomeOneElse: isSomeOneElse,
            service_provider_id: service_provider_id,
            appointment_date: appointment_date,
            appointment_time: appointment_time,
            profile_pic: profile_pic,
            total_visit: total_visit,
            homecare_id: homecare_id,
            nurse_user_id: nurse_user_id,
            pickup_address: pickup_address,
            nurse_service_type: nurse_service_type,
            visit_type: visit_type,
            selected_hours: selectedHours,
            availablity_id: availablity_id,
            applied_coupon: applied_coupon,
            coupon_discount: coupon_discount

        });
        getCouponList()
    }, [])

    useEffect(() => { setprofilepicture(profile_pic) }, [])



    useEffect(() => {
        if (isSomeOneElse === true) {
            setbookAndPayForm({
                ...bookAndPayForm,
                other_name: "",
                other_mobile: "",
                other_gender: "",
            })
        } else {
            setbookAndPayForm({
                ...bookAndPayForm,
                other_name: localStorage.getItem("user_name"),
                other_mobile: localStorage.getItem("user_mobile"),
                other_gender: localStorage.getItem("gender")

            })

        }
    }, [isSomeOneElse])

    function isDate(val) {
        // Cross realm comptatible
        return Object.prototype.toString.call(val) === '[object Date]'
    }

    function isObj(val) {
        return typeof val === 'object'
    }


    // For Paytm Stringify input field values
    function stringifyValue(val) {
        if (isObj(val) && !isDate(val)) {
            return JSON.stringify(val)
        } else {
            return val
        }
    }


    // Making a custom form by this code
    function buildForm({ action, params }) {
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', action)

        Object.keys(params).forEach(key => {
            const input = document.createElement('input')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', key)
            input.setAttribute('value', stringifyValue(params[key]))
            form.appendChild(input)
        })
        return form
    }

    function goToPaymentPage_Post_Information(details) {
        const form = buildForm(details)
        document.body.appendChild(form)
        form.submit()
        form.remove()
    }



    const bookAndPay = (e) => {
        e.preventDefault();
        if (!other_name?.trim()) {
            return toast.error(err_NotFullName)
        }
        else if (regHtml.test(other_name?.trim())) {
            return toast.error(HtmlErrorMessage)
        }
        else if (!other_mobile) {
            return toast.error(err_NotMobile)
        }
        else if (!pickup_address) {
            return toast.error("Please enter address")
        }
        else if (other_mobile.length !== 10) {
            return toast.error(err_10DigitMobile)
        }
        else if (!appointment_date) {
            return toast.error("Please select date")
        }
        else if (!appointment_time) {
            return toast.error("Please select time")
        }
        else if (total_amount === 0) {
            return toast.error("Amount should not be 0")
        }
        else {
            const data = {
                user_id: localStorage.getItem("userid"),
                booking_date: moment(new Date()).format("YYYY-MM-DD"),
                service_type: profileType,
                booked_for: isSomeOneElse ? "other" : "self",
                total_amount: total_amount,
                other_name: other_name,
                other_mobile: other_mobile,
                other_gender: other_gender,
                remark: short_description,
                paid_amount: 0,
                isSomeOneElse: isSomeOneElse,
                service_provider_id: service_provider_id,
                appointment_date: appointment_date,
                appointment_time: appointment_time,
                allergy: allergy,
                past_medical_issue: pastMedicalIssue,
                on_medication: onMedication,
                booking_type: bookingType,
                total_visit: total_visit,
                homecare_id: homecare_id,
                nurse_user_id: nurse_user_id,
                pickup_address: pickup_address,
                nurse_service_type: nurse_service_type,
                visit_type: visit_type,
                selected_hours: selectedHours,
                availablity_id: availabilityId,
                applied_coupon: applied_coupon,
                coupon_discount: coupon_discount

            }
          
            apiFunction.bookAndPayForHomecare(data).then(result => {

                if (result !== undefined) {
                    if (result.success === true) {

                        localStorage.setItem("BookingId", result.data.booking.id)                       
                        const datanew = {
                            amount: total_amount,
                            requestfrom: "WEB",
                            booking_id: result.data.booking.id
                        }                       
                        apiFunction.PaymentOrder(datanew).then(result => {
                            if (result !== undefined) {
                                if (result.success === true) {

                                    const transaction_token = result.data.transaction_token
                                    const order_id = result.data.order_id

                                    var information = {
                                        action: `${paytmOpenPaymentPageUrl}?mid=${merchantId}&orderId=${order_id}`,
                                        params: {
                                            mid: merchantId,
                                            orderId: order_id,
                                            txnToken: transaction_token,
                                            TXN_AMOUNT: total_amount / 100,
                                            CURRENCY: "INR",
                                            WEBSITE: "WEBSTAGING"
                                        }
                                    }

                                    localStorage.setItem("paymentAmount", total_amount)
                                    // this is function that show's paytm gate way
                                    goToPaymentPage_Post_Information(information)
                                }
                            }
                        })
                    }

                }
            })

        }
    }
    // handle medical history
    const handleMedicalHistory = (medicalAttribute) => {
        let checkbox = document.getElementById(medicalAttribute)
        if (checkbox.checked != true) {           
            checkbox.checked = true
        } else {           
            checkbox.checked = false
        }
    }
    const handleCheckBox = () => {
        // calculateTotalAmount()
        const user_id = localStorage.getItem("userid")
        if (user_id === null || user_id === undefined || user_id === "") {
            toast.error("Please login first")
            sessionStorage.setItem("notLoggedInBookingFlowUrlDetails", JSON.stringify(state));
            window.location.href = "/login#/login"
        }
        if (isChecked) {
            setIsChecked(false)
        } else {
            setIsChecked(true)
        }
    }

    //writing a function for calculating total amount according to visit type and toatal_visit two hours_charge,four_hours_charge,per_visit_charge
    const calculateTotalAmount = async () => {
        if (visitType === "per_visit") {
            setbookAndPayForm({
                ...bookAndPayForm,
                ["total_amount"]: specialization.per_visit_charge * total_visit
            })
        }
        else if (visitType === "fixed_hour_visit") {
            if (selectedHours === 2) {
                setbookAndPayForm({
                    ...bookAndPayForm,
                    ["total_amount"]: specialization.two_hours_charge * total_visit
                })
            }
            else if (selectedHours === 4) {
                setbookAndPayForm({
                    ...bookAndPayForm,
                    ["total_amount"]: specialization.four_hours_charge * total_visit
                })
            }
            else if (selectedHours === 6) {
                setbookAndPayForm({
                    ...bookAndPayForm,
                    ["total_amount"]: specialization.six_hours_charge * total_visit
                })
            }
            else if (selectedHours === 12) {
                setbookAndPayForm({
                    ...bookAndPayForm,
                    ["total_amount"]: specialization.twelve_hours_charge * total_visit
                })
            }
            else if (selectedHours === 24) {
                setbookAndPayForm({
                    ...bookAndPayForm,
                    ["total_amount"]: specialization.twenty_four_hours_charge * total_visit
                })
            }
        }

    }

    const handleButtonClick = (hours) => {
        setSelectedHours(hours);
    };

    const goBack = () => {
        window.history.go(-1);
    }

    useEffect(() => {
        selectDate()
        getAppointmentTimeList()
    }, [appointment_date])

    const selectDate = (event) => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var today = tomorrow.toISOString().split('T')[0];
        document.getElementById("birthdaytime").setAttribute("min", today);
        /* setbookAndPayForm({
            ...bookAndPayForm,
            ["appointment_date"]: event.target.value
        }) */
    }

    const getAppointmentTimeList = async () => {
        var config = {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },
        };
        const result = await axios.get(`${baseurl}/homecareAvailablity/user/${nurse_user_id}/date/${appointment_date}`, config)
        if (result !== undefined) {
            if (result.data.success === true) {
                setAppointmentTimeList(result.data.data)
            }
        }
    }

    const getAvailabilityId = (e) => {
        const id2 = e.target.value.split("-")[1]
        const appointment_time1 = e.target.value.split("-")[0]
        setAvailabilityId(id2)
        //set appointment time of getting value of appointment_time1
        setbookAndPayForm({
            ...bookAndPayForm,
            ["appointment_time"]: appointment_time1,
            ["availablity_id"]: id2
        })
    }

    const getCouponList = () => {
        apiFunction.couponList('Nurse').then(result => {
            if (result !== undefined) {
                if (result.success) {
                    setCouponList(result.data)
                }
            }
        })
    }
    /* const getCouponById = async (id) => {
        apiFunction.couponDetailsById(id).then(result => {
            if (result !== undefined) {
                if (result.success) {
                    setCouponDetails(result.data)
                }
            }
        })
    } */

    const handleCouponSelect = async (event) => {
        if (appointment_date === "") {
            return toast.error("Please select date")
        }
        if (appointment_time === "") {
            return toast.error("Please select time")
        }
        if (pickup_address === "") {
            return toast.error("Please enter address")
        }
        const selectedCouponId = parseInt(event.target.value);
        // Filter the couponList to find the selected coupon by id
        const selectedCouponDetails = couponList.find((coupon) => coupon.id === selectedCouponId);

        // Set the filtered coupon object in setCouponDetails
        setCouponDetails(selectedCouponDetails);

        if (selectedCouponDetails.minimum_amount > total_amount) {
           return toast.error(`Minimum amount should be ${selectedCouponDetails.minimum_amount}`)
        }

        //calling api to validate coupon
        apiFunction.ValidateCoupon(selectedCouponDetails.coupon_code, 'Nurse', total_amount).then(result => {
            if (result !== undefined) {
                if (result.success) {
                    setbookAndPayForm({
                        ...bookAndPayForm,
                        ["total_amount"]:(total_amount - selectedCouponDetails.total_discount),
                        ["coupon_discount"]: selectedCouponDetails.total_discount,
                        ["applied_coupon"]: selectedCouponDetails.coupon_code
                    })
                    setSelectedCoupon(selectedCouponId);
                    toast.success(result.message)
                } else {
                    toast.error(result.message)
                }
            }

        })
       
    }

    const handleClearCouponSelection = () => {
        // Reset the coupon-related state and update total_amount without the coupon discount
        setSelectedCoupon(null); // Deselect the coupon  
        setbookAndPayForm({
            ...bookAndPayForm,
            ["total_amount"]: (total_amount + couponDetails.total_discount), // Restore the original total_amount
            ["coupon_discount"]: 0, // Reset the coupon discount
            ["applied_coupon"]: "" // Reset the applied coupon
        });
        
        setCouponDetails(null); // Reset the coupon details
    };
    


    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section class="paddtop paddbottom">
                <div class="">
                    <div class="booking_flex">
                        <div class="right_itme">
                            <div class="booking_dtl">
                                <h3 class="bk_title">Patient details</h3>
                                {/* <h4 class="bk_sub_title">This video consultation is for:</h4> */}
                                <div className="vc_for">
                                    <div className="vc_rb">
                                        {/* Radio Buttons */}
                                        <input type="radio"
                                            id="test1"
                                            name="radio-group"
                                            defaultChecked={isSomeOneElse === false ? true : false}
                                            onClick={() =>
                                                setbookAndPayForm({
                                                    ...bookAndPayForm,
                                                    booked_for: "self",
                                                    isSomeOneElse: false
                                                })}
                                        />
                                        <label htmlFor="test1">{localStorage.getItem("user_name")}</label>
                                    </div>
                                    <hr />
                                    <div className="vc_rb">
                                        {/* Radio Buttons */}
                                        <input type="radio"
                                            id="test2"
                                            name="radio-group"
                                            onClick={() =>
                                                setbookAndPayForm({
                                                    ...bookAndPayForm,
                                                    booked_for: "other",
                                                    isSomeOneElse: true
                                                })}
                                            defaultChecked={isSomeOneElse === true ? true : false}
                                        />
                                        <label htmlFor="test2">Someone else</label>
                                    </div>
                                </div>
                                <h4 class="bk_sub_title">Please provide following information</h4>
                                <form>
                                    <div>
                                        <div class="form-group"><label for="exampleInputEmail1">Full name *</label>
                                            <input
                                                onChange={(e) => onInputChange(e)}
                                                value={other_name} name="other_name"
                                                type="text" className="form-control"
                                                placeholder="Enter full name"
                                                readOnly={isSomeOneElse ? false : true}
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Mobile *</label>
                                            <input
                                                readOnly={isSomeOneElse ? false : true}
                                                type="text"
                                                maxLength={10}
                                                onChange={(e) => onInputChange(e)}
                                                value={other_mobile}
                                                name="other_mobile"
                                                className="form-control"
                                                placeholder="Enter mobile" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Gender *</label>
                                            <select
                                                disabled={!isSomeOneElse}
                                                onChange={(e) => onInputChange(e)}
                                                value={other_gender}
                                                name="other_gender"
                                                className="form-control"
                                            >
                                                <option value="">Select gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div class="form-group"><label for="exampleInputEmail1">Address *</label>
                                            <input

                                                type="text"
                                                //maxLength={10}
                                                onChange={(e) => onInputChange(e)}
                                                // value={localStorage.getItem("user_address")}
                                                name="pickup_address"
                                                className="form-control"
                                                placeholder="Enter Address" />
                                        </div>
                                        <div class="form-group">
                                            <label>Medical History</label>
                                            <div className='gap-3'>
                                                <input type="checkbox" id='Allergies' onChange={e => setAllergy(!allergy)} checked={allergy} />
                                                <label className='mx-3-medical'>Allergies </label>
                                                <input type="checkbox" id='Past-Medical-Issue' onChange={e => setPastMedicalIssue(!pastMedicalIssue)} checked={pastMedicalIssue} />
                                                <label className='mx-3-medical'>Past Medical Issue </label>
                                                <input type="checkbox" id='On-Medication' onChange={e => setOnMeditation(!onMedication)} checked={onMedication} />
                                                <label className='mx-3-medical'>On Medication </label>
                                            </div>
                                        </div>
                                        <div class="form-group"><label for="exampleInputEmail1">Remark </label>
                                            <input
                                                type="text"
                                                //maxLength={10}
                                                onChange={(e) => onInputChange(e)}
                                                value={short_description}
                                                name="short_description"
                                                className="form-control"
                                                placeholder="Enter remarks" />
                                        </div>
                                        <a href="javascript:void(0)" class="go-back" onClick={goBack}>
                                            <i class="fa-solid fa-arrow-left"></i> Go Back
                                        </a>



                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="left_itme ">
                            <div className='bg_light padding-3'>
                                <h4 class="bk_sub_title pt20">Booking details</h4>
                                <hr />
                                <div class="user-name-booking">
                                    <h3 className="dr_name">{nurseName}</h3>
                                    <p className="dr_exp">{specialization.name}</p>
                                    <p className="dr_exp">{experience} years</p>
                                    <p className="dr_exp">{city}</p>
                                </div>
                                <hr />
                                <div class="belowbtn">
                                    <div class="cons_fee"><span class="consl">Home Care</span><span class="cons2">{specialization.name}</span></div>
                                    <div class="cons_fee"><span class="consl">Visit Type</span><span class="cons2">{visitType === "per_visit" ? "Per Visit" : "Fixed hour Visit"}</span></div>
                                    {specialization.nurse_service_type && specialization.nurse_service_type !== "" && specialization.nurse_service_type !== null && specialization.nurse_service_type !== undefined && (
                                        <div class="cons_fee"><span class="consl">Required service Type</span><span class="cons2">{nurseServiveType}</span></div>
                                    )}
                                    <hr />
                                    <p>First Visit Required on</p>
                                    <div className='date-time'>
                                        <div class="date">
                                            <h5>Date</h5>
                                            <input type="date" id="birthdaytime" name="appointment_date" onChange={(e) => onInputChange(e)} />
                                        </div>
                                        {/* <div className='time'>
                                            <h5>Preferred Time</h5><input type="time" id="appt" name="appointment_time" onChange={(e) => onInputChange(e)} />
                                        </div> */}
                                        <div className='time'>
                                            <h5>Preferred Time</h5>
                                            <select name="appointment_time" onChange={(e) => getAvailabilityId(e)}>
                                                <option >Select Time</option>
                                                {appointmentTimeList.map((item, index) => (
                                                    <option
                                                        key={index}
                                                        value={item.start_time + "-" + item.id}
                                                        onClick={() => getAvailabilityId(item.id)}
                                                    >
                                                        {Change_to_12HourFormat(item.start_time)} to {Change_to_12HourFormat(item.end_time)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>



                                    </div>
                                    <hr />
                                    <div>
                                        {visitType === "fixed_hour_visit" && (
                                            <div className="service-hours">
                                                <h5>Service Hours Per Day</h5>
                                                <button
                                                    className={`btn_fill btn_hours ${selectedHours === 2 ? "btnhours-selected" : ""}`}
                                                    onClick={() => handleButtonClick(2)}

                                                >
                                                    2 Hours
                                                </button>
                                                <button
                                                    className={`btn_fill btn_hours ${selectedHours === 4 ? "btnhours-selected" : ""}`}
                                                    onClick={() => handleButtonClick(4)}

                                                >
                                                    4 Hours
                                                </button>
                                                <button
                                                    className={`btn_fill btn_hours ${selectedHours === 6 ? "btnhours-selected" : ""}`}
                                                    onClick={() => handleButtonClick(6)}

                                                >
                                                    6 Hours
                                                </button>
                                                <button
                                                    className={`btn_fill btn_hours ${selectedHours === 12 ? "btnhours-selected" : ""}`}
                                                    onClick={() => handleButtonClick(12)}

                                                >
                                                    12 Hours
                                                </button>
                                                <button
                                                    className={`btn_fill btn_hours ${selectedHours === 24 ? "btnhours-selected" : ""}`}
                                                    onClick={() => handleButtonClick(24)}
                                                //   style={{ backgroundColor: selectedHours === 24 ? "white" : "" }}
                                                >
                                                    24 Hours
                                                </button>
                                            </div>
                                        )}

                                        <hr />
                                        {visitType === "fixed_hour_visit" && (
                                            <p>Selected Hours: {selectedHours}</p>
                                        )}
                                    </div>

                                    <div className='bill-update'>
                                        <h6>Bill Detail</h6>
                                        <div className='display-flex-flexend'>
                                            <div>
                                                <span>
                                                    {visitType === "per_visit"
                                                        ? `${specialization.per_visit_charge} * ${total_visit} Visit`
                                                        : visitType === "fixed_hour_visit" && selectedHours === 2
                                                            ? `${specialization.two_hours_charge} * ${total_visit} Visit`
                                                            : visitType === "fixed_hour_visit" && selectedHours === 4
                                                                ? `${specialization.four_hours_charge} * ${total_visit} Visit`
                                                                : visitType === "fixed_hour_visit" && selectedHours === 6
                                                                    ? `${specialization.six_hours_charge} * ${total_visit} Visit`
                                                                    : visitType === "fixed_hour_visit" && selectedHours === 12
                                                                        ? `${specialization.twelve_hours_charge} * ${total_visit} Visit`
                                                                        : visitType === "fixed_hour_visit" && selectedHours === 24
                                                                            ? `${specialization.twenty_four_hours_charge} * ${total_visit} Visit`
                                                                            : ""
                                                    }
                                                </span>
                                            </div>
                                            <div>
                                                <input type='number' className='increment' min="1" name="total_visit" onChange={(e) => onInputChange(e)} />
                                            </div>
                                            <div>
                                                <span>
                                                    {visitType === "per_visit" ? `₹ ${total_amount}` : `₹ ${specialization.per_visit_charge * total_visit}`}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <hr />
                                    {couponList && couponList.length > 0 && (
                                        <>
                                            <h5 className='mb-5'>Applicable Coupons</h5>
                                            {couponList && couponList.length > 0 && (
                                                <div className='under-box'>
                                                    <h6>Apply Coupon</h6>
                                                    {couponList.map((item) => (
                                                        <div key={item.id} className="display-flex-t">
                                                            <input
                                                                type="radio"
                                                                style={{ cursor: 'pointer' }}
                                                                id={`test${item.id}`}
                                                                name="radio-group"
                                                                value={item.id}
                                                                checked={selectedCoupon === item.id}
                                                                onChange={handleCouponSelect}
                                                            />
                                                            <div className="div-f">
                                                                <label htmlFor={`test${item.id}`} className="line-height">
                                                                    {item.coupon_code}
                                                                </label>
                                                                <p>Save ₹ {item.total_discount}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {selectedCoupon && (
                                                <button onClick={handleClearCouponSelection}>Remove selected coupon</button>
                                            )}
                                        </>
                                    )}
                                    <h6 className='under-box-update-email'>Updates will be sent to registered email address</h6>
                                    <h5>Undertaking</h5>
                                    <p class="Undertaking">
                                        Home medical care offers a convenient, comfortable, and cost-effective option for patients who require medical attention. However, it should be noted that home medical care cannot replace hospital services in cases of emergency or critical illness.

                                    </p>
                                    <p>
                                        {/* <input type="checkbox" /><span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span> */}
                                        <input type={'checkbox'} checked={isChecked} onClick={handleCheckBox} /><span> I agree to the <Link to={'/terms-and-conditions'}>Terms and Conditions.</Link></span>
                                    </p>
                                </div>
                                <div>
                                    {/* <div class="text-center"><button disabled="" type="submit" class="btn btn_fill btn-block">Book &amp; pay</button></div> */}
                                    <div className="text-center"><button disabled={isChecked ? false : true} type="submit" className="btn btn_fill btn-block" onClick={(e) => bookAndPay(e)}>Book &amp;  pay</button></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

        </div>
    )
}

export default NrusingBillingDetails