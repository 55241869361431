import React from 'react'

const LocationGettingStatrtedPatient = ({incrementBtn,decrementBtn,profileData,setAddress,mobile,setMobile,email,setEmail}) => {
  setMobile(profileData.mobile)
  setEmail(profileData.email)
  console.log(mobile,email,profileData)
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="profile_form1">
          <div className="row">
            <div className="col-lg-9">
              <h3 className="drprofile_title">Location</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="form-group ">
                <label>Phone number</label>
                <div className="inputflex">
                  <div className="input-group inputtext">
                    <span className="input-group-addon">91</span>
                    <input type="text" className="form-control" value={mobile} onChange={(e)=>setMobile(e.target.value)}/>
                    {/* <span className="spanverify"><img src="images/check.png" /> Verified</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="form-group">
                <label>Email address</label>
                <div className="inputflex">
                  <div className="inputtext">
                    <input type="text" className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="form-group">
                <label>Street address</label>
                <input type="text" className="form-control" onChange={(e)=>setAddress(e.target.value)} placeholder="Lorem ipsum dolor sit " />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-5">
              <div className>
                <a href="#" className="btn btn-theme btn-lg btn-block redius10">Conitnue</a>
              </div>
            </div>
          </div> */}
          <div className="info_box_btn">
            <button className="btnlink" onClick={decrementBtn}>Go back</button>
            <button type='button' onClick={incrementBtn} className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default LocationGettingStatrtedPatient