import React, { useEffect, useState } from 'react'
import TimePicker from 'react-time-picker';
import { toast } from 'react-toastify';

const DaysGettingStatrtedPatient = ({ incrementBtn, decrementBtn, setAvailability, setDay, setStart_time, setEnd_time, addAvail }) => {
  const [colorId, setColorId] = useState()
  const [start_time, setStartTime] = useState('10:00')
  let avail = []
  const [newAvail, setNewAvail] = useState()
  const [end_time, setEndTime] = useState('11:00')
  let days = ['All', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const [selecteddays, setSelectedDays] = useState([]);
  const selectDays = (val, index) => {
    if (colorId == index) {
      setNewAvail([])
      setColorId()
    } else {
      setColorId(index)
      var temp = selecteddays
      // console.log(temp)
      if (!temp.includes(index)) {
        if (index == 0) {
          for (let i = 0; i < days.length; i++) {
            temp.push(i);
          }
        }
        else {
          temp.push(index);
        }

      }
      else {
        if (index === 0)
          temp = [];
        else
          temp = temp.filter(x => (x != index && x !== 0));
      }
      console.log(temp)
      setSelectedDays(temp)
      for (let i = 0; i < temp.length; i++) {
        if (i > 0) {
          // console.log(index)
          avail.push({
            day: temp[i] - 1,
            start_time: start_time,
            end_time: end_time,
            fee: null
          })
        }
        setNewAvail(avail)
      }
      console.log(avail)
    }
  }
  setAvailability(newAvail)
  console.log(newAvail)
  // console.log(avail)
  const handleClick = () => {
    incrementBtn();

  }
  const onChangeStart = (val) => {
    if (end_time <= val) {
      toast.error('Please Enter Right Time')
    } else {
      setStartTime(val)
      for (let i = 0; i < newAvail.length; i++) {
        newAvail[i].start_time = val
      }
    }
  }
  const onChangeEnd = (val) => {
    if (start_time >= val) {
      toast.error('Please Enter Right Time')
    } else {
      setEndTime(val)
      for (let i = 0; i < newAvail.length; i++) {
        newAvail[i].end_time = val
      }
    }
  }
  // console.log(startTime,endTime)
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="profile_form1">
          <div className="row">
            <div className="col-lg-10">
              <h3 className="drprofile_title ">Days</h3>
              <div className="daylist mb-30">
                {
                  days.map((val, ind) => {
                    return (
                      <button className={selecteddays.includes(ind) ? "btn margin-10px dat-selection mt-1" : "btn margin-10px btn-primary mt-1"} key={ind} onClick={(e) => selectDays(val, ind)}>{val}</button>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="col-lg-10"><label>From</label></div>
              <div className="form-group ">
                <TimePicker locale="sv-sv" className="form-control manage-height-time-picker" disableClock onChange={(onChangeStart)} value={start_time} />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="col-lg-10"><label>To</label></div>
              <div className="form-group ">
                <TimePicker locale="sv-sv" className="form-control manage-height-time-picker" disableClock onChange={(onChangeEnd)} value={end_time} />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12">
              <div className="col-lg-10"><label></label></div>
              <div className="form-group ">
                <button >Add</button>
              </div>
            </div> */}
          {/* <div className="row">
            <div className="col-lg-10"><label>Session 2</label></div>
            <div className="col-lg-5">
              <div className="form-group ">
                <select className="form-control">
                  <option>From</option>
                  <option>2</option>
                </select>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="form-group ">
                <select className="form-control">
                  <option>To</option>
                  <option>2</option>
                </select>
              </div>
            </div>
          </div> */}
          {/* <p style={{ color: '#999DA1', marginBottom: 30 }}><i className="fa fa-plus" /> Add timings for remaining days</p> */}
          <div className='position-abslute-bottom'>
            <div className="info_box_btn padding-130px">
              <button type='button' onClick={decrementBtn} className="btnlink">Go back</button>
              <button type='button' onClick={incrementBtn} className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default DaysGettingStatrtedPatient