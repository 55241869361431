import {  createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null, token: null
}


export const authenticateSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      const { user, accessToken } = action.payload
      state.user = user
      state.token = accessToken
    },
    setLoggedOut: (state, action) => {
      state.user = null
      state.token = null
    }
  },
})



// Action creators are generated for each case reducer function
export const {setLoggedIn,setLoggedOut} = authenticateSlice.actions
export default authenticateSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token