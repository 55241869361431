import React from 'react'
import { useLocation } from 'react-router'

const ShowData = () => {
    const location=useLocation()
    let image=location.state.data
    const url=window.location.origin
   
  return (
    <>
        <div>
            <img src={"https://fasttrack.acolabz.com/#"+image}/>
        </div>
    </>
  )
}

export default ShowData