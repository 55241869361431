import APIHandler from '../services/APICore';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';

const Bookingsdetails = ({ apidata }) => {
    const [mdcal, setMdcal] = useState("");
    useEffect(() => {
        var temp = mdcal;
        if (apidata.allergy === 1 || apidata.allergy === true) {
            if (temp == "") {
                temp += "allergy, "
            }
            else {
                temp += "allergy"
            }
        }
        if (apidata.on_medication === 1 || apidata.on_medication === true) {
            if (temp == "") {
                temp += "on_medication, "
            }
            else {
                temp += "on medication, "
            }

        }
        if (apidata.past_medical_issue === 1 || apidata.past_medical_issue === true) {
            if (temp == "") {
                temp += "past medical issue, "
            }
            else {
                temp += "past medical issue"
            }
        }

        setMdcal(temp)
    }, [apidata.allergy]);



    return (
        <>
            <div className='profile_body'>
                <div className="row">
                    <div className="col-lg-2">
                        <div class="fd_input  mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Service</label> <br />
                                <input type="text" class="form-control" value={apidata.service == "Doctor" ? "Teleconsultation" : apidata.service == "Nurse" ? "Homecare" : apidata.service == "Ambulance" ? "Ambulance" : ""} placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div class="fd_input  mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Status</label> <br />
                                <input type="text" value={(apidata.status)} class="form-control" placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class=" fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Booking Date & Time</label>
                                <input type="text" class="form-control" value={(moment(apidata.booking_date).utc()).format("DD/MM/YYYY") + "," + (moment(apidata.booking_date).utc()).format("hh:mm a")} placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="fd_input  mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label text-wrap">Appointment/Schedule Date</label>
                                <input type="text" class="form-control" value={(moment(apidata.appointment_date).utc()).format("DD/MM/YYYY") + "," + (moment(apidata.appointment_date).utc()).format("hh:mm a")} placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div class="fd_input  mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Fee Paid</label> <br />
                                <input type="text" class="form-control" value={(apidata.paid_amount)} placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-lg-12"><h4 class="page_title">Service Provider</h4>  </div>
                    <div className="col-lg-6">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Name</label>
                                <input type="text" class="form-control" value={apidata.provider_name} placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Address</label>
                                <input type="text" class="form-control" value={apidata.service_provider_address} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Specialization</label>
                                <input type="text" class="form-control" value={(apidata != undefined && apidata.specializations != null) && (apidata.specializations).map((key) => (key.title))} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Email</label>
                                <input type="text" class="form-control" value={apidata.provider_email} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class=" fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                                <input type="text" class="form-control" value={apidata.provider_mobile} placeholder="" disabled={true}/>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row ">
                    <div className="col-lg-12"><h4 class="page_title">User Details</h4>  </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Booked By</label>
                                <input type="text" class="form-control" value={apidata.booked_by} placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Address</label>
                                <input type="text" class="form-control" value={apidata.user_address} placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Email</label>
                                <input type="text" class="form-control" value={apidata.user_email} placeholder="" disabled={true} />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                                <input type="text" class="form-control" value={apidata.other_mobile} placeholder="" disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Booked For</label>
                                <input type="text" class="form-control" value={apidata.booked_for} placeholder="" disabled={true} />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Name</label>
                                <input type="text" class="form-control" value={apidata.other_name} placeholder="" disabled={true} />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Age</label>
                                <input type="text" class="form-control" value={apidata.other_age} placeholder="" disabled={true} />
                            </div>

                        </div>

                    </div>
                </div>
                <div className="row ">
                    {/* <div className="col-lg-12">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Medical History(If Any)</label>
                                <textarea class="form-control" value={mdcal} id="exampleFormControlTextarea1" rows="3" disabled={true}></textarea>
                            </div>
                        </div>


                    </div> */}
                    <div className="col-lg-12">
                        <div class="fd_input mb-3">
                            <div className='form-group'>
                                <label for="exampleFormControlInput1" class="form-label">Notes(If Any)</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" value={apidata.remark} rows="3" disabled={true}></textarea>
                            </div>
                        </div>

                        <div className="col-lg mb-3">

                            <div class="fd_input">
                                <label for="exampleFormControlInput1" class="form-label">Medical History</label>

                            </div>
                            <div className='gap-3'>
                                <input type="checkbox" disabled id='Allergies' checked={apidata?.allergy} />
                                <label className='mx-3-medical'>Allergies </label>
                                <input type="checkbox" disabled id='Past-Medical-Issue' checked={apidata?.pastMedicalIssue} />
                                <label className='mx-3-medical'>Past Medical Issue </label>
                                <input type="checkbox" disabled id='On-Medication' checked={apidata?.onMedication} />
                                <label className='mx-3-medical'>On Medication </label>
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className='form-group fd_input mt-3'>
                                <label for="" class="form-label">Remarks</label>
                                <textarea disabled class="form-control" value={apidata?.short_description} v rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Bookingsdetails;