import React, { useEffect, useState } from 'react'
import BookingComponent from '../../../components/BookingComponent'
import { ServicesProviderService } from '../../../services'

const AccountTeleconsultation = () => {

    const apiFunction = new ServicesProviderService()
    const [datalist, setDatalist] = useState([])
    const [isloading, setIsLoading] = useState(false)
    const [status, setStatus] = useState("Booked")
    const service = JSON.parse(localStorage.getItem("services"));
    const [type, setType] = useState("Doctor");
    let a=true

    useEffect(() => {
        if (service && service.length > 0) {
            setType(service[0].service);
        }
    }, [])
    useEffect(() => {
        loadBookingsAllBooking();
        setIsLoading(true);
        
        // if (type && type !== "")
    }, [status || a])
    // console.log(type, status)
    const loadBookingsAllBooking = () => {
        apiFunction.loadBookingsDetails(type, status).then(result => {
            console.log('doctor', result)
            if (result !== undefined) {
                if (result.success === true) {
                    setDatalist(result.data)
                    setIsLoading(false);
                    // setStatus('')
                }
            }
        })
    }

    return (
        <BookingComponent setStatus={setStatus} loadBookingsAllBooking={loadBookingsAllBooking} type={type} setType={setType} service={service} status={status} data={datalist} selfDetails={true} isloading={isloading} />
    )
}

export default AccountTeleconsultation;