import { CheckBox } from '@mui/icons-material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Specialization } from '../../services/DoctorOnboarding'
import { toast } from 'react-toastify';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const DoctorRegistration = ({ incrementBtn, profileData, setDescription, description, gender, specializationsList, city, setGender, setSpecializationsList, setCity,headline,setheadline }) => {
    const [check, setCheck] = useState(false)
    const token = localStorage.getItem('token')
    const [specializationList, setSpecializationList] = useState([])
    
    useEffect(() => {
        if (gender != null) {
            setCheck(true)
        }
        masterSpecialization()
    }, [])

    const masterSpecialization = () => {
        try {
            console.log("API call", token)
            if (token != null) {
                Specialization(token)
                    .then((res) => {
                        console.log(res);
                        let isSuccess = res.success;
                        if (isSuccess) {
                            setSpecializationList(res.data)
                        }
                    })
                    .catch();
            } else if (token.length <= 0) {
                console.log("hello")
                toast.error("Something went wrong , Please try again");
            }
        } catch (error) {
        }
    }
    const increaseStep = () => {
        if (description !== '' && gender !== '' && city !== '' && specializationsList !== []) {
            incrementBtn()
        } else if (description === '') {
            toast.error("Please enter description")
        } else if (gender === '') {
            toast.error("Please select gender")
        } else if (city === '') {
            toast.error("Please select city")
        } else if (specializationsList === []) {
            toast.error("Please select specialization")
        }
    }
    const animatedComponents = makeAnimated();
    let option = []
    console.log(specializationsList)
    if (specializationList !== null && specializationList !== undefined && specializationList !== []) {
        for (let i = 0; i < specializationList.length; i++) {
            option.push({ value: specializationList[i].title, label: specializationList[i].title, id: specializationList[i].id })
            // console.log(specializationList[i].title)
        }
    }
    // console.log(option)
    const selectGender = (value, idName) => {
        setCheck(true)
        // console.log(value)
        setGender(value)
        // let idChecked = document.getElementById(idName).checked
        // console.log(idChecked)
        // if (idChecked) {
        //     setGender(value)
        //     gend = value
        //     console.log(gend)
        // }
    }
    // setGender('Male')
    // console.log(specializationsList)
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="profile_form1">
                    <h3 className="drprofile_title">Medical Registration</h3>                  
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>Name</label>
                                <div className="input-group">
                                    <span className="input-group-addon">Dr.</span>
                                    <input type="text" value={profileData.name} className="form-control" placeholder="Please enter your name" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea className="form-control inputtext" type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="form-group">
                                <label>Headline</label>
                                <div className='checkbox-doctor'>
                                <input type="text" value={headline} className="form-control" placeholder="Please enter your headline" onChange={(e)=>setheadline(e.target.value)} />
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>Specialization</label>
                                <div className='checkbox-doctor'>
                                    <Select className='select-magage-width-height' closeMenuOnSelect={false}
                                        components={animatedComponents} onChange={setSpecializationsList} defaultValue={specializationsList} isMulti options={option} />
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div className="row">
                        
                    </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="form-group gender-fixing">
                                <label>Gender</label>
                                <div className="radioblock">
                                    <p>
                                        <input type="radio" id="test1" checked={gender === "Male" && check} onClick={(e) => selectGender('Male', "test1")} name="radio-group" value={'Male'} />
                                        <label htmlFor="test1">Male</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="test2" checked={gender === "Female" && check} onClick={(e) => selectGender('Female', "test2")} name="radio-group" value={'Female'} />
                                        <label htmlFor="test2">Female</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="test3" checked={gender === "Other" && check} onClick={(e) => selectGender('Other', "test3")} name="radio-group" value={'Other'} />
                                        <label htmlFor="test3">Other</label>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="form-group ">
                                <label>City</label>
                                <select className="form-control" value={city} onChange={(e) => setCity(e.target.value)}>
                                    <option>Select city</option>
                                    <option>Aligarh</option>
                                    <option>Allahabad</option>
                                    <option>Azamgarh</option>
                                    <option>Mirzapur</option>
                                    <option>Gorakhpur</option>
                                    <option>Basti</option>
                                    <option>Amroha</option>
                                    <option>Saharanpur</option>
                                    <option>Fatehpur</option>
                                    <option>Ambedkar Nagar</option>
                                    <option>Bareilly</option>
                                    <option>Firozabad</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <div className>
                                <button onClick={increaseStep} className="btn btn-theme btn-lg btn-block redius10">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoctorRegistration