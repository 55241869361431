import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { HomepageHeader, SidebarUserPanel } from '.';
import moment from 'moment'
import { ProfileAndSlotService } from '../services';
import { ShowProfileType } from '../enums/Enums';
import CheckCircle from "../images/images/check-circle.png"
import userIcon from "../images/adminpanel/UserIcon.svg"
import { ActionDoctor, AddPrescription } from '../services/ServiceProviderDoctor';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const BookingComponent = ({ selfDetails, data, isloading, loadBookingsAllBooking, setStatus, status, type, setType, service }) => {

    const apiFunction = new ProfileAndSlotService();
    const navigateTo = useNavigate()
    const [showAddPrescription, setShowAddPrescription] = useState(false)
    const pagination = useRef();
    const [offset, setOffset] = useState(0);
    const perPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(1);
    const [pesentData, setPesentData] = useState(0)
    const [prescription, setPrescription] = useState()
    const [startValue, setStartValue] = useState(0)
    const [endValue, setEndValue] = useState(7)
    let prescriptionText
    // const [serviceProviderId, setServiceProviderId] = useState(0);
    const [searchVal, setSearchVal] = useState("");
    const [paginateList, setPaginateList] = useState([])
    const [userData, setUserdata] = useState({
        username: "",
        bio: "",
        profile_pic: "",
        qualification: "",
        specialization: "",
        experience: "",
        consultation_fees: "",
        video_consult: "",
        slots: [],
        patientStories: []
    })
    const filterDataBySearch = () => {
        let dataArr = data.filter(item =>
            item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            item.service_type.toLowerCase().includes(searchVal.toLowerCase())
        )
        setTotalData(dataArr.length)
        if (dataArr.length > 0) {
            const slice = dataArr.slice(offset, offset + perPage);
            setPaginateList(slice);
            setPageCount(Math.ceil(dataArr.length / perPage));
        } else {
            setPaginateList([]);
        }
    }

    // Debounce function
    const debounce = (fn, delay) => {
        let timer;
        return (() => {
            clearTimeout(timer);
            timer = setTimeout(() => fn(), delay);
        })();//ifee-->  Immediately Invoked Function Expression
    };
    useEffect(() => {


    }, [])
    useEffect(() => {
        if (searchVal) {
            debounce(filterDataBySearch, 1000);
        }
        else {
            loadData();
        }

    }, [searchVal])


    const loadData = () => {
        let dataArr = data
        setTotalData(dataArr.length)
        if (dataArr.length > 0) {
            const slice = dataArr.slice(offset, offset + perPage);
            setPaginateList(slice);
            setPageCount(Math.ceil(dataArr.length / perPage));
        } else {
            setPaginateList([]);
        }
    }

    useEffect(() => {
        loadData()
    }, [number, offset, data])

    const setPage = ({ selected }) => {
        setNumber(selected)
        setOffset(perPage * selected)
    }
    // handling to show user Data
    const handleShowPesentData = (id) => {
        const result = data.filter(item => item.id === id);
        setPesentData(result[0])
    }
    let monthsList = ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    // Adding Pricaption
    const addingPricaptions = (user_id, booking_id) => {
        data = {
            booking_id,
            user_id,
            prescription,
            // complaints,
            // clinical_notes
        }
        console.log(data)
        try {
            AddPrescription(data)
                .then((res) => {
                    console.log(res)
                    if (res.success === true) {
                        setPrescription('')
                        prescriptionText = booking_id
                        setStatus("Booked")
                        loadBookingsAllBooking()
                        setPesentData(0)
                        toast.success(res.message)
                        // navigateTo('/doctor-profile-details',{state:{profileData:res.data}})
                    }
                    //   setDoctorData(res.data)
                })
                .catch();
        } catch (error) { }
    }
    const joinCallNow = (roomId) => {
        console.log('first',roomId)
        navigateTo('/video-call', { state: { roomId } })
    }
    const DoctorAction = (stat, id) => {
        const randomID = (len) => {
            let result = '';
            if (result) return result;
            var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
                maxPos = chars.length,
                i;
            len = len || 5;
            for (i = 0; i < len; i++) {
                result += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return result;
        }
        let roomID = randomID(5)
        try {
            ActionDoctor(stat, id, roomID)
                .then((res) => {
                    // console.log(res)
                    if (res.success === true) {
                        setStatus('Pending')
                        loadBookingsAllBooking()
                        setPesentData(0)
                        // navigateTo('/doctor-profile-details',{state:{profileData:res.data}})
                    }
                    //   setDoctorData(res.data)
                })
                .catch();
        } catch (error) { }
    }
    const statusSetting = (stu) => {
        setStatus(stu)
        setPesentData(0)
    }
    const handleSliceMapping = (val) => {
        if (val === "Next") {
            setStartValue(startValue + 7)
            setEndValue(endValue + 7)
        } else {
            setStartValue(startValue - 7)
            setEndValue(endValue - 7)
        }
    }
    console.log(startValue, endValue)
    let serviceLength = 0
    for (let i = 0; i < service.length; i++) {
        if (service[i].is_approved == 1) {
            serviceLength += 1
        }
    }
    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <SidebarUserPanel />
                        <div className="right_panel table-responsive">
                            {(service && serviceLength > 1) &&
                                <div className='col-md-12' style={{ marginBottom: "22px", marginTop: "20px" }}>
                                    <div class="tab pull-right">
                                        {

                                            service.map((item, ind) => {
                                                return (
                                                    <>
                                                        {
                                                            item.is_approved == 1 && <button onClick={(e) => setType(item.service)}
                                                                className={type === item.service ? 'active'
                                                                    : ""}>
                                                                {item.service}
                                                            </button>
                                                        }
                                                    </>
                                                )
                                            })

                                        }
                                    </div>
                                </div>
                            }
                            <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                <div class="patient_panel">
                                    <div class="left_tab ">
                                        <div class="tab_header">
                                            <h4>Patient ‘s list</h4>
                                            {/* <a href="#" class="btn btn-info radius">Today</a> */}
                                        </div>
                                        <div class="dropdown-option-booking tab_header-second">
                                            <span>{status}</span><span class="caret"></span>
                                            <div class="dropdown-option-booking-content tab">
                                                <p onClick={() => statusSetting("Pending")}
                                                    className={status === "Pending" ? 'active' : ""}><Link to={''} className={status === 'Pending' ? 'color-white' : ''}>Pending</Link></p>
                                                <p onClick={() => statusSetting("Booked")}
                                                    className={status === "Booked" ? 'active'
                                                        : ""}><Link to={''} className={status === 'Booked' ? 'color-white' : ''}>Confirm</Link></p>
                                                <p onClick={() => statusSetting("Completed")}
                                                    className={status === "Completed" ? 'active' : ""}><Link to={''} className={status === 'Completed' ? 'color-white' : ''}>Completed</Link></p>
                                                <p onClick={() => statusSetting("Cancelled")}
                                                    className={status === "Cancelled" ? 'active' : ""}><Link to={''} className={status === 'Cancelled' ? 'color-white' : ''}>Canceled</Link></p>
                                            </div>
                                        </div>
                                        <div class="patient_list">
                                            <ul>
                                                {paginateList.length === 0 ? <li>No booking found</li> :
                                                    paginateList.slice(startValue, endValue).map((item, ind) =>
                                                    (
                                                        <li class="active" key={ind} onClick={() => handleShowPesentData(item.id)}>
                                                            <p class="time"><span>{(moment(item?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(item?.appointment_date)).format('MMM')} {item.scheduled_at}</p>
                                                            <p class="name">{item?.other_name || item.user_name}</p>
                                                            {/* <p class={item.status == 'Booked' ? "status-booking-conferm" : "status-booking-pending"}>{item.status}</p> */}
                                                        </li>
                                                    ))}
                                            </ul>
                                            {
                                                (paginateList.length !== 0 && paginateList.length > 8) && <div className='display-flex-space-between'>
                                                    <div>
                                                        <button onClick={() => handleSliceMapping('Prev')} class="btn">&laquo; Prev</button>
                                                    </div>
                                                    <div>
                                                        <button onClick={() => handleSliceMapping('Next')} class="btn btn-success ms-auto">Next &raquo;</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='right_tab'>
                                        {
                                            status === "Pending" &&
                                            <>
                                                {
                                                    pesentData === 0 ?
                                                        data[0]?.id ?
                                                            <div class="">
                                                                <div class="patient_header">
                                                                    <div class="photo">
                                                                        <img src={data[0]?.image || "images/user.png"} />
                                                                    </div>
                                                                    <div class="patient_detials">
                                                                        <h4 class="patient_name">{data[0]?.user_name || data[0].other_name}</h4>
                                                                        <div class="ptinfo">
                                                                            <h5>Gender: <span>{data[0]?.user_gender||data[0].other_gender}</span></h5>
                                                                        </div>
                                                                        <div class="ptinfo">
                                                                            <h5>Age:<span>{data[0]?.user_age || data[0].other_age == null ? 'N/A' : data[0].user_age || data[0].other_age}</span></h5>
                                                                            <h5>Mobile:<span>+91 {data[0]?.user_mobile || data[0].other_mobile}</span></h5>
                                                                            <h5>Email:<span>{data[0]?.user_email ||data[0]?.other_email || 'N/A'}</span></h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="patient_body">
                                                                    <div class="appoint_schedule">
                                                                        <div class="dateblock">
                                                                            <div class="date"><span>{(moment(data[0]?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(data[0].appointment_date)).format('MMM')}</div>
                                                                            <div class="datedtl">
                                                                                <h5>Appointment scheduled at  {(moment(data[0].appointment_date)).format("hh:mm A")}</h5>
                                                                                {/* <p>Records for {data[0]?.name || data[0].other_name}</p> */}
                                                                            </div>
                                                                            <div class="datedtl">
                                                                                <Link to></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="btnblock">
                                                                        <Link to={''} class="btn btn-themeoutline radius btnbig" onClick={() => DoctorAction(false, data[0].id)}>Reject</Link>
                                                                        <Link to={''} class="btn btn-theme radius btnbig" onClick={() => DoctorAction(true, data[0].id)}>Accept</Link>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            : '' :
                                                        <div class="">
                                                            <div class="patient_header">
                                                                <div class="photo">
                                                                    <img src={pesentData?.image || "images/user.png"} />
                                                                </div>
                                                                <div class="patient_detials">
                                                                    <h4 class="patient_name">{pesentData?.user_name || pesentData?.other_name}</h4>
                                                                    <div class="ptinfo">
                                                                        <h5>Gender: <span>{pesentData?.user_gender || pesentData.other_gender}</span></h5>
                                                                        {/* <h5>Blood group: <span>“O” +ve</span></h5> */}
                                                                    </div>
                                                                    <div class="ptinfo">
                                                                        <h5>Age:<span>{pesentData?.other_age || pesentData?.user_age == null ? 'N/A' : pesentData?.user_age || pesentData?.other_age}</span></h5>
                                                                        <h5>Mobile:<span>+91 {pesentData?.user_mobile || pesentData.other_mobile}</span></h5>
                                                                        <h5>Email:<span>{pesentData?.user_email ||pesentData?.other_email || 'N/A'}</span></h5>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="patient_body">
                                                                <div class="appoint_schedule">
                                                                    <div class="dateblock">
                                                                        <div class="date"><span>{(moment(pesentData?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(pesentData?.appointment_date).utc()).format("MMM")}</div>
                                                                        <div class="datedtl">
                                                                            <h5>Appointment scheduled at {(moment(pesentData.appointment_date)).format("hh:mm A")}</h5>
                                                                            {/* <p>Records for Vivek Singhal</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="btnblock">
                                                                    <Link to={''} class="btn btn-themeoutline radius btnbig" onClick={() => DoctorAction(false, pesentData.id)}>Reject</Link>
                                                                    <Link to={''} class="btn btn-theme radius btnbig" onClick={() => DoctorAction(true, pesentData.id)}>Accept</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                        }
                                        {
                                            status === "Booked" &&
                                            <>
                                                {
                                                    pesentData === 0 ?
                                                        data[0]?.id ?
                                                            <div class="">
                                                                <div class="patient_header">
                                                                    <div class="photo">
                                                                        <img src={data[0]?.image || "images/user.png"} />
                                                                    </div>
                                                                    <div class="patient_detials">
                                                                        <h4 class="patient_name">{data[0].user_name || data[0].other_name}</h4>
                                                                        <div class="ptinfo">
                                                                            <h5>Gender: <span>{data[0]?.user_gender|| data[0]?.other_gender}</span></h5>
                                                                        </div>
                                                                        <div class="ptinfo">
                                                                            <h5>Age:<span>{data[0]?.other_age ||data[0].user_age == null  ? 'N/A' : data[0].other_age}</span></h5>
                                                                            <h5>Mobile:<span>+91 {data[0]?.user_mobile || data[0].other_mobile}</span></h5>
                                                                            <h5>Email:<span>{data[0]?.user_email ||data[0].other_email || 'N/A'}</span></h5>

                                                                        </div>
                                                                        <div>
                                                                            <h5 className='mt-2'>

                                                                                <button className='btn btn-info' onClick={(e) => joinCallNow(data[0].room_id
                                                                                )}><i class="bi bi-telephone-fill me-2" style={{ "font-size": "13px", "color": "white" }}> </i> <span className='ms-2'>Join Call</span> </button>

                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="patient_body">
                                                                    <div class="appoint_schedule">
                                                                        <div class="dateblock">
                                                                            <div class="date"><span>{(moment(data[0]?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(data[0]?.appointment_date).utc()).format("MMM")}</div>
                                                                            <div class="datedtl">
                                                                                <h5>Appointment scheduled at {(moment(data[0].appointment_date)).format("hh:mm A")}</h5>
                                                                                {/* <p>Records for Vivek Singhal</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="note_form">
                                                                        <div class="form-group">
                                                                            <label>Add Prescription/Clinical note</label>
                                                                            <textarea class="form-control" onChange={(e) => setPrescription(e.target.value)} rows="5" placeholder="" spellcheck="false"></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div class="btnblock">
                                                                        <a href="#" class="btn btn-themeoutline radius btnbig">Cancel</a>
                                                                        <Link to={''} class="btn btn-theme radius btnbig" onClick={() => addingPricaptions(data[0].user_id, data[0].id)} >Save</Link>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            : '' :
                                                        <div class="">
                                                            <div class="patient_header">
                                                                <div class="photo">
                                                                    <img src={pesentData?.image || "images/user.png"} />
                                                                </div>
                                                                <div class="patient_detials">
                                                                    <h4 class="patient_name">{pesentData?.user_name || pesentData.other_name}</h4>
                                                                    <div class="ptinfo">
                                                                        <h5>Gender: <span>{pesentData?.user_gender || pesentData?.user_gender}</span></h5>
                                                                        {/* <h5>Blood group: <span>“O” +ve</span></h5> */}
                                                                    </div>
                                                                    <div class="ptinfo">
                                                                        <h5>Age:<span>{pesentData?.user_age || pesentData?.other_age || 'N/A' }</span></h5>
                                                                        <h5>Mobile:<span>+91 {pesentData?.user_mobile || pesentData.other_mobile}</span></h5>
                                                                        <h5>Email:<span>{pesentData?.user_email ||pesentData?.other_email || 'N/A'}</span></h5>
                                                                    </div>
                                                                    <h5 className='mt-2'>

                                                                        <button className='btn btn-info' onClick={(e) => joinCallNow(pesentData.room_id
                                                                        )}><i class="bi bi-telephone-fill me-2" style={{ "font-size": "13px", "color": "white" }}> </i> <span className='ms-2'>Join Call</span> </button>

                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div class="patient_body">
                                                                <div class="appoint_schedule">
                                                                    <div class="dateblock">
                                                                        <div class="date"><span>{(moment(pesentData?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(pesentData?.appointment_date).utc()).format("MMM")}</div>
                                                                        <div class="datedtl">
                                                                            <h5>Appointment scheduled at {(moment(pesentData.appointment_date)).format("hh:mm A")}</h5>
                                                                            {/* <p>Records for Vivek Singhal</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="note_form">
                                                                    <div class="form-group">
                                                                        <label>Add Prescription/Clinical note</label>
                                                                        <textarea class="form-control" onChange={(e) => setPrescription(e.target.value)} rows="5" placeholder="" spellcheck="false"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="btnblock">
                                                                    <a href="#" class="btn btn-themeoutline radius btnbig">Cancel</a>
                                                                    <Link to={''} class="btn btn-theme radius btnbig" onClick={() => addingPricaptions(pesentData.user_id, pesentData.id)} >Save</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                        }


                                        {
                                            status === "Completed" &&
                                            <>
                                                {
                                                    pesentData === 0 ?
                                                        data[0]?.id ?
                                                            <div class="">
                                                                <div class="patient_header">
                                                                    <div class="photo">
                                                                        <img src={data[0]?.image || "images/user.png"} />
                                                                    </div>
                                                                    <div class="patient_detials">
                                                                        <h4 class="patient_name">{data[0]?.user_name|| data[0].other_name}</h4>
                                                                        <div class="ptinfo">
                                                                            <h5>Gender: <span>{data[0].user_gender || data[0]?.other_gender}</span></h5>
                                                                        </div>
                                                                        <div class="ptinfo">
                                                                            <h5>Age:<span>{data[0]?.user_age || data[0]?.other_age}</span></h5>
                                                                            <h5>Mobile:<span>+91 {data[0]?.user_mobile ||data[0].other_mobile}</span></h5>
                                                                            <h5>Email:<span>{data[0]?.user_email|| data[0]?.other_email || 'N/A'}</span></h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="patient_body">
                                                                    <div class="appoint_schedule">
                                                                        <div class="dateblock">
                                                                            <div class="date"><span>{(moment(data[0]?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(data[0]?.appointment_date).utc()).format("MMM")}</div>
                                                                            <div class="datedtl">
                                                                                <h5>Appointment scheduled at {(moment(data[0].appointment_date)).format("hh:mm A")}</h5>
                                                                                {/* <p>Records for Vivek Singhal</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="note_form">
                                                                        <div class="form-group">
                                                                            <label>Prescription/Clinical note</label>
                                                                            <p class="form-control">{data[0]?.prescription[0]?.prescription}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            : '' :
                                                        <div class="">
                                                            <div class="patient_header">
                                                                <div class="photo">
                                                                    <img src={pesentData?.image || "images/user.png"} />
                                                                </div>
                                                                <div class="patient_detials">
                                                                    <h4 class="patient_name">{pesentData.user_name}</h4>
                                                                    <div class="ptinfo">
                                                                        <h5>Gender: <span>{pesentData?.user_gender|| pesentData.other_gender}</span></h5>
                                                                        {/* <h5>Blood group: <span>“O” +ve</span></h5> */}
                                                                    </div>
                                                                    <div class="ptinfo">
                                                                        <h5>Age:<span>{pesentData.other_age == null ? 'N/A' : pesentData.other_age}</span></h5>
                                                                        <h5>Mobile:<span>+91 {pesentData.user_mobile || pesentData.other_mobile}</span></h5>
                                                                        <h5>Email:<span>{pesentData?.user_email || pesentData?.other_email || 'N/A'}</span></h5>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="patient_body">
                                                                <div class="appoint_schedule">
                                                                    <div class="dateblock">
                                                                        <div class="date"><span>{(moment(pesentData?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(pesentData?.appointment_date).utc()).format("MMM")}</div>
                                                                        <div class="datedtl">
                                                                            <h5>Appointment scheduled at {(moment(pesentData.appointment_date)).format("hh:mm A")}</h5>
                                                                            {/* <p>Records for Vivek Singhal</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="note_form">
                                                                    <div class="form-group">
                                                                        <label>Prescription/Clinical note</label>
                                                                        <p class="form-control">{pesentData?.prescription[0]?.prescription}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                }
                                            </>
                                        }

                                        {
                                            status === "Cancelled" &&
                                            <>
                                                {
                                                    pesentData === 0 ?
                                                        pesentData?.id ?
                                                            <div class="">
                                                                <div class="patient_header">
                                                                    <div class="photo">
                                                                        <img src={data[0]?.image || "images/user.png"} />
                                                                    </div>
                                                                    <div class="patient_detials">
                                                                        <h4 class="patient_name">{data[0]?.user_name|| data[0].other_name}</h4>
                                                                        <div class="ptinfo">
                                                                            <h5>Gender: <span>{data[0].user_gender || data[0]?.other_gender}</span></h5>
                                                                        </div>
                                                                        <div class="ptinfo">
                                                                            <h5>Age:<span>{data[0]?.user_age || data[0]?.other_age}</span></h5>
                                                                            <h5>Mobile:<span>+91 {data[0]?.user_mobile ||data[0].other_mobile}</span></h5>
                                                                            <h5>Email:<span>{data[0]?.user_email|| data[0]?.other_email || 'N/A'}</span></h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="patient_body">
                                                                    <div class="appoint_schedule">
                                                                        <div class="dateblock">
                                                                            <div class="date"><span>{(moment(data[0]?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(data[0].appointment_date)).format('MMM')}</div>
                                                                            <div class="datedtl">
                                                                                <h5>Appointment scheduled at  {(moment(data[0].appointment_date)).format("hh:mm A")}</h5>
                                                                                {/* <p>Records for {data[0]?.name || data[0].other_name}</p> */}
                                                                            </div>
                                                                            <div class="datedtl">
                                                                                <Link to></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            : '' :
                                                        <div class="">
                                                            <div class="patient_header">
                                                                <div class="photo">
                                                                    <img src={pesentData?.image || "images/user.png"} />
                                                                </div>
                                                                <div class="patient_detials">
                                                                    <h4 class="patient_name">{pesentData.user_name}</h4>
                                                                    <div class="ptinfo">
                                                                        <h5>Gender: <span>{pesentData?.user_gender|| pesentData.other_gender}</span></h5>
                                                                        {/* <h5>Blood group: <span>“O” +ve</span></h5> */}
                                                                    </div>
                                                                    <div class="ptinfo">
                                                                        <h5>Age:<span>{pesentData.other_age == null ? 'N/A' : pesentData.other_age}</span></h5>
                                                                        <h5>Mobile:<span>+91 {pesentData.user_mobile || pesentData.other_mobile}</span></h5>
                                                                        <h5>Email:<span>{pesentData?.user_email || pesentData?.other_email || 'N/A'}</span></h5>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="patient_body">
                                                                <div class="appoint_schedule">
                                                                    <div class="dateblock">
                                                                        <div class="date"><span>{(moment(pesentData?.appointment_date).utc()).format("DD")}</span>&nbsp;{(moment(pesentData?.appointment_date).utc()).format("MMM")}</div>
                                                                        <div class="datedtl">
                                                                            <h5>Appointment scheduled at {(moment(pesentData.appointment_date)).format("hh:mm A")}</h5>
                                                                            {/* <p>Records for Vivek Singhal</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                        }

                                    </div>











                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </section >

            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}


        </div >
    )
}

export default BookingComponent;