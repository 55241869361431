import React, { useEffect, useState } from 'react'
import HealthIssueListService from '../services/HealthIssueListService';
import VectorImage from "../images/images/Vector.png"
import SimpleAccordion from '../pages/publicPages/SimpleAccordion';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//  FAQs
const FaqsSections = ({fqatype}) => {
    const apicalls = new HealthIssueListService();
    const [faqsList, setFaqsList] = useState([])

    useEffect(() => {
        loadFaqs(fqatype);
    }, [])

    const loadFaqs = () => {
        apicalls.FaqsListFunctions(fqatype).then(res => {
            if (res !== undefined) {
                var result = res
                if (result.success === true) {
                    setFaqsList(result.data)
                }
            }
        })
    }
    return (
        <section className="faq_sec  paddbottom" >
            <div className="container">
                <h4 className="faq_title mb20">FAQs</h4>
                {/* <div className="faq_flex">
                    {faqsList.length > 0 ?
                        faqsList.map(item =>
                        (
                            <div key={item.id + "faq"} className="faq_content">
                                <h4 className="faq_heading"><img src={VectorImage} alt />{item.question}</h4>
                                <p className="faq_text">{item.answer}</p>
                            </div>
                        ))
                        :
                        "No records found"
                    }


                </div> */}
                <div>
                {faqsList.length > 0 ?
                        faqsList.map(item =>
                        (<>
                            <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                           
                            id={item.id} >
                            <Typography  className="faq_content"> <h4 className="faq_heading">{item.question}</h4></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            
                            <p className="faq_text">{item.answer}</p>
                           
                        </AccordionDetails>
                    </Accordion></>
                        ))
                        :
                        "No records found"
                    }

                    
                </div>
                <div className='mb30'>&nbsp;</div>
            </div>
        </section>
    )
}

export default FaqsSections