import React,{useState} from 'react'
import { DoctorUploadImageFor } from '../../services/DoctorOnboarding'
import { toast } from 'react-toastify';
import { uploadImageFor } from '../../enums/Enums';
import { UploadImageFor } from '../../services'


const MedicalRegProof = ({incrementBtn,decrementBtn,setMedical_registration,medical_registration}) => {
    const [image,setImage]=useState()
    const [imagePreview,setImagePreview]=useState(false)
    const [asd,setAsd]=useState()
    const name=localStorage.getItem('user_name')
    const inputImage=()=>{
        let selectPhotos = document.getElementById("file-input");
        selectPhotos.click();
        
    }

    function checkAndFilterImage(e) {
       console.log(e.target.files)
        const selectedFile = e.target.files[0];
        setImage(selectedFile.name)
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, uploadImageFor.medical_doc).then(result => {
            toast.success(result.message)
            if (result.success === true) {
                const profileData = {
                    profile_pic: result.data.filePath
                }
                setMedical_registration(profileData.profile_pic)
                setAsd(profileData.profile_pic)
                var reader = new FileReader();
            reader.onload = function(e) {
                document.getElementById("myModalChild").innerHTML = '<img src="'+e.target.result+'"/>';
            };
            reader.readAsDataURL(selectedFile);
            }
        })
      }
    //  Popup javascript
    var modal = document.getElementById("myModal");

// Get the button that opens the modal
var btn = document.getElementById("myBtn");

// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks the button, open the modal 
const showImage = ()=>{
    setImagePreview(true)
}

// When the user clicks on <span> (x), close the modal
const spanShow = ()=> {
    setImagePreview(false)
}

// When the user clicks anywhere outside of the modal, close it

const incrementfunction=()=>{
    if(medical_registration!==''){
        incrementBtn()
    }
    else {
        toast.error("Please upload Medical Registration")
        
    }
}


    return (
        <div className="row">
            <div className="col-md-12">
                <div className="profile_form1">
                    <div className>
                        <div>
                            <h4 className="mb-30">Medical Registration Proof</h4>
                            <h3 className="drprofile_title">{name}</h3>
                        </div>
                        <div className="upload_box">
                            {
                                image===undefined?<img id='imagePreview' src="images/upload.png" />:
                                <><h4>File </h4><p style={{'color':'green'}}>{image}</p>
                                    <button id="myBtn" className='btn btn-success' onClick={showImage}>View File</button>
                                </>
                            }
                            <p>Please upload your medical registration proof to insure that the ownership of your profile remains with only you.</p>
                            

{/* <!-- The Modal --> */}
<div id="myModal" class="modal" style={{'display':imagePreview?'block':'none'}}>

  {/* <!-- Modal content --> */}
  <div class="modal-content myModalChild">
    <span class="close"onClick={spanShow}>&times;</span>
    <div id='myModalChild' className='onboarding-image-preview'></div>
  </div>

</div>

                            <input
                            id="file-input"
                            type="file"
                            name="name"
                            onChange={checkAndFilterImage}
                            style={{ display: "none" }}
                            />
                            <button onClick={inputImage} className="btn btn-theme btnbig radius">Browse</button>
                        </div>
                        <div className='mb-30 pb-50'>
                            <h4 className=" mt30 mb-20">Acceptable documents:</h4>
                            <p>*Medical registration certificate</p>
                            {/* <p>23WS677 Maharastra Counsil of Indian Medicine</p> */}
                            <p>*Voter Id</p>
                            <p>*Any other Govt. Id</p>
                            <hr style={{ borderTop: '1px solid #eee' }} />
                            {/* <h4 className="mt30 mb-20">Extra proofs required to be uploaded</h4>
                            <p>1.Establishment proof</p> */}
                        </div>
                        <div className='position-abslute-bottom'>
                            <div className="info_box_btn padding-130px">
                                <button type='button' onClick={decrementBtn} className="btnlink">Go back</button>
                                <button type='button' onClick={()=>incrementfunction()} className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MedicalRegProof