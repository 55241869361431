import React, { useEffect } from 'react';
import { Footer, HomepageHeader } from '../../components';
import { useState } from 'react';

const RefundAndPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HomepageHeader />
            <section className="banner_inner lohwheight">
                <div className="container">
                    <h1>Cancellation and Refund Policy</h1>
                </div>
            </section>

            <section class="policy paddtop">
                <div class="container">
                    <h2><u>Cancellation Policy</u></h2>
                    <h3>Teleconsultation Services</h3>

                    <ul>
                        <li><b>Cancellation by the Patient:</b></li>
                        <ul>
                            <li>Teleconsultation appointments can be canceled by the patient up to 4 hours before the scheduled appointment
                                time.</li>
                            <li>To cancel an appointment, the patient must log in to their account on our website/app and navigate to the
                                "My Appointments" section.</li>
                            <li>In case of cancellation within the stipulated time, the full appointment amount will be refunded to the
                                patient's original payment method.</li>
                            <li>If the patient cancels the appointment after the allowed time frame or fails to attend the teleconsultation
                                session, no refund will be provided.</li>
                        </ul>
                    </ul>
                    <ul>
                        <li><b>Cancellation by the Doctor:</b></li>
                        <ul>
                            <li>In the rare event that a doctor needs to cancel a scheduled teleconsultation appointment, the patient will
                                be notified promptly.</li>
                            <li>The patient will have the option to reschedule the appointment with the same doctor or receive a full
                                refund.</li>
                        </ul>
                    </ul>

                    <h3>Homecare Services</h3>

                    <ul>
                        <li><b>Cancellation by the User:</b></li>
                        <ul>
                            <li>Home care service appointments can be canceled by the user up to 8 hours before the scheduled service
                                time.</li>
                            <li>To cancel a service appointment, the user must contact our customer support team.</li>
                            <li>In case of cancellation within the stipulated time, a full refund of the service fee will be provided to
                                the user’s original payment method.</li>
                            <li>If the user cancels the service appointment after the allowed time frame or fails to avail of the service,
                                no refund will be provided.</li>
                        </ul>
                    </ul>

                    <ul>
                        <li><b>Cancellation by Service Provider:</b></li>
                        <ul>
                            <li>In unforeseen circumstances, if we are unable to deliver the scheduled home care service, the user will be
                                notified promptly.</li>
                            <li>The user will have the option to reschedule the service or receive a full refund.</li>
                        </ul>
                    </ul>

                    <h3>Ambulance Services</h3>

                    <ul>
                        <li><b>Cancellation by the User:</b></li>
                        <ul>
                            <li>Ambulance service bookings can be canceled by the user up to 8 hours before the scheduled pickup time.</li>
                            <li>To cancel an ambulance service booking, the user must contact our customer support team.</li>
                            <li>In case of cancellation within the stipulated time, a full refund of the ambulance service fee will be
                                provided to the user’s original payment method.</li>
                            <li>If the user cancels the ambulance service after the allowed time frame or if the ambulance has already
                                been dispatched, no refund will be provided.</li>
                        </ul>
                    </ul>


                    <ul>
                        <li><b>Cancellation by Service Provider:</b></li>
                        <ul>
                            <li>If, due to unforeseen circumstances, we are unable to provide the scheduled ambulance service, the user
                                will be notified promptly.</li>
                            <li>The user will have the option to receive a full refund or reschedule the ambulance service as per their
                                convenience.</li>
                        </ul>
                    </ul>

                    <p><b>Note</b>: The terms and conditions mentioned in this policy are subject to change without prior notice. Users are advised to review the policy periodically for any updates. For any questions or concerns
                        regarding our refund and cancellation policy, please contact our support team. Our support team will be happy to assist you and provide further guidance on the refund
                        process.</p>

                </div>
            </section>

            <section class="policy paddtop">
                <div class="container">

                    <h2><u>Refund Policy</u></h2>
                    <h3>Teleconsultation Services</h3>
                    <ul>
                        <li>Patients are eligible for a refund under the following circumstances:
                            <ul>
                                <li>If the doctor cancels the appointment for any reason.</li>
                                <li>If the patient cancels the appointment within the allowed time frame, as per the cancellation
                                    policy mentioned above.</li>
                                <li>If technical issues from our end prevent the teleconsultation from taking place.</li>
                            </ul>
                        </li>
                        <li>Refunds will be credited to the original payment method used during the booking process.</li>
                    </ul>

                    <h3>Homecare Services</h3>
                    <ul>
                        <li>Users are eligible for a refund under the following circumstances:
                            <ul>
                                <li>If Service provider cancels the home care service appointment for any reason.</li>
                                <li>If the user cancels the appointment within the allowed time frame, as per the cancellation policy
                                    mentioned above.</li>
                            </ul>
                        </li>
                        <li>Refunds will be processed within 8 business days and credited to the original payment method used during the booking process.</li>
                    </ul>

                    <h3>Ambulance Services</h3>
                    <ul>
                        <li>Users are eligible for a refund under the following circumstances:
                            <ul>
                                <li>If Service Provider cancels the ambulance service booking for any reason.</li>
                                <li>If the user cancels the appointment within the allowed time frame, as per the cancellation policy
                                    mentioned above.</li>
                            </ul>
                        </li>
                        <li>Refunds will be processed within 8 business days and credited to the original payment method
                            used during the booking process.</li>
                    </ul>

                    <p><b>Note</b>: The terms and conditions mentioned in this policy are subject to change without prior notice. Users are
                        advised to review the policy periodically for any updates. For any questions or concerns regarding our refund
                        and cancellation policy, please contact our support team. Our support team will be
                        happy to assist you and provide further guidance on the refund process.</p>


                </div>
            </section>


            <Footer />
        </>
    )
}

export default RefundAndPolicy