import React, { useEffect, useState } from 'react'
import { SyncLoader } from "react-spinners";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#256a66;",
};
const ReactSpinners = ({ isLoading }) => {
    const [loading, setLoading] = useState(false)
    const color = "#256a66"
    useEffect(() => {
        setLoading(isLoading)
    }, [loading])
    return (
        <div className="sweet-loading">
            {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
            <input value={color} onChange={(input) => setColor("#256a66")} placeholder="Color of the loader" /> */}
            <SyncLoader color={color} loading={loading} cssOverride={override} size={15} />
        </div>
    )
}

export default ReactSpinners;