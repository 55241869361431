import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { FaqsSections, Footer, HomepageHeader } from '../../components'
import CheckImg from "../../images/images/check.png"
import SelectSpeciallityImg from "../../images/howItWorks/hiw1.png"
import AudioVideoImg from "../../images/howItWorks/hiw2.png"
import getDigitalPrescriptionImg from "../../images/howItWorks/hiw3.png"
import AmbulanceAls from "../../images/ambulance/abl1.png"
import AmbulanceBls from "../../images/ambulance/abl2.png"
import AmbulancePts from "../../images/ambulance/abl3.png"
import AmbulanceImg from "../../images/ambulance/ambulance.png";
import LocationIcon from '../../images/home/map-pin.png'
import SearchIcon from '../../images/home/search.png'
import LocationsList from '../../components/locations/LocationsList'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import { err_10DigitMobile, err_NotName, HtmlErrorMessage } from '../../enums/Enums'
import moment from 'moment'


const regMobile = /^\d+$/;
const regHtml = /<(.|\n)*?>/g;

const Ambulance = () => {
    const [search_key, setsearch_key] = useState("");
    const [search_keyCity, setsearch_keyCity] = useState("");
    const birthdayTimeRef = useRef(null);
    const [makeActive, setMakeActive] = useState("Advance life support")
    const [ambulance_type, setambulance_type] = useState("")
    const [pickUp_address, setpickUp_address] = useState("")
    const [drop_address, setdrop_address] = useState("")
    const [isNow, setisNow] = useState(true)
    const [isLater, setIsLater] = useState(false)
    const navigate = useNavigate()

    var currentTime = moment();
    var newTime = currentTime.add(10, 'minutes');
    var formattedTime = newTime.format('HH:mm:ss');

    const [appointmentDate, setappointmentDate] = useState(new Date().toISOString().slice(0, 10))
    const [appointmentTime, setappointmentTime] = useState(formattedTime)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const navigateTo = () => {

        return navigate('/advance-support-ambulance', {
            state: {
                ambulance_typeState: ambulance_type,
                pickUp_addressState: pickUp_address,
                drop_addressState: drop_address,
                isNowState: isNow,
                appointmentDateState: appointmentDate,
                appointmentTimeState: appointmentTime,
                pageType: "ambulance"
            }

        })

    }

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };
    const isBookAndPayEnabled = ambulance_type && pickUp_address && drop_address && appointmentDate && appointmentTime;


    useEffect(() => {
        selectDate();
    }, [!isNow]);

    const selectDate = () => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var today = tomorrow.toISOString().split('T')[0];

        if (birthdayTimeRef.current) {
            birthdayTimeRef.current.setAttribute('min', today);
        }
    };

    const changeIslater = () => {
        setisNow(false)
        setIsLater(true)
        setappointmentDate("")
        setappointmentTime("")
    }

    const changeIsNow = () => {
        setisNow(true)
        setIsLater(false)
        setappointmentDate(new Date().toISOString().slice(0, 10))
        setappointmentTime(formattedTime)
    }

    /* useEffect(() => {
        if(!isNow){
            setappointmentDate("")
            setappointmentTime("")
        }
        setIsLater(false)
      }, [isLater]);  */


    return (
        <div className='outerhomepage'>
            <HomepageHeader />

            {/* --Inner Page Banner-- */}
            <section className="banner_inner">
                <div className="container">
                    <div className="ambulance_box">

                        <div className='abulance'>

                            <h6>Book An ambulance</h6>
                            <form className=''>
                                <div className='ambulance-flex-filter'>
                                    <div className='flex-type'>
                                        <div className='image-icon'>
                                            <img src="/images/bls-removebg-preview.png" alt='' width="100%" />
                                        </div>
                                    </div>
                                    <div className='flex-type-concern'>
                                        <label htmlFor="InputName" className='ambulance-label'>Ambulance Type</label>
                                        <select name="ambulance_type" class="form-control" value={ambulance_type} onChange={(e) => setambulance_type(e.target.value)}>
                                            <option value="">Select Ambulance Type</option>
                                            <option value="BLS">Basic Life Support</option>
                                            <option value="ALS">Advance Life Support</option>
                                            <option value="PTS">Patient Transport Service</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='ambulance-flex-filter'>
                                    <div className='flex-type'>
                                        <div className='image-icon'>
                                            <img src="/images/Screenshot_3-removebg-preview.png" alt='' width="100%" />
                                        </div>
                                    </div>
                                    <div className='flex-type-concern'>
                                        <div class="form-group">
                                            <label htmlFor="InputName" className='ambulance-label'>Pick-up Address</label>
                                            <input type="text" name="pickup_address" class="form-control" id="pick_up" placeholder="Pick-Up Address" onChange={(e) => setpickUp_address(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className='ambulance-flex-filter'>
                                    <div className='flex-type'>
                                        <div className='image-icon'>
                                            <img src="/images/Screenshot_4-removebg-preview.png" alt='' width="100%" />
                                        </div>
                                    </div>
                                    <div className='flex-type-concern'>
                                        <div class="form-group">
                                            <label htmlFor="InputName" className='ambulance-label'>Distination Address</label>
                                            <input type="text" name="drop_address" class="form-control" id="drop_add" placeholder="Drop Address" onChange={(e) => setdrop_address(e.target.value)} />
                                        </div>

                                    </div>
                                </div>
                                <div className=''>
                                    <div className='flex-type'>
                                        <div className='image-icon'>

                                        </div>
                                    </div>
                                    <div className='flex-type-concern'>
                                        <div class="form-group">
                                            <label htmlFor="InputName" className='ambulance-label'>Time</label>
                                            <div className='border-box-time'>
                                                <div className='flex-ambulane-radio-btn'>
                                                    <div className='radio-btn'>
                                                        <input type='radio' className='radio-button' name='isNow' onChange={() => changeIsNow()} checked={isNow === true} />
                                                        <label className='labale-radio'>Now</label>
                                                    </div>
                                                    <div className='radio-btn'>
                                                        <input type='radio' className='radio-button' name='isNow' onChange={() => changeIslater()} checked={isNow === false} />
                                                        <label className='labale-radio'>Later</label>
                                                    </div>
                                                </div>
                                                {!isNow ?
                                                    <div class="">
                                                        <div class="date-time-ambulance">
                                                            <h5>Date</h5>
                                                            <input type="date" id="birthdaytime" ref={birthdayTimeRef} name='appointmentDate' onChange={(e) => setappointmentDate(e.target.value)} />
                                                        </div>
                                                        <div class="date-time-ambulance">
                                                            <h5>Time</h5>
                                                            <input type="time" id="appt" name='appointmentTime' onChange={(e) => setappointmentTime(e.target.value)} />
                                                        </div>
                                                    </div> : ""
                                                }
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='search-btn-ambulance'>
                                <div className={`btn btn_fill btn-type-custum ${!isBookAndPayEnabled ? 'disabled' : ''}`} onClick={() => { if (isBookAndPayEnabled) navigateTo(); }}>Search</div>
                            </div>

                        </div>

                        {makeActive === "Advance life support" &&
                            <div className="ambulance_content">
                                <div className="ambulance_img">
                                    <img src={AmbulanceImg} />
                                </div>
                            </div>
                        }


                    </div>
                </div>
            </section>


            {/* How it works*/}
            <section className="how_it_works mt-5">
                <div className="container">
                    <div className="paddtop paddbottom">
                        <h3 className="heading2 text-center">Types of ambulances</h3>
                        <p className='text-center'>Types of ambulances you can book through Fastrack healthcare platform</p>
                        <div className="hiw_section">
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src="/images/two-ambulance.png" alt className='ambulance-image-backbg' /></div>
                                <p className="description">Basic life support</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src="/images/two-ambulance.png" alt className='ambulance-image-backbg' /></div>
                                <p className="description">Advanced life support</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src="/images/two-ambulance.png" alt className='ambulance-image-backbg' /></div>
                                <p className="description">Transport Ambulance</p>
                            </div>

                        </div>
                    </div>
                </div>




            </section>
            <article>
                <div className='type-abulance-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <div className='type-ambulance-img'>
                                    <img src='/images/ambulance-image-ab.jpg' width="100%" />
                                </div>
                            </div>
                            <div className='col-lg-1'>

                            </div>
                            <div className='col-md-6'>
                                <div className='type-ambulance-content'>
                                    <h3>Types of ambulances</h3>

                                    <p>A large Vehicle, Advance Life Support is used for
                                        critical patients. The ambulance consists of an array of
                                        ICU equipment. it has an inbuilt ventilator with oxygen
                                        cylinder,BP Monitor and specialized medication
                                        required
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </article>
            {/* Benefits */}
            <section className="benefit_sec paddtop paddbottom bg_light padding-all">
                <div className="container">
                    <div className="row mb20">
                        <div className="col-lg-8 col-sm-8">
                            <div className="benefit_content">
                                <h4 className="benefit_title">FastTrack Emergency Medical Services</h4>
                                <p className="para2">We are a trusted provider of emergency medical transportation in India. Our team of highly skilled paramedics and drivers work tirelessly to ensure that patients receive prompt, reliable, and compassionate care when they need it the most.  </p>
                                <p className="para2">Whether you need BLS (Basic Life Support), ALS (Advanced Life Support), or simple PTS (Patient Transport Service), our team is equipped and ready to provide the medical transportation you need. Our commitment to your health and well-being is at the heart of everything we do. Choose Fasttrack Ambulance Service for safe, reliable, and compassionate medical transportation.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div className="benefit_list">
                                <p><img src={CheckImg} alt />Patient First Policy</p>
                                <p><img src={CheckImg} alt />Fastest Response Time</p>
                                <p><img src={CheckImg} alt />Huge Ambulance Network</p>
                                <p><img src={CheckImg} alt /> 24/7 Support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FaqsSections fqatype="Ambulance" />

            <Footer />
        </div>
    )
}

export default Ambulance