import React from "react";
import {
  Homepage,
  Login, OtpScreen,
  FillYourPersonalDetails, LandingPage, About, SetPassword,
  Teleconsultation, HomeCare, Ambulance, SearchFilterPage,
  ShowSlotsProfileAppoinment, ContactUs, PartnerWithUs,
  BookingDetailsFillForm, ViewUpdateProfile, MyDoctors,
  MyNurses, PaymentSuccessFail,
  MyCompletedBookings, MyPaymentHistory, MedicalHistoryAndNotes,
  MyMedicalReports, MyFeedbackQuery, MyBookings, AccountTeleconsultation,
  AccountHomecare, AccountAmbulance, AdminBooking, AdminDashBoard, DoctorBookingApproval,AggregatorBooking,
  MyPatient,MyPatientProfileView, UserDashBoard
} from "./pages"
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import ServicesProviderStarter from "./pages/privatePages/ServicesProviderStarter";
import TeleconsultationRegistration from "./pages/privatePages/TeleconsultationRegistration";
import DoctorViewProfileDetails from "./components/Admin/component/DoctorViewProfileApproval";
import ApplicationStatusView from "./components/ApplicationStatusView";
import HomeCareRegistration from "./pages/privatePages/HomeCareRegistration";
import HomeCareApplicationdetails from "./pages/AdminPage/HomeCareApplicationDetails";
import NurseViewProfileDetails from "./components/Admin/component/HomeCareProfileApproval";
import VideoCall from "./pages/publicPages/VideoCall";
import ServicesProvider from "./pages/privatePages/ServiceProvider";
import NewDashBoard from "./pages/newdashboard/NewDashBoard";
import ShowData from "./pages/publicPages/ShowData";
import ProfileAll from "./pages/privatePages/ProfileAll";
import Bookingssummary from "./pages/Bookingssummary";
import Eprecription from "./pages/E-prescription/E-prescription";
import Revenue from "./pages/Revenue/Revenue";
import Applydoctor from "./pages/ApplyDoctor";
import ServiceProvider from "./pages/serviceprovider/ServiceProvider";
const Dashboard =React.lazy(()=>import("./pages/privatePages/Dashboard")) ;
import ProviderDashboard from "./pages/serviceproviderdashboard/providerdashboard";
import Spincome from "./pages/SPincom/Spincome";
import MyBookingsServiceProvider from "./pages/privatePages/mydetails/MyBookingsServiceProvider";
import Eprecriptiondownload from "./pages/E-prescription/downloadeprescription";
import PrivacyPolicy from "./pages/publicPages/PrivacyPolicy";
import TermsConditions from "./pages/publicPages/TermsConditions";
import Invoicedownload from "./pages/E-prescription/downloadinvoice";

import HeaderLogin from "./components/headers/HeaderLogin";
import NursingHomeCare from "./pages/publicPages/nursing-home-care";
import NrusingBillingDetails from "./pages/publicPages/nursing-home-care-bing";
import NursingBillingHours from "./pages/publicPages/nursing-home-care-hours";
import BookingsVisitsummary from "./pages/Bookingvisitsummary";
import AdvanceSupportAmbulance from "./pages/publicPages/Advance-support-ambulance";
import BookAmbulance from "./pages/publicPages/Bookambulance";
import BookingSummaryAmbulance from "./pages/publicPages/Bookingsummaryabulance";
import DriverRegistration from "./pages/publicPages/Driverregistration";
import RefundAndPolicy from "./pages/publicPages/refund-policy";
import Pricing from "./pages/publicPages/pricing";

// Routes define here 
// descrption:- last updated 15-11-22
// descrption:- By Mayur
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="login" element={<Login />} />
        <Route path="validate-otp" element={<OtpScreen />} />
        <Route path="fill-details" element={<FillYourPersonalDetails />} />
        <Route path="landing" element={<LandingPage />} />
        <Route path="apply-doctor" element={<Applydoctor/>}/>
        <Route path="about" element={<About />} />
        <Route path="set-password" element={<SetPassword />} />
        <Route path="tele-consultation" element={<Teleconsultation />} />
        <Route path="homecare" element={<HomeCare />} />
        <Route path="nursing-home-care" element={<NursingHomeCare />} />
        <Route path="nursing-home-care-bing" element={<NrusingBillingDetails />} />
        <Route path="nursing-home-care-hours" element={<NursingBillingHours />} />

        <Route path="ambulance" element={<Ambulance />} />
        <Route path="driver-registration" element={<DriverRegistration />} />
        <Route path="advance-support-ambulance" element={<AdvanceSupportAmbulance />} />
        <Route path="book-ambulance" element={<BookAmbulance />} />
        <Route path="book-summary-ambulance/:id" element={<BookingSummaryAmbulance />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="search" element={<SearchFilterPage />} />
        <Route path="partner-with-us" element={<PartnerWithUs />} />
        <Route path="show-profile-and-slots" element={<ShowSlotsProfileAppoinment />} />
        <Route path="payment-status/:orderid" element={<PaymentSuccessFail />} />
        <Route path="booking-fill-form" element={<BookingDetailsFillForm />} />
        <Route path="my-profile" element={<ViewUpdateProfile />} />
        <Route path="my-dashboard" element={<UserDashBoard/>}/>
        <Route path="show-result" element={<ShowData/>}/>
        <Route path="bookings-summary/:id" element={<Bookingssummary/>}/>
        <Route path="bookings-visit-summary/:id" element={<BookingsVisitsummary/>}/>
        <Route path="providerdashboard" element={<ProviderDashboard/>}/>
        <Route path="Spincome" element={<Spincome/>}/>
        <Route path="Eprecriptiondownload" element={<Eprecriptiondownload/>}/>

        <Route path="history-my-doctors" element={<MyDoctors />} />
        <Route path="history-my-nurses" element={<MyNurses />} />
        <Route path="history-my-payments" element={<MyPaymentHistory />} />
        <Route path="history-my-medical-history-notes" element={<MedicalHistoryAndNotes />} />
        <Route path="history-my-medical-reports" element={<MyMedicalReports />} />
        <Route path="history-my-feedback-query" element={<MyFeedbackQuery />} />
        <Route path="history-my-completed-booking" element={<MyCompletedBookings />} />
        <Route path="E-precription" element={<Eprecription />} />
        <Route path="Revenue" element={<Revenue />} />
        <Route path="ServiceProvider" element={<ServiceProvider />} />
        <Route path="downloadinvoice" element={<Invoicedownload />} />

        {/* My Accounts */}
        <Route path="accounts-bookings" element={<MyBookings />} />
        <Route path="serviceprovider-bookings" element={<MyBookingsServiceProvider />} />
        <Route path="accounts-teleconsultaion" element={<AccountTeleconsultation />} />
        <Route path="accounts-homecare" element={<AccountHomecare />} />
        <Route path="my-patient" element={<MyPatient />} />
        <Route path="my-patient-profile" element={<MyPatientProfileView />} />
        <Route path="accounts-ambulance" element={<AccountAmbulance />} />
        {/* <Route path="dashboard" element={<Dashboard />} /> */}

        {/* Service Provider */}
        <Route path="be-service-provider" element={<ServicesProviderStarter />} />
        <Route path="want-to-be-service-provider" element={<ServicesProvider />} />
        <Route path="service-provider-teleconsultation" element={<TeleconsultationRegistration />} />
        <Route path="service-provider-homecare" element={<HomeCareRegistration/>}/>
        <Route path='Aggregator-Booking-details' element={<AggregatorBooking/>}/>
        <Route path='Application-sent' element={<ApplicationStatusView/>}/>
        <Route path="/video-call" element={<VideoCall />} />
        <Route path="doctor-profile" element={<ProfileAll/>}/>

        {/* Admin Panel */}
        <Route path='Admin-panel' element={<AdminBooking/>}/>
        <Route path='AdminDashBoard' element={<AdminDashBoard/>}/>
        
        <Route path='doctor-Application-details' element={<DoctorBookingApproval/>}/>
        <Route path='doctor-profile-details' element={<DoctorViewProfileDetails/>}/>
        <Route path='homecare-Application-details' element={<HomeCareApplicationdetails/>}/>
        <Route path="nurse-profile-details" element={<NurseViewProfileDetails/>}/>

        {/* new dashboard */}
        <Route path="newdashboard" element={<NewDashBoard/>}/>
        <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="refund-policy" element={<RefundAndPolicy/>}/>
        <Route path="pricing" element={<Pricing/>}/>
        <Route path="terms-and-conditions" element={<TermsConditions/>}/>
        <Route path="hedaerlogin" element={<HeaderLogin/>}/>
       
      </Routes>


    </Router>
  );
}


export default App;
