import { CropLandscapeOutlined } from '@mui/icons-material';
import axios from 'axios';
import * as process from "process";
import { toast } from 'react-toastify';


class DriverServices {
    constructor() {
        this.state = {
            ApibaseURL: process.env.REACT_APP_API_BASE_URL,
            AccessToken: localStorage.getItem('token'),
        }
    }

    async addDriver(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/ambulanceDriver`, data, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async getDriverListOfUser(id, search_key) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}/ambulanceDriver/user/${id}?search_key=${search_key}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async getDriverDetailsByid(id) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}/ambulanceDriver/${id}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async updateDriver(data,id) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.put(`${this.state.ApibaseURL}/ambulanceDriver/${id}`,data, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async deleteDriver(id) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.delete(`${this.state.ApibaseURL}/ambulanceDriver/${id}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

}
export default DriverServices;