import { Token } from '@mui/icons-material'
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react'
import { HomepageHeader } from '../../components'
import ConsultationFeeGettingStatrtedPatient from '../../components/teleconsultation_reg/ConsultationFeeGettingStatrtedPatient'
import DaysGettingStatrtedPatient from '../../components/teleconsultation_reg/DaysGettingStatrtedPatient'
import DoctorRegistration from '../../components/teleconsultation_reg/DoctorRegistration'
import EducationReg from '../../components/teleconsultation_reg/EducationReg'
import EstablishmentProof from '../../components/teleconsultation_reg/EstablishmentProof'
import IdentityProof from '../../components/teleconsultation_reg/IdentityProof'
import LocationGettingStatrtedPatient from '../../components/teleconsultation_reg/LocationGettingStatrtedPatient'
import MedicalRegProof from '../../components/teleconsultation_reg/MedicalRegProof'
import { ProfileService, UploadImageFor } from '../../services'
import { DoctorRegistered } from '../../services/DoctorOnboarding'
import { useNavigate } from 'react-router';
import Signature from '../../components/teleconsultation_reg/Signature';

const TeleconsultationRegistration = () => {
    const [token, setToken] = useState()
    const [headline, setheadline] = useState("")
    const [stepCount, setStepCount] = useState(1)
    const [description, setDescription] = useState('')
    const [gender, setGender] = useState('')
    const [city, setCity] = useState('')
    const [specializationsList, setSpecializationsList] = useState([])
    const [qualification, setQualification] = useState('')
    const [experience, setExperience] = useState('')
    const [College, setCollege] = useState('')
    const [completion, setCompletion] = useState('')
    const [identity, setIdentity] = useState()
    const [establishment_proof, setEstablishment_proof] = useState()
    const [medical_registration, setMedical_registration] = useState()
    const [signature, setSignature] = useState()
    const [consultation_fees, setConsultation_fees] = useState()
    const [followup_fees, setfollowup_fees] = useState()
    const [mobile, setMobile] = useState()
    const [email, setEmail] = useState()
    const [address, setAddress] = useState()
    const [bio, setBio] = useState('')
    const [is_video_consultancy, setIs_video_consultancy] = useState(false)
    // const [specializations,setSpecializations]=useState({})
    const [availability, setAvailability] = useState([])
    const [day, setDay] = useState()
    const [start_time, setStart_time] = useState()
    const [end_time, setEnd_time] = useState()
    const [fee, setFee] = useState(1234)
    // let token=localStorage.getItem('token')
    const navigateTo = useNavigate()
    let specializations = []
    // let specialization = ''
    for (let i = 0; i < specializationsList.length; i++) {
        specializations.push({ type: specializationsList[i].value, specialization_id: specializationsList[i].id })
    }

    const doctorData = {
        gender, city, followup_fees, qualification, description, experience, College, completion, consultation_fees, bio, is_video_consultancy, specializations, signature, availability, mobile, email, address, identity, establishment_proof, medical_registration, headline
    }

    const incrementFunc = () => {
        setStepCount((stepCount) => 1 + stepCount)
    }
    const decrementFunc = () => {
        setStepCount((stepCount) => stepCount - 1)
    }

    const apiFunction = new ProfileService()
    const [userProfile, setUserProfile] = useState({})
    const loadUserProfileData = () => {
        apiFunction.LoadProfileData().then(result => {           
            if (result.success === true) {
                setUserProfile(result.data)
            }
        })
    }

    useEffect(() => {
        loadUserProfileData()

        setToken(localStorage.getItem('token'))
    }, [])
    // Adding Avail
    const DoctorRegistrationCall = () => {
        setAvailability([{ day, start_time, end_time, fee }])

        try {
            alert(token)

            if (token != null) {
               
                DoctorRegistered(doctorData, token).then((res) => {
                   
                    if (res != undefined) {
                        if (res.success === true) {
                            toast.success('Your profile has been compleated, Please wait for approval')
                            navigateTo('/Application-sent')
                        }
                    }



                })
            }

        } catch (error) {
            toast.error(error)
        }


    }
  
    return (
        <div className="outerhomepage background-color-F7F7F7">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom paddingbottom_90px">
                <div className="content-wrapper marginleft0">
                    {/* Main content */}
                    <section className="content content-body">
                        {/* Main row */}
                        <div className="container">
                            <div className="box box-solid">

                                {/* Progres bar */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* <ol className="steps">
                                            <li className="step is-complete" data-step={1}>
                                                <span className="step_name">Profile details</span>
                                            </li>
                                            <li className="step is-active" data-step={2}>
                                                <span className="step_name">Profile verification</span>
                                            </li>
                                            <li className="step" data-step={3}>
                                                <span className="step_name">Start getting paitents</span>
                                            </li>
                                        </ol> */}
                                        {/* <div className="cont">
                                            <progress id="progress-bar" min="1" max="100" value="0"></progress>
                                            <span className="first step_name border-change"></span>
                                            <span className="second step_name border-change"></span>
                                            <span className="third step_name"></span>
                                        </div> */}
                                    </div>
                                </div>


                                {/* --- Registration Forms --- */}

                                {stepCount === 1 ?
                                    <DoctorRegistration setDescription={setDescription} description={description} setGender={setGender} gender={gender} setSpecializationsList={setSpecializationsList} specializationsList={specializationsList} setCity={setCity} city={city} decrementBtn={decrementFunc} incrementBtn={incrementFunc} profileData={userProfile} token={token} headline={headline} setheadline={setheadline} />
                                    : stepCount === 2 ?
                                        <EducationReg setCollege={setCollege} College={College} setCompletion={setCompletion} completion={completion} qualification={qualification} setQualification={setQualification} experience={experience} setExperience={setExperience} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                        : stepCount === 3 ?
                                            <IdentityProof token={token} setIdentity={setIdentity} identity={identity} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                            : stepCount === 4 ?
                                                <MedicalRegProof setMedical_registration={setMedical_registration} medical_registration={medical_registration} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                : stepCount === 5 ?
                                                    <EstablishmentProof establishment_proof={establishment_proof} setEstablishment_proof={setEstablishment_proof} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                    : stepCount === 6 ?
                                                        <Signature signature={signature} setSignature={setSignature} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                        : stepCount === 7 ?
                                                            <LocationGettingStatrtedPatient profileData={userProfile} setAddress={setAddress} address={address} mobile={mobile} setMobile={setMobile} email={email} setEmail={setEmail} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                            : stepCount === 8 ?
                                                                <DaysGettingStatrtedPatient setDay={setDay} setStart_time={setStart_time} setEnd_time={setEnd_time} setAvailability={setAvailability} decrementBtn={decrementFunc} incrementBtn={incrementFunc} />
                                                                : stepCount === 9 ?
                                                                    <ConsultationFeeGettingStatrtedPatient setConsultation_fees={setConsultation_fees} DoctorRegistrationCall={DoctorRegistrationCall} setFee={setFee} decrementBtn={decrementFunc} incrementBtn={incrementFunc} followup_fees={followup_fees} setfollowup_fees={setfollowup_fees} />
                                                                    :
                                                                    <>
                                                                    </>
                                }
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </section>
                    {/* /.content */}
                </div>
            </section >
        </div >

    )
}

export default TeleconsultationRegistration