import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { HomepageHeader, SidebarUserPanel } from '../../components/index';
import { Change_to_12HourFormat } from '../../helpers/Change_to_12HourFormat';
import moment from 'moment'
import { ServicesProviderService } from '../../services'
import { BookingServices } from '../../services';
import { saveAs } from "file-saver";
import { ThreeDots } from 'react-loader-spinner'
import axios from 'axios';
import { Scheduler } from "@aldabil/react-scheduler";
import { Link } from 'react-router-dom';
import Sidebarmobile from '../../components/user/Sidebarmobile';
const baseurl = process.env.REACT_APP_API_BASE_URL
const token = localStorage.getItem("token")
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router'

import Eprecriptiondownload from '../E-prescription/downloadeprescription';
const UserDashBoard = () => {
    const navigate = useNavigate()



    const apiFunction = new ServicesProviderService()
    const apiFunction3 = new BookingServices()
    const service = JSON.parse(localStorage.getItem("services"));
    const [statuschng, setstatuschng] = useState("Cancelled")
    const [type, setType] = useState("user");
    const date = "2022-08-10"//moment(new Date()).format("YYYY-MM-DD")
    const [dashboard, setDashboard] = useState([]);
    const [users, setUsers] = useState([]);
    const [EVENTS, setEvents] = useState([])
    const [todayappointment, settodayappointment] = useState([])
    const [recentPrescription, setrecentPrescription] = useState([])
    const [recentReports, setrecentReports] = useState([])
    const [upcomingBookings, setupcomingBookings] = useState([])
    const [isLoaded, setIsLoaded] = useState(true)
    const [prescriptionid, setPrescriptionid] = useState("");
    const [stepCompleted, SetstepCompleted] = useState("")
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [bookingId, setBookingId] = useState("")
    const [homecareName, setHomecareName] = useState("")
    const [appointmentDate, setAppointmentDate] = useState("")
    const [appointmentTime, setAppointmentTime] = useState("")
    const [bookingItemId, setBookingItemId] = useState("")
    const [scheduleType, setScheduledType] = useState("")
    const [nurseUserId, setNurseUserId] = useState("")
    const [remaining_amount, setRemainingAmount] = useState("")
    const [availablity_id, setAvailablity_id] = useState("")
    const [appointmentTimeList, setAppointmentTimeList] = useState([])


    const [userIcon, SetuserIcon] = useState()

    useEffect(() => {
        loadUserProfileData();
    }, [isLoaded])


    useEffect(() => {
        if (type && type !== "") {
            loadDashboard();

        }

    }, [type])

    let todayDate = new Date()
    let a = new Date((todayDate.getMonth() + 1) + "-" + (todayDate.getUTCDate() + 1) + "-" + todayDate.getUTCFullYear())
    let today = (moment(a).utc()).format(`DD/MM/YYYY`)

    let token = localStorage.getItem('token')
    const loadDashboard = () => {
        setIsLoaded(false)
        if (token !== null || token !== undefined) {
            apiFunction.loadDashboardApi(date, token).then(result => {
                if (result !== undefined) {
                    if (result.data.success === true) {
                        setDashboard(result.data.data)
                        settodayappointment(result.data.data.todayAppointmentTeleConsult)
                        setrecentPrescription(result.data.data.recentPrescription)
                        setrecentReports(result.data.data.recentReports)
                        setupcomingBookings(result.data.data.upcomingBookingsTeleconsult)
                        setIsLoaded(true)
                        // setUsers(result.data.users);
                    }
                }
            })
        }
    }

    const loadUserProfileData = async () => {
        try {

            var config = {

                headers: {
                    'authorization': token
                }
            };
            const result = await axios.get(`${baseurl}users/me`, config)

            SetstepCompleted(result.data.data.completed_step)

            if (result.data.data.completed_step !== 4) {
                navigate('/fill-details')
            }


        } catch (error) {
            toast.error(error)
        }
    }

    const saveFile = (link) => {
        saveAs(
            link,
            "report.pdf"
        );
    };

    const bookingcancel = async (id, service) => {
        try {
            const data = {
                status: statuschng,
            };
            const config = {
                headers: {
                    authorization: token,
                    'Content-Type': 'application/json',
                },
            };
            if (service === 'Doctor') {
                var result = await axios.put(`${baseurl}bookings/changebookingstatus/${id}`, data, config);
            }
            else if (service === 'Nurse') {
                var result = await axios.put(`${baseurl}homecareBooking/bookingItem/${id}`, data, config);
            }
            else if (service === 'Ambulance') {
                var result = await axios.put(`${baseurl}ambulances/bookings/${id}`, JSON.stringify(data), config)
            }

            if (result !== null && result !== undefined) {
                toast.error('Booking Cancelled Successfully');
                loadDashboard();
            }
        } catch (error) {
            // Handle error
        }
    };


    const getLocalToUTC = (date) => {
        const finalDate = new Date(date);
        const utcDate = new Date(finalDate.getTime() - (finalDate.getTimezoneOffset() * 60000));
        return utcDate;
    }
    const [isVisible, setIsVisible] = useState(false);

    const scheduleHomecareVisit = async () => {
        try {
            var data = {
                "booking_id": bookingId,
                "appointment_date": appointmentDate.slice(0, 10),
                "appointment_time": appointmentTime + ":00",
                "availablity_id": availablity_id
            };

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.put(`${baseurl}homecareBooking/schedule/${bookingItemId}`, JSON.stringify(data), config)
            if (result != null && result !== undefined) {
                toast.success("Booking scheduled sucessfully")
                loadDashboard()
                const modal = document.getElementById('myModal');
                if (modal) {
                    const bootstrapModal = window.jQuery(modal);
                    bootstrapModal.modal('hide');
                }
            }

        } catch (error) {
        }

    }

    const rescheduleHomecareBooking = async () => {
        try {
            var data = {
                "booking_id": bookingId,
                "appointment_date": appointmentDate.slice(0, 10),
                "appointment_time": appointmentTime + ":00",
                "availablity_id": availablity_id
            };

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.put(`${baseurl}homecareBooking/reschedule/${bookingItemId}`, JSON.stringify(data), config)
            if (result != null && result !== undefined) {
                toast.success("Booking Rescheduled sucessfully")
                loadDashboard()
                const modal = document.getElementById('myModal');
                if (modal) {
                    const bootstrapModal = window.jQuery(modal);
                    bootstrapModal.modal('hide');
                }
            }

        } catch (error) {
        }

    }

    const handleBookAndPay = () => {
        if (scheduleType === 'Pending') {
            scheduleHomecareVisit();
        } else {
            rescheduleHomecareBooking();
        }
    };

    const onClickHomecare = (booking_id, name, id, schedule_type, nurseId) => {
        setBookingId(booking_id)
        setHomecareName(name)
        setBookingItemId(id)
        setScheduledType(schedule_type)
        setNurseUserId(nurseId)

    }


    // For Paytm Stringify input field values
    function stringifyValue(val) {
        if (isObj(val) && !isDate(val)) {
            return JSON.stringify(val)
        } else {
            return val
        }
    }

    function isDate(val) {
        // Cross realm comptatible
        return Object.prototype.toString.call(val) === '[object Date]'
    }

    function isObj(val) {
        return typeof val === 'object'
    }

    // Making a custom form by this code
    function buildForm({ action, params }) {
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', action)

        Object.keys(params).forEach(key => {
            const input = document.createElement('input')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', key)
            input.setAttribute('value', stringifyValue(params[key]))
            form.appendChild(input)
        })
        return form
    }
    function goToPaymentPage_Post_Information(details) {
        const form = buildForm(details)
        document.body.appendChild(form)
        form.submit()
        form.remove()
    }

    const payRemainingAmount = async (id, remainingAmount) => {
        localStorage.setItem("remaining_amount", remainingAmount)

        const merchantId = process.env.REACT_APP_PayTM_MID
        const paytmOpenPaymentPageUrl = "https://securegw.paytm.in/theia/api/v1/showPaymentPage"

        try {

            localStorage.setItem("BookingId", id)
            const datanew = {
                amount: remainingAmount,
                requestfrom: "WEB",
                booking_id: id,
            }
            apiFunction3.PaymentOrder(datanew).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {

                        const transaction_token = result.data.transaction_token
                        const order_id = result.data.order_id

                        var information = {
                            action: `${paytmOpenPaymentPageUrl}?mid=${merchantId}&orderId=${order_id}`,
                            params: {
                                mid: merchantId,
                                orderId: order_id,
                                txnToken: transaction_token,
                                TXN_AMOUNT: remaining_amount / 100,
                                CURRENCY: "INR",
                                WEBSITE: "WEBSTAGING"
                            }
                        }

                        localStorage.setItem("paymentAmount", remaining_amount)
                        // this is function that show's paytm gate way
                        goToPaymentPage_Post_Information(information)

                    }
                }
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const getAppointmentTimeList = async (appointment_date1) => {
        setAppointmentDate(appointment_date1)
        var config = {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },
        };

        const result = await axios.get(`${baseurl}/homecareAvailablity/user/${nurseUserId}/date/${appointment_date1}`, config)
        if (result !== undefined) {           
            if (result.data.success === true) {
                setAppointmentTimeList(result.data.data)
            }
        }
    }

    const getAvailabilityId = (e) => {
        const id2 = e.target.value.split("-")[1]
        const appointment_time1 = e.target.value.split("-")[0]
        setAvailablity_id(id2)
        //set appointment time of getting value of appointment_time1
        setAppointmentTime(appointment_time1)
    }

    const RequestOrComplete = async (id, serviceStatus, service) => {

        try {
            var data = {
                "service_status": serviceStatus
            };
            var dataForNurse = {
                "item_service_status": serviceStatus,
            }
            let result

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            if (service === 'Nurse') {
                result = await axios.put(`${baseurl}homecareBooking/serviceStatus/${id}`, JSON.stringify(dataForNurse), config)
            }
            else if (service === 'Ambulance') {
                result = await axios.put(`${baseurl}ambulances/bookings/serviceStatus/${id}`, JSON.stringify(data), config)
            }

            if (result != null && result !== undefined) {
                if (serviceStatus === "Completed")
                    toast.success("Booking Completed Sucessfully")
                else if (serviceStatus === "Requested")
                    toast.success("Booking completion Requested Sucessfully")
                else if (serviceStatus === "Rejected")
                    toast.success("Booking Rejected Sucessfully")
                loadDashboard()
            }
        } catch (error) {
        }

    }


    useEffect(() => {
        selectDate()
    }, [appointmentDate])

    const selectDate = (event) => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var today = tomorrow.toISOString().split('T')[0];
        document.getElementById("birthdaytime1").setAttribute("min", today);
    }

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };
    const isBookAndPayEnabled = appointmentDate && appointmentTime && isCheckboxChecked;

    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        {/* <SidebarUserPanel />  */}
                        <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                            {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
                        </button>
                        {isVisible && (
                            <div>
                                <Sidebarmobile />
                            </div>
                        )}

                        <SidebarUserPanel />
                        <div className="right_panel table-responsive">
                            {isLoaded ?
                                <div class="content-wrapper" style={{ marginLeft: 0 }}>

                                    <section class="content content-body">

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="content-header">
                                                    <h1 class="pull-left">
                                                        Dashboard
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row spacetop">
                                            <div className='col-lg-12'>
                                                <div class="db_box">
                                                    <div className='db_header mh51'>
                                                        <h5>Services</h5>

                                                    </div>
                                                    <div className='db_body'>
                                                        <div class="spd_card">
                                                            <div className='spd_card_list2'>
                                                                <p><i class="fa fa-stethoscope" aria-hidden="true"></i> Teleconsultation</p>
                                                                <Link to={"/tele-consultation"} className='btn btn-info btn-sm mt-5' > Book Now </Link>
                                                            </div>
                                                            <div className='spd_card_list2'>
                                                                <p><i class="fa fa-user-nurse"></i> Home Care</p>
                                                                <Link to={"/homecare"} className='btn btn-info btn-sm mt-5' > Book Now </Link>
                                                            </div>
                                                            <div className='spd_card_list2'>
                                                                <p><i class="fa fa-ambulance" aria-hidden="true"></i> Ambulance</p>
                                                                <Link to={"/ambulance"} className='btn btn-info btn-sm mt-5' > Book Now </Link>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div class="spacetop">
                                            {/* Today's Appointments */}
                                            {todayappointment != null && todayappointment !== undefined && todayappointment.length > 0 && (
                                                <>
                                                    <h4 className="page_title">Today's Appointments</h4>
                                                    <div className="row">
                                                        {todayappointment.map((key) => (
                                                            <div className="col-lg-4" key={key.id}>
                                                                <div className="db_box">
                                                                    <div className="db_header">
                                                                        <h5>
                                                                            {key.service_type === "Doctor"
                                                                                ? "Teleconsultation"
                                                                                : key.service_type === "Nurse"
                                                                                    ? "Home care"
                                                                                    : "Ambulance"}
                                                                        </h5>
                                                                        <div className="dbh_right">
                                                                            <p>#{key.id}</p>
                                                                            <div className="dropdown">
                                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu">
                                                                                    <li> <Link to={`/bookings-summary/${key.id}`} state={{ type: "Doctor" }}>
                                                                                        Booking Info
                                                                                    </Link></li>
                                                                                    <li>
                                                                                        <Link onClick={() => bookingcancel(key.id, key.service_type)}>Cancel</Link>
                                                                                    </li>
                                                                                    {key.service_type === "Doctor" && key.status === "Booked" && (
                                                                                        <li key={key.id + "doctorsNursesAmbulances-list"}>
                                                                                            <Link
                                                                                                to="/show-profile-and-slots"
                                                                                                state={{
                                                                                                    userid: key.provider_id,
                                                                                                    searchType: key.service_type,
                                                                                                    bookingid: key.id,
                                                                                                    scheduleType: "Reschedule",
                                                                                                }}
                                                                                            >
                                                                                                Reschedule
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_body">
                                                                        <div className="infono">
                                                                            <div className="infpo_icon">
                                                                                <img src={key.profile_pic !== null && key.profile_pic !== "null" ? key.profile_pic : <i className="fa fa-user-circle iconclass" aria-hidden="true"></i>} alt="user img" />
                                                                            </div>
                                                                            <div className="info_text">
                                                                                <h5>{key.name}</h5>
                                                                                <p>
                                                                                    {key.headline}, {key.city}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_footer">
                                                                        <div className="date">
                                                                            {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}
                                                                        </div>
                                                                        <div>
                                                                            {key.status.toLowerCase() !== "cancelled" ? (
                                                                                key.service_type === "Doctor" && key.is_confirmed === 1 && moment.utc(getLocalToUTC(new Date())).add(1, "days").startOf("day") >= moment.utc(key.appointment_date).startOf("day") && moment.utc(getLocalToUTC(new Date())).subtract(1, "days").endOf("day") <= moment.utc(key.appointment_date).endOf("day") ? (
                                                                                    <Link to="/video-call" state={{ roomId: key.room_id }}>
                                                                                        <i className="fa fa-video"></i> Join Call
                                                                                    </Link>
                                                                                ) : (
                                                                                    <a style={{ color: "green" }}>Confirmed</a>
                                                                                )
                                                                            ) : key.is_confirmed === 0 ? (
                                                                                <a style={{ color: "orange" }}>Pending</a>
                                                                            ) : (
                                                                                <a style={{ color: "green" }}>Confirmed</a>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}

                                            {/* Today's Appointments */}
                                            {dashboard.todayAppointmentHomecare != null && dashboard.todayAppointmentHomecare !== undefined && dashboard.todayAppointmentHomecare.length > 0 && (
                                                <>
                                                    <h4 className="page_title">Today's Appointments</h4>
                                                    <div className="row">
                                                        {dashboard.todayAppointmentHomecare.map((key) => (
                                                            <div className="col-lg-4" key={key.id}>
                                                                <div className="db_box">
                                                                    <div className="db_header">
                                                                        <h5>
                                                                            {key.service_type === "Doctor" ? "Teleconsultation" : key.service_type === "Nurse" ? "Home care" : "Ambulance"}
                                                                        </h5>
                                                                        <div className="dbh_right">
                                                                            <p>#{key.booking_id}</p>
                                                                            <div className="dropdown">
                                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu">
                                                                                    <li>
                                                                                        <Link to={`/bookings-visit-summary/${key.id}`} state={{ type: "Nurse" }}>
                                                                                            Booking Info
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link onClick={() => bookingcancel(key.id, key.service_type)}>Cancel</Link>
                                                                                    </li>
                                                                                    {key.service_type === "Nurse" && (key.status === "Scheduled" || key.status === "Accepted") && (
                                                                                        <li data-toggle="modal" data-target="#myModal" onClick={() => onClickHomecare(key.booking_id, key.homecare_name, key.id, key.status, key.nurse_user_id)}>
                                                                                            Reschedule
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_body">
                                                                        <div className="infono">
                                                                            <div className="infpo_icon">
                                                                                <img src={key.service_provider_pic !== null && key.service_provider_pic !== "null" ? key.service_provider_pic : <i className="fa fa-user-circle iconclass" aria-hidden="true"></i>} alt="user img" />
                                                                            </div>
                                                                            <div className="info_text">
                                                                                <h5>{key.provider_name}</h5>
                                                                                <p>{(key.headline)},{key.city}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_footer">
                                                                        <div className="date">
                                                                            {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}
                                                                        </div>
                                                                        <div>
                                                                            {key.status.toLowerCase() !== "cancelled" ? (
                                                                                key.is_confirmed && key.status === "Pending" ? (
                                                                                    <button type="button" className="btn btn-info" data-toggle="modal" data-target="#myModal"
                                                                                        onClick={() => onClickHomecare(key.booking_id, key.homecare_name, key.id, key.status, key.nurse_user_id)}
                                                                                    >
                                                                                        Schedule Now
                                                                                    </button>
                                                                                ) : key.is_confirmed === 0 ? (
                                                                                    <a style={{ color: "orange" }}>Pending</a>
                                                                                ) : (
                                                                                    <a style={{ color: "green" }}>Confirmed</a>
                                                                                )
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}

                                            {/* Today's Appointments */}
                                            {dashboard.todayAppointmentAmbulance != null && dashboard.todayAppointmentAmbulance !== undefined && dashboard.todayAppointmentAmbulance.length > 0 && (
                                                <>
                                                    <h4 className="page_title">Today's Appointments</h4>
                                                    <div className="row">
                                                        {dashboard.todayAppointmentAmbulance.map((key) => (
                                                            <div className="col-lg-4" key={key.id}>
                                                                <div className="db_box">
                                                                    <div className="db_header">
                                                                        <h5>
                                                                            {key.service_type === "Doctor" ? "Teleconsultation" : key.service_type === "Nurse" ? "Home care" : "Ambulance"}
                                                                        </h5>
                                                                        <div className="dbh_right">
                                                                            <p>#{key.id}</p>
                                                                            <div className="dropdown">
                                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu">
                                                                                    <li>
                                                                                        <Link to={`/book-summary-ambulance/${key.id}`} state={{ type: "Ambulance" }}>
                                                                                            Booking Info
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link onClick={() => bookingcancel(key.id, key.service_type)}>Cancel</Link>
                                                                                    </li>
                                                                                    {(key.service_status === 'Requested' && key.status === 'Booked' && key.is_confirmed === 1) && (
                                                                                        <li>
                                                                                            <Link onClick={() => RequestOrComplete(key.id, 'Completed', key.service_type)}>Complete Request</Link>
                                                                                        </li>

                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_body">
                                                                        <div className="infono">
                                                                            <div className="infpo_icon">

                                                                                <img src={key.service_provider_pic !== null && key.service_provider_pic !== "null" ? key.service_provider_pic : <i className="fa fa-user-circle iconclass" aria-hidden="true"></i>} alt="user img" />

                                                                            </div>
                                                                            <div className='info_text'>
                                                                                <h5>{key.provider_name}</h5>
                                                                                <p>Ambulance Type: <span>{key.ambulance_type}</span></p>
                                                                                <p>Route: From - {key.pickup_address} To - {key.destination_address}</p>
                                                                                <p>Schedule Time: {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}</p>
                                                                                <p>Request Raise Time: {moment(key.created_at).utc().format("MMM DD")}, {moment(key.created_at).utc().format("hh:mm a")}</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_footer">
                                                                        <div className="date">
                                                                            {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}
                                                                        </div>
                                                                        <div>
                                                                            {key.status.toLowerCase() !== "cancelled" ? (
                                                                                type === "user" && key.is_confirmed && key.status === "Booked" && key.remaining_amount && key.remaining_payment_status !== "Paid" ? (
                                                                                    <button style={{ color: "green" }} onClick={() => payRemainingAmount(key.id, key.remaining_amount)}>
                                                                                        Pay ₹ {key.remaining_amount}
                                                                                    </button>
                                                                                ) : key.is_confirmed === 0 ? (
                                                                                    <a style={{ color: "orange" }}>Pending</a>
                                                                                ) : (
                                                                                    <a style={{ color: "green" }}>Confirmed</a>
                                                                                )
                                                                            ) : null}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}

                                            {/* Recent Prescription */}
                                            <h4 className='page_title'>Recent Prescription</h4>
                                            <div class="row ">

                                                {(recentPrescription != undefined && recentPrescription != null && recentPrescription.length > 0) ?
                                                    (recentPrescription != undefined) && recentPrescription.map((key) => (
                                                        <div className='col-lg-4 '>
                                                            <div class="db_box">
                                                                <div className='db_header'>
                                                                    <h5>E-Prescription</h5>
                                                                    <div className='dbh_right'>
                                                                        <p>#{key.booking_id}</p>
                                                                        {/* <span><a onClick={() => setPrescriptionid(key.id)}> <i class="fa fa-download" aria-hidden="true"></i></a></span> */}
                                                                        <a href={key.prescription_file} target="_blank" download><i className="fa fa-download" aria-hidden="true"></i></a>
                                                                    </div>
                                                                </div>
                                                                <div className='db_body'>
                                                                    <div class="infono">
                                                                        <div className='info_text'>
                                                                            <h5>{key.name}</h5>
                                                                            <p>{key.headline}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )) : <p className='marginme'>"No Record Found"</p>}





                                            </div>

                                            {/* Recent Reports */}
                                            <h4 className='page_title'>Recent Reports</h4>
                                            <div class="row ">

                                                {recentReports != undefined && recentReports != null && recentReports.length > 0 ?
                                                    (recentReports != undefined) && recentReports.map((key) => (
                                                        <div className='col-lg-3 col-md-4 '>
                                                            <div class="db_box">
                                                                <div className='db_header'>
                                                                    <h5>{key.report_name}</h5>
                                                                    <div className='dbh_right'>
                                                                        <span><a onClick={() => saveFile(key.attachment)}><i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                                    </div>
                                                                </div>
                                                                <div className='db_body'>
                                                                    <div class="infono">
                                                                        <div className='info_text'>
                                                                            <p><div className='date'>{(moment(key.report_date).utc()).format("MMM DD")}, {(moment(key.report_date).utc()).format("hh:mm a")} </div> </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )) : <p className='marginme'>"No Record Found"</p>}

                                            </div>

                                            {/* Upcoming Bookings (Next 7 days) */}
                                            {upcomingBookings != null && upcomingBookings !== undefined && upcomingBookings.length > 0 && (
                                                <>
                                                    <h4 className="page_title">Upcoming Bookings(upcoming 7 days)</h4>
                                                    <div className="row">
                                                        {upcomingBookings.map((key) => (
                                                            <div className="col-lg-4" key={key.id}>
                                                                <div className="db_box">
                                                                    <div className="db_header">
                                                                        <h5>
                                                                            {key.service_type === "Doctor"
                                                                                ? "Teleconsultation"
                                                                                : key.service_type === "Nurse"
                                                                                    ? "Home care"
                                                                                    : "Ambulance"}
                                                                        </h5>
                                                                        <div className="dbh_right">
                                                                            <p>#{key.id}</p>
                                                                            <div className="dropdown">
                                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu">
                                                                                    <li>
                                                                                        <Link to={`/bookings-summary/${key.id}`} state={{ type: "Doctor" }}>
                                                                                            Booking Info
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link onClick={() => bookingcancel(key.id, key.service_type)}>Cancel</Link>
                                                                                    </li>
                                                                                    {key.service_type === "Doctor" && key.status === "Booked" && (
                                                                                        <li key={key.id + "doctorsNursesAmbulances-list"}>
                                                                                            <Link
                                                                                                to="/show-profile-and-slots"
                                                                                                state={{
                                                                                                    userid: key.provider_id,
                                                                                                    searchType: key.service_type,
                                                                                                    bookingid: key.id,
                                                                                                    scheduleType: "Reschedule",
                                                                                                }}
                                                                                            >
                                                                                                Reschedule
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_body">
                                                                        <div className="infono">
                                                                            <div className="infpo_icon">
                                                                                <img src={key.profile_pic !== null && key.profile_pic !== "null" ? key.profile_pic : <i className="fa fa-user-circle iconclass" aria-hidden="true"></i>} alt="user img" />
                                                                            </div>
                                                                            <div className="info_text">
                                                                                <h5>{key.name}</h5>
                                                                                <p>
                                                                                    {key.headline}, {key.city}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_footer">
                                                                        <div className="date">
                                                                            {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}
                                                                        </div>
                                                                        <div>
                                                                            {key.status.toLowerCase() !== "cancelled" ? (
                                                                                <div>
                                                                                    {key.service_type === "Doctor" && key.is_confirmed === 1 && moment.utc(getLocalToUTC(new Date())).add(2, "days").startOf("day") >= moment.utc(key.appointment_date).startOf("day") && moment.utc(getLocalToUTC(new Date())).subtract(2, "days").endOf("day") <= moment.utc(key.appointment_date).endOf("day") ? (
                                                                                        <Link to="/video-call" state={{ roomId: key.room_id }}>
                                                                                            <i className="fa fa-video"></i> Join Call
                                                                                        </Link>
                                                                                    ) : key.is_confirmed === 1 && key.status === "Booked" ? (
                                                                                        <a style={{ color: "green" }}>Confirmed</a>
                                                                                    ) : (
                                                                                        <a style={{ color: "orange" }}>Pending</a>
                                                                                    )}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}

                                            {dashboard.upcomingBookingsHomecare != null && dashboard.upcomingBookingsHomecare !== undefined && dashboard.upcomingBookingsHomecare.length > 0 && (
                                                <>
                                                    <h4 className="page_title">Upcoming Homecare Bookings(upcoming 7 days)</h4>
                                                    <div className="row">
                                                        {dashboard.upcomingBookingsHomecare.map((key) => (
                                                            <div className="col-lg-4" key={key.id}>
                                                                <div className="db_box">
                                                                    <div className="db_header">
                                                                        <h5>
                                                                            {key.service_type === "Doctor" ? "Teleconsultation" : key.service_type === "Nurse" ? "Home care" : "Ambulance"}
                                                                        </h5>
                                                                        <div className="dbh_right">
                                                                            <p>#{key.booking_id}</p>
                                                                            <div className="dropdown">
                                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu">
                                                                                    <li>
                                                                                        <Link to={`/bookings-visit-summary/${key.id}`} state={{ type: "Nurse" }}>
                                                                                            Booking Info
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link onClick={() => bookingcancel(key.id, key.service_type)}>Cancel</Link>
                                                                                    </li>
                                                                                    {(key.item_service_status === 'Requested') && (
                                                                                        <li><Link onClick={() => RequestOrComplete(key.id, 'Completed', key.service_type)}>Complete Request</Link></li>
                                                                                    )}
                                                                                    {key.service_type === "Nurse" && (key.status === "Scheduled" || key.status === "Accepted") && (
                                                                                        <li>
                                                                                            <Link data-toggle="modal" data-target="#myModal" onClick={() => onClickHomecare(key.booking_id, key.homecare_name, key.id, key.status, key.nurse_user_id)}>
                                                                                                Reschedule
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_body">
                                                                        <div className="infono">
                                                                            <div className="infpo_icon">
                                                                                <img src={key.service_provider_pic !== null && key.service_provider_pic !== "null" ? key.service_provider_pic : <i className="fa fa-user-circle iconclass" aria-hidden="true"></i>} alt="user img" />
                                                                            </div>
                                                                            <div className="info_text">
                                                                                <h5>{key.provider_name}</h5>
                                                                                <p>{(key.headline)},{key.city}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_footer">
                                                                        <div className="date">
                                                                            {key.status === "Pending" ? (
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-info"
                                                                                    data-toggle="modal"
                                                                                    data-target="#myModal"
                                                                                    onClick={() =>
                                                                                        onClickHomecare(key.booking_id, key.homecare_name, key.id, key.status, key.nurse_user_id)}>
                                                                                    Schedule Now
                                                                                </button>
                                                                            ) : key.scheduled_date && key.scheduled_date !== 'undefined' ? (
                                                                                <>
                                                                                    {moment.utc(key.scheduled_date).format("MMM DD, hh:mm a")}
                                                                                </>
                                                                            ) : null}
                                                                        </div>

                                                                        <div>
                                                                            {key.status.toLowerCase() !== "cancelled" ?
                                                                                (key.is_confirmed === 0 ? (
                                                                                    <a style={{ color: "orange" }}>Pending</a>
                                                                                ) : (
                                                                                    <a style={{ color: "green" }}>Confirmed</a>
                                                                                )
                                                                                ) : (<a style={{ color: 'orange' }}>{key.status}</a>)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}


                                            {dashboard.upcomingBookingsAmbulance != null && dashboard.upcomingBookingsAmbulance !== undefined && dashboard.upcomingBookingsAmbulance.length > 0 && (
                                                <>
                                                    <h4 className="page_title">Upcoming Ambulance Bookings(upcoming 7 days)</h4>
                                                    <div className="row">
                                                        {dashboard.upcomingBookingsAmbulance.map((key) => (
                                                            <div className="col-lg-4" key={key.id}>
                                                                <div className="db_box">
                                                                    <div className="db_header">
                                                                        <h5>
                                                                            {key.service_type === "Doctor" ? "Teleconsultation" : key.service_type === "Nurse" ? "Home care" : "Ambulance"}
                                                                        </h5>
                                                                        <div className="dbh_right">
                                                                            <p>#{key.id}</p>
                                                                            <div className="dropdown">
                                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu">
                                                                                    <li>
                                                                                        <Link to={`/book-summary-ambulance/${key.id}`} state={{ type: "Ambulance" }}>
                                                                                            Booking Info
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link onClick={() => bookingcancel(key.id, key.service_type)}>Cancel</Link>
                                                                                    </li>
                                                                                    {(key.service_status === 'Requested' && key.status === 'Booked' && key.is_confirmed === 1) && (
                                                                                        <li>
                                                                                            <Link onClick={() => RequestOrComplete(key.id, 'Completed', key.service_type)}>Complete Request</Link>
                                                                                        </li>

                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_body">
                                                                        <div className="infono">
                                                                            <div className="infpo_icon">

                                                                                <img src={key.service_provider_pic !== null && key.service_provider_pic !== "null" ? key.service_provider_pic : <i className="fa fa-user-circle iconclass" aria-hidden="true"></i>} alt="user img" />

                                                                            </div>
                                                                            <div className='info_text'>
                                                                                <h5>{key.provider_name}</h5>
                                                                                <p><span className='title-semibold'>Ambulance Type:</span> <span>{key.ambulance_type}</span></p>
                                                                                <p><span className='title-semibold'> Route:</span> From - {key.pickup_address} To - {key.destination_address}</p>
                                                                                <p><span className='title-semibold'>Schedule Time:</span> {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}</p>
                                                                                <p><span className='title-semibold'>Request Raise Time:</span> {moment(key.created_at).utc().format("MMM DD")}, {moment(key.created_at).utc().format("hh:mm a")}</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="db_footer">
                                                                        <div className="date">
                                                                            {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}
                                                                        </div>
                                                                        <div>
                                                                            {key.status.toLowerCase() !== "cancelled" ? (
                                                                                type === "user" && key.is_confirmed && key.status === "Booked" && key.remaining_amount && key.remaining_payment_status !== "Paid" ? (
                                                                                    <button style={{ color: "green" }} onClick={() => payRemainingAmount(key.id, key.remaining_amount)}>
                                                                                        Pay ₹ {key.remaining_amount}
                                                                                    </button>
                                                                                ) : key.is_confirmed === 0 ? (
                                                                                    <a style={{ color: "orange" }}>Pending</a>
                                                                                ) : (
                                                                                    <a style={{ color: "green" }}>Confirmed</a>
                                                                                )
                                                                            ) : null}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}


                                        </div>
                                    </section>

                                </div> :
                                <div class="defaultloader">
                                    <ThreeDots
                                        height="80"
                                        width="80"
                                        radius="9"
                                        color="#056B66"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    /></div>}
                        </div>
                    </div>
                </div>
                {prescriptionid !== undefined && prescriptionid !== null && prescriptionid !== "" ?
                    <div id="capture">
                        <Eprecriptiondownload prescriptionid={prescriptionid} setPrescriptionid={setPrescriptionid}></Eprecriptiondownload>
                    </div>
                    : ""
                }
            </section>

            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* <!-- Modal --for schedule visit--> */}
            <div className="modal fade" id="myModal" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Book Visit</h4>
                        </div>
                        <div className="modal-body">
                            <p><b style={{ fontSize: "18px" }}>{homecareName}</b></p>
                            <p className="font-size font-blod">Visit Required on</p>
                            <div className="date-time">
                                <div className="date">
                                    <h5>Date</h5>
                                    <input type="date" id="birthdaytime1" name="appointmentDate" value={appointmentDate} onChange={(e) => getAppointmentTimeList(e.target.value)} />
                                </div>
                                {/* <div className="time">
                                    <h5>Preferred Time</h5>
                                    <input type="time" id="appt" name="appointmentTime" onChange={(e) => setAppointmentTime(e.target.value)} />
                                </div> */}
                                <select name="appointment_time" onChange={(e) => getAvailabilityId(e)}>
                                    <option >Select Time</option>
                                    {appointmentTimeList.map((item, index) => (
                                        <option
                                            key={index}
                                            value={item.start_time + "-" + item.id}
                                            onClick={() => getAvailabilityId(item.id)}
                                        >
                                            {Change_to_12HourFormat(item.start_time)} to {Change_to_12HourFormat(item.end_time)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h6 className="under-box-update-email">Updates will be sent to registered email address</h6>
                            <p className="Undertaking">Home medical care offers a convenient, comfortable, and cost-effective option for patients who require medical attention. However, it should be noted that home medical care cannot replace hospital services in cases of emergency or critical illness.</p>
                            <p>
                                <input type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                                <span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span>
                            </p>
                            <div className="text-center margintop-bottom">
                                <button
                                    type="submit"
                                    className={`btn btn_fill btn-type-custum ${isBookAndPayEnabled ? '' : 'disabled'}`}
                                    onClick={() => {
                                        handleBookAndPay();
                                        $('#myModal').modal('hide'); // Hide the modal using jQuery
                                    }}
                                    disabled={!isBookAndPayEnabled}
                                >
                                    Schedule Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default UserDashBoard;