import React, { useEffect, useState } from 'react'
import { FaqsSections, Footer, HomepageHeader } from '../../components'
import SearchIcon from "../../images/home/search.png"
import LocationIconWhite from "../../images/images/mapIconWhite.png"
import Verifiydr from "../../images/images/verifiydr.png"
import Teleconsultansy_bnr from "../../images/images/tc_bnr.png"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import SelectSpeciallityImg from "../../images/howItWorks/hiw1.png"
import AudioVideoImg from "../../images/howItWorks/hiw2.png"
import getDigitalPrescriptionImg from "../../images/howItWorks/hiw3.png"
import CheckImg from "../../images/images/check.png"
import HealthIssueListService from '../../services/HealthIssueListService'
import { Link, useNavigate } from "react-router-dom"
import ImageNotAvailable from "../../images/images/image_not_available.png"
import CardShimmer from '../../components/CardShimmer'
import { ShowProfileType } from '../../enums/Enums'
import LocationIcon from '../../images/home/map-pin.png';
import axios from 'axios'

import LocationsList from '../../components/locations/LocationsList'
const baseurl = process.env.REACT_APP_API_BASE_URL


const Teleconsultation = () => {
    const apicalls = new HealthIssueListService();
    const [search_keyCity, setsearch_keyCity] = useState("");
    const navigate = useNavigate();

    const [speciality, setSpeciality] = useState([])
    const [diseases, setDiseases] = useState([])

    //Search  
    const [items, setItems] = useState([])
    const [search_key, setsearch_key] = useState("")
    const [isloading, setisloading] = useState(true)
    const [isloading1, setisloading1] = useState(true)
    const [isloading2, setisloading2] = useState(true)
    const [isedit, setIsEdit] = useState(false);
    const [type, setType] = useState("Doctor")

    // Owl carousel Options you manage item count for different screen sizes
    const options = {
        margin: 1,
        className: "owl-theme",
        responsiveClass: true,
        // nav: true,
        autoplay: false,
        // navText: ["Prev", "Next"],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
            }
        },
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadSpeciality();
        loadDiseases();
    }, [])
 
    const loadSpeciality = async () => {

        const result = await axios.get(`${baseurl}specialization/user`)

        if (result !== undefined) {
           
            if (result.data.success) {
                setSpeciality(result.data.data)

            }
        }
        setisloading(false)
    }


    const loadDiseases = async () => {

        const result = await axios.get(`${baseurl}disease`)

        if (result !== undefined) {          
            if (result.data.success) {
                setDiseases(result.data.data)
            }

        }
        setisloading1(false)
    }


    useEffect(() => {
        setisloading2(true)
        const delayDebounceFn = setTimeout(() => {
            if (search_key) {
                return searchDoctors(search_key);
            } else {
                setisloading2(false)
                return setItems([]);
            }
        }, 300);
        return () => clearTimeout(delayDebounceFn)
    }, [search_key])

    const searchDoctors = (search_key) => {
        apicalls.searchDoctorsService(search_key, search_keyCity).then(res => {

            if (res !== undefined) {
                let result = res
             
                if (result.success === true) {
                    setItems(result.data)
                    setisloading2(false);
                }
            }
        })
    }

    const onEnterPress = (e) => {
        e.preventDefault();
        return navigate('/search', {
            state: {
                locationState: localStorage.getItem("SelectedLocation"),
                searchKeyState: search_key, pageType: "teleconsultancy"
            }
        })
    }

    const navigateTo = (itemsearch) => {
        return navigate('/search', {
            state: {
                locationState: localStorage.getItem("SelectedLocation"),
                searchKeyState: itemsearch,
                pageType: "teleconsultancy"
            }
        })
    }

    const handleInputBlur = event => {
        setIsEdit(false)
    };
   
    return (
        <div className='outerhomepage'>
            <HomepageHeader />
            <section className="banner_inner">
                <div className="container">
                    <div className="card_flex">
                        <div className="card_text">
                            <h1>Online Consultation</h1>
                            <h2>with verified doctors </h2>
                            {/* <div className="searchbox tc_search">
                                <div className="keyword">
                                    <span className="searchicon"><img src={SearchIcon} /></span>
                                    <form onSubmit={(e) => onEnterPress(e)}>
                                        <input type="text" value={search_key} onChange={(e) => setsearch_key(e.target.value)} className="form-control" placeholder="Choose Doctors " />
                                    </form>
                                </div>
                            </div> */}
                            <div className="searchbox">
                                <div className="locality">
                                    <span className="mapicon">
                                        <img src={LocationIcon} />
                                    </span>
                                    <input
                                        type="text"
                                        value={search_keyCity}
                                        onChange={(e) => setsearch_keyCity(e.target.value)}
                                        className="form-control"
                                        placeholder="Location"
                                    />
                                </div>


                                <div className={"cardSuggetionsLocation"}>
                                    {/* Render Location List */}
                                    <LocationsList
                                        search_keyCity={search_keyCity}
                                        setsearch_keyCity={setsearch_keyCity}
                                    />
                                </div>
                                <div className="vline" />
                                <div className="keyword">
                                    <span className="searchicon">
                                        <img src={SearchIcon} />
                                    </span>

                                    <form>
                                        <input
                                            type="text"
                                            value={search_key}
                                            onChange={(e) => { setsearch_key(e.target.value); setIsEdit(true) }}
                                            className="form-control"
                                            placeholder="Enter Name / Specialization / symptoms "
                                           
                                        />
                                    </form>
                                </div>

                            </div>
                            {isedit ?
                                <div id={items.length} className="cardSuggetions topment0">

                                    {items.length > 0 ?
                                        <>
                                            <ul>
                                                {items.map((item) => (
                                                     
                                                    <li key={item.id + "doctors-list"}>
                                                        <Link to="/show-profile-and-slots"
                                                            state={{
                                                                userid: item.id,
                                                                searchType: ShowProfileType.type1}}>
                                                            {item.name}
                                                            <p className="resultType">{item.specialization}</p>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                        :
                                        <>
                                            {isloading2 ?
                                                <p className={search_key == "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                                                    Loading...
                                                </p>
                                                :
                                                <p className={search_key == "" ? "noRecordsPara displayNoneCls" : "noRecordsPara"}>
                                                    No Records Found
                                                </p>
                                            }
                                        </>
                                    }


                                </div> : ""
                            }
                            <div className="verifiydr">
                                <span><img src={Verifiydr} /> Verified Doctors</span>
                                <span><img src={Verifiydr} /> Digital Prescription</span>
                            </div>
                            {/* <div className="verifiydr locationWhiteIcon">
                                <span><img src={LocationIconWhite} alt="LocationIconWhite" />{localStorage.getItem("SelectedLocation")}</span>
                            </div> */}
                        </div>
                        <div className="card_img">
                            <div className="dr_img"><img src={Teleconsultansy_bnr} /></div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Specialities Slider*/}
            < section className="specialities_Slider paddtop" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slider_heading">
                                <h3 className="left-h">Specialities</h3>
                                <div className="product-btn">
                                    {/* <a href="#" className="btnlink">See All </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {!isloading ?
                                <OwlCarousel {...options}>
                                    <>

                                        {(speciality != null && speciality.length > 0)
                                            ?
                                            (speciality != null && speciality.map((item) => (
                                                <div className="post-slide">
                                                    <div className="white_card text-center">
                                                        <div className="card_img">
                                                            <img src={item.image_path ? item.image_path : ImageNotAvailable} alt className="sp_img" />
                                                        </div>
                                                        <div className="card_dtl">
                                                            <h3 className="card_title">{item.title}</h3>
                                                            <div className="card_rate"></div>
                                                            <div className="card_btn">
                                                                <button onClick={() => navigateTo(item.title)} className="btn btn_outline btn-xs" type='button'>View  </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )))
                                            :
                                            "No records found"
                                        }
                                    </>
                                </OwlCarousel>
                                : "Loading..."}
                        </div>
                    </div>
                </div>
            </section>

            {/* Disease Slider*/}
            < section className="disease_Slider paddtop" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slider_heading">
                                <h3 className="left-h">Disease</h3>
                                <div className="product-btn">
                                    {/* <a href="#" className="btnlink">See All </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {!isloading1 ?
                                <OwlCarousel {...options}>

                                    {(diseases != null && diseases.length > 0)
                                        ?
                                        (diseases != null && diseases.map((item) => (
                                            <div className="post-slide">
                                                <div className="white_card disease_card">
                                                    <div className="card_img text-center">
                                                        <img src={item.image_path ? item.image_path : ImageNotAvailable} alt className="dis_img" />
                                                    </div>
                                                    <div className="card_dtl">
                                                        <h3 className="card_title">{item.title}</h3>
                                                        <div className="card_rate"></div>
                                                        <div className="card_btn">
                                                            <button onClick={() => navigateTo(item.title)} className="linktheme" >View </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )))
                                        :
                                        "No records found"
                                    }
                                </OwlCarousel>
                                : "Loading..."}
                        </div>
                    </div>
                </div>
            </section >

            {/* How it works*/}
            < section className="how_it_works bg_light margintop" >
                <div className="container">
                    <div className="paddtop paddbottom">
                        <h3 className="heading2 text-center">How it works</h3>
                        <div className="hiw_section">
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={SelectSpeciallityImg} alt /></div>
                                <p className="description">Select a speciality or symptom</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={AudioVideoImg} alt /></div>
                                <p className="description">Audio/ video call with a verified doctor</p>
                            </div>
                            <div className="value_prop">
                                <div className="value_prop_icon"><img src={getDigitalPrescriptionImg} alt /></div>
                                <p className="description">Get a digital prescription &amp; a free follow-up</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* Benefits */}
            < section className="benefit_sec paddtop paddbottom" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-8">
                            <div className="benefit_content">
                                <h4 className="benefit_title">Benefits of online consultation</h4>
                                <p className="para2">Say goodbye to the stress of inexperienced doctors and long hospital queues! With fast-track teleconsultation, you can easily access world-class treatment from renowned doctors anywhere in the world. Say hello to convenience and peace of mind with online consultations that allow you to receive medical advice and treatment from the comfort of your own home. No more wasting time traveling to a clinic or hospital and exposing yourself to infectious diseases. This service is especially beneficial for busy individuals, pregnant women, and those living in remote areas or with limited mobility. Don't wait any longer, take the first step towards your health and book your first teleconsultation today!</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div className="benefit_list">
                                <p><img src={CheckImg} alt /> Consult Top Doctors 24x7</p>
                                <p><img src={CheckImg} alt /> Convenient and Easy</p>
                                <p><img src={CheckImg} alt /> Similar Clinic Experience</p>
                                <p><img src={CheckImg} alt /> 100% Safe Consultations</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <FaqsSections  fqatype="Teleconsultation"/>

            <Footer />


        </div >
    )
}

export default Teleconsultation