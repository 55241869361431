import { CropLandscapeOutlined } from '@mui/icons-material';
import axios from 'axios';
import * as process from "process";
import { toast } from 'react-toastify';



// descrption:- last updated 24-06-22
// descrption:- By Mayur
class ProfileService {
    constructor() {
        this.state = {
            ApibaseURL: process.env.REACT_APP_API_BASE_URL,
            AccessToken: localStorage.getItem('token'),
        }
    }

    // Never Used
    async UpdateProfile(userid, data) {        
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.put(`${this.state.ApibaseURL}/users/${userid}/update`,data, headers).then((response) => {
                return response
            }
            ).catch(error => {
               console.log(error)
            });
            return response.data
        } catch (error) {
            console.log("error from doctor profile",error)
        }
    }


    async LoadProfileData(token) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            
            const response = await axios.get(`${this.state.ApibaseURL}users/me`, headers).then((response) => {               
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
        
    }



    async stateData(token) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}states`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized to view this page")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
        
    }


    async MyDetailsServicesListLoad(type) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }

            const response = await axios.get(`${this.state.ApibaseURL}users/Doctor/list`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async MyDetailsPaymentList(search,sortby,status) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }

            const response = await axios.get(`${this.state.ApibaseURL}users/transactions?status=${status}&searchKey=${search}&period=${sortby}&from_date&to_date`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async UpdateProfilePhoto(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }

            const response = await axios.put(`${this.state.ApibaseURL}/users/profile`,data, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async AddMedicalHistory(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/users/medicalhistory`,data, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async AddMedicalReports(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/users/medicalreports`,data, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async PostFeedbackAndQuery(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/users/feedback`,data, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }
    
    async loadMyMedicalReports() {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}/users/medicalreports?outputFor=User`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async deleteMyMedicalReports(id) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': this.state.AccessToken
                }
            }
            const response = await axios.delete(`${this.state.ApibaseURL}/users/medicalreport/${id}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async doctorProfile(id) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization':  this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}doctors/${id}/profile`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async nurseProfile(id) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization':  this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}homecares/${id}/profile`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async ambulanceProfile(id) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization':  this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}/ambulances/profile/${id}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async ambulanceList(search_key) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization':  this.state.AccessToken
                }
            }
            const response = await axios.get(`${this.state.ApibaseURL}/ambulances/list?search_key=${search_key}`, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }

    async addAmbulance(data) {
        try {
            const headers = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization':  this.state.AccessToken
                }
            }
            const response = await axios.post(`${this.state.ApibaseURL}/ambulances/add`,data, headers).then((response) => {
                return response
            }
            ).catch(error => {
                if (error.response.status === 400) {
                    if (error.response.data.success === false)
                        return toast.error(error.response.data.message)
                }
                else if (error.response.status === 401) {
                    return toast.error("You are not authorized")
                }
                else if (error.response.status === 404) {
                    return toast.error("Url not found")
                }
                else if (error.response.status === 500) {
                    return toast.error("Internal server error please try after sometime")
                }
            });
            return response.data
        } catch (error) {
            return toast.error(error)
        }
    }



}
export default ProfileService;
