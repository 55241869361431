import React, { useEffect, useState } from 'react'
import VinitaIcon from "../../../images/images/vinita.png"
import DownloadPng from "../../../images/images/download.png"
import { HomepageHeader, SidebarUserPanel } from '../../../components'
import { ProfileService } from '../../../services'
import moment from 'moment'

const MyNurses = () => {
  const [myDoctors, setMyDoctors] = useState([])
  const apiFunction = new ProfileService();
  useEffect(() => {
    loadDoctors();
  }, [])

  const loadDoctors = () => {
    apiFunction.MyDetailsServicesListLoad("Nurse").then(result => {
      console.log(result)
      if (result !== undefined) {
        if (result.success === true) {
 
          setMyDoctors(result.data)
        }
      }
    })

  }
  return (
    <div className="outerhomepage">
      {/*Top Navbar Menu*/}
      <HomepageHeader />

      <section className=" paddbottom">
        <div className="">
          <div className="userpanel_page bg_light">

            {/* Side bar */}
            <SidebarUserPanel />

            <div className="right_panel table-responsive">
              <table className="table mytable">
                <tbody>
                  <tr>
                    <th style={{ width: '33.33%' }}>Name</th>
                    <th style={{ width: '33.33%' }}>Booked at</th>
                    <th style={{ width: '33.33%' }}>Amount</th>
                  </tr>
                  {myDoctors.length > 0 ? myDoctors.map(item => (
                    <tr>
                      <td>
                        <div className="userdtl">
                          <div className="userpic">
                            <img src={VinitaIcon} alt />
                          </div>
                          <div className="userinfo">
                            <p>{item.name}</p>
                            <span>{item.service_type}</span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="bookedat">
                          <p>{(moment(item.booking_date).utc()).format("DD/MM/YYYY")} </p><span>
                            {(moment(item.booking_date).utc()).format("hh:mm a")}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="amount_td">
                          <div>
                            <p>&#x20B9; {item.total_amount}</p><span>{item.status}</span>
                          </div>
                          {/* <div>
                                                        <span><a href="#">Download Prescription</a></span>
                                                    </div>
                                                    <div>
                                                        <a href="#"><img src={DownloadPng} alt /></a>
                                                    </div> */}
                        </div>
                      </td>
                    </tr>
                  ))

                    :
                    <h4 className='text-center'>
                      No record found
                    </h4>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default MyNurses