// Check if any required item is missing from localStorage
function chekIsProfileCompleted() {
    const requiredItems = ['userid', 'user_mobile', 'user_name', 'user_email', 'profile_pic', 'gender'];
    const missingItems = [];
    
    requiredItems.forEach(item => {
      if (!localStorage.getItem(item)) {
        missingItems.push(item);
      }
    });
  
    if (missingItems.length > 0) {
      const missingFieldsMessage = `Please update values for the following fields: ${missingItems.join(', ')}`;
      alert(missingFieldsMessage);
    }
  }

export default chekIsProfileCompleted;
  