import React, { useState } from 'react'

const ConsultationFeeGettingStatrtedPatient = ({ incrementBtn, decrementBtn, setConsultation_fees, DoctorRegistrationCall,followup_fees,setfollowup_fees }) => {
  const handleClick = () => {
    incrementBtn();
    DoctorRegistrationCall();

  }
  console.log(setConsultation_fees,"setting fees in comp")
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="profile_form1">
          <div className="row">
            <div className="col-lg-9">
              <h3 className="drprofile_title">Consultation fee</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="form-group">
                <div className="input-group">
                  <span className="input-group-addon">&#8377;</span>
                  <input type="text" className="form-control" placeholder="Type consulatation fee" onChange={(e) => setConsultation_fees(e.target.value)} />
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-lg-9">
              <h3 className="drprofile_title">Follow Up Fees</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="form-group">
                <div className="input-group">
                  <span className="input-group-addon">&#8377;</span>
                  <input type="text" className="form-control" value={followup_fees} placeholder="Type consulatation fee" onChange={(e) => setfollowup_fees(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          
          <div className='position-abslute-bottom'>
            <div className="info_box_btn padding-130px">
              <button type='button' onClick={decrementBtn} className="btnlink">Go back</button>
              <button type='button' onClick={handleClick} className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default ConsultationFeeGettingStatrtedPatient