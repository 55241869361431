import React from 'react'
import { Link } from 'react-router-dom'

const ApplicationStatusView = () => {
  return (
    <>
        <div className='ApplicationStatusView'>
            <p>
                Your profile is sent for approval.
            </p>
            <button className='btn'>
            <Link to='/accounts-bookings' style={{'color':'white'}}>Back to Bookings</Link></button>
        </div>

    </>
  )
}

export default ApplicationStatusView