import React, { useEffect, useState } from 'react'
import { HomepageHeader } from '../../components';
import { SidebarUserPanel } from '../../components';
import Sidebarmobile from '../../components/user/Sidebarmobile';
import {
    err_10DigitMobile,
    err_NotCity,
    err_NotCountry,
    err_NotDistrict,
    err_NotGender,
    err_NotHouseNo,
    err_NotMobile, err_NotName,
    err_NotSixDigitPinCode, err_NotState,
    err_NotStreetColony,
    err_NotValidEmail, HtmlErrorMessage, uploadImageFor
} from '../../enums/Enums';
import { ProfileService, UploadImageFor } from '../../services'
import DriverServices from '../../services/DriverServices';
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify';
import { set } from 'date-fns';

const DriverRegistration = () => {
    let token = localStorage.getItem('token')
    const navigate = useNavigate()
    const apiFunction = new DriverServices()
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [driverList, setdriverList] = useState({});
    const [search_key, setSearch_key] = useState("");
    const [driverId, setdriverId] = useState("");
    const [driverData, setdriverData] = useState({
        id: "",
        driver_name: "",
        license_number: "",
        mobile: "",
        mobile2: "",
        address: "",
        driver_photo: "",
        license_photo: ""
    })

    let { id, driver_name, license_number, mobile, mobile2, address, driver_photo, license_photo } = driverData

    const [driverPhoto, setDriverPhoto] = useState("")


    const handleInputs = (e) => {
        setdriverData({ ...driverData, [e.target.name]: e.target.value })
    }

    const handleImage = (e) => {
        setdriverData({ ...driverData, [e.target.name]: e.target.files[0] })
    }

    useEffect(() => {
        getDriverList(search_key)
    }, [search_key])




    const DriverRegistration = async (e) => {
        e.preventDefault();
        if (driver_name === "") {
            return toast.error(err_NotName)
        }
        else if (license_number === "") {
            return toast.error('Please enter License Number')
        }
        else if (mobile === "") {
            return toast.error(err_NotMobile)
        }
        else if (mobile.length !== 10) {
            return toast.error(err_10DigitMobile)
        }
        else if (address === "") {
            return toast.error('Please enter Address')
        }
        else if (license_photo === "") {
            return toast.error('Please upload License Photo')
        }
        else {
            apiFunction.addDriver(driverData).then(result => {
                if (result != null && result !== undefined) {
                    toast.success("Driver Added Successfully")
                    getDriverList(search_key)
                }
                else {
                    toast.error(result.message)
                }
            })
        }

    }

    //function for list of driver
    const getDriverList = async (search_key) => {

        let id = localStorage.getItem('userid')
        apiFunction.getDriverListOfUser(id, search_key).then(result => {
            if (result != null && result !== undefined) {
                setdriverList(result.data)
                setDriverPhoto(result.data.license_photo)
              
            }
        })

    }

    //function to delete driver
    const deleteDriver = async (id) => {
        apiFunction.deleteDriver(id).then(result => {
            if (result != null && result !== undefined) {
                toast.success("Driver Deleted Successfully")
                getDriverList(search_key)
                // close the modal
                document.getElementById("deleteModel").click()
                
            }
        })
    }

    //function to update driver
    const updateDriver = async (id) => {
        apiFunction.updateDriver(driverData, id).then(result => {
            if (result != null && result !== undefined) {
                toast.success("Driver Updated Successfully")
                getDriverList(search_key)
            }
        })
    }

    //function to get driver by id
    const getDriverById = async (id) => {
        apiFunction.getDriverDetailsByid(id).then(result => {
            if (result != null && result !== undefined) {
                setdriverData(result.data)
                setDriverPhoto(result.data.license_photo)
            }
        })
    }


    const handleProfileImage = (type) => {
        if (type == 'profile') {
            let inPutClick = document.getElementById('profilePic')
            inPutClick.click()
        } else {
            let inPutClick = document.getElementById('identity')
            inPutClick.click()
        }
    }

    //uploading profile picture--------------------------------------
    const uploadProfilePictur = (e) => {
        setIsLoading(true)
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, uploadImageFor.identity).then(result => {

            if (result !== null) {
                var temp = driverData;
                temp.license_photo = result.data.filePath;
                setdriverData(temp)

                var reader = new FileReader();
                reader.onload = function (e) {
                    setDriverPhoto(e.target.result)
                };
                reader.readAsDataURL(selectedFile);

                // document.getElementById("showProfile").innerHTML = '<img src="' + e.target.result + '"/>';
            }
        })
    }


    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}

            {/*Top Navbar Menu*/}
            <HomepageHeader />

            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page">
                        {/* Side bar */}
                        {/* <SidebarUserPanel /> */}
                        <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                            {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
                        </button>
                        {isVisible && (
                            <div>
                                <Sidebarmobile />
                            </div>
                        )}

                        <SidebarUserPanel />


                        <section class="right_panel">

                            <div class="">
                                <div className='profile_body'>
                                    <h3 className=' margin-mb-30'>Ambulance Driver Registration </h3>
                                       <div className='col-lg-10'>


                                       <div className='row'>
                                        <div className="col-lg-12"><h4 class="page_title">Service Provider</h4>  </div>
                                        <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Driver name</label>
                                                    <input type="text" class="form-control" name="driver_name" value={driver_name} onChange={(e) => handleInputs(e)} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">License Number</label>
                                                    <input type="text" class="form-control" name="license_number" value={license_number} onChange={(e) => handleInputs(e)} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Driver Contact Number</label>
                                                    <input type="text" class="form-control" name="mobile" value={mobile} onChange={(e) => handleInputs(e)} placeholder="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div class=" fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Another Contact number(if any)</label>
                                                    <input type="text" class="form-control" name="mobile2" value={mobile2} onChange={(e) => handleInputs(e)} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div class="fd_input mb-3">
                                                <div className='form-group'>
                                                    <label for="exampleFormControlInput1" class="form-label">Address</label>
                                                    <input type="text" class="form-control" name="address" value={address} onChange={(e) => handleInputs(e)} placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                             
                                       </div>

                                       <div className="col-lg-2">
                                            <div class="fd_input mb-3 mt-5">
                                                <div className='form-group mx-auto'>
                                                    <label for="exampleFormControlInput1" class="form-label">Upload File</label>
                                                    <div class="form-group profile-photo ">
                                                        <div className='profile-photo-sig heigth200' id='showProfile' style={{ margin: "0 0" }} onClick={() => handleProfileImage('profile')}>

                                                            {(driverPhoto) ? <img style={{ 'z-indez': '11111' }} src={driverPhoto} alt="Select Picture" /> : <p>Select Picture</p>}
                                                        </div>
                                                        <input id='profilePic' type={'file'} onChange={(e) => uploadProfilePictur(e)} />
                                                        {/* <button className='btn btn-info'>Upload</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='btn-drver-save'>
                                                <button className='btn btn-info' onClick={DriverRegistration}>Save</button>
                                            </div> 
                                        </div>
                                    <div className="row ">
                                        <div className='col-lg-12'>
                                            <div className='col-lg-12'><h3 className='margin-my-30'>Ambulance Driver List</h3></div>
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <div className='driver-top'>
                                                        <p style={{padding:"0 10px"}}>Show</p>
                                                        <select className='form-control'>
                                                            <option>10</option>
                                                            <option>20</option>
                                                            <option>30</option>
                                                        </select>
                                                        <p style={{padding:"0 10px"}}>Entries</p>
                                                    </div>
                                                </div>
                                                <div className='col-lg-7'></div>
                                                <div className='col-lg-3'>
                                                    <div className=''>
                                                        <div className=''>
                                                            <input type="text" class="form-control" placeholder="Search"
                                                                value={search_key}
                                                                onChange={(e) => { setSearch_key(e.target.value) }} />
                                                            <div className='input-group-append'>






                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />

                                            <table class="table table-striped table-bordered">
                                                <thead>

                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Mobile</th>
                                                        <th scope="col">Address</th>
                                                        <th scope="col">License Number</th>
                                                        <th scope="col"> Uploaded File</th>
                                                        <th scope="col">Edit</th>
                                                        <th scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(driverList.length > 0) ? driverList.map((key, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{key.driver_name}</td>
                                                            <td>{key.mobile}</td>
                                                            <td >{key.address}</td>
                                                            <td>{key.license_number}</td>
                                                            <td> <a href={key.license_photo} target="_blank" rel="noopener noreferrer">View</a></td>
                                                            <td ><i class="fa-solid fa-pen-to-square" data-toggle="modal" data-target="#myModal" onClick={() => getDriverById(key.id)}></i></td>
                                                            {/* open confimation modal after clickin on delete */}
                                                            <td ><i class="fa-solid fa-trash" data-toggle="modal" data-target="#deleteModel" onClick={() =>setdriverId(key.id) }></i></td>
                                                        </tr>
                                                    )) : <tr><td colSpan={8} className='text-center'>No Data Found</td></tr>}

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </section>
                    </div>
                </div>
            </section>
            {/* <!-- Modal --> */}
            <div id="myModal" class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">

                    {/* <!-- Modal content--> */}
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Update Driver Details</h4>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div class="fd_input mb-2">
                                        <div class="form-group" >
                                            <label>Driver Name</label>
                                            <input type="text" name="driver_name" class="form-control" placeholder="" value={driver_name} onChange={(e) => handleInputs(e)} />

                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-3">
                                    <div class="fd_input">
                                        < div class="form-group">
                                            <label>License Number</label>
                                            <input type="text" name="license_number" class="form-control" placeholder="" value={license_number} onChange={(e) => handleInputs(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="fd_input">
                                        <div class="form-group">
                                            <label>Driver Contact No</label>
                                            <input type="text" name="mobile" class="form-control" placeholder="" value={mobile} onChange={(e) => handleInputs(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="fd_input">
                                        <div class="form-group">
                                            <label>Another Contact No</label>
                                            <input type="text" name="mobile2" class="form-control" placeholder="" value={mobile2} onChange={(e) => handleInputs(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div class="fd_input">
                                        <div class="form-group">
                                            <label> Address</label>
                                            <input type="text" name="address" class="form-control" placeholder="" value={address} onChange={(e) => handleInputs(e)} />
                                        </div>
                                    </div>
                                </div>
                                {/* writing code to upload driver licence photo */}
                                <div className="col-lg-5">
                                    <div class="fd_input mb-3">
                                        <div className='form-group'>
                                            <label for="exampleFormControlInput1" class="form-label">Upload File</label>
                                            <div class="form-group profile-photo ">
                                                <div className='profile-photo-sig' id='showProfile' onClick={() => handleProfileImage('profile')}>

                                                    {(driverPhoto) ? <img style={{ 'z-indez': '11111' }} src={driverPhoto} alt="Select Picture" /> : <p>Select Picture</p>}

                                                </div>
                                                <input id='profilePic' type={'file'} onChange={(e) => uploadProfilePictur(e)} />
                                                {/* <button className='btn btn-info'>Upload</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <hr />

                            </div>
                            <hr />

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-info" onClick={() => updateDriver(id)}>Save</button>
                            {/* <button type="button" class="btn btn-info" data-toggle="modal" data-target="#myModal-001" onClick={addAmbulanceForService1}>Save</button> */}
                        </div>

                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}

            <div id="deleteModel" class="modal fade" role="dialog">
                <div class="modal-dialog modal-lg">

                    {/* <!-- Modal content--> */}
                    <div class="modal-content">

                        <h2 style={{marginBottom:"1rem", textAlign:"center"}}>Confirm Deletion</h2>
                        <p style={{marginBottom:"1rem", textAlign:"center"}}>Are you sure you want to delete?</p>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-info" onClick={() => deleteDriver(driverId)}>Confirm</button>
                            {/* <button type="button" class="btn btn-info" data-toggle="modal" data-target="#myModal-001" onClick={addAmbulanceForService1}>Save</button> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriverRegistration
