import React, { useEffect } from 'react'
import { Footer, HomepageHeader } from '../../components'
import { useState } from 'react'


const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])




    return (
        <>
            <HomepageHeader />
            <section className="banner_inner lohwheight">
                <div className="container">
                    <h1>Privacy Policy</h1>
                </div>
            </section>

            <section class="policy paddtop">
                <div class="container">
                    <h2>Introduction</h2>
                    <p>By using the services or by otherwise giving us your information, you will be deemed to have read, understood and agreed to the practices and policies outlined in this privacy policy and agree to be bound by the privacy policy. You hereby consent to our collection, use and sharing, disclosure of your information as described in this privacy policy. We reserve the right to change, modify, add or delete portions of the terms of this privacy policy, at our sole discretion, at any time. If you do not agree with this privacy policy at any time, do not use any of the services or give us any of your information. If you use the services on behalf of someone else (such as your child) or an entity (such as your employer), you represent that you are authorised by such individual or entity to (i) accept this privacy policy on such individual&rsquo;s or entity&rsquo;s behalf, and (ii) consent on behalf of such individual or entity to our collection, use and disclosure of such individual&rsquo;s or entity&rsquo;s information as described in this privacy policy.</p>

                    <h4>WHY THIS PRIVACY POLICY?</h4>
                    <p>This Privacy Policy is published in compliance with, inter alia:</p>

                    <p>Section 43A of the Information Technology Act, 2000;</p>

                    <p>Regulation 4 of the Information Technology (Reasonable Security Practices and&nbsp;</p>
                    <p>Procedures and Sensitive Personal Information) Rules, 2011 (the &ldquo;SPI Rules&rdquo;);</p>

                    <p>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</p>

                    <p>The purpose of this document (&ldquo;<b>Data Protection Policy</b>&rdquo;) is to inform you of how Fasttrack Health care Private Ltd. (&ldquo;<b>the Company</b>&rdquo;) manages Personal Data (as defined below) which is subject to the Indian Personal Data Protection Act 2018 (&quot;the Act&quot;) Please take a moment to read this Data Protection Policy so that you know and understand the purposes for which we collect, use and disclose your Personal Data.</p>

                    <p>By interacting with us, submitting information to us, signing up for or using our services, you agree and consent to the Company and its representatives or agents (collectively referred to as &ldquo;<b>FASTTRACK&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo; or &ldquo;our&rdquo;</b>) collecting, using, disclosing and sharing amongst ourselves your Personal Data, and disclosing such Personal Data to our authorised service providers and relevant third parties in the manner set forth in this Data Protection Policy.</p>

                    <p>This Data Protection Policy supplements but does not supersede nor replace any other consents you may have previously provided to the Company in respect of your Personal Data, and your consents herein are additional to any rights which we may have at law to collect, use or disclose your Personal Data.</p>

                    <p>FASTTRACK may from time to time update this Data Protection Policy to ensure that this Data Protection Policy is consistent with our future developments, industry trends and/or any changes in legal or regulatory requirements. Subject to your rights at law, you agree to be bound by the prevailing terms of the Data Protection Policy as updated from time to time on our website (<a data-fr-linked="true" href="https://fasttrackhealthcare.in/">https://fasttrackhealthcare.in/</a>).</p>

                    <h4>Personal Data</h4>
                    <p><b>1.1</b> In this Data Protection Policy, &ldquo;Personal Data&rdquo; refers to any data, whether true or not, about an individual who can be identified (a) from that data; or (b) from that data and other information to which we have or are likely to have access, including data in our records as may be updated from time to time.</p>

                    <p><b>1.2</b> Examples of such Personal Data you may provide to us include (depending on the nature of your interaction with us) your name, aadhar number or other identification number, telephone number(s), mailing address, email address and any other information relating to any individuals which you have provided us in any forms you may have submitted to us, or via other forms of interaction with you.</p>

                    <h4>Collection of Personal Data</h4>
                    <p><b>2.1</b> Generally, we collect Personal Data in the following ways:</p>

                    <p>When you submit any form, including but not limited to application and registration forms;</p>

                    <p>When you enter into any agreement or provide other documentation or information in respect of your interactions with us, or when you use or request for or are referred to our services;</p>

                    <p>When you are request a patient assessment or when you interact with our staff and affiliated care providers, via telephone calls (which may be recorded), letters, face-to-face meetings and emails;</p>

                    <p>When you use our electronic services, or interact with us or use our services via our websites and app;</p>

                    <p>When you request that we contact you or request that you be included in an email or other mailing list;</p>

                    <p>When you respond to our initiatives or to any request for additional Personal Data;&nbsp;</p>

                    <p>When you submit an employment application or when you provide documents or information including your resume and/or cvs in connection with any appointment as a care coordinator, nurse, medical care provider or any other position;</p>

                    <p>When you are contacted by, and respond to, our staff, nurses, doctors, agents, representatives and other service providers;</p>

                    <p>When you make payment or provide details to facilitate payment, or secure or administer the application of grants / benefits / subsidies;</p>

                    <p>When we seek information about you and receive your Personal Data in connection with your relationship with us, including for our services or job applications, for example, from healthcare partners, public agencies, your ex-employer, referral intermediaries and the relevant authorities; and/or</p>

                    <p>When you submit your Personal Data to us for any other reason.</p>

                    <p><b>2.2</b> When you browse our website, you generally do so anonymously and we do not, at our website, automatically collect Personal Data unless you provide such information.</p>

                    <p><b>2.3</b> If you provide us with any Personal Data relating to a third party (e.g. Information of your spouse, children, parents, and/or employees), by submitting such information to us, you represent to us that you have obtained the consent of the third party to provide us with their Personal Data for the respective purposes.</p>

                    <p><b>2.4</b> You should ensure that all Personal Data submitted to us is complete, accurate, true and correct. Failure on your part to do so may result in our inability to provide you with the services you have requested.</p>

                    <h4>Purpose for the Collection, Use and Disclosure of Your Personal Data</h4>
                    <p><b>3.1</b> Generally, FASTTRACK collects, uses and discloses your Personal Data for the following purposes:</p>

                    <p>Responding to your inquiries, feedback, complaints and requests;</p>

                    <p>Verifying your identity;</p>

                    <p>Making or obtaining payment managing the administrative and business operations of FASTTRACK conducting surveys, research and statistical analysis.</p>

                    <p>Matching any Personal Data held which relates to you for any of the purposes listed herein;</p>

                    <p>Detecting and preventing fraud, unlawful or improper activities;</p>

                    <p>Monitoring or recording phone calls and customer-facing interactions for quality assurance, employee training and performance evaluation and identity verification purposes;</p>

                    <p>In connection with any claims, actions or proceedings (including but not limited to drafting and reviewing documents, transaction documentation, obtaining legal advice, and facilitating dispute resolution), and/or protecting and enforcing our contractual and legal rights and obligations;</p>

                    <p>Meeting or complying with any applicable rules, laws, regulations, codes of practice or guidelines issued by any legal or regulatory bodies which are binding on FASTTRACK (including but not limited to responding to regulatory complaints, disclosing to regulatory bodies and conducting audit checks, due diligence and investigations); and/or</p>

                    <p>Promote complementary products or services to you from business partners with whom we have formed an association</p>

                    <p>Purposes which are reasonably related to the aforesaid.</p>

                    <p><b>3.2</b> In addition, FASTTRACK collects, uses and discloses your Personal Data for the following purposes depending on the nature of our relationship:</p>

                    <h4>(a) If you are an existing or prospective client of FASTTRACK:</h4>
                    <p>Obtaining information on the relevant individuals necessary to establish patient records and to commence treatment and care of the patient;</p>

                    <p>Managing your relationship with us, including providing medical treatment, services and advice, the management of your house visits, registration, advising you of alternative treatment options, purchasing of equipment and consumables on your behalf, sending notifications to you, communicating patient care issues and securing instructions on treatment choices;</p>

                    <p>Contacting your next-of-kin / caregivers / representatives / legal guardians for purposes of arranging home visits, providing medical updates, and seeking consent from them in emergency / incapacity situations;</p>

                    <p>Ensuring appropriate delivery of core patient care services other medical updates and facilitating the provision of healthcare services equipment;</p>

                    <p>Ensuring proper and complete diagnosis and appropriate treatment including and without limitation to identifying health / treatment risks (e.g. Collecting, identifying and communicating vulnerabilities, conditions, allergies, potentially adverse reactions etc.) And monitoring appropriateness of medication usage, specimen testing and reporting recording patient infection data;</p>

                    <p>Prescribing appropriate medication;</p>

                    <p>Ensuring a patient&rsquo;s health and safety;</p>

                    <p>Internal auditing, managing medical records, including facilitating patient merge processing and answering requests for medical records (including compilation of medical records for quick reference for medical purposes), and producing medical reports and associated administrative documents;</p>

                    <p>Co-ordinating healthcare services provided by other healthcare providers;</p>

                    <p>Managing referrals / collaboration with / transfer of patients to and from other institutions, healthcare professionals, caregivers, persons, organisations for procedures, additional support on treatment, specialist assistance, the procurement or provision of follow up care;</p>

                    <p>Reviewing and evaluating processes and outcomes for quality assurance, service improvement, policy and programme formulation and review;</p>

                    <p>Conducting medical reviews to better address your medical risks and improve patient care;</p>

                    <p>Complying with any laws, rules, guidelines and regulations or schemes imposed by any governmental authority including for the purpose of policy formulation and review; and/or</p>

                    <p>Purposes which are reasonably related to the aforesaid.</p>

                    <h4>(b) If you are a next-of-kin, family member, caregiver, representative or legal guardian of one of our patients:</h4>
                    <p>Processing your application for our services on behalf of the patient;</p>

                    <p>Facilitating the provision of our services (including but not limited to providing care assiatant, home-nursing care, home medical care and home therapy services);</p>

                    <p>Developing a care plan that best matches the patient&rsquo;s requirements with his home environment (including but not limited to providing you with training to equip you procedurally and emotionally to care for the patient);</p>

                    <p>Referring patient to &nbsp;other available resources and services;</p>

                    <p>Obtaining written consent for nursing procedures;</p>

                    <p>Contacting you in the event of an emergency; and/or</p>

                    <p>Purposes which are reasonably related to the aforesaid.</p>

                    <h4>(c) If you are an affiliated service provider or caregiving specialist providing medical and healthcare services for and on behalf of FASTTRACK:</h4>
                    <p>Administrative and support processes relating to your providing of medical and healthcare services, including logistics management, processing expense claims and scheduling your appointments and creating and maintaining your profile in our database;</p>

                    <p>Facilitating the communication between you and the patient and the patient&rsquo;s next-of-kin / caregivers / representatives / legal guardians;</p>

                    <p>Managing our relationship with you and ensuring medical records are kept up-to-date; and/or</p>

                    <p>Purposes which are reasonably related to the aforesaid.</p>

                    <h4>(d) If you are an employee, officer or owner of an external service provider or vendor outsourced or prospected by FASTTRACK:</h4>
                    <p>Assessing your suitability as an external service provider or vendor for FASTTRACK;</p>

                    <p>Managing project tenders and quotations, processing orders or managing the supply of goods and services;</p>

                    <p>Processing and payment of vendor invoices and bills;</p>

                    <p>Managing business operations;</p>

                    <p>Purposes which are reasonably related to the aforesaid.</p>

                    <h4>(e) If you submit an application to us as a candidate for employment or representative position:</h4>
                    <p>Conducting interviews;</p>

                    <p>Processing your application (including but not limited to pre-recruitment checks involving your qualifications and facilitating interviews);</p>

                    <p>Obtaining employee references to conduct background screening;</p>
                    <p>Assessing your suitability for the position applied for;</p>

                    <p>Onboarding successful applicants; and/or</p>

                    <p>Purposes which are reasonably related to the aforesaid.</p>

                    <p><b>3.3</b> Furthermore, where permitted under the Act, FASTTRACK may also collect, use and disclose your Personal Data for the following purposes:</p>

                    <p>Communicating to you advertisements involving details of our services, special offers and promotions, either to you generally, or which we have identified may be of interest to you;</p>

                    <p>Conducting market research and surveys to enable us to understand and determine preferences and demographics for us to offer you services as well as special offers and marketing programmes which may be relevant to your preferences and profile; and/or</p>

                    <p>Purposes which are reasonably related to the aforesaid.</p>

                    <p><b>3.4</b> If you have provided your contact number(s) and have indicated that you consent to receiving marketing or promotional information via your contact number(s), then from time to time, FASTTRACK may contact you using such contact number(s) (including via voice calls, text, email or other means) with information about our services.</p>

                    <p><b>3.5</b> In relation to particular services or in your interactions with us, we may also have specifically notified you of other purposes for which we collect, use or disclose your Personal Data. If so, we will collect, use and disclose your Personal Data for these additional purposes as well, unless we have specifically notified you otherwise.</p>

                    <p><b>3.6</b> You have a choice to withdraw your consent for receiving marketing or promotional materials/communication. You may contact us using the contact details found below.</p>

                    <p><b>3.7</b> Please be aware that once we receive confirmation that you wish to withdraw your consent for marketing or promotional materials/communication, it may take up to 30 working days for your withdrawal to be reflected in our systems. Therefore, you may still receive marketing or promotional materials/communication during this period of time. Please note that even if you withdraw your consent for the receipt of marketing or promotional materials, we may still contact you for other purposes in relation to the services that you have requested from FASTTRACK.</p>

                    <h4>Disclosure of Personal Data</h4>
                    <p><b>4.1</b> FASTTRACK will take reasonable steps to protect your Personal Data against unauthorised disclosure. Subject to the provisions of any applicable law, your Personal Data may be disclosed, for the purposes listed above (where applicable), to the following entities or parties, whether they are located overseas or in India:</p>
                    <p>Other healthcare partners, healthcare institutions and parties providing referrals of patients to FASTTRACK;</p>

                    <p>Our affiliated caregiving specialists;</p>

                    <p>Caretakers of patients, including next-of-kin, caregivers, representatives and legal guardians;</p>

                    <p>Agents, contractors or third party service providers who provide operational services to FASTTRACK, such as laboratory and medical research services, courier services, telecommunications, information technology, payment, printing, billing, technical services, training, market research, security or other services to FASTTRACK;</p>
                    <p>External banks and their respective service providers;</p>

                    <p>Our professional advisers such as consultants, auditors and lawyers;</p>

                    <p>Relevant government ministries, regulators and professional regulatory bodies, registries, statutory boards or authorities or law enforcement agencies to comply with any laws, rules, guidelines and regulations or schemes imposed by any governmental authority; and/or</p>
                    <p>Any other party to whom you authorise us to disclose your Personal Data to.</p>

                    <h4>Contacting Us &ndash; Feedback, Withdrawal of Consent, Access and Correction of your Personal Data</h4>
                    <p><b>5.1</b> If you have any questions or feedback relating to your Personal Data or our Data Protection Policy;</p>

                    <p>Would like to withdraw your consent to any use of your Personal Data as set out in this Data Protection Policy; or</p>

                    <p>Would like to obtain access and make corrections to your Personal Data records, you can approach us via the following channels:</p>

                    <p>Email: <a data-fr-linked="true" href="mailto:support@fasttrackhealthcare.in">support@fasttrackhealthcare.in</a></p>

                    <p><b>5.2</b> Please note that if your Personal Data has been provided to us by a third party (e.g. A referrer, or your company), you should contact such party directly to make any queries, feedback, complaints, and access and correction requests to FASTTRACK on your behalf.</p>

                    <p><b>5.3</b> If you withdraw your consent to any or all use of your Personal Data, depending on the nature of your request, FASTTRACK may not be in a position to continue to provide its services to you, administer any contractual relationship in place, which in turn may also result in the termination of any agreements with FASTTRACK, and your being in breach of your contractual obligations or undertakings. FASTTRACK&rsquo;s legal rights and remedies in such event are expressly reserved.</p>


                </div>
            </section>


            <Footer />
        </>
    )
}

export default PrivacyPolicy