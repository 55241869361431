import { CheckBox } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
import { Specialization } from '../../services/DoctorOnboarding'
import { toast } from 'react-toastify';

const NurseRegistration = ({ incrementBtn, profileData, setGender, setServices, setCity, token }) => {
    const [check, setCheck] = useState(false)
    // try {

    //     if (token!=null) {
    //         Specialization()
    //         .then((res) => {
    //             console.log(res);
    //           let isSuccess = res.success;
    //           if (isSuccess) {
    //             return toast.success("Identity successfully updated")
    //           }
    //         })
    //         .catch();
    //     } else if (token.length <= 0) {
    //       console.log("hello")
    //       toast.error("Something went wrong , Please try again");
    //     }
    //   } catch (error) {
    //   }
    // if (document.getElementById('flexRadioDefault1').checked) {
    //     let rate_value = document.getElementById('flexRadioDefault1').value;
    //     setGender(rate_value)
    //     console.log(rate_value)
    //   }
    const selectGender = (gen) => {
        if (gen === 'Male') {
            setGender('Male')
        }
        else if (gen === 'Female') {
            setGender('Female')
        }
        else {
            setGender('Other')
        }
    }
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="profile_form1">
                    <div className="row">
                        <div className="col-lg-9">
                            <h3 className="drprofile_title">Medical Registration</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="form-group">
                                <label>Name</label>
                                <div className="input-group">
                                    <span className="input-group-addon">Dr.</span>
                                    <input type="text" value={profileData.name} className="form-control" placeholder="Please enter your name" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="form-group">
                                <h4>Services</h4>
                                <div className='checkbox-doctor'>
                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Nanny" />
                                    <label for="vehicle1"> Nanny &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Old Age Care" />
                                    <label for="vehicle1"> Old Age Care &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Patient Care" />
                                    <label for="vehicle1"> Patient Care  </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="form-group gender-fixing">
                                <label>Gender</label>
                                <div className="radioblock">
                                    <p>
                                        <input type="radio" id="test1" onClick={(e) => selectGender('Male')} name="radio-group" defaultChecked />
                                        <label htmlFor="test1">Male</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="test2" onClick={(e) => selectGender('Female')} name="radio-group" />
                                        <label htmlFor="test2">Female</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="test3" onClick={(e) => selectGender('Other')} name="radio-group" />
                                        <label htmlFor="test3">Other</label>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="form-group ">
                                <label>City</label>
                                <select className="form-control" onChange={(e) => setCity(e.target.value)}>
                                    <option>Select city</option>
                                    <option>Rewa</option>
                                    <option>Bhopal</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-9">
                            <div className>
                                <button onClick={incrementBtn} className="btn btn-theme btn-lg btn-block redius10">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NurseRegistration