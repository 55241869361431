import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { HomepageHeader, SidebarUserPanel } from '.';
import moment from 'moment'
import { Change_to_12HourFormat } from '../helpers/Change_to_12HourFormat';
import { ProfileAndSlotService } from '../services';
import { ShowProfileType } from '../enums/Enums';
import CheckCircle from "../images/images/check-circle.png"
import userIcon from "../images/adminpanel/UserIcon.svg"
import { useNavigate, useParams } from 'react-router';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import APICore from '../services/APICore';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner'
import Sidebarmobile from './user/Sidebarmobile';
import BookingsVisitsummary from '../pages/Bookingvisitsummary';
import { set } from 'date-fns';
import userDashboardServices from '../services/userDashboardServices';
import { BookingServices } from '../services';
import { Pending } from '@mui/icons-material';

const baseurl = process.env.REACT_APP_API_BASE_URL
const token = localStorage.getItem("token")

const AccountBookingComponent = ({ loadcarddata, selfDetails, data, isloading, setStatus, status, type, setType, service, carddata, sortby, setsortby, setCardData, filterset, setHomecareData, setAmbulanceData, homecareData, ambulanceData }) => {

    const apiFunction = new ProfileAndSlotService();
    const apiFunction2 = new userDashboardServices();
    const apiFunction3 = new BookingServices();
    const navigateTo = useNavigate()
    const [showAddPrescription, setShowAddPrescription] = useState(false)
    const pagination = useRef();
    const [offset, setOffset] = useState(0);
    const perPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(1);
    const [disableTrue, setDisableTrue] = useState(false)
    const [filter, setfilter] = useState("All")
    const [bookingList2, setbookingList2] = useState([])

    const [bookingList, setBookingList] = useState([])
    const [homecareBookingList, setHomecareBookingList] = useState([])
    const [ambulanceBookingList, setAmbulanceBookingList] = useState([])
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [bookingId, setBookingId] = useState("")
    const [homecareName, setHomecareName] = useState("")
    const [appointmentDate, setAppointmentDate] = useState("")
    const [appointmentTime, setAppointmentTime] = useState("")
    const [bookingItemId, setBookingItemId] = useState("")
    const [scheduleType, setScheduledType] = useState("")
    const [driverId, setDriverId] = useState("")
    const [ambulace_id, setAmbulace_id] = useState("")
    const [ambulanceList, setAmbulanceList] = useState([])
    const [driverList, setDriverList] = useState([])
    const [appointmentTimeList, setAppointmentTimeList] = useState([])
    const [availablity_id, setAvailablity_id] = useState("")
    const [nurseUserId, setNurseUserId] = useState("")
    const [feedback, setFeedback] = useState("")

    const [remaining_amount, setRemainingAmount] = useState("")

    useEffect(() => {
        if (carddata !== null) {
            setBookingList(carddata)
            setbookingList2(carddata)
            if (homecareData !== null) {
                setHomecareBookingList(homecareData)
            }
            if (ambulanceData !== null) {
                setAmbulanceBookingList(ambulanceData)
            }
            status = "Upcoming"
        }

    }, [carddata], [homecareData], [ambulanceData])


    const [searchVal, setSearchVal] = useState("");
    const [userData, setUserdata] = useState({
        username: "",
        bio: "",
        profile_pic: "",
        qualification: "",
        specialization: "",
        experience: "",
        consultation_fees: "",
        video_consult: "",
        slots: [],
        patientStories: []
    })
    const [statuschng, setstatuschng] = useState("Cancelled")
    useEffect(() => {
        var temp = bookingList
        if (searchVal.length > 2) {
            filterthedata(searchVal, temp)
        }
        else {
            setBookingList(carddata)
        }

    }, [searchVal, filter])
    const filterthedata = (value, list) => {
        if (type == "user" && list.length > 0) {

            list = list.filter(item =>
                item.provider_name.toLowerCase().includes(value.toLowerCase()) ||
                item.service.toLowerCase().includes(value.toLowerCase())
            )
        }
        else {
            list = list.filter(item =>
                item.user_name.toLowerCase().includes(value.toLowerCase()) ||
                item.service_type.toLowerCase().includes(value.toLowerCase())
            )
        }
        setBookingList(list)
    }
    useEffect(() => {
        if (filter !== null && filter !== "")
            FilterList(filter)
    }, [filter])


    const FilterList = (filtervalue) => {

        var templist = bookingList;
        var templist2 = bookingList2;

        if (filtervalue === "Ongoing") {
            setBookingList(templist)
        }
        else if (filtervalue === "Confirmed") {

            templist = templist2.filter(x => x.is_confirmed === 1)

            setBookingList(templist)


        }
        else if (filtervalue === "Pending") {

            templist = templist2.filter(x => x.is_confirmed === 0)
            setBookingList(templist)

        }
        else {
            setBookingList(templist)
        }
    }


    // const filterDataBySearch = () => {
    //     let dataArr = carddata.filter(item =>
    //         item.provider_name.toLowerCase().includes(searchVal.toLowerCase()) ||
    //         item.service_type.toLowerCase().includes(searchVal.toLowerCase())
    //     )
    //     setTotalData(dataArr.length)
    //     if (dataArr.length > 0) {
    //         const slice = dataArr.slice(offset, offset + perPage);
    //         setPaginateList(slice);
    //         setPageCount(Math.ceil(dataArr.length / perPage));
    //     } else {
    //         setPaginateList([]);
    //     }
    // }

    // Debounce function
    // const debounce = (fn, delay) => {
    //     let timer;
    //     return (() => {
    //         clearTimeout(timer);
    //         timer = setTimeout(() => fn(), delay);
    //     })();//ifee-->  Immediately Invoked Function Expression
    // };


    const bookingcancel = async (id, service) => {
        try {
            var data = {
                "status": statuschng // Make sure 'statuschng' is defined
            };
    
            var config = {
                headers: {
                    'authorization': token, // Make sure 'token' is defined
                    'Content-Type': 'application/json'
                },
                data: data
            };
    
            let result;
            if (service === "Doctor") {
                result = await axios.put(`${baseurl}bookings/changebookingstatus/${id}`, JSON.stringify(data), config)
            } else if (service === "Nurse") {
                result = await axios.put(`${baseurl}homecareBooking/bookingItem/${id}`, JSON.stringify(data), config)
            } else if (service === "Ambulance") {
                result = await axios.put(`${baseurl}ambulances/bookings/${id}`, JSON.stringify(data), config)
            }
    
            if (result != null && result !== undefined) {
                if (result.status === 200) {
                    toast.success("Booking Cancelled Successfully");
                    await loadcarddata(); // Refresh the page
                }
            }
        } catch (error) {
            // Handle other errors
            console.error("Error:", error);
            toast.error("Something went wrong");
        }
    }
    

    const scheduleHomecareVisit = async () => {
        try {
            var data = {
                "booking_id": bookingId,
                "appointment_date": appointmentDate.slice(0, 10),
                "appointment_time": appointmentTime,
                "availablity_id": availablity_id
            };

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.put(`${baseurl}homecareBooking/schedule/${bookingItemId}`, JSON.stringify(data), config)
            if (result != null && result !== undefined) {
                toast.success("Booking scheduled sucessfully")

                const modal = document.getElementById('myModal');
                if (modal) {
                    const bootstrapModal = window.jQuery(modal);
                    bootstrapModal.modal('hide');
                }
                await loadcarddata()
            }

        } catch (error) {
        }

    }

    const rescheduleHomecareBooking = async () => {
        try {
            var data = {
                "booking_id": bookingId,
                "appointment_date": appointmentDate.slice(0, 10),
                "appointment_time": appointmentTime,
                "availablity_id": availablity_id
            };

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.put(`${baseurl}homecareBooking/reschedule/${bookingItemId}`, JSON.stringify(data), config)
            if (result != null && result !== undefined) {
                toast.success("Booking Rescheduled sucessfully")

                const modal = document.getElementById('myModal');
                if (modal) {
                    const bootstrapModal = window.jQuery(modal);
                    bootstrapModal.modal('hide');
                    set
                }
                await loadcarddata()

            }

        } catch (error) {
        }

    }

    const ConfirmOrrejectHomecareVisit = async (booking_item_id, itemStatus) => {
        try {
            var data = {
                "is_confirmed": itemStatus
            };

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.put(`${baseurl}homecareBooking/confirmOrReject/${booking_item_id}`, JSON.stringify(data), config)

            if (result !== null && result !== undefined && itemStatus == 1) {
                toast.success("Booking Confirmed sucessfully")
                await loadcarddata()
            } else if (result != null && result !== undefined && itemStatus == 0) {
                toast.error("Booking Rejected sucessfully")
                await loadcarddata()
            }

        } catch (error) {
        }

    }

    function randomID(len) {
        let result = '';
        if (result) return result;
        var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
            maxPos = chars.length,
            i;
        len = len || 5;
        for (i = 0; i < len; i++) {
            result += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return result;
    }

    const AcceptRejectBooking = (bookingid, status, service1) => {
        let roomid = ""
        if (service1 === "Doctor") {
            roomid = randomID(5)
        }
        apiFunction2.AcceptBookingApi(bookingid, status, roomid).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    if (status) {
                        toast.success("Booking successfully accepted.")
                    }
                    else {
                        toast.success("Booking successfully rejected.")
                    }
                    loadcarddata()


                }
            }
        })
    }

    const addRemainingamount = (bookingid) => {
        apiFunction2.AddRemainingPayableAmount(bookingid, remaining_amount).then(result => {
            if (result !== undefined && result.success === true) {
                toast.success("Amount successfully Added.")
                loadcarddata()

            }
        })
        setIsCheckboxChecked(false)
    }

    const handleBookAndPay = () => {
        if (scheduleType === 'Pending') {
            scheduleHomecareVisit();
        } else if (scheduleType !== 'Pending') {
            rescheduleHomecareBooking();
        }
        setIsCheckboxChecked(false)
    };

    const RequestOrComplete = async (id, serviceStatus, service) => {

        try {
            var data = {
                "service_status": serviceStatus
            };
            var dataForNurse = {
                "item_service_status": serviceStatus,
            }
            let result

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            if (service === 'Nurse') {
                result = await axios.put(`${baseurl}homecareBooking/serviceStatus/${id}`, JSON.stringify(dataForNurse), config)
            }
            else if (service === 'Ambulance') {
                result = await axios.put(`${baseurl}ambulances/bookings/serviceStatus/${id}`, JSON.stringify(data), config)
            }

            if (result != null && result !== undefined) {
                if (serviceStatus === "Completed")
                    toast.success("Booking Completed Sucessfully")
                else if (serviceStatus === "Requested")
                    toast.success("Booking completion Requested Sucessfully")
                else if (serviceStatus === "Rejected")
                    toast.success("Booking Rejected Sucessfully")
                // window.location.reload()
                await loadcarddata()
            }
        } catch (error) {
        }

    }


    const loadServiceProviderProfile = (userid, serviceType) => {
        if (serviceType === ShowProfileType.type1) {
            apiFunction.LoadDoctorProfileAndSlots(userid).then(result => {
                if (result !== undefined) {

                    if (result.success === true) {
                        setUserdata({
                            username: result.data.doctor.name,
                            bio: result.data.doctor.bio,
                            profile_pic: result.data.doctor.profile_pic,
                            qualification: result.data.doctor.qualification,
                            specialization: result.data.doctor.specialization,
                            experience: result.data.doctor.experience,
                            consultation_fees: result.data.doctor.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }

            })
        } else if (serviceType === ShowProfileType.type2) {
            apiFunction.LoadNursesProfileAndSlots(userid).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.nurse.name,
                            bio: result.data.nurse.bio,
                            profile_pic: result.data.nurse.profile_pic,
                            qualification: result.data.nurse.qualification,
                            specialization: result.data.nurse.specialization,
                            experience: result.data.nurse.experience,
                            consultation_fees: result.data.nurse.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }

            })

        }
        else if (serviceType === ShowProfileType.type3) {
            apiFunction.LoadAmbulancesProfileAndSlots(userid).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.ambulance.name,
                            bio: result.data.ambulance.bio,
                            profile_pic: result.data.ambulance.profile_pic,
                            qualification: result.data.ambulance.qualification,
                            specialization: result.data.ambulance.specialization,
                            experience: result.data.ambulance.experience,
                            consultation_fees: result.data.ambulance.consultation_fees,
                            slots: result.data.slots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: []
                        })
                    }
                }
            })
        }

    }
    const joinCallNow = (roomId) => {
        //navigateTo('/video-call', { state: { roomId } })
    }
    let todayDate = new Date()
    let a = new Date((todayDate.getMonth() + 1) + "-" + (todayDate.getUTCDate() + 1) + "-" + todayDate.getUTCFullYear())
    let today = (moment(a).utc()).format(`DD/MM/YYYY`)


    const getLocalToUTC = (date) => {
        const finalDate = new Date(date);
        const utcDate = new Date(finalDate.getTime() - (finalDate.getTimezoneOffset() * 60000));
        return utcDate;
    }
    const [isVisible, setIsVisible] = useState(false);

    const onClickHomecare = (booking_id, name, id, schedule_type, nurseId) => {
        setBookingId(booking_id)
        setHomecareName(name)
        setBookingItemId(id)
        setScheduledType(schedule_type)
        setNurseUserId(nurseId)

    }


    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };
    const isBookAndPayEnabled = appointmentDate && appointmentTime && isCheckboxChecked;
    const isBookAndPayEnabled2 = remaining_amount && isCheckboxChecked;
    const isBookAndPayEnabled3 = driverId && ambulace_id && isCheckboxChecked;
    //fn for openign modal to assign ambulance
    const openAssignAmbulanceModal = (id) => {
        setBookingId(id)
        const modal = document.getElementById('modelForAssignAmbulanceToDriver');
        if (modal) {
            const bootstrapModal = window.jQuery(modal);
            bootstrapModal.modal('show');
        }

    }

    const assignAmbulance = async () => {
        try {
            var data = {
                "booking_id": bookingId,
                "driver_id": driverId,
                "ambulance_id": ambulace_id,
            };

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.post(`${baseurl}assignAmbulance`, JSON.stringify(data), config)
            if (result != null && result !== undefined) {
                toast.success("Ambulance assigned sucessfully")
                const modal = document.getElementById('myModal');
                if (modal) {
                    const bootstrapModal = window.jQuery(modal);
                    bootstrapModal.modal('hide');
                }
                await loadcarddata()
            }
        }
        catch (error) {
            console.log(error)
        }
        setIsCheckboxChecked(false)
    }


    const findAmbulanceList = async () => {
        try {
            var config = {
                headers: {
                    'authorization': token
                }
            };
            const result = await axios.get(`${baseurl}ambulances/list`, config)
            if (result !== null && result !== undefined) {
                setAmbulanceList(result.data.data.approvedAmbulances)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const getDriverList = async () => {
        try {
            let user_id = localStorage.getItem("userid")
            var config = {
                headers: {
                    'authorization': token
                }
            };
            const result = await axios.get(`${baseurl}ambulanceDriver/user/${user_id}`, config)
            if (result !== null && result !== undefined) {
                setDriverList(result.data.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        findAmbulanceList()
        getDriverList()
    }, [])

    // For Paytm Stringify input field values
    function stringifyValue(val) {
        if (isObj(val) && !isDate(val)) {
            return JSON.stringify(val)
        } else {
            return val
        }
    }

    function isDate(val) {
        // Cross realm comptatible
        return Object.prototype.toString.call(val) === '[object Date]'
    }

    function isObj(val) {
        return typeof val === 'object'
    }

    // Making a custom form by this code
    function buildForm({ action, params }) {
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', action)

        Object.keys(params).forEach(key => {
            const input = document.createElement('input')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', key)
            input.setAttribute('value', stringifyValue(params[key]))
            form.appendChild(input)
        })
        return form
    }
    function goToPaymentPage_Post_Information(details) {
        const form = buildForm(details)
        document.body.appendChild(form)
        form.submit()
        form.remove()
    }

    const payRemainingAmount = async (id, remainingAmount) => {
        localStorage.setItem("remaining_amount", remainingAmount)

        const merchantId = process.env.REACT_APP_PayTM_MID
        const paytmOpenPaymentPageUrl = "https://securegw.paytm.in/theia/api/v1/showPaymentPage"

        try {

            localStorage.setItem("BookingId", id)
            const datanew = {
                amount: remainingAmount,
                requestfrom: "WEB",
                booking_id: id,
            }
            apiFunction3.PaymentOrder(datanew).then(result => {
                if (result !== undefined) {
                    if (result.success === true) {

                        const transaction_token = result.data.transaction_token
                        const order_id = result.data.order_id

                        var information = {
                            action: `${paytmOpenPaymentPageUrl}?mid=${merchantId}&orderId=${order_id}`,
                            params: {
                                mid: merchantId,
                                orderId: order_id,
                                txnToken: transaction_token,
                                TXN_AMOUNT: remaining_amount / 100,
                                CURRENCY: "INR",
                                WEBSITE: "WEBSTAGING"
                            }
                        }

                        localStorage.setItem("paymentAmount", remaining_amount)
                        // this is function that show's paytm gate way
                        goToPaymentPage_Post_Information(information)

                    }
                }
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const getAppointmentTimeList = async (appointment_date1) => {
        setAppointmentDate(appointment_date1)
        var config = {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },

        };
        const result = await axios.get(`${baseurl}/homecareAvailablity/user/${nurseUserId}/date/${appointment_date1}`, config)
        if (result !== undefined) {
            if (result.data.success === true) {
                setAppointmentTimeList(result.data.data)
            }
        }
    }

    const getAvailabilityId = (e) => {
        const id2 = e.target.value.split("-")[1]
        const appointment_time1 = e.target.value.split("-")[0]
        setAvailablity_id(id2)
        //set appointment time of getting value of appointment_time1
        setAppointmentTime(appointment_time1)
    }

    useEffect(() => {
        selectDate()
    }, [appointmentDate])

    const selectDate = (event) => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var today = tomorrow.toISOString().split('T')[0];
        document.getElementById("birthdaytime1").setAttribute("min", today);
    }

    const addFeedback = async () => {
        try {
            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },

            };
            var data = {
                "feedback": feedback,
            };
            const result = await axios.put(`${baseurl}/bookings/feedback/${bookingId}`, JSON.stringify(data), config)
            if (result !== undefined) {
                if (result.data.success === true) {
                    toast.success(result.data.message)
                    setFeedback("")
                }
                setFeedback("")
            }
            else {
                toast.error(result.data.message)
                setFeedback("")
            }
        }
        catch (error) {
            console.log(error)
        }
    }






    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        {/* <SidebarUserPanel /> */}
                        <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                            {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
                        </button>
                        {isVisible && (
                            <div>
                                <Sidebarmobile />
                            </div>
                        )}

                        <SidebarUserPanel />
                        <div className="right_panel ">
                            <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                <section class="content content-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="content-header">
                                                <h1 class="pull-left">
                                                    My Bookings
                                                </h1>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="spacetop">
                                        <div className='row'>
                                            <div className='col-md-6' >
                                                <div class="tab">
                                                    <button onClick={() => setStatus("Upcoming")}
                                                        className={status === "Upcoming" ? 'active' : ""}>
                                                        Upcoming
                                                    </button>
                                                    <button onClick={() => setStatus("Completed")}
                                                        className={status === "Completed" ? 'active' : ""}>Completed</button>
                                                    <button onClick={() => setStatus("Cancelled")}
                                                        className={status === "Cancelled" ? 'active' : ""}>Cancelled</button>
                                                    <button onClick={() => setStatus("Rejected")}
                                                        className={status === "Rejected" ? 'active' : ""}>Rejected</button>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <input type="text" value={searchVal} onChange={(e) => setSearchVal(e.target.value)} className='form-control' placeholder='Search...' />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <select className='form-control' value={filter} onChange={(e) => setfilter(e.target.value)}>
                                                            <option value={""}>All</option>
                                                            <option value={"Ongoing"}>Ongoing</option>
                                                            <option value={"Pending"}>Pending</option>
                                                            <option value={"Confirmed"}>Confirmed</option>
                                                        </select>
                                                    </div>
                                                    {/* <div className='col-md-4'>
                                                        <select className='form-control' value={sortby} onChange={(e) => setsortby(e.target.value)}>
                                                            <option value={""}>Sort by Date</option>
                                                            <option value={"service"}>Sort by Service</option>
                                                            <option value={"service_provider"}>Sort by Service Provider</option>
                                                        </select>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        {(!isloading) ?
                                            <div className='profile_body'>
                                                <div className='row'>

                                                    <h4 className='hedding-proper-p-m'>Teleconsultation Available Bookings</h4>
                                                    {(bookingList !== null && bookingList.length > 0) ?

                                                        (bookingList !== null && bookingList !== undefined && bookingList.length > 0) &&
                                                        bookingList.map((key) => (

                                                            <div className='col-lg-4'>
                                                                <div class="db_box">
                                                                    <div className='db_header'>
                                                                        <h5>{(key.service === "Doctor") ? "Teleconsultation" : (key.service === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                        <div className='dbh_right'>
                                                                            <p>#{key.id}</p>
                                                                            <div className='dropdown' >
                                                                                <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                                <ul class="dropdown-menu ">
                                                                                    <li><Link to={`/bookings-summary/${key.id}`} state={{ type: type }}>Booking Info</Link></li>
                                                                                    {type === "user" && key.service_type === "Doctor" && key.status === "Completed" &&
                                                                                        <li>  <a data-toggle="modal" data-target="#modelForAddingFeedback" onClick={e => setBookingId(key.id)}>Give Feedback</a></li>
                                                                                    }
                                                                                    {type === "service" && key.service_type === "Doctor" && key.is_confirmed === 0 &&
                                                                                        <li>   <a onClick={e => AcceptRejectBooking(key.id, false, key.service_type)}>Reject</a></li>
                                                                                    }
                                                                                    {
                                                                                        (type === "user" && key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") && key.status.toLowerCase() !== "rejected" &&
                                                                                        <> {(moment.utc(getLocalToUTC(new Date())).add(240, 'minutes') >= moment.utc(key.appointment_date)) ? ""
                                                                                            : <> <li><Link onClick={() => bookingcancel(key.id, key.service)}>Cancel</Link></li>
                                                                                                <li key={key.service_provider_id + "doctorsNursesAmbulances-list"}>
                                                                                                    <Link to="/show-profile-and-slots"
                                                                                                        state={{
                                                                                                            userid: key.provider_id,
                                                                                                            searchType: key.service_type,
                                                                                                            bookingid: key.id,
                                                                                                            scheduleType: "Reschedule"
                                                                                                        }}
                                                                                                    >
                                                                                                        Rescheduled
                                                                                                    </Link>
                                                                                                </li>
                                                                                            </>}

                                                                                        </>
                                                                                    }
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className='db_body'>
                                                                        <div class="infono">
                                                                            <div className='infpo_icon'>
                                                                                {type === "user" ? <img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt="user img" />
                                                                                    : <img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />}
                                                                            </div>
                                                                            <div className='info_text'>
                                                                                <h5>{type === "user" ? key.provider_name : key.booked_by}</h5>
                                                                                {type === "user" ?
                                                                                    <p>{(key.headline)},{key.city}</p>
                                                                                    : <p>{key.booked_for === "self" ?
                                                                                        <p>{(key.gender)},{key.city}</p>
                                                                                        : <p>{key.other_gender},{key.city}</p>
                                                                                    }</p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='db_footer'>
                                                                        <div className='date'>
                                                                            {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}
                                                                        </div>

                                                                        <div>
                                                                            {(key.service_type === 'Doctor' && key.is_confirmed === 1 && key.status === 'Booked' && ((moment.utc(getLocalToUTC(new Date())).add(1, 'days').startOf('day') >= moment.utc(key.appointment_date).startOf('day')) &&
                                                                                (moment.utc(getLocalToUTC(new Date())).subtract(1, 'days').endOf('day') <= moment.utc(key.appointment_date).endOf('day')))) ? (
                                                                                <Link to="/video-call" state={{ roomId: key.room_id || "12345" }}>
                                                                                    <i className='fa fa-video'></i> Join Call
                                                                                </Link>
                                                                            ) : (type === 'service' && key.is_confirmed === 0 && key.status === 'Booked' ? (
                                                                                <button className='btn btn-sm btn-success' onClick={e => AcceptRejectBooking(key.id, true, key.service_type)}>Accept</button>
                                                                            ) : (key.is_confirmed === 1 && key.status == 'Booked') ? (
                                                                                <a style={{ color: 'green' }}>Confirmed</a>
                                                                            ) : (key.is_confirmed === 0 && key.status === 'Booked') ? (
                                                                                <a style={{ color: 'Orange' }}>Pending</a>

                                                                            ) : (<a style={{ color: 'orange' }}>{key.status}</a>
                                                                            ))}
                                                                        </div>



                                                                    </div>
                                                                </div>

                                                            </div>

                                                        )) : <> <p className='no-data'>"No Data Found"</p>

                                                        </>
                                                    }

                                                </div>

                                                <div className='row'>

                                                    <h4 className='hedding-proper-p-m'>Homecare Available Visits</h4>
                                                    {(homecareBookingList !== null && homecareBookingList.length > 0) ?

                                                        (homecareBookingList !== null && homecareBookingList !== undefined && homecareBookingList.length > 0) &&
                                                        homecareBookingList.map((key) => (

                                                            <div className='col-lg-4'>
                                                                <div class="db_box">
                                                                    <div className='db_header'>
                                                                        <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                        <div className='dbh_right'>
                                                                            <p>#{key.booking_id}</p>
                                                                            <div className='dropdown'>
                                                                                <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                                <ul class="dropdown-menu">
                                                                                    <li><Link to={`/bookings-visit-summary/${key.id}`} state={{ type: "Nurse" }}>Booking Info</Link></li>
                                                                                    {type === "user" && key.status === "Completed" &&
                                                                                        <li>  <a data-toggle="modal" data-target="#modelForAddingFeedback" onClick={e => setBookingId(key.booking_id)}>Give Feedback</a></li>
                                                                                    }
                                                                                    {type === "service" && key.status === 'Accepted' && key.item_service_status !== 'Completed' && (
                                                                                        <li><Link onClick={() => RequestOrComplete(key.id, 'Requested', key.service_type)}>Request For Completion</Link></li>
                                                                                    )}
                                                                                    {(type === "service" && key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") && key.status.toLowerCase() !== "rejected" &&
                                                                                        key.is_confirmed === 0 && key.status === 'Scheduled' && (
                                                                                            <li><Link onClick={() => ConfirmOrrejectHomecareVisit(key.id, false)}>Reject</Link></li>
                                                                                        )}
                                                                                    {type === "user" && key.status !== 'Completed' && key.status !== 'Cancelled' && key.status !== 'Rejected' && (
                                                                                        <>
                                                                                            {(key.item_service_status === 'Requested') && (
                                                                                                <li><Link onClick={() => RequestOrComplete(key.id, 'Completed', key.service_type)}>Complete Request</Link></li>
                                                                                            )}
                                                                                            {(key.status === 'Scheduled' || key.status === 'Accepted') && (
                                                                                                <li data-toggle="modal" data-target="#myModal" onClick={() => onClickHomecare(key.booking_id, key.homecare_name, key.id, key.status, key.nurse_user_id)}><Link>Reschedule</Link></li>
                                                                                            )}

                                                                                            {key.status !== 'Completed' && key.status !== 'Cancelled' && key.status !== 'Rejected' && (
                                                                                                <li><Link onClick={() => bookingcancel(key.id, key.service_type)}>Cancel</Link></li>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </ul>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div className='db_body'>
                                                                        <div class="infono">
                                                                            <div className='infpo_icon'>
                                                                                {type === "user" ? <img src={(key.service_provider_pic !== null && key.service_provider_pic !== "null") ? key.service_provider_pic : userIcon} alt="user img" />
                                                                                    : <img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />}
                                                                            </div>
                                                                            <div className='info_text'>
                                                                                <h5>{type === "user" ? key.provider_name : key.booked_by}</h5>
                                                                                {type === "user" ?
                                                                                    <p>{(key.headline)},{key.city}</p>
                                                                                    : <p>{key.booked_for === "self" ?
                                                                                        <p>{(key.gender)},{key.city}</p>
                                                                                        : <p>{key.other_gender},{key.city}</p>
                                                                                    }</p>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='db_footer'>
                                                                        <div className="date">
                                                                            {type == 'user' && key.status === "Pending" ? (
                                                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#myModal"
                                                                                    onClick={() => onClickHomecare(key.booking_id, key.homecare_name, key.id, key.status, key.nurse_user_id)}
                                                                                >
                                                                                    Schedule Now
                                                                                </button>
                                                                            ) : (key.scheduled_date && key.scheduled_date !== 'undefined') ? (
                                                                                <>
                                                                                    {moment(key.scheduled_date).utc().format("MMM DD")}
                                                                                    {", "}
                                                                                    {moment(key.scheduled_date).utc().format("hh:mm a")}
                                                                                </>
                                                                            ) : <a style={{ color: "orange" }}>Not Scheduled</a>
                                                                            }
                                                                        </div>


                                                                        <div>
                                                                            {type === 'user' && key.is_confirmed === 0 && key.status === 'Scheduled' ? (<a style={{ color: 'orange' }}>Pending</a>)
                                                                                : (type === 'user' && key.is_confirmed && key.status === 'Accepted') ? (<a style={{ color: 'green' }}>Confirmed</a>)
                                                                                    : (key.service_type === 'Nurse' && key.is_confirmed === 0 && key.status === 'Scheduled') ? (<button className='btn btn-sm btn-success' onClick={() => ConfirmOrrejectHomecareVisit(key.id, true)}>Accept</button>)
                                                                                        : (key.service_type === 'Nurse' && key.is_confirmed === 1 && key.status === 'Accepted') ? <a style={{ color: 'green' }}>Confirmed</a>
                                                                                            : (<a style={{ color: 'orange' }}>{key.status}</a>
                                                                                            )}
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )) : <> <p className='no-data'>"No Data Found"</p>

                                                        </>
                                                    }

                                                </div>


                                                <div className='row'>

                                                    <h4 className='hedding-proper-p-m'>Ambulance Available Bookings</h4>
                                                    {(ambulanceBookingList !== null && ambulanceBookingList.length > 0) ?

                                                        (ambulanceBookingList !== null && ambulanceBookingList !== undefined && ambulanceBookingList.length > 0) &&
                                                        ambulanceBookingList.map((key) => (

                                                            <div className='col-lg-4'>
                                                                <div class="db_box">
                                                                    <div className='db_header'>
                                                                        <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                        <div className='dbh_right'>
                                                                            <p>#{key.id}</p>
                                                                            <div className='dropdown'>
                                                                                <a href='#' className='dropdown-toggle' data-toggle="dropdown">
                                                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu">
                                                                                    <li><Link to={`/book-summary-ambulance/${key.id}`} state={{ type: "Nurse" }}>Booking Info</Link></li>
                                                                                    {type === "user" && key.status === "Completed" &&
                                                                                        <li> <a data-toggle="modal" data-target="#modelForAddingFeedback" onClick={e => setBookingId(key.id)}>Give Feedback</a></li>
                                                                                    }
                                                                                    {type === "user" &&
                                                                                        key.status.toLowerCase() !== "cancelled" &&
                                                                                        key.status.toLowerCase() !== "completed" &&
                                                                                        key.status.toLowerCase() !== "rejected" &&
                                                                                        moment.utc(getLocalToUTC(new Date())).add(240, 'minutes') <= moment.utc(key.appointment_date) ? (
                                                                                        <li>
                                                                                            <Link onClick={() => bookingcancel(key.id, key.service_type)}>Cancel</Link>
                                                                                        </li>
                                                                                    ) : (type === 'service' && key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed" && key.status.toLowerCase() !== "rejected" && key.is_confirmed === 1 && key.status === 'Booked' && key.driver_id !== null) ? (
                                                                                        <li>
                                                                                            <Link onClick={() => {
                                                                                                if (key.driver_id === null) {
                                                                                                    openAssignAmbulanceModal(key.id);
                                                                                                } else {
                                                                                                    openAssignAmbulanceModal(key.id);
                                                                                                }
                                                                                            }}>
                                                                                                {key.driver_id === null ? "Assign Ambulance" : "Change Ambulance"}
                                                                                            </Link>
                                                                                        </li>
                                                                                    ) : null}
                                                                                    {type === 'service' && key.status === 'Booked' && key.service_status !== 'Completed' && key.remaining_amount > 0 && (
                                                                                        <li><Link onClick={() => RequestOrComplete(key.id, 'Requested', key.service_type)}>Request For Completion</Link></li>
                                                                                    )}
                                                                                    {(type === "service" && key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") && key.status.toLowerCase() !== "rejected" &&
                                                                                        key.is_confirmed === 0 && key.status === 'Booked' ? (
                                                                                        <li><Link onClick={() => AcceptRejectBooking(key.id, false, key.service_type)}>Reject</Link></li>
                                                                                    ) : null}

                                                                                    {(type === "user" && key.service_status === 'Requested' && key.status === 'Booked' && key.is_confirmed === 1) && (
                                                                                        <li>
                                                                                            <Link onClick={() => RequestOrComplete(key.id, 'Completed', key.service_type)}>Complete Request</Link>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className='db_body'>
                                                                        <div class="infono">
                                                                            <div className='infpo_icon'>
                                                                                {type === "user" ? <img src={(key.service_provider_pic !== null && key.service_provider_pic !== "null") ? key.service_provider_pic : userIcon} alt="user img" />
                                                                                    : <img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />}
                                                                            </div>
                                                                            <div className='info_text'>
                                                                                <h5>{type === "user" ? key.provider_name : key.booked_by}</h5>
                                                                                <p><span className='title-semibold'>Ambulance Type:</span> <span>{key.ambulance_type}</span></p>
                                                                                <p> <span className='title-semibold'>Route</span>: From - {key.pickup_address} To - {key.destination_address}</p>
                                                                                <p><span className='title-semibold'>Schedule Time:</span> {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}</p>
                                                                                <p> <span className='title-semibold'>Request Raise Time:</span> {moment(key.created_at).utc().format("MMM DD")}, {moment(key.created_at).utc().format("hh:mm a")}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='db_footer'>
                                                                        <div className='date'>
                                                                            {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}
                                                                        </div>

                                                                        <div >
                                                                            {type === 'user' && key.is_confirmed === 0 && key.status === 'Booked' ? (<a style={{ color: 'orange' }}>Pending</a>)
                                                                                : (type === 'user' && key.is_confirmed && key.status === 'Booked' && key.remaining_amount && key.remaining_payment_status !== 'Paid') ? (<button style={{ color: 'green' }} onClick={() => payRemainingAmount(key.id, key.remaining_amount)}>Pay ₹ {key.remaining_amount}</button>)
                                                                                    : (type === 'service' && key.is_confirmed === 0 && key.status === 'Booked') ? (<button className='btn btn-sm btn-success' onClick={() => AcceptRejectBooking(key.id, true, key.service_type)}>Accept</button>)
                                                                                        : (type === 'service' && key.is_confirmed === 1 && key.status === 'Booked' && (!key.remaining_amount || key.remaining_amount === 0 ) && key.driver_id !== null) ? (
                                                                                            <button type="button" className="btn btn-info" data-toggle="modal" data-target="#modelForRemainingAmount"
                                                                                                onClick={() => setBookingId(key.id)}
                                                                                            >
                                                                                                Add Amount
                                                                                            </button>
                                                                                        )
                                                                                            : (type === 'service' && key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed" && key.status.toLowerCase() !== "rejected" && key.is_confirmed === 1 && key.status === 'Booked' && key.driver_id === null) ? (
                                                                                                <button type="button" className="btn btn-info" onClick={() => openAssignAmbulanceModal(key.id)}>
                                                                                                    Assign Ambulance
                                                                                                </button>
                                                                                            )
                                                                                                : (key.is_confirmed === 1 && key.status == 'Booked') ? <a style={{ color: 'green' }}>Confirmed</a>
                                                                                                    : (<a style={{ color: 'orange' }}>{key.status}</a>
                                                                                                    )}
                                                                        </div>


                                                                    </div>
                                                                </div>



                                                            </div>

                                                        )) : <>  <p className='no-data'>"No Data Found"</p>

                                                        </>
                                                    }

                                                </div>

                                            </div>



                                            : <div class="defaultloader">
                                                <ThreeDots
                                                    height="80"
                                                    width="80"
                                                    radius="9"
                                                    color="#056B66"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                /></div>

                                        }
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}
            <div className="modal fade" id="viewServiceProviderDetailsPopup" role="dialog">
                <div className="modal-dialog" style={{ width: "100rem" }}>
                    {/* Modal content*/}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <h4 className="modal-title">Service Provider Details</h4>
                        </div>
                        <div className="modal-body">
                            <div className="col-lg-12">
                                {/*Doctor Info*/}
                                <div className="doctor_details bg_light">
                                    <div className="doctor_img">
                                        <div className="dr_photo"><img src={userIcon} alt="" /></div>
                                    </div>
                                    <div className="doctor_bio">
                                        {/* <button className="btn btn_fill" style={{ float: "right" }}>Rate & Review</button> */}
                                        <h3 className="dr_name">{userData.username}</h3>
                                        {userData.specialization &&
                                            <p className="dr_quali">{userData.specialization}</p>
                                        }
                                        {userData.qualification &&
                                            <p className="dr_quali">{userData.qualification}</p>
                                        }
                                        {userData.experience &&
                                            <p className="dr_exp"><img src={CheckCircle} />&nbsp;&nbsp; {userData.experience} Years</p>
                                        }
                                        {/* <p className="dr_votes"><span><img src={ThumbPng} />&nbsp;&nbsp; 96% </span>(935 votes)</p> */}
                                        <div className="dr_desc">
                                            {userData.bio &&
                                                <p className="dr_quali">{userData.bio}</p>
                                            }
                                            {/* Dr. Manohar Saini is a senior consultant Orthopedics specialized in Joint Replacement and Arthroscopic Surgery. He attended KGMC, Lucknow and was trained in Ortho
                                            <span id="dots">...</span>
                                            <span id="more">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
                                            </span>
                                            <a onclick="ReadMore()" id="myBtn" style={{ color: '#056B66', cursor: 'pointer' }}>Read more</a> */}

                                        </div>
                                    </div>
                                </div>
                                {/*Patient Stories*/}
                                {userData.patientStories.length !== 0 &&
                                    <div className="patient_stories bg_light">
                                        <div className="pt_stories_header">
                                            <h3>Patient Stories</h3>
                                        </div>

                                        {userData.patientStories.length === 0 ?
                                            <h4 className='text-center'>No stories found</h4>
                                            :
                                            <>
                                                <div className="pt_stories_body">
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw1"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw2"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="happy_block">
                                                                    <p>Happy with:&nbsp; <span className="happy_tag">Treatment satisfaction</span> <span className="happy_tag">Value for money</span></p>
                                                                </div>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw1"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt_stories_footer">
                                                    <div className="text-center"><a href="#" className="btn btn_fill">View more</a></div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                }

                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-theme radius">Acknowledged</button> w */}
                            <button type="button" className="btn btn-default radius" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* View Profile --- Doctor Homecare Ambulance */}

            {/* Modal */}
            <div className="modal fade" id="viewPatientDetailsPopup" role="dialog">
                <div className="modal-dialog" style={{ width: "100rem" }}>
                    {/* Modal content*/}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <h4 className="modal-title">Patient Details</h4>
                        </div>
                        <div className="modal-body">
                            <div className="patient_panel">
                                <div className="right_tab">
                                    <div className="patient_header">
                                        <div className="photo">
                                            <img src="images/ptimages.png" />
                                        </div>
                                        <div className="patient_detials">
                                            <h4 className="patient_name">Rabia Khan</h4>
                                            <div className="ptinfo">
                                                <h5><span>Gender:</span> F</h5>
                                                <h5><span>Blood group: </span>“O” +ve</h5>
                                            </div>
                                            <div className="ptinfo">
                                                <h5>Age:<span>27</span></h5>
                                                <h5>Mobile:<span>+91 4586749761</span></h5>
                                                <h5>Email:<span>rabia@khan.com</span></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="patient_body">
                                        <div className="appoint_schedule">
                                            <div className="dateblock">
                                                <div className="date"><span>26</span>&nbsp;Jan</div>
                                                <div className="datedtl">
                                                    <h5>Appointment scheduled at  10:30 AM</h5>
                                                    <p>Records for Vivek Singhal</p>
                                                </div>
                                            </div>
                                            {/* <div className="shareicon">
                                                <p><i className="fa fa-share-alt" /></p>
                                                <span>Follow-up</span>
                                            </div>
                                            <div className="shareicon">
                                                <p><i className="fa fa-share-alt" /></p>
                                                <span>Share</span>
                                            </div> */}
                                            <div className="radiusbtn">
                                                <div className="btn-group pull-right">
                                                    <button type="button" onClick={() => setShowAddPrescription(true)} className="btn btn-info">Add Record &nbsp;<i className="fa fa-plus" /></button>
                                                    <button type="button" className="btn btn-info dropdown-toggle" data-toggle="dropdown">
                                                        <span className="caret" />
                                                        <span className="sr-only">Toggle Dropdown</span>
                                                    </button>
                                                    <ul className="dropdown-menu" role="menu">
                                                        <li><a href="patient.html">Clinical note</a></li>
                                                        <li><a href="prescription.html">Prescription</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {showAddPrescription ?
                                            <form className="note_form">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Clinical note" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Complaints" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Prescroption</label>
                                                    <textarea className="form-control" rows={5} placeholder spellCheck="false" defaultValue={""} />
                                                </div>
                                                <div className="btnblock">
                                                    <button type="button" onClick={() => setShowAddPrescription(false)} className="btn btn-themeoutline radius btnbig">Cancel</button>
                                                    <button type='button' className="btn btn-theme radius btnbig">Save</button>
                                                </div>
                                            </form>
                                            : ""
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-theme radius">Acknowledged</button>
                            <button type="button" className="btn btn-default radius" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --for schedule visit--> */}
            <div className="modal fade" id="myModal" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Book Visit</h4>
                        </div>
                        <div className="modal-body">
                            <p>{homecareName}</p>
                            <hr />
                            <p className="font-size font-blod">Visit Required on</p>
                            <div className="date-time">
                                <div className="date">
                                    <h5>Date</h5>
                                    <input type="date" id="birthdaytime1" name="appointmentDate" value={appointmentDate} onChange={(e) => getAppointmentTimeList(e.target.value)} />
                                </div>
                                {/* <div className="time">
                                    <h5>Preferred Time</h5>
                                    <input type="time" id="appt" name="appointmentTime" onChange={(e) => setAppointmentTime(e.target.value)} />
                                </div> */}
                                <select name="appointment_time" onChange={(e) => getAvailabilityId(e)}>
                                    <option >Select Time</option>
                                    {appointmentTimeList.map((item, index) => (
                                        <option
                                            key={index}
                                            value={item.start_time + "-" + item.id}
                                            onClick={() => getAvailabilityId(item.id)}
                                        >
                                            {Change_to_12HourFormat(item.start_time)} to {Change_to_12HourFormat(item.end_time)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h6 className="under-box-update-email">Updates will be sent to registered email address</h6>
                            <p className="Undertaking">Home medical care offers a convenient, comfortable, and cost-effective option for patients who require medical attention. However, it should be noted that home medical care cannot replace hospital services in cases of emergency or critical illness.</p>
                            <p>
                                <input type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                                <span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span>
                            </p>
                            <div className="text-center margintop-bottom">
                                <button
                                    type="submit"
                                    className={`btn btn_fill btn-type-custum ${isBookAndPayEnabled ? '' : 'disabled'}`}
                                    onClick={() => {
                                        handleBookAndPay();
                                        $('#myModal').modal('hide'); // Hide the modal using jQuery
                                    }}
                                    disabled={!isBookAndPayEnabled}
                                >
                                    Schedule Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- modal for add remaining amount and showing booking_id oncliq add amount --> */}
            <div className="modal fade" id="modelForRemainingAmount" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Add Amount</h4>
                        </div>
                        <div className="modal-body">
                            <p>Booking Id: {bookingId}</p>
                            <hr />
                            <div className="date-time">
                                <div className="date">
                                    <h5>Remaining Amount</h5>
                                    <input type="number" step="0.01" id="remainingAmountInput" name="remainingAmount" onChange={(e) => setRemainingAmount(e.target.value)} />
                                </div>
                                {/* <div className="time">
                                    <h5>Payable Amount</h5>
                                    <input type="number" step="0.01" id="payableAmountInput" name="payableAmount" onChange={(e) => setPayableAmount(parseFloat(e.target.value))} />
                                </div> */}
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h6 className="under-box-update-email">Updates will be sent to the registered email address</h6>
                            <p className="Undertaking">Teleconsultation provides an accessible, convenient, and cost-effective alternative to in-person consultations. However, it should be noted that teleconsultation may not include physical examination and other diagnostic procedures that are necessary for certain medical conditions.</p>
                            <p>
                                <input type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                                <span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span>
                            </p>
                            <div className="text-center margintop-bottom">
                                <button
                                    type="submit"
                                    className={`btn btn_fill btn-type-custum ${isBookAndPayEnabled2 ? '' : 'disabled'}`}
                                    onClick={() => {
                                        addRemainingamount(bookingId);
                                        $('#modelForRemainingAmount').modal('hide'); // Hide the modal
                                    }}
                                    disabled={!isBookAndPayEnabled2}
                                >
                                    Add Amount
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- modal to show list of ambulances and list of drivers and selecting assign the ambulances to driver --> */}
            <div className="modal fade" id="modelForAssignAmbulanceToDriver" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: '#056B66', color: 'white' }}>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Assign Ambulance To Driver</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="sel1">Select Ambulance:</label>
                                        <select className="form-control" id="sel1" onChange={(e) => setAmbulace_id(e.target.value)}>
                                            <option value="">Select Ambulance</option>
                                            {(ambulanceList !== null && ambulanceList !== undefined && ambulanceList.length > 0) && ambulanceList.map((ambulance) => (
                                                <option value={ambulance.id}>{ambulance.vehicle_number}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="sel1">Select Driver:</label>
                                        <select className="form-control" id="sel1" onChange={(e) => setDriverId(e.target.value)}>
                                            <option value="">Select Driver</option>
                                            {driverList != null && driverList.map((driver) => (
                                                <option value={driver.id}>{driver.driver_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h6 className="under-box-update-email">Updates will be sent to the registered email address</h6>
                            <p className="Undertaking">Teleconsultation provides an accessible, convenient, and cost-effective alternative to in-person consultations. However, it should be noted that teleconsultation may not include physical examination and other diagnostic procedures that are necessary for certain medical conditions.</p>
                            <p>
                                <input type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                                <span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span>
                            </p>
                            <div className="text-center margintop-bottom">
                                <button
                                    type="submit"
                                    className={`btn btn_fill ${isBookAndPayEnabled3 ? '' : 'disabled'}`}
                                    onClick={() => {
                                        assignAmbulance(ambulace_id, driverId);
                                        $('#modelForAssignAmbulanceToDriver').modal('hide'); // Hide the modal
                                    }}
                                    disabled={!isBookAndPayEnabled3}
                                >
                                    Assign Ambulance To Driver
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- modal to add feedback --> */}
            <div className="modal fade" id="modelForAddingFeedback" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">Feedback</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <textarea style={{ width: '100%' }} rows="5"
                                            className='form-control' placeholder="Enter your feedback" onChange={(e) => setFeedback(e.target.value)}
                                        // Add necessary attributes and styles here
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="text-center margintop-bottom">
                            <button
                                type="button"
                                className={`btn btn_fill ${feedback ? '' : 'disabled'}`}
                                onClick={() => {
                                    addFeedback();
                                    $('#modelForAddingFeedback').modal('hide'); // Hide the modal
                                }}
                                disabled={!feedback}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default AccountBookingComponent;