import React from 'react';
import html2canvas from "html2canvas";
import { Component, PropTypes, useEffect, useState } from 'react';
import { jsPDF } from "jspdf";

import APIHandler from '../../services/APICore';
import moment from 'moment';

const Invoicedownload = ({ invoiceData,setDownLoad }) => {

    const mobile = localStorage.getItem("user_mobile")

    const name = localStorage.getItem("user_name")
    const email = localStorage.getItem("user_email")

    useEffect(() => {

        if (invoiceData!==null && invoiceData!==undefined) {
            console.log(invoiceData,"invoice data---")
            pdfDownload()
            setDownLoad(false)
        }

    }, [invoiceData])




    const pdfDownload = () => {
        try {
            var w = document.getElementById("capture").offsetWidth;
            var h = document.getElementById("capture").offsetHeight;
            html2canvas(document.querySelector("#capture")
            ).then(canvas => {
                var imgData = canvas.toDataURL(
                    'image/png');
                var doc = new jsPDF('L', 'px', [w, h]);
                doc.addImage(imgData, 'JPEG', 0, 0, w, h);
                doc.save('invoice.pdf');

            });
        }
        catch (err) {

        }

    }

    return (
        <>
            {invoiceData !== null && invoiceData !== undefined &&
                <div className='row' id="capture">
                    <div className="col-lg-1"></div>
                    <div className='col-lg-10'>
                        <div className='eprescription_print' id="testId">
                            {/* E-Prescriptions-Print Header */}
                            <div className='epres_header'>
                                <div className='epres_info_left'>
                                    <div className='logo'><img src="../images/fasttrackLogo.svg" /></div>
                                    <div className='dr_info'>
                                        <h4>{name}</h4>
                                        <p>{mobile}</p>
                                        <p>{email}</p>
                                    </div>
                                </div>
                                <div className='epres_info_right'>
                                    <h3>Invoice</h3>
                                    <p>Teleconsultation</p>
                                </div>
                            </div>
                            {/* E-Prescriptions-Print Patient Info */}
                            <div className='epres_pate_info'>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                       
                                            <p>#{invoiceData.booking_id}</p>
                                            <p>{invoiceData.name} - {invoiceData.service_type}</p>
                                            


                                            
                                            <p>{invoiceData.mobile}</p>
                                            <p>{moment(invoiceData.transaction_date).format("DD MMM YYYY")}</p>
                                            <p>Rs. {invoiceData.amount}</p>
                                        
                                    </div>
                                    
                                </div>
                            </div>

                            {/* E-Prescriptions-Print Body */}
                            {/* get html formate */}
                            <div className='epres_body'>
                                <div className='ep_details'>

                                </div>
                                <div className='dr_sign'></div>
                                <div className='dr_sign'></div>
                            </div>

                            {/* E-Prescriptions-Print Footer */}
                            <div className='epres_footer'>
                                <p>Invoice created from FastTrack Healthcare</p>
                            </div>




                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            }
        </>
    );

}
export default Invoicedownload;