import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router';
import moment from 'moment'
import AdminSidebar from '../AdminSidebar';
import {DoctorAllDetails, OneDoctorDetails,DoctorPendingDetails} from '../../../services/AdminServices/DoctorServices'
import DoctorViewProfileDetails from './DoctorViewProfileApproval';
import { NurseAllDetails, NursePendingDetails, OneNurseDetails } from '../../../services/AdminServices/HomeCareServices';

const HomeCareApplicationComponent = ({ selfDetails, data, isloading, setStatus, status,type,setType,service }) => {
    const [nurseData,setNurseData]=useState([])
    const [pending,setPending]=useState(true)
    const [active,setActive]=useState(false)
    const [blocked,setBlocked]=useState(false)
    const navigateTo=useNavigate()

   const ActiveDoctor=(boolean)=>{
    try {
            NurseAllDetails(boolean)
            .then((res) => {
                console.log(res)
              setNurseData(res.data)
            })
            .catch();
      } catch (error) {}
   }
   const handleDoctorType=(paramsData)=>{
    if(paramsData){
        ActiveDoctor(paramsData);
        setStatus("Completed")
        setActive(true)
        setPending(false)
        setBlocked(false)
    }else{
        ActiveDoctor(paramsData);
        setStatus("Canceled")
        setActive(false)
        setPending(false)
        setBlocked(true)
    }
    
   }
   const handlePendingDoctor=()=>{
    setStatus("Booked")
    setActive(false)
    setPending(true)
    setBlocked(false)
    try {
        NursePendingDetails()
        .then((res) => {
            console.log(res)
          setNurseData(res.data)
        })
        .catch();
        } catch (error) {}
    }
   
   useEffect(()=>{
       setStatus("Booked")
    handlePendingDoctor();
   },[])
   console.log(nurseData)
   
   const handleViewDoctorProfile=(id,statusShow)=>{
    console.log(id)
    if(id!==null){
        try {
            OneNurseDetails(id)
            .then((res) => {
                console.log(res)
                if(res.success===true){
                    navigateTo('/nurse-profile-details',{state:{profileData:res.data,statusShow:statusShow}})
                }
            //   setNurseData(res.data)
            })
            .catch();
      } catch (error) {}
    }

   }
    return (
        <div className="outerhomepage">

            {/*Top Navbar Menu*/}
            {/* <HomepageHeader /> */}
            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">
                        {/* Side bar */}
                        <AdminSidebar/>
                        <div className="right_panel table-responsive" style={{ marginTop: "20px" }}>
                            <div className='col-md-12' style={{ marginBottom: "22px" }}>
                                <div class="tab pull-right">
                                    {
                                        service &&
                                        service.map((item) => (
                                            <button onClick={(e) => setType(item.service)}
                                                className={type === item.service ? 'active'
                                                    : ""}>
                                                {item.service}
                                            </button>
                                        ))

                                    }
                                </div>

                            </div>
                            <div className='col-md-6' style={{ marginTop: "22px" }}>
                                <div class="tab col-md-12">
                                    <button onClick={handlePendingDoctor}
                                        className={status === "Booked" ? 'active'
                                            : ""}>
                                        Pending
                                    </button>
                                    <button onClick={() =>handleDoctorType(true) }
                                        className={status === "Completed" ? 'active' : ""}>Active</button>
                                    <button onClick={() => handleDoctorType(false)}
                                        className={status === "Canceled" ? 'active' : ""}>Blocked</button>
                                </div>
                            </div>
                            <div>
                            {
                            
                                pending?<table className="table mytable">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '12.5%' }}>Booking Id</th>
                                        <th style={{ width: '12.5%' }}>Name</th>
                                        <th style={{ width: '12.5%' }}>Contect No.</th>
                                        <th style={{ width: '12.5%' }}>Summery</th>
                                        <th style={{ width: '12.5%' }}>Role</th>
                                        <th style={{ width: '12.5%' }}>View</th>
                                        <th style={{ width: '12.5%' }}>Active/Disable</th>
                                    </tr>
                                    {
                                        nurseData.map((val,ind)=>{
                                            return(
                                                <tr key={ind}>
                                                    <td>{val.id}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.mobile}</td>
                                                    <td>{val.experience}</td>
                                                    <td>{val.specialization}</td>
                                                    <td style={{'cursor':'pointer'}} onClick={()=>handleViewDoctorProfile(val.id,'pending')}><i class="fa fa-eye"></i></td>
                                                    
                                                    <td className='d-flex-alin-item'><button className='btn btn-success btn-sm'>Accept</button>
                                                    <button className='btn btn-danger btn-sm'>Reject</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                </table>:''
                            }
                            {
                                active?<table className="table mytable">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '12.5%' }}>Booking Id</th>
                                        <th style={{ width: '12.5%' }}>Name</th>
                                        <th style={{ width: '12.5%' }}>Contect No.</th>
                                        <th style={{ width: '12.5%' }}>Summery</th>
                                        <th style={{ width: '12.5%' }}>Role</th>
                                        <th style={{ width: '12.5%' }}>View</th>
                                        <th style={{ width: '12.5%' }}>Active/Disable</th>
                                    </tr>
                                    {
                                        nurseData.map((val,ind)=>{
                                            return(
                                                <tr key={ind}>
                                                    <td>{val.id}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.mobile}</td>
                                                    <td>{val.experience}</td>
                                                    <td>{val.specialization}</td>
                                                    <td style={{'cursor':'pointer'}} onClick={()=>handleViewDoctorProfile(val.id,'active')}><i class="fa fa-eye"></i></td>
                                                    
                                                    <td className='d-flex-alin-item'>
                                                        {/* <button className='btn btn-success btn-sm'>Active</button> */}
                                                    <button className='btn btn-danger btn-sm'>InActive</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                </table>:''
                            }
                            {
                                blocked?<table className="table mytable">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '12.5%' }}>Booking Id</th>
                                        <th style={{ width: '12.5%' }}>Name</th>
                                        <th style={{ width: '12.5%' }}>Contect No.</th>
                                        <th style={{ width: '12.5%' }}>Summery</th>
                                        <th style={{ width: '12.5%' }}>Role</th>
                                        <th style={{ width: '12.5%' }}>View</th>
                                        <th style={{ width: '12.5%' }}>Active/Disable</th>
                                    </tr>
                                    {
                                        nurseData.map((val,ind)=>{
                                            return(
                                                <tr key={ind}>
                                                    <td>{val.id}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.mobile}</td>
                                                    <td>{val.experience}</td>
                                                    <td>{val.specialization}</td>
                                                    <td style={{'cursor':'pointer'}} onClick={()=>handleViewDoctorProfile(val.id,'block')}><i class="fa fa-eye"></i></td>
                                                    
                                                    <td className='d-flex-alin-item'><button className='btn btn-success btn-sm'>Active</button>
                                                    {/* <button className='btn btn-danger btn-sm'>InActive</button> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                </table>:''
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            

        </div >
    )
}

export default HomeCareApplicationComponent;