import React, { useEffect, useState } from 'react'
import VinitaIcon from "../../../images/images/vinita.png"
import DownloadPng from "../../../images/images/download.png"
import { HomepageHeader, SidebarUserPanel } from '../../../components'
import { ProfileService, UploadImageFor } from '../../../services'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router'
import { uploadImageFor } from '../../../enums/Enums'
import Sidebarmobile from '../../../components/user/Sidebarmobile';

const MyFeedbackQuery = () => {
  const apiFunction = new ProfileService();

  const [feedbackQuery, setFeebackQuery] = useState({
    type: "test",
    short_description: "",
    description: "",
    attachment: ""
  })
  const [fileName, setFileName] = useState('')
  const [isFileUploaded, setIsFileUploaded] = useState(true)
  const { short_description, description, attachment } = feedbackQuery;

  const onInputChange = e => {
    setFeebackQuery({ ...feedbackQuery, [e.target.name]: e.target.value })
  }

  const loadAttachmentUrl = (e) => {
    if (e.target.files.length > 0) {
      setIsFileUploaded(false)
      const selectedFile = e.target.files[0];
      const selectedFileName = e.target.files[0].name;
      setFileName(e.target.files[0].name)
      const formData = new FormData();
      formData.append("files", selectedFile, selectedFileName);
      UploadImageFor(formData, uploadImageFor.feedback).then(result => {
        if (result.success === true) {
          setIsFileUploaded(true)
          setFeebackQuery(
            {
              ...feedbackQuery,
              attachment: result.data.filePath
            })
        }
      })
    }
  }

  const postFeedbackandQuery = (e) => {
    e.preventDefault();
    if (!short_description.trim()) {
      return toast.error('Please enter summary')
    }
    else {
      const data = feedbackQuery
      apiFunction.PostFeedbackAndQuery(data).then(result => {
        if (result !== undefined) {
          if (result.success === true) {
            setFeebackQuery({
              type: "test",
              short_description: "",
              description: "",
              attachment: ""
              
            })
            return toast.success(result.message)
          }
        }
      })
    }
    setFileName("")
   
  }
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="outerhomepage">
      {/*Top Navbar Menu*/}
      <HomepageHeader />

      <section className=" paddbottom">
        <div className="">
          <div className="userpanel_page bg_light">

            {/* Side bar */}
            {/* <SidebarUserPanel />  */}
            <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                                 {isVisible ? <i class="fa-solid fa-caret-down"></i>: <i class="fa-solid fa-caret-right"></i>}
                              </button>
                            {isVisible && (
                     <div>
                     <Sidebarmobile />
                </div>
      )}

                        <SidebarUserPanel />
            <div className="right_panel">
              <table className="table mytable">
                <tbody>
                  {/* <tr>
                    <th>
                      <div className="tableTab">
                        <a href="feedback.html" className="btn btn-link ">Provide feedback</a>
                        <a href="queries.html" className="btn btn-link active">Queries</a>
                        <a href="responses.html" className="btn btn-link">Responses</a>
                      </div>
                    </th>                                    
                  </tr>                                */}
                  <tr>
                    <td>
                      <form onSubmit={postFeedbackandQuery}>
                        <div className="fd_input">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Summary *</label>
                            <input type="text" className="form-control" onChange={e => onInputChange(e)} name='short_description' value={short_description} id="exampleInputEmail1" placeholder />
                          </div>
                        </div>
                        <div className="fd_input">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Explain in detail</label>
                            <textarea className="form-control" name='description' onChange={e => onInputChange(e)} value={description} rows={3} placeholder spellCheck="false" defaultValue={""} />
                          </div>
                        </div>
                        <div className=" fd_btnsec">
                          <div className="file-input">
                            <input onChange={loadAttachmentUrl} type="file" name="file-input" id="file-input" className="file-input__input" />
                            {
                              isFileUploaded ?
                                fileName == "" ? <label className="file-input__label" htmlFor="file-input" style={{ padding: "15px 12px" }}>
                                  <span>Choose file</span>
                                </label> :
                                  <label className="file-input__label" onClick={(e) => setFileName("")} style={{ padding: "15px 12px" }}>
                                    <span>{fileName} &times;</span>
                                  </label>
                                :
                                <label className="file-input__label" style={{ padding: "15px 12px" }}>
                                  <span>Uploading...</span>
                                </label>
                            }
                          </div>
                          <div>
                            <button type='submit' className="btn btn_fill btn-xs" disabled={!isFileUploaded}>Send</button>
                          </div>
                        </div>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default MyFeedbackQuery;