import moment from 'moment';
import { useEffect, useState } from 'react';
import HomepageHeader from '../../components/headers/Header';
import SidebarUserPanel from '../../components/user/Sidebar';
import userDashboardServices from '../../services/userDashboardServices';
import { ThreeDots } from 'react-loader-spinner'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Eprecriptiondownload from '../E-prescription/downloadeprescription';
import { useNavigate } from 'react-router';
import Sidebarmobile from '../../components/user/Sidebarmobile';
import { set } from 'date-fns';

const baseurl = process.env.REACT_APP_API_BASE_URL


const ProviderDashboard = () => {
    const apiFunction = new userDashboardServices()

    const [homecareDashboard, setHomecareDashboard] = useState("")
    const [ambulanceDashboard, setAmbulanceDashboard] = useState("")
    const [bookingId, setBookingId] = useState("")
    const [driverId, setDriverId] = useState("")
    const [ambulace_id, setAmbulace_id] = useState("")
    const [ambulanceList, setAmbulanceList] = useState([])
    const [driverList, setDriverList] = useState([])
    const [appointmentDate, setAppointmentDate] = useState("")
    const [remaining_amount, setRemaining_amount] = useState("")
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [todayAppoinment, setTodayAppoinment] = useState([])
    const [overAllSummary, setOverAllSummary] = useState(null)
    const [sheduledForToday, setSheduledForToday] = useState(null)
    const [pendingForAcknowledge, setPendingForAcknowledge] = useState([])
    const [recentPrescription, setRecentPrescription] = useState([])
    const [recentReports, setRecentReports] = useState([])
    const [upcomingBookings, setUpcomingBookings] = useState([])
    const [ambulanceBookings, setAmbulanceBookings] = useState([])
    const [ambulances, setAmbulances] = useState([])
    const [ambulanceTrips, setAmbulanceTrips] = useState([])
    const [ambulanceRevenue, setAmbulanceRevenue] = useState([])
    const [ambulanceDrivers, setAmbulanceDrivers] = useState([])
    const [ambulanceLastTrips, setAmbulanceLastTrips] = useState([])
    const [isLoaded, setIsLoaded] = useState(true)
    const [prescriptionid, setPrescriptionid] = useState("");
    const [userIcon, SetuserIcon] = useState("../images/user-icon.png")
    const [difftime, SetDiffertime] = useState("");
    const [Period, setPeriod] = useState("ThisWeek");
    let date1 = moment();
    let date2 = "";
    let videocalltime = "";

    let token = localStorage.getItem('token')




    useEffect(() => {
        LoadDashboard()
        LoadHomecareDashboard()
        LoadAmbulanceDashboard()
    }, [])

    const LoadDashboard = async () => {
        var date = moment(new Date()).format("YYYY-MM-DD")
        setIsLoaded(false)
        apiFunction.DoctorDashboardApi(date, token).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    setTodayAppoinment(result.data.todayAppointment)
                    setOverAllSummary(result.data.overallSummary)
                    setSheduledForToday(result.data.sheduledForToday)
                    setPendingForAcknowledge(result.data.PendingForAcknowledge)
                    setRecentPrescription(result.data.recentPrescription)
                    //setRecentReports(result.data.recentReports)
                    setUpcomingBookings(result.data.upcomingBookings)
                    setIsLoaded(true)


                    //  different time                

                    /* const date2 = new Date(result.data.todayAppointment[1].appointment_date)
                    date2.setMinutes((date2).getMinutes() - 330)
                    const crtdate = new Date();
                    const diff = Math.abs(date2 - crtdate);
                    const diffInMinutes = Math.floor((diff / 1000) / 60); */

                }
            }
        })
    }

    const LoadHomecareDashboard = async () => {
        setIsLoaded(false)
        const response = await apiFunction.HomecareDashboardApi(token).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    setHomecareDashboard(result.data)
                    setIsLoaded(true)
                }
            }
        }
        )

    }

    const LoadAmbulanceDashboard = async () => {
        setIsLoaded(false)
        apiFunction.AmbulanceDashboardApi(token).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    setAmbulanceDashboard(result.data)
                    setAmbulanceBookings(result.data.ambulanceBooking)
                    setAmbulances(result.data.ambulances)
                    setAmbulanceTrips(result.data.trips)
                    setAmbulanceRevenue(result.data.ambulanceRevenue)
                    setAmbulanceDrivers(result.data.ambulanceDriver)
                    setAmbulanceLastTrips(result.data.lastTripsDetails)
                    setIsLoaded(true)
                }
            }
        })
    }

    useEffect(() => {
        LoadOverallSummery()
    }, [Period])

    const LoadOverallSummery = async () => {

        apiFunction.DashboardOverallSummery(Period, token).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    setOverAllSummary(result.data)
                }
            }
        })
    }



    function randomID(len) {
        let result = '';
        if (result) return result;
        var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
            maxPos = chars.length,
            i;
        len = len || 5;
        for (i = 0; i < len; i++) {
            result += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return result;
    }
    const AcceptRejectBooking = (bookingid, status, service1) => {
        let roomid = ""
        if (service1 === "Doctor") {
            roomid = randomID(5)
        }
        apiFunction.AcceptBookingApi(bookingid, status, roomid).then(result => {
            if (result !== undefined) {
                if (result.success === true) {

                    LoadDashboard()
                    LoadAmbulanceDashboard()
                    if (status) {
                        toast.success("Booking successfully accepted.")
                    }
                    else {
                        toast.success("Booking successfully rejected.")
                    }


                }
            }
        })
    }
    const ConfirmOrrejectHomecareVisit = async (booking_item_id, itemStatus) => {

        try {
            var data = {
                "is_confirmed": itemStatus
            };

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const result = await axios.put(`${baseurl}homecareBooking/confirmOrReject/${booking_item_id}`, JSON.stringify(data), config)
            if (result !== null && result !== undefined && itemStatus == 1) {
                toast.success("Booking Confirmed sucessfully")
                await LoadHomecareDashboard()
            } else if (result != null && result !== undefined && itemStatus == 0) {
                toast.error("Booking Rejected sucessfully")
                await LoadHomecareDashboard()
            }
            await LoadHomecareDashboard()

        } catch (error) {
        }

    }

    const RequestOrComplete = async (id, serviceStatus, service) => {

        try {
            var data = {
                "service_status": serviceStatus
            };
            var dataForNurse = {
                "item_service_status": serviceStatus,
            }
            let result

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            if (service === 'Nurse') {
                result = await axios.put(`${baseurl}homecareBooking/serviceStatus/${id}`, JSON.stringify(dataForNurse), config)
            }
            else if (service === 'Ambulance') {
                result = await axios.put(`${baseurl}ambulances/bookings/serviceStatus/${id}`, JSON.stringify(data), config)
            }

            if (result != null && result !== undefined) {
                if (serviceStatus === "Completed")
                    toast.success("Booking Completed Sucessfully")
                else if (serviceStatus === "Requested")
                    toast.success("Booking completion Requested Sucessfully")
                else if (serviceStatus === "Rejected")
                    toast.success("Booking Rejected Sucessfully")
                // window.location.reload()
                await LoadHomecareDashboard()
                await LoadAmbulanceDashboard()
            }
        } catch (error) {
        }

    }

    //fn for openign modal to assign ambulance
    const openAssignAmbulanceModal = (id) => {
        setBookingId(id)
        const modal = document.getElementById('modelForAssignAmbulanceToDriver');
        if (modal) {
            const bootstrapModal = window.jQuery(modal);
            bootstrapModal.modal('show');
        }
    }

    const addRemainingamount = (bookingid) => {
        apiFunction.AddRemainingPayableAmount(bookingid, remaining_amount).then(result => {
            if (result !== undefined && result.success === true) {
                toast.success("Amount successfully Added.")
                LoadAmbulanceDashboard()

            }
        })
    }


    const assignAmbulance = async () => {
        try {
            var data = {
                "booking_id": bookingId,
                "driver_id": driverId,
                "ambulance_id": ambulace_id,
            };

            var config = {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const result = await axios.post(`${baseurl}assignAmbulance`, JSON.stringify(data), config)
            if (result != null && result !== undefined) {
                toast.success("Ambulance assigned sucessfully")
                const modal = document.getElementById('myModal');
                if (modal) {
                    const bootstrapModal = window.jQuery(modal);
                    bootstrapModal.modal('hide');
                }
                await LoadAmbulanceDashboard()
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    const findAmbulanceList = async () => {
        try {
            var config = {
                headers: {
                    'authorization': token
                }
            };
            const result = await axios.get(`${baseurl}ambulances/list`, config)
            if (result !== null && result !== undefined) {
                setAmbulanceList(result.data.data.approvedAmbulances)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const getDriverList = async () => {
        try {
            let user_id = localStorage.getItem("userid")
            var config = {
                headers: {
                    'authorization': token
                }
            };
            const result = await axios.get(`${baseurl}ambulanceDriver/user/${user_id}`, config)
            if (result !== null && result !== undefined) {
                setDriverList(result.data.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        findAmbulanceList()
        getDriverList()
    }, [])

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };

    const isBookAndPayEnabled2 = remaining_amount && isCheckboxChecked;
    const isBookAndPayEnabled3 = driverId && ambulace_id && isCheckboxChecked;

    const GetTimeFifference = (appointmentdate) => {
        const date2 = new Date(appointmentdate)
        date2.setMinutes((date2).getMinutes() - 330)
        const crtdate = new Date();
        const diff = Math.abs(date2 - crtdate);
        SetDiffertime(Math.floor((diff / 1000) / 60));

    }

    const getLocalToUTC = (date) => {
        const finalDate = new Date(date);
        const utcDate = new Date(finalDate.getTime() - (finalDate.getTimezoneOffset() * 60000));
        return utcDate;
    }

    let todayDate = new Date()
   
    let a = new Date((todayDate.getMonth() + 1) + "-" + (todayDate.getUTCDate() + 1) + "-" + todayDate.getUTCFullYear())
    let today = (moment(a).utc()).format(`DD/MM/YYYY`)
    const [isVisible, setIsVisible] = useState(false);


    return (<>
        <div className='outerhomepage'>
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section className=" paddbottom">
                <div className="userpanel_page bg_light">
                    {/* Side bar */}
                    {/* <SidebarUserPanel /> */}
                    <button onClick={() => setIsVisible(!isVisible)} className='mobile-sidebar-tab'>
                        {isVisible ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>}
                    </button>
                    {isVisible && (
                        <div>
                            <Sidebarmobile />
                        </div>
                    )}

                    <SidebarUserPanel />
                    {isLoaded ?
                        <div className="right_panel ">
                            <div class="content-wrapper" style={{ marginLeft: 0 }}>
                                <div class="content content-body">
                                    {/* Page Title */}
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="content-header">
                                                <h1 class="pull-left">
                                                    Dashboard
                                                </h1>
                                            </div>
                                        </div>

                                    </div>


                                    {/* Scheduled for Today */}
                                    <div class="row spacetop">
                                        {(sheduledForToday !== undefined && sheduledForToday !== null) &&
                                            <div className='col-lg-6'>
                                                <div class="db_box">
                                                    <div className='db_header mh51'>
                                                        <h5>Scheduled for Today</h5>
                                                        {/* <div className='dbh_right'>
                                                        <p>#817</p>
                                                        <span><a href='#'><i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                    </div> */}
                                                    </div>
                                                    <div className='db_body'>
                                                        <div class="spd_card">
                                                            <div className='spd_card_list'>
                                                                <p> Scheduled</p>
                                                                <h4>{sheduledForToday.sheduled > 0 ? sheduledForToday.sheduled : 0}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Completed</p>
                                                                <h4>{sheduledForToday.completed > 0 ? sheduledForToday.completed : 0}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Pending</p>
                                                                <h4>{sheduledForToday.pending > 0 ? sheduledForToday.pending : 0}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Cancelled</p>
                                                                <h4>{sheduledForToday.cancelled > 0 ? sheduledForToday.cancelled : 0}</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        }
                                        {(overAllSummary !== undefined && overAllSummary !== null) &&
                                            <div className='col-lg-6'>
                                                <div class="db_box">
                                                    <div className='db_header'>
                                                        <h5>Overall Summary</h5>
                                                        <div className='dbh_right'>
                                                            <select className='form-control input-sm' name="Period" value={Period} onChange={(e) => setPeriod(e.target.value)}>
                                                                <option value="ThisWeek">This Week</option>
                                                                <option value="ThisMonth">This Month</option>
                                                                <option value="ThisYear">This Year</option>
                                                                <option value="PreviousWeek">Last Week</option>
                                                                <option value="PreviousMonth">Last Month</option>
                                                                <option value="PreviousYear">Last Year</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='db_body'>
                                                        <div class="spd_card">
                                                            <div className='spd_card_list'>
                                                                <p> Bookings</p>
                                                                <h4>{overAllSummary.totalBookings > 0 ? overAllSummary.totalBookings : 0}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Completed</p>
                                                                <h4>{overAllSummary.completedBookings > 0 ? overAllSummary.completedBookings : 0}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Pending</p>
                                                                <h4>{overAllSummary.PendingBooings > 0 ? overAllSummary.PendingBooings : 0}</h4>
                                                            </div>
                                                            <div className='spd_card_list'>
                                                                <p> Income</p>
                                                                <h4>{overAllSummary.income > 0 ? overAllSummary.income : 0}</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {/* <div class="row spacetop">
                                        {(ambulanceBookings !== undefined && ambulanceBookings !== null) &&
                                        <      div className='col-lg-12'>
                                            <div class="db_box">
                                                <div className='db_header mh51'>
                                                    <h5>Ambulance booking</h5>
                                                </div>
                                                <div className='db_body'>
                                                    <div class="spd_card">
                                                        <div className='spd_card_list'>
                                                            <p>  New Booking</p>
                                                            <h4>{ambulanceBookings.newBookingCount}</h4>
                                                        </div>
                                                        <div className='spd_card_list'>
                                                            <p> Schedule Booking</p>
                                                            <h4>{ambulanceBookings.scheduledBookingCount}</h4>
                                                        </div>
                                                        <div className='spd_card_list'>
                                                            <p> Completed</p>
                                                            <h4>{ambulanceBookings.completedBookingCount}</h4>
                                                        </div>

                                                        <div className='spd_card_list'>
                                                            <p> Pending</p>
                                                            <h4>{ambulanceBookings.pendingBookingCount}</h4>
                                                        </div>
                                                        <div className='spd_card_list'>
                                                            <p> Cancelled</p>
                                                            <h4>{ambulanceBookings.cancelledBookingCount}</h4>
                                                        </div>

                                                    </div>
                                                </div>

                                            </  div>
                                        </div>
                                        }
                                    </div> */}

                                    {/* <div class="row spacetop">
                                        <      div className='col-lg-8'>
                                            <div class="db_box">
                                                <div className='db_header mh51'>
                                                    <h5>Ambulances</h5>
                                                  
                                                </div>
                                                <div className='db_body'>
                                                    <div class="spd_card">
                                                        <div className='spd_card_list width-30'>
                                                            <div class="db_body">
                                                                <div class="infono">
                                                                    <div class="infpo_icon">
                                                                        <img src="/images/bls-removebg-preview.png" alt="user img" />
                                                                    </div>
                                                                    <div class="info_text">
                                                                        <h5>{ambulances.totalAmbulances} Ambulances</h5>

                                                                    </div>
                                                                </div>
                                                                <div className='lbl-footer'>
                                                                    <p>3 ALS</p>
                                                                    <p>2 BLS</p>
                                                                    <p>1 ALS</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='spd_card_list width-30'>
                                                            <div class="db_body">
                                                                <div class="infono">
                                                                    <div class="infpo_icon">
                                                                        <img src="/images/bls-removebg-preview.png" alt="user img" />
                                                                    </div>
                                                                    <div class="info_text">
                                                                        <h5>{ambulances.activeAmbulances} Active Ambulances</h5>

                                                                    </div>
                                                                </div>
                                                                <div className='lbl-footer'>
                                                                    <p>3 ALS</p>
                                                                    <p>2 BLS</p>
                                                                    <p>1 ALS</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='spd_card_list width-30'>
                                                            <div class="db_body">
                                                                <div class="infono">
                                                                    <div class="infpo_icon">

                                                                    </div>
                                                                    <div class="info_text">
                                                                        <h5>{ambulances.mostusedAmbulances} Most used <br></br>
                                                                            ambulances</h5>

                                                                    </div>
                                                                </div>
                                                                <div className='lbl-footer'>
                                                                    <p>70% ALS</p>
                                                                    <p>40% BLS</p>
                                                                    <p>10% ALS</p>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>

                                            </  div>
                                        </div>
                                       
                                    </div> */}

                                    <div class="row spacetop">
                                        {(ambulanceTrips !== undefined && ambulanceTrips !== null && ambulanceTrips.length > 0) &&
                                            <div className='col-lg-4'>
                                                <div class="db_box">
                                                    <div className='db_header mh51'>
                                                        <h5>Trips </h5>
                                                        <div className='dbh_right'>
                                                            <select class="form-control" placeholder="">
                                                                <option>Total Trips</option>
                                                                <option value="Identity Proof">This week</option>
                                                                <option value="Medical Registration Proof">This month</option>
                                                                <option value="Establishment Proof Proof">Last year </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='db_body'>
                                                        <div class="spd_card">
                                                            <div className='spd_card_list width-45'>
                                                                <p>  Total trips</p>
                                                                <h4>{ambulanceTrips.total_trips}</h4>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </  div>
                                            </div>
                                        }
                                        {/* {(ambulanceRevenue !== undefined && ambulanceRevenue !== null && ambulanceRevenue.length > 0) &&
                                        <div className='col-lg-4'>
                                            <div class="db_box">
                                                <div className='db_header mh51'>
                                                    <h5>Revenue</h5>
                                                    <div className='dbh_right'>
                                                        <select class="form-control" placeholder="">
                                                            <option>Total revenue</option>
                                                            <option value="Identity Proof">This week</option>
                                                            <option value="Medical Registration Proof">This month</option>
                                                            <option value="Establishment Proof Proof">Last year </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='db_body'>
                                                    <div class="spd_card">
                                                        <div className='spd_card_list width-45'>
                                                            <p>  Total revenue <br />
                                                                Generate
                                                            </p>
                                                            <h4>{ambulanceRevenue.total_revenue}</h4>
                                                        </div>

                                                    </div>
                                                </div>

                                            </  div>
                                        </div>
                                         } */}
                                    </div>
                                    <div class="row spacetop">
                                        {ambulanceDrivers &&

                                            <      div className='col-lg-7'>
                                                <div class="db_box">
                                                    <div className='db_header mh51'>
                                                        <h5>Drivers</h5>
                                                        {/* <div className='dbh_right'>
                                                        <p>#817</p>
                                                        <span><a href='#'><i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                    </div> */}
                                                    </div>
                                                    <div className='db_body'>
                                                        <div class="spd_card">
                                                            <div className='spd_card_list width-45'>
                                                                <p>  Total drivers</p>
                                                                <h4>{ambulanceDrivers.totalDrivers}</h4>
                                                            </div>
                                                            <div className='spd_card_list width-45'>
                                                                <p> Active drivers</p>
                                                                <h4>{ambulanceDrivers.activeDrivers}</h4>
                                                            </div>
                                                            <div className='spd_card_list width-45'>
                                                                <p> idle Drivers</p>
                                                                <h4>{ambulanceDrivers.idleDrivers}</h4>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </  div>
                                            </div>
                                        }
                                    </div>


                                    <div className="row spacetop">
                                        {ambulanceLastTrips !== undefined && ambulanceLastTrips !== null && ambulanceLastTrips.length > 0 && (
                                            <div className="col-lg-12">
                                                <div className="db_box">
                                                    <div className="db_header mh51">
                                                        <h5>Last Trips</h5>
                                                    </div>
                                                    <div className="db_body">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Pick up</th>
                                                                    <th scope="col">Drop off</th>
                                                                    <th scope="col">Bill</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ambulanceLastTrips.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.id}</td>
                                                                        {(moment(item.appointment_date).utc()).format("MMM DD")}, {(moment(item.appointment_date).utc()).format("hh:mm a")}
                                                                        <td>{item.pickup_address}</td>
                                                                        <td>{item.destination_address}</td>
                                                                        <td>{item.total_amount}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>


                                    {/* Pending for Acknowledgement */}
                                    <div class="row ">
                                        {(pendingForAcknowledge !== undefined && pendingForAcknowledge !== null && pendingForAcknowledge.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Pending for Acknowledgement</h4></div>
                                                {pendingForAcknowledge.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box">
                                                            <div className='db_header'>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.booking_id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            {(key.service_type === "Doctor") ? <li><Link to={`/bookings-summary/${key.id}`} state={{ type: "Doctor" }}>Booking Info</Link></li>
                                                                                : (key.service_type === "Nurse") ? <li><Link to={`/bookings-visit-summary/${key.id}`} state={{ type: "Nurse" }}>Booking Info</Link></li> :
                                                                                    <li><Link to={`/book-summary-ambulance/${key.id}`} state={{ type: "Ambulance" }}>Booking Info</Link></li>}
                                                                            {/* <li><Link to={`/bookings-summary/${key.id}`} state={{ type: type }}>Booking Info</Link></li> */}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.name}</h5>
                                                                        <p>{key.gender}, {key.age} Yrs</p>
                                                                        <div className='date'> {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                {/* <div className='date'> {(moment(item.appointment_date).utc()).format("MMM DD")}, {(moment(item.appointment_date).utc()).format("hh:mm a")}</div> */}
                                                                <div>
                                                                    <button className='btn btn-sm btn-success' onClick={e => AcceptRejectBooking(key.booking_id, true, 'Doctor')}>Accept</button>
                                                                    <a style={{ cursor: "pointer" }} className='btnreject ml-1' onClick={e => AcceptRejectBooking(key.booking_id, false, 'Doctor')}>Reject</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>

                                    <div class="row ">
                                        {(homecareDashboard.pendingForAcknowledgement !== undefined && homecareDashboard.pendingForAcknowledgement !== null && homecareDashboard.pendingForAcknowledgement.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Homecare Bookings for Acknowledgement</h4></div>
                                                {homecareDashboard.pendingForAcknowledgement.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box" style={{ border: "1px solid #056B66" }}>
                                                            <div className='db_header'>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.booking_id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/bookings-visit-summary/${key.id}`}>Booking Info</Link></li>
                                                                            {key.status === 'Accepted' && key.item_service_status !== 'Completed' && (
                                                                                <li><Link onClick={() => RequestOrComplete(key.id, 'Requested', key.service_type)}>Request For Completion</Link></li>
                                                                            )}

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.booked_by}</h5>
                                                                        <p>{key.other_gender || key.gender}, {key.pickup_address}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'>
                                                                    {key.scheduled_date && key.scheduled_date !== 'undefined' ? (
                                                                        <>
                                                                            {moment(key.scheduled_date).utc().format("MMM DD")},{" "}
                                                                            {moment(key.scheduled_date).utc().format("hh:mm a")}
                                                                        </>
                                                                    ) : (
                                                                        <>Not Scheduled</>
                                                                    )}
                                                                </div>

                                                                <div>

                                                                    <button className="btn btn-sm btn-success" onClick={() => ConfirmOrrejectHomecareVisit(key.id, true)} >Accept</button>
                                                                    <a style={{ cursor: "pointer" }} className='btnreject ml-1' onClick={e => ConfirmOrrejectHomecareVisit(key.id, false)}>Reject</a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>

                                    <div class="row ">
                                        {(ambulanceDashboard.pendingForAcknowledgement !== undefined && ambulanceDashboard.pendingForAcknowledgement !== null && ambulanceDashboard.pendingForAcknowledgement.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Ambulance Bookings for Acknowledgement</h4></div>
                                                {ambulanceDashboard.pendingForAcknowledgement.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box">
                                                            <div className='db_header'>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/book-summary-ambulance/${key.id}`}>Booking Info</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.booked_by}</h5>
                                                                        <p><span className='title-semibold'>Ambulance Type:</span> <span>{key.ambulance_type}</span></p>
                                                                        <p><span className='title-semibold'>Route:</span> From - {key.pickup_address} To - {key.destination_address}</p>
                                                                        <p><span className='title-semibold'>Schedule Time:</span> {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}</p>
                                                                        <p><span className='title-semibold'>Request Raise Time:</span> {moment(key.created_at).utc().format("MMM DD")}, {moment(key.created_at).utc().format("hh:mm a")}</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                {/* <div className='date'> {(moment(item.appointment_date).utc()).format("MMM DD")}, {(moment(item.appointment_date).utc()).format("hh:mm a")}</div> */}
                                                                <div>
                                                                    <button className='btn btn-sm btn-success' onClick={e => AcceptRejectBooking(key.id, true)}>Accept</button>
                                                                    <a style={{ cursor: "pointer" }} className='btnreject ml-1' onClick={e => AcceptRejectBooking(key.id, false)}>Reject</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>



                                    {/* Today's Appointments */}
                                    <div class="row ">
                                        {(todayAppoinment !== undefined && todayAppoinment !== null && todayAppoinment.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title" style={{ fontWeight: "600" }}>Today's Appointments</h4></div>
                                                {todayAppoinment.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box" style={{ border: "1px solid #056B66" }}>
                                                            <div className='db_header' style={{ backgroundColor: "#056B66" }}>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.booking_id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            {(key.service_type === "Doctor") ? <li><Link to={`/bookings-summary/${key.id}`} state={{ type: "Doctor" }}>Booking Info</Link></li>
                                                                                : (key.service_type === "Nurse") ? <li><Link to={`/bookings-visit-summary/${key.id}`} state={{ type: "Nurse" }}>Booking Info</Link></li> :
                                                                                    <li><Link to={`/book-summary-ambulance/${key.id}`} state={{ type: "Ambulance" }}>Booking Info</Link></li>}
                                                                            {/* <li><Link to={`/bookings-summary/${key.id}`} state={{ type: type }}>Booking Info</Link></li> */}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.name}</h5>
                                                                        <p>{key.gender}, {key.age} Yrs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'> {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}</div>
                                                                <div>

                                                                    {/* {(item.status.toLowerCase() !== "cancelled") ?
                                                                        ((item.is_confirmed === 1) ?
                                                                            ((moment.utc(getLocalToUTC(new Date())).add(5, 'minutes') >= moment.utc(item.appointment_date)) && (moment.utc(getLocalToUTC(new Date())) <= moment.utc(item.appointment_date).add(30, 'minutes'))) ?
                                                                                <Link to="/video-call" state={{ roomId: item.room_id }}> <i className='fa fa-video'></i>  Join Call</Link> : "" :
                                                                            item.is_confirmed === 0 ? <a style={{ color: "orange" }}>Pending</a> : <a style={{ color: "green" }}>Confirmed </a>)
                                                                        : <button className='btn btn-sm btn-success' onClick={e => AcceptRejectBooking(item.booking_id, true)}>Accept</button>} */}

                                                                    {(key.status.toLowerCase() !== "cancelled") ?
                                                                        ((key.is_confirmed === 1 && key.service_type === 'Doctor') ?
                                                                            ((moment.utc(getLocalToUTC(new Date())).add(2, 'days').startOf('day') >= moment.utc(key.appointment_date).startOf('day')) &&
                                                                                (moment.utc(getLocalToUTC(new Date())).subtract(2, 'days').endOf('day') <= moment.utc(key.appointment_date).endOf('day'))) ?
                                                                                <Link to="/video-call" state={{ roomId: key.room_id }}>
                                                                                    <i className='fa fa-video'></i> Join Call
                                                                                </Link> : "" :
                                                                            key.is_confirmed === 0 ? <a style={{ color: "orange" }}>Pending</a> : <a style={{ color: "green" }}>Confirmed</a>
                                                                        ) :
                                                                        <button className='btn btn-sm btn-success' onClick={e => AcceptRejectBooking(key.booking_id, true)}>Accept</button>
                                                                    }



                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>

                                    <div class="row ">
                                        {(homecareDashboard.todayAppointments !== undefined && homecareDashboard.todayAppointments !== null && homecareDashboard.todayAppointments.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title" style={{ fontWeight: "600" }}>Today's Appointments For Homecare </h4></div>
                                                {homecareDashboard.todayAppointments.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box" style={{ border: "1px solid #056B66" }}>
                                                            <div className='db_header' style={{ backgroundColor: "#056B66" }}>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.booking_id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/bookings-visit-summary/${key.id}`} >Booking Info</Link></li>
                                                                            {key.status === 'Accepted' && key.item_service_status !== 'Completed' && (
                                                                                <li><Link onClick={() => RequestOrComplete(key.id, 'Requested', key.service_type)}>Request For Completion</Link></li>
                                                                            )}
                                                                            {(key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") && key.status.toLowerCase() !== "rejected" &&
                                                                                key.is_confirmed === 0 && key.status === 'Scheduled' && (
                                                                                    <li><Link onClick={() => ConfirmOrrejectHomecareVisit(key.id, false)}>Reject</Link></li>
                                                                                )}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.booked_by}</h5>
                                                                        <p>{key.other_gender || key.gender}, {key.pickup_address} Yrs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'>
                                                                    {key.scheduled_date && key.scheduled_date !== 'undefined' ? (
                                                                        <>
                                                                            {moment(key.scheduled_date).utc().format("MMM DD")},{" "}
                                                                            {moment(key.scheduled_date).utc().format("hh:mm a")}
                                                                        </>
                                                                    ) : (
                                                                        <>Not Scheduled</>
                                                                    )}
                                                                </div>

                                                                <div>
                                                                    {key.status.toLowerCase() !== "cancelled" ? (
                                                                        key.is_confirmed === 1 ? (
                                                                            <a style={{ color: "green" }}>Confirmed</a>
                                                                        ) : (
                                                                            <button
                                                                                className="btn btn-sm btn-success"
                                                                                onClick={() => ConfirmOrrejectHomecareVisit(key.id, true)}
                                                                            >
                                                                                Accept
                                                                            </button>
                                                                        )
                                                                    ) : null}




                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>

                                    <div class="row ">
                                        {(ambulanceDashboard.todayAppointments !== undefined && ambulanceDashboard.todayAppointments !== null && ambulanceDashboard.todayAppointments.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title" style={{ fontWeight: "600" }}>Today's Appointments For Ambulance</h4></div>
                                                {ambulanceDashboard.todayAppointments.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box" style={{ border: "1px solid #056B66" }}>
                                                            <div className='db_header' style={{ backgroundColor: "#056B66" }}>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/book-summary-ambulance/${key.id}`}>Booking Info</Link></li>
                                                                            {(key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed" && key.status.toLowerCase() !== "rejected" && key.is_confirmed === 1 && key.status === 'Booked' && key.driver_id !== null) ? (
                                                                                <li>
                                                                                    <Link onClick={() => {
                                                                                        if (key.driver_id === null) {
                                                                                            openAssignAmbulanceModal(key.id);
                                                                                        } else {
                                                                                            openAssignAmbulanceModal(key.id);
                                                                                        }
                                                                                    }}>
                                                                                        {key.driver_id === null ? "Assign Ambulance" : "Change Ambulance"}
                                                                                    </Link>
                                                                                </li>
                                                                            ) : null}
                                                                            {key.status === 'Booked' && key.service_status !== 'Completed' && key.remaining_amount > 0 && (
                                                                                <li><Link onClick={() => RequestOrComplete(key.id, 'Requested', key.service_type)}>Request For Completion</Link></li>
                                                                            )}
                                                                            {(key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") && key.status.toLowerCase() !== "rejected" &&
                                                                                key.is_confirmed === 0 && key.status === 'Booked' ? (
                                                                                <li><Link onClick={() => AcceptRejectBooking(key.id, false, key.service_type)}>Reject</Link></li>
                                                                            ) : null}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.booked_by}</h5>
                                                                        <p>Ambulance Type: <span>{key.ambulance_type}</span></p>
                                                                        <p>Route: From - {key.pickup_address} To - {key.destination_address}</p>
                                                                        <p>Schedule Time: {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}</p>
                                                                        <p>Request Raise Time: {moment(key.created_at).utc().format("MMM DD")}, {moment(key.created_at).utc().format("hh:mm a")}</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'> {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}</div>
                                                                <div>
                                                                    {(key.is_confirmed === 0 && key.status === 'Booked') ? (<button className='btn btn-sm btn-success' onClick={() => AcceptRejectBooking(key.id, true, key.service_type)}>Accept</button>)
                                                                        : (key.is_confirmed === 1 && key.status === 'Booked' && key.remaining_amount == 0 && key.driver_id !== null) ? (
                                                                            <button type="button" className="btn btn-info" data-toggle="modal" data-target="#modelForRemainingAmount"
                                                                                onClick={() => setBookingId(key.id)}
                                                                            >
                                                                                Add Amount
                                                                            </button>
                                                                        )
                                                                            : (key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed" && key.status.toLowerCase() !== "rejected" && key.is_confirmed === 1 && key.status === 'Booked' && key.driver_id === null) ? (
                                                                                <button type="button" className="btn btn-info" onClick={() => openAssignAmbulanceModal(key.id)}>
                                                                                    Assign Ambulance
                                                                                </button>
                                                                            )
                                                                                : (key.is_confirmed === 1 && key.status == 'Booked') ? <a style={{ color: 'green' }}>Confirmed</a>
                                                                                    : (<a style={{ color: 'orange' }}>{key.status}</a>
                                                                                    )}


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>

                                    {/* Recent Prescription */}
                                    <div class="row ">
                                        {(recentPrescription !== undefined && recentPrescription !== null && recentPrescription.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Recent Prescription</h4></div>
                                                {(recentPrescription != null && recentPrescription != undefined && recentPrescription.length > 0) && recentPrescription.map(item =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box">
                                                            <div className='db_header'>
                                                                <h5>E-Prescription </h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{item.id}</p>
                                                                    <span><a onClick={() => setPrescriptionid(item.id)} target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a></span>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">

                                                                    <div className='info_text'>
                                                                        <h5>{item.name}</h5>
                                                                        <p>{item.gender}, {item.age} Yrs</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}

                                            </>
                                        }

                                    </div>

                                    {/* Upcoming Bookings (Next 7 Days) */}
                                    <div class="row ">
                                        {(upcomingBookings !== undefined && upcomingBookings !== null && upcomingBookings.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Upcoming Bookings (Next 7 Days) </h4></div>
                                                {upcomingBookings.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box">
                                                            <div className='db_header'>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            {(key.service_type === "Doctor") ? <li><Link to={`/bookings-summary/${key.id}`} state={{ type: "Doctor" }}>Booking Info</Link></li>
                                                                                : (key.service_type === "Nurse") ? <li><Link to={`/bookings-visit-summary/${key.id}`} state={{ type: "Nurse" }}>Booking Info</Link></li> :
                                                                                    <li><Link to={`/book-summary-ambulance/${key.id}`} state={{ type: "Ambulance" }}>Booking Info</Link></li>}
                                                                            {/* <li><Link to={`/bookings-summary/${key.id}`} state={{ type: type }}>Booking Info</Link></li> */}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.profile_pic !== null && key.profile_pic !== "null") ? key.profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.name}</h5>
                                                                        <p>{key.gender}, {key.age} Yrs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'> {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}</div>
                                                                <div>
                                                                    {key.status.toLowerCase() !== "cancelled" ? (
                                                                        key.is_confirmed === 1 && key.service_type === "Doctor" ? (
                                                                            moment
                                                                                .utc(getLocalToUTC(new Date()))
                                                                                .add(2, "days")
                                                                                .startOf("day")
                                                                                .isSameOrAfter(moment.utc(key.appointment_date).startOf("day")) &&
                                                                                moment
                                                                                    .utc(getLocalToUTC(new Date()))
                                                                                    .subtract(2, "days")
                                                                                    .endOf("day")
                                                                                    .isSameOrBefore(moment.utc(key.appointment_date).endOf("day")) ? (
                                                                                <Link to="/video-call" state={{ roomId: key.room_id }}>
                                                                                    <i className="fa fa-video"></i> Join Call
                                                                                </Link>
                                                                            ) : null
                                                                        ) : key.is_confirmed === 1 && key.status === "Booked" ? (
                                                                            <a style={{ color: "green" }}>Confirmed</a>
                                                                        ) : (
                                                                            <button
                                                                                className="btn btn-sm btn-success"
                                                                                onClick={(e) => AcceptRejectBooking(key.booking_id, true)}
                                                                            >
                                                                                Accept
                                                                            </button>
                                                                        )
                                                                    ) : null}
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }

                                    </div>

                                    <div class="row ">
                                        {(homecareDashboard.upcomingBookings !== undefined && homecareDashboard.upcomingBookings !== null && homecareDashboard.upcomingBookings.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Upcoming Homecare Bookings (Next 7 Days) </h4></div>
                                                {homecareDashboard.upcomingBookings.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box" style={{ border: "1px solid #056B66" }}>
                                                            <div className='db_header'>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.booking_id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/bookings-visit-summary/${key.id}`} >Booking Info</Link></li>
                                                                            {key.status === 'Accepted' && key.item_service_status !== 'Completed' && (
                                                                                <li><Link onClick={() => RequestOrComplete(key.id, 'Requested', key.service_type)}>Request For Completion</Link></li>
                                                                            )}
                                                                            {(key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") && key.status.toLowerCase() !== "rejected" &&
                                                                                key.is_confirmed === 0 && key.status === 'Scheduled' && (
                                                                                    <li><Link onClick={() => ConfirmOrrejectHomecareVisit(key.id, false)}>Reject</Link></li>
                                                                                )}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.booked_by}</h5>
                                                                        <p>{key.other_gender || key.gender}, {key.pickup_address}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'>
                                                                    {key.scheduled_date && key.scheduled_date !== 'undefined' ? (
                                                                        <>
                                                                            {moment(key.scheduled_date).utc().format("MMM DD")},{" "}
                                                                            {moment(key.scheduled_date).utc().format("hh:mm a")}
                                                                        </>
                                                                    ) : (
                                                                        <>Not Scheduled</>
                                                                    )}
                                                                </div>

                                                                <div>
                                                                    {key.status.toLowerCase() !== "cancelled" ?
                                                                        (key.status === 'Scheduled' && key.is_confirmed === 0) ? (
                                                                            <button className="btn btn-sm btn-success" onClick={() => ConfirmOrrejectHomecareVisit(key.id, true)}>
                                                                                Accept
                                                                            </button>
                                                                        ) : (key.is_confirmed === 1 && key.status === 'Accepted') ? (
                                                                            <a style={{ color: "green" }}>Confirmed</a>
                                                                        ) : (
                                                                            <a style={{ color: "orange" }}>Pending</a>
                                                                        ) : null}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }

                                    </div>

                                    <div class="row ">
                                        {(ambulanceDashboard.upcomingBookingsForAmbulance !== undefined && ambulanceDashboard.upcomingBookingsForAmbulance !== null && ambulanceDashboard.upcomingBookingsForAmbulance.length > 0) &&
                                            <>
                                                <div className='col-lg-12'><h4 class="page_title">Upcoming Ambulance Bookings (Next 7 Days) </h4></div>
                                                {ambulanceDashboard.upcomingBookingsForAmbulance.map(key =>
                                                    <div className='col-lg-4 '>
                                                        <div class="db_box" style={{ border: "1px solid #056B66" }}>
                                                            <div className='db_header'>
                                                                <h5>{(key.service_type === "Doctor") ? "Teleconsultation" : (key.service_type === "Nurse") ? "Home care" : "Ambulance"}</h5>
                                                                <div className='dbh_right'>
                                                                    <p>#{key.id}</p>
                                                                    <div className='dropdown' >
                                                                        <a href='#' className='dropdown-toggle' data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                                        <ul class="dropdown-menu ">
                                                                            <li><Link to={`/book-summary-ambulance/${key.id}`}>Booking Info</Link></li>
                                                                            {(key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed" && key.status.toLowerCase() !== "rejected" && key.is_confirmed === 1 && key.status === 'Booked' && key.driver_id !== null) ? (
                                                                                <li>
                                                                                    <Link onClick={() => {
                                                                                        if (key.driver_id === null) {
                                                                                            openAssignAmbulanceModal(key.id);
                                                                                        } else {
                                                                                            openAssignAmbulanceModal(key.id);
                                                                                        }
                                                                                    }}>
                                                                                        {key.driver_id === null ? "Assign Ambulance" : "Change Ambulance"}
                                                                                    </Link>
                                                                                </li>
                                                                            ) : null}
                                                                            {key.status === 'Booked' && key.service_status !== 'Completed' && key.remaining_amount > 0 && (
                                                                                <li><Link onClick={() => RequestOrComplete(key.id, 'Requested', key.service_type)}>Request For Completion</Link></li>
                                                                            )}
                                                                            {(key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed") && key.status.toLowerCase() !== "rejected" &&
                                                                                key.is_confirmed === 0 && key.status === 'Booked' ? (
                                                                                <li><Link onClick={() => AcceptRejectBooking(key.id, false, key.service_type)}>Reject</Link></li>
                                                                            ) : null}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_body'>
                                                                <div class="infono">
                                                                    <div className='infpo_icon'>
                                                                        {<img src={(key.patient_profile_pic !== null && key.patient_profile_pic !== "null") ? key.patient_profile_pic : userIcon} alt="user img" />
                                                                        }
                                                                    </div>
                                                                    <div className='info_text'>
                                                                        <h5>{key.booked_by}</h5>
                                                                        <p><span className='title-semibold'>Ambulance Type:</span> <span>{key.ambulance_type}</span></p>
                                                                        <p><span className='title-semibold'>Route:</span> From - {key.pickup_address} To - {key.destination_address}</p>
                                                                        <p><span className='title-semibold'>Schedule Time:</span> {moment(key.appointment_date).utc().format("MMM DD")}, {moment(key.appointment_date).utc().format("hh:mm a")}</p>
                                                                        <p><span className='title-semibold'>Request Raise Time:</span> {moment(key.created_at).utc().format("MMM DD")}, {moment(key.created_at).utc().format("hh:mm a")}</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='db_footer'>
                                                                <div className='date'> {(moment(key.appointment_date).utc()).format("MMM DD")}, {(moment(key.appointment_date).utc()).format("hh:mm a")}</div>
                                                                <div>
                                                                    {(key.is_confirmed === 0 && key.status === 'Booked') ? (<button className='btn btn-sm btn-success' onClick={() => AcceptRejectBooking(key.id, true, key.service_type)}>Accept</button>)
                                                                        : (key.is_confirmed === 1 && key.status === 'Booked' && key.remaining_amount == 0 && key.driver_id !== null) ? (
                                                                            <button type="button" className="btn btn-info" data-toggle="modal" data-target="#modelForRemainingAmount"
                                                                                onClick={() => setBookingId(key.id)}
                                                                            >
                                                                                Add Amount
                                                                            </button>
                                                                        )
                                                                            : (key.status.toLowerCase() !== "cancelled" && key.status.toLowerCase() !== "completed" && key.status.toLowerCase() !== "rejected" && key.is_confirmed === 1 && key.status === 'Booked' && key.driver_id === null) ? (
                                                                                <button type="button" className="btn btn-info" onClick={() => openAssignAmbulanceModal(key.id)}>
                                                                                    Assign Ambulance
                                                                                </button>
                                                                            )
                                                                                : (key.is_confirmed === 1 && key.status == 'Booked') ? <a style={{ color: 'green' }}>Confirmed</a>
                                                                                    : (<a style={{ color: 'orange' }}>{key.status}</a>
                                                                                    )}


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }

                                    </div>



                                </div>
                            </div>
                        </div> :
                        <div class="defaultloader">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#056B66"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            /></div>
                    }
                </div>
            </section>
            {prescriptionid !== undefined && prescriptionid !== null && prescriptionid !== "" ?
                <div id="capture">
                    <Eprecriptiondownload prescriptionid={prescriptionid} setPrescriptionid={setPrescriptionid}></Eprecriptiondownload>
                </div>
                : ""
            }

            {/* <!-- modal to show list of ambulances and list of drivers and selecting assign the ambulances to driver --> */}
            <div className="modal fade" id="modelForAssignAmbulanceToDriver" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: '#056B66', color: 'white' }}>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Assign Ambulance To Driver</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="sel1">Select Ambulance:</label>
                                        <select className="form-control" id="sel1" onChange={(e) => setAmbulace_id(e.target.value)}>
                                            <option value="">Select Ambulance</option>
                                            {(ambulanceList !== null && ambulanceList !== undefined && ambulanceList.length > 0) && ambulanceList.map((ambulance) => (
                                                <option value={ambulance.id}>{ambulance.vehicle_number}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="sel1">Select Driver:</label>
                                        <select className="form-control" id="sel1" onChange={(e) => setDriverId(e.target.value)}>
                                            <option value="">Select Driver</option>
                                            {driverList != null && driverList.map((driver) => (
                                                <option value={driver.id}>{driver.driver_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h6 className="under-box-update-email">Updates will be sent to the registered email address</h6>
                            <p className="Undertaking">Teleconsultation provides an accessible, convenient, and cost-effective alternative to in-person consultations. However, it should be noted that teleconsultation may not include physical examination and other diagnostic procedures that are necessary for certain medical conditions.</p>
                            <p>
                                <input type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                                <span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span>
                            </p>
                            <div className="text-center margintop-bottom">
                                <button
                                    type="submit"
                                    className={`btn btn_fill ${isBookAndPayEnabled3 ? '' : 'disabled'}`}
                                    onClick={() => {
                                        assignAmbulance(ambulace_id, driverId);
                                        $('#modelForAssignAmbulanceToDriver').modal('hide'); // Hide the modal
                                    }}
                                    disabled={!isBookAndPayEnabled3}
                                >
                                    Assign Ambulance To Driver
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- modal for add remaining amount and showing booking_id oncliq add amount --> */}
            <div className="modal fade" id="modelForRemainingAmount" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Add Amount</h4>
                        </div>
                        <div className="modal-body">
                            <p>Booking Id: {bookingId}</p>
                            <hr />
                            <p className="font-size font-blod">Remaining Amount</p>
                            <div className="date-time">
                                <div className="date">
                                    <h5>Remaining Amount</h5>
                                    <input type="number" step="0.01" id="remainingAmountInput" name="remainingAmount" onChange={(e) => setRemaining_amount(e.target.value)} />
                                </div>
                                {/* <div className="time">
                                    <h5>Payable Amount</h5>
                                    <input type="number" step="0.01" id="payableAmountInput" name="payableAmount" onChange={(e) => setPayableAmount(parseFloat(e.target.value))} />
                                </div> */}
                            </div>
                        </div>
                        <hr />
                        <div>
                            <h6 className="under-box-update-email">Updates will be sent to the registered email address</h6>
                            <p className="Undertaking">Teleconsultation provides an accessible, convenient, and cost-effective alternative to in-person consultations. However, it should be noted that teleconsultation may not include physical examination and other diagnostic procedures that are necessary for certain medical conditions.</p>
                            <p>
                                <input type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                                <span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span>
                            </p>
                            <div className="text-center margintop-bottom">
                                <button
                                    type="submit"
                                    className={`btn btn_fill btn-type-custum ${isBookAndPayEnabled2 ? '' : 'disabled'}`}
                                    onClick={() => {
                                        addRemainingamount(bookingId);
                                        $('#modelForRemainingAmount').modal('hide'); // Hide the modal
                                    }}
                                    disabled={!isBookAndPayEnabled2}
                                >
                                    Add Amount
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>





    </>);
}
export default ProviderDashboard;