import React, { useEffect, useState } from 'react'
import VinitaIcon from "../../../images/images/vinita.png"
import DownloadPng from "../../../images/images/download.png"
import { HomepageHeader, SidebarUserPanel } from '../../../components'
import { ProfileService } from '../../../services'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router'

const MedicalHistoryAndNotes = () => {
  const location = useLocation();
  // const {bookingId} = location.state;
  const [myDoctors, setMyDoctors] = useState([])
  const apiFunction = new ProfileService();
  const [notes, setNotes] = useState("")
  const [checkBoxNotes, setCheckBoxNotes] = useState(
    [
      { id: "All", checked: false },
      { id: "Alergies", checked: false },
      { id: "Diabetas", checked: false },
      { id: "On Medication", checked: false },
    ])
  useEffect(() => {
    // loadDoctors();
  }, [])


  const onAddMedicalHistory = (e) => {
    e.preventDefault();
   const checkedArr = checkBoxNotes.filter(item=>item.id !=="All" && item.checked===true)
   const lengthCheckedArr = checkedArr.length
   if(lengthCheckedArr===0){
    return toast.error("Please select at list one reasone")
   }

   const medical_history = []
   for(let i=0; i < checkedArr.length;i++){
     medical_history.push({history_name:checkedArr[i].id})
   }
const data = {
  // bookingId:bookingId,
  medical_history:medical_history,
  notes:notes
}
apiFunction.AddMedicalHistory(data).then(result => {
  if (result !== undefined) {
    if (result.success === true) {
      window.$('#addNotes').modal('hide');
    }
  }
})
  }
  const handelChecked = (event) => {
    // const id = event.target.getAttribute("data-id")
    const updatedNotesList = [...checkBoxNotes]
    const modifiedNotesList = updatedNotesList.map(item => {
      if(event.target.value=="All"){
        item.checked = event.target.checked
        return item;
      }
      else if(item.id === event.target.value){
        item.checked = event.target.checked
      }
      return item;
    })
    setCheckBoxNotes(modifiedNotesList)
  }


  return (
    <div className="outerhomepage">
      {/*Top Navbar Menu*/}
      <HomepageHeader />

      <section className=" paddbottom">
        <div className="container">
          <div className="userpanel_page bg_light">

            {/* Side bar */}
            <SidebarUserPanel />

            <div className="right_panel table-responsive">
              <table className="table mytable">
                <tbody>
                  <tr>
                    <th style={{ width: '33.33%' }}>
                      <input type="checkbox" />Select All
                    </th>
                    <th style={{ width: '33.33%' }}>Name</th>
                    <th style={{ width: '33.33%', textAlign: "right" }} >
                      <button type="button" class="btn btn-info btn-sm" data-toggle="modal" data-target="#addNotes">Add</button>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <div >
                        <input type="checkbox" />
                      </div>
                      <div >
                        <input type="checkbox" />
                      </div>
                      <div >
                        <input type="checkbox" />
                      </div>
                    </td>
                    <td>
                      
                   
                      <div className="userdtl">
                        <div className="userinfo">
                          <p>Alergies</p>
                        </div>
                      </div>
                    
                      <div className="userdtl">
                        <div className="userinfo">
                          <p>On Medication</p>
                        </div>
                      </div>
                      <div className="userdtl">
                        <div className="userinfo">
                          <p>Diabetes</p>
                        </div>
                      </div>
                      
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

            {/* Modal */}
            <div className="modal fade" id="addNotes" role="dialog">
              <div className="modal-dialog">
                {/* Modal content*/}
                <form onSubmit={onAddMedicalHistory}>
                  <div className="modal-content">
                    <div className="modal-header" style={{ borderBottom: "none" }}>
                      <button type="button" className="close" data-dismiss="modal">×</button>
                      <h4 className="modal-title">Add medical history</h4>
                    </div>
                    <div className="modal-body">
                      <div className='col-md-12'>
                      {checkBoxNotes.map(item=>(
                        <div className='col-md-4'>
                        <input type="checkbox"
                        className='checkBoxPd'
                          value={item.id}
                          checked={item.checked}
                          onChange={handelChecked} />
                        <span>
                          {item.id}
                        </span>
                        </div>
                      ))}
                      </div>

                      <div className='col-md-12' style={{
                        paddingTop: "15px", paddingBottom: "10px"
                      }}>
                        <span> Notes</span>
                        <textarea value={notes} onChange={(e)=>setNotes(e.target.value)} class="form-control" rows="5" id="comment"></textarea>
                      </div>
                    </div>

                    <div className="modal-footer" style={{ borderTop: "none" }}>
                      <button type="submit" className="btn btn-success">Save</button>
                      <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>



          </div>
        </div>
      </section>

    </div>
  )
}

export default MedicalHistoryAndNotes;