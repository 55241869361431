import React from 'react'
import LogoWhite from "../../images/home/logo_white.svg"
import {Link} from "react-router-dom"

const Footer = () => {
    const id=localStorage.getItem('userid')
    return (
        <footer className="ft_footer">
            <div className="container">
                <div className="ft_footer_links">
                    <div className="footer_column">
                        <div className="footer_title"><span>FastTrack</span></div>
                        <ul className="footer_list">
                            <li><Link className="footer_item" to="/about">About Us</Link></li>
                            
                            <li><Link className="footer_item" to="/contact-us">Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="footer_column">
                        <div className="footer_title"><span>For Patients</span></div>
                        <ul className="footer_list">
                            <li><Link className="footer_item" to="/tele-consultation">Search Doctors</Link></li>
                            <li><Link className="footer_item" to="/homecare">Search Nurses</Link></li>
                            <li><Link className="footer_item" to="/ambulance">Search Ambulances</Link></li>
                            <li><a className="footer_item" href="https://play.google.com/store/apps/details?id=com.acompworld.fasttrackhealthcare" target="_blank">Download FastTrack App</a></li>
                  
                        </ul>
                    </div>
                    <div className="footer_column">
                        <div className="footer_title"><span>For Service Providers</span></div>
                        <ul className="footer_list">
                            <li><Link className="footer_item" to={id?'/be-service-provider':'/login'}>Create Profile</Link></li>
                            
                        </ul>
                    </div>
                    <div className="footer_column">
                        <div className="footer_title"><span>More</span></div>
                        <ul className="footer_list">
                            <li> <Link className="footer_item" to={"/privacy-policy"}>Privacy Policy</Link> </li>
                            <li><Link className="footer_item" to={"/terms-and-conditions"}>Terms and Condition</Link></li>
                            <li><Link className="footer_item" to={"/pricing"}>Pricing</Link></li>
                            <li><Link className="footer_item" to={"/refund-policy"}>Cancellation and Refund Policy</Link></li>
                        </ul>
                    </div>
                    <div className="footer_column">
                        <div className="footer_title"><span>We’re Social</span></div>
                        <ul className="footer_list">
                            <li><a className="footer_item" target="_blank" href="https://www.facebook.com/fasttrackhealthcare.in">Facebook</a></li>
                            <li><a className="footer_item" target="_blank" href="https://twitter.com/FasttrackH34922">Twitter</a> </li>
                            <li><a className="footer_item" target="_blank" href="https://instagram.com/fasttrackhealthcare">Instagram</a></li>
                            <li><a className="footer_item" target="_blank" href="https://www.linkedin.com/company/fasttrack-healthcare/">LinkedIn</a></li>
                            <li><a className="footer_item" target="_blank" href="https://www.youtube.com/@fasttrack_healthcare">Youtube</a></li>
                        </ul>
                    </div>
                </div>
                <div className="ft_footer_copyright">
                    <span className><img src={LogoWhite} alt="FastTrack" className="footer_logo" /></span>
                    <div className="c_copyright"><span>FastTrack Copyright © 2023. </span><span>All rights reserved.</span></div>
                </div>
            </div>
        </footer>

    )
}

export default Footer