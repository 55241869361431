import React, { useEffect, useState, Alert } from 'react'
import { Footer, HomepageHeader, LocationsList } from '../../components'
import SearchIcon from "../../images/home/search.png"
import LocationIcon from "../../images/home/map-pin.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import CheckCircle from "../../images/images/check-circle.png"
import iconArrowRight from "../../images/images/iconArrow-Right.svg"
import iconArrowLeft from "../../images/images/iconArrow-Left.svg"
import VcIconPng from "../../images/images/vc_icon.png"
import { HomePageServices, ProfileAndSlotService } from '../../services'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShowProfileType } from '../../enums/Enums'
import { Change_to_12HourFormat } from '../../helpers/Change_to_12HourFormat'
import moment from 'moment'
import { toast } from 'react-toastify'
import axios from 'axios';
import { Message } from '@mui/icons-material'

const token = localStorage.getItem("token")
const baseurl = process.env.REACT_APP_API_BASE_URL
// import LoginPopup from '../../popups/LoginPopup'





const ShowSlotsProfileAppoinment = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const date = new Date();
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1)
    const { userid, searchType, bookingid, scheduleType } = state; //searchType is -- from which type of page we are coming from 
   

    const [newsearchType, setNewSearchType] = useState(searchType)

    const [sliceArr, setSliceArr] = useState(
        {
            indexFrom: 0,
            indexTo: 2
        })
    const { indexFrom, indexTo } = sliceArr;

    const apiFunction = new ProfileAndSlotService();
    const apiFunction2 = new HomePageServices();
    const [activeSlotsDays, setActiveSlotsDays] = useState("FirstItem")
    const [search_key, setsearch_key] = useState("")
    const [items, setItems] = useState([])
    const [isloading, setisloading] = useState("")
    const [search_keyCity, setsearch_keyCity] = useState("")
    const [nursesButton, setNursesTabButton] = useState("Hourly")
    const [isSuggestionListShow, setisSuggestionListShow] = useState(false)
    const [slotArr, setSlotArr] = useState([])
    const [arrMultipleSlotItem, setArrMultipleSlotItem] = useState([])
    const [arrMultipleSlotDate, setArrMultipleSlotDate] = useState("")
    const [arrMultipleSlotTime, setArrMultipleSlotTime] = useState("")
    const [selectedSlotDate, setSelectedSlotDate] = useState("")
    const [previouddates, setpreviouddates] = useState("")
    const [arrLength, setArrLength] = useState(0)



    const [userData, setUserdata] = useState({
        username: "",
        bio: "",
        profile_pic: "",
        qualification: "",
        specialization: "",
        experience: "",
        consultation_fees: "",
        followup_fees:"",
        video_consult: "",
        slots: [],
        patientStories: [],
        service_provider_id: 0
    })
   

    const [count, setCount] = useState(0);

    useEffect(() => {       
        const arr = userData.slots.slice(indexFrom, indexTo)
        setSlotArr(arr)
        // Empty all the - to send values 
        setArrMultipleSlotItem([])
        setArrMultipleSlotDate("")
        setArrMultipleSlotTime("")
        // setSelectedSlotDate(item.date === moment(new Date()).format("YYYY-MM-DD")
    }, [sliceArr])

    useEffect(() => {
        window.scrollTo(0, 0);
        
        searchBySuggetions(userid, newsearchType);
        sessionStorage.setItem("notLoggedInBookingFlowUrlDetails", "")

    }, [])

    // Go to book deatils if not logged in login form will open
    const RescheduleBookin = async (bookingid, slotid) => {
        try {
            var config = {
                headers: {
                    'authorization': token,
                },
            };
            const data = {
                slot_id: slotid
            }

            const result = await axios.put(`${baseurl}bookings/reschedule/${bookingid}`, data, config)
                .catch(error => {
                    if (error.response.status === 400) {
                        if (error.response.data.success === false)
                            return toast.error(error.response.data.message)
                    }
                    else if (error.response.status === 401) {
                        return toast.error("You are authorized ")
                    }
                    else if (error.response.status === 404) {
                        return toast.error("Url not found")
                    }
                    else if (error.response.status === 500) {
                        return toast.error("Internal server error please try after sometime")
                    }
                });

            if (result !== null && result !== undefined) {
                navigate('/accounts-bookings')

            }
            else {
                // toast.error("Try agian!")
            }
        }
        catch (error) {
            return toast.error(error.response.data.message)
        }
    }

    const goToBookDetailsOpenForm = (SlotIdArr, date, time) => {

        let slotIdArr = SlotIdArr

        if (scheduleType !== undefined && scheduleType !== null && scheduleType === "Reschedule") {
            if (confirm('Are you sure you want to reschedule?')) {
                RescheduleBookin(bookingid, SlotIdArr[0].slot_id)
            } else {
                // Do nothing!
            }

        }
        else {

            if (localStorage.getItem("token")) {
                navigate('/booking-fill-form', {
                    state: {
                        slotidArr: slotIdArr,
                        slotDate: date,
                        slotTime: time,
                        doctorName: userData.username,
                        doctorFee: userData.consultation_fees,
                        followupFee: userData.followup_fees,
                        qualification: userData.qualification,
                        specialization: userData.specialization,
                        profileId: userid,
                        profileType: searchType,
                        service_provider_id: userData.service_provider_id,
                        experience: userData.experience,
                        profile_pic: userData.profile_pic
                    }
                })
            } else {
                sessionStorage.setItem("notLoggedInBookingFlowUrlDetails", JSON.stringify({
                    slotidArr: slotIdArr,
                    slotDate: date,
                    slotTime: time,
                    doctorName: userData.username,
                    doctorFee: userData.consultation_fees,
                    followupFee: userData.followup_fees,
                    qualification: userData.qualification,
                    specialization: userData.specialization,
                    profileType: searchType,
                    profileId: userid,
                    SlotIdArr: SlotIdArr,
                    service_provider_id: userData.service_provider_id,
                    profile_pic: userData.profile_pic


                }))
                toast.info("Please login to book a slot")
                navigate("/login")
            }
        }
    }

    useEffect(() => {
        setArrMultipleSlotItem([])
        setArrMultipleSlotDate("")
        setArrMultipleSlotTime("")
    }, [nursesButton])


    const prevDaySlots = (selectedslotdate) => {
        setpreviouddates(selectedslotdate)
        setSelectedSlotDate(selectedslotdate);
        if (activeSlotsDays === "SecondItem") {
            setActiveSlotsDays("FirstItem")
        } else {
            setSliceArr({
                indexFrom: indexFrom - 2,
                indexTo: indexTo - 2
            })
            setActiveSlotsDays("SecondItem")
        }
    }

    const nextDaySlots = (selectedslotdate) => {
        setpreviouddates(selectedslotdate)
        setSelectedSlotDate(selectedslotdate);
        if (activeSlotsDays === "FirstItem") {
            setActiveSlotsDays("SecondItem")
        } else {
            setSliceArr({
                indexFrom: indexFrom + 2,
                indexTo: indexTo + 2
            })
            setActiveSlotsDays("FirstItem")
        }
    }


    const searchBySuggetions = (userid, newsearchType) => {
        setisSuggestionListShow(false)

        if (newsearchType === ShowProfileType.type1) {
            apiFunction.LoadDoctorProfileAndSlots(userid).then(res => {
                if (res !== undefined) {
                    var result = res
                    if (result.success === true) {
                        const filterSlots = result.data.slots.filter(element => moment(new Date(element.date)).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD"));
                        let sliceSlotsArrData = filterSlots.slice(indexFrom, indexTo)
                        setArrLength(filterSlots.length)
                        setSlotArr(sliceSlotsArrData)
                        setsearch_key(result.data.doctor.name)
                        setSelectedSlotDate(moment(new Date(filterSlots[0]?.date)).format("YYYY-MM-DD"))
                        setUserdata({
                            username: result.data.doctor.name,
                            bio: result.data.doctor.bio,
                            profile_pic: result.data.doctor.profile_pic,
                            qualification: result.data.doctor.qualification,
                            specialization: result.data.specialization,
                            experience: result.data.doctor.experience,
                            consultation_fees: result.data.doctor.consultation_fees || 0,
                            followup_fees: result.data.doctor.followup_fees || 0,
                            video_consult: result.data.doctor.video_consult,
                            slots: filterSlots, // result.data.slots ||,
                            patientStories: [],
                            service_provider_id: result.data.doctor.service_provider_id

                        })
                      
                    }
                }
            })
        } else if (newsearchType === ShowProfileType.type2) {
            apiFunction.LoadNursesProfileAndSlots(userid).then(res => {
                if (res !== undefined) {
                    var result = res
                    
                    if (result.success === true) {
                        setUserdata({
                            username: result.data.name,
                            bio: result.data.bio,
                            profile_pic: result.data.profile_pic,
                            qualification: result.data.degree,
                            specialization: result.data.services,
                            experience: result.data.experience,
                            consultation_fees: result.data.consultation_fees || 0,
                            
                           // slots: filterSlots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: [],
                            service_provider_id: result.data.service_provider_id
                        })
                        const filterSlots = result.data.filter(element => moment(new Date(element.date)).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD"));
                     
                        let sliceSlotsArrData = filterSlots.slice(indexFrom, indexTo)
                        setsearch_key(result.data.name)
                        setSlotArr(sliceSlotsArrData)
                        setSelectedSlotDate(moment(new Date(filterSlots[0].date)).format("YYYY-MM-DD"))
                    }
                }
            })

        } else if (newsearchType === ShowProfileType.type3) {
            apiFunction.LoadAmbulancesProfileAndSlots(userid).then(res => {
                if (res !== undefined) {
                    var result = res
                    if (result.success === true) {
                        const filterSlots = result.data.slots.filter(element => moment(new Date(element.date)).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD"));
                        let sliceSlotsArrData = filterSlots.slice(indexFrom, indexTo)
                        let ambulancename = "", ambulanceBio = "", ambulanceProfilePic = "", ambulanceConsultationFees = ""
                        const ambulance = result.data.ambulance

                        if (ambulance) {
                            ambulancename = ambulance.name
                            ambulanceBio = ambulance.bio
                            ambulanceProfilePic = ambulance.profile_pic
                            ambulanceConsultationFees = ambulance.ambulanceConsultationFees
                        }
                        setsearch_key(ambulancename)
                        setSlotArr(sliceSlotsArrData)
                        setSelectedSlotDate(moment(new Date(filterSlots.length > 0 ? filterSlots[0].date : "")).format("YYYY-MM-DD"))
                        setUserdata({
                            username: ambulancename,
                            bio: ambulanceBio,
                            profile_pic: ambulanceProfilePic,
                            consultation_fees: ambulanceConsultationFees || 0,
                            slots: filterSlots, // result.data.slots ||,sliceSlotsArrData
                            patientStories: [],
                            service_provider_id: result.data.ambulance.service_provider_id
                        })
                    }
                }
            })
        }
        setCount(1)
    }

    const handelMultipleSelectionSlots = (id, date, time) => {


        if (arrMultipleSlotItem.filter(item => item.slot_id === id).length > 0) {
            const removeItem = arrMultipleSlotItem.filter(item => item.slot_id !== id)
            setArrMultipleSlotItem(removeItem);
        } else {
            const newSlotItem = [...arrMultipleSlotItem];
            newSlotItem.push({ slot_id: id });
            setArrMultipleSlotItem(newSlotItem);
        }
        setArrMultipleSlotDate(date)
        setArrMultipleSlotTime(time)



    }

    const handelClassName = (status, id) => {
        let className;
        if (status === "Available") {
            if (arrMultipleSlotItem.length > 0) {
                if (arrMultipleSlotItem.filter(item => item.slot_id === id).length > 0) {
                    className = "btn btn-primary active"
                } else {
                    className = "btn btn-primary"
                }
            }
            else {
                className = "btn btn-primary"
            }
        } else if (status) {
            className = "btn btn-primary disabled"
        }
        return className
    }

    const onClickSlotDate = (activeTab, selectedslotdate) => {
        setActiveSlotsDays(activeTab)
        setSelectedSlotDate(selectedslotdate)
    }

    const handelBookFullDaySlots = () => {
        let tempArr = []

        const arr = userData.slots.filter(item => item.date === selectedSlotDate)
        arr[0].mornings.forEach(element => {
            tempArr.push({ slot_id: element.id })
        });
        arr[0].afternoons.forEach(element => {
            tempArr.push({ slot_id: element.id })
        });
        arr[0].evenings.forEach(element => {
            tempArr.push({ slot_id: element.id })
        });

        setArrMultipleSlotItem(tempArr)
        goToBookDetailsOpenForm(tempArr, selectedSlotDate, "")
    }

    return (
        <>
            <div className='outerhomepage'>
                <HomepageHeader />
                {/*Inner Page Banner*/}


                {/* Book Appoinment*/}
                <section className="book_appoinment_box paddtop paddbottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                {/*Doctor Info*/}
                                <div className="doctor_details bg_light">
                                    <div className="doctor_img">
                                        <div className="dr_photo">
                                            <img src={userData?.profile_pic || userIcon} alt="service-provider" />
                                        </div>
                                    </div>
                                    <div className="doctor_bio">
                                        {/* <button className="btn btn_fill" style={{ float: "right" }}>Rate & Review</button> */}
                                        <h3 className="dr_name">{userData.username}</h3>
                                      
                                        {userData.specialization &&
                                            <p className="dr_quali">{
                                                (userData.specialization != null && userData.specialization.length > 0) &&
                                                userData.specialization.map((item, i) => <>{i !== 0 ? "," : ""}<span>{item.type || item.name}</span></>)
                                            }
                                            </p>
                                        }
                                        {userData.qualification &&
                                            <p className="dr_quali">{userData.qualification}</p>
                                        }
                                        {userData.experience &&
                                            <p className="dr_exp"><img alt='' src={CheckCircle} />&nbsp;&nbsp; {userData.experience} {userData.experience <= 1 ? "Year" : "Years"}</p>
                                        }
                                        {/* <p className="dr_votes"><span><img alt='' src={ThumbPng} />&nbsp;&nbsp; 96% </span>(935 votes)</p> */}
                                        <div className="dr_desc">
                                            {userData.bio &&
                                                <p className="dr_quali">{userData.bio}</p>
                                            }
                                            {/* Dr. Manohar Saini is a senior consultant Orthopedics specialized in Joint Replacement and Arthroscopic Surgery. He attended KGMC, Lucknow and was trained in Ortho
                                            <span id="dots">...</span>
                                            <span id="more">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
                                            </span>
                                            <a onclick="ReadMore()" id="myBtn" style={{ color: '#056B66', cursor: 'pointer' }}>Read more</a> */}
                                        </div>
                                    </div>
                                </div>

                                {/*Patient Stories*/}
                                {userData.patientStories.length !== 0 &&
                                    <div className="patient_stories bg_light">
                                        <div className="pt_stories_header">
                                            <h3>Patient Stories</h3>
                                        </div>

                                        {userData.patientStories.length === 0 ?
                                            <h4 className='text-center'>No stories found</h4>
                                            :
                                            <>
                                                <div className="pt_stories_body">
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw1"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img src="images/thumb-outline.png" alt='' />&nbsp;&nbsp; Recommended</p>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img alt='' src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw2"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img alt='' src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="happy_block">
                                                                    <p>Happy with:&nbsp; <span className="happy_tag">Treatment satisfaction</span> <span className="happy_tag">Value for money</span></p>
                                                                </div>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img alt='' src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt_info_head">
                                                        <div className="name_wraper">
                                                            <div className="name_word nw1"><span />PS</div>
                                                        </div>
                                                        <div className="pt_wraper">
                                                            <div className="name_dtl">
                                                                <div className="pt_wrap">
                                                                    <h4>Pushkar Srinvas</h4>
                                                                    <h3>Visited For Joint Pain</h3>
                                                                </div>
                                                                <span className="years">1 Year ago</span>
                                                            </div>
                                                            <div className="pt_feedback">
                                                                <p className="recommended"><img alt='' src="images/thumb-outline.png" />&nbsp;&nbsp; Recommended</p>
                                                                <div className="pt_text">
                                                                    <p>
                                                                        Dr. Manohar Saini is a very friendly and polite doctor, very professional in treating the patients.
                                                                    </p>
                                                                    <p>
                                                                        I had pain in my back, i went to doctor, he guided me very well. He performed xray and ultra sound scanning also. Hence i feel when an xray was taken there was no means and need to take ultra sound scanning again as both are similar kind of tests.
                                                                    </p>
                                                                    <p>
                                                                        However doctor has given us the best treatment and he has suggested us the best practices and prescribed few medicines. Now i am better without the back pain.
                                                                    </p>
                                                                </div>
                                                                {/*Doctor Reply*/}
                                                                <div className="dr_wraper">
                                                                    <div className="dr_photo"><img alt='' src="images/dr_photo.png" /></div>
                                                                    <div className="dr_reply">
                                                                        <h4>Dr. Manohar Saini replied</h4>
                                                                        <p>Thanks for your appreciation. Ultrasound and X rays are totally different investigations. Ultrasound was advised to rule out any visceral cause of backache whereas X- ray was taken to see any bony pathology. I am glad that you are alright now. Best Wishes.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt_stories_footer">
                                                    <div className="text-center"><a href="#" className="btn btn_fill">View more</a></div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                }
                            </div>

                            {/*Video Consultation*/}
                            {slotArr.length === 0 ?
                                <h4 className='text-center'>No slots found</h4>
                                :
                                <div className="col-lg-4">
                                    <div className="video_consult bg_light">
                                        <div className="vc_header">
                                            <h4><img alt='' src={searchType === ShowProfileType.type1 ? VcIconPng : ""} />&nbsp;&nbsp;{searchType === ShowProfileType.type1 ? "Video Consultation" :
                                                searchType === ShowProfileType.type2 ? "Choose Slots" : "Choose Slots"}
                                            </h4>
                                            {userData.consultation_fees &&
                                                <h4>&#x20B9; &nbsp;{userData.consultation_fees}</h4>
                                            }
                                        </div>

                                        <div className='col-md-12'>
                                            {/* Slider */}
                                            {slotArr.length !== 0 && slotArr.map((item, index) => (
                                                <>
                                                    {(index === 0) ?
                                                        <div className='col-md-6'>
                                                            {(previouddates < new Date()) ? "" :
                                                                (indexFrom > 0) &&
                                                                <button className='leftBtnSlots' onClick={() => prevDaySlots(moment(new Date(item.date)).format("YYYY-MM-DD"))}>
                                                                    <img src={iconArrowLeft} alt="Arrow Left" width="12px" />
                                                                </button>}
                                                            <div className={activeSlotsDays === "FirstItem" ? "leftSideSlots active" : "leftSideSlots"}
                                                                onClick={() => onClickSlotDate("FirstItem", moment(new Date(item.date)).format("YYYY-MM-DD"))}>
                                                                <div className='text-center'>
                                                                    <div className='row'>
                                                                        <div className='slotsDays'>
                                                                            <h6>{item.date === moment(new Date()).format("YYYY-MM-DD")
                                                                                ? "Today" : item.date === moment(new Date()).add(1, 'day').format("YYYY-MM-DD")
                                                                                    ? "Tomorrow" : moment(item.date, "YYYY-MM-DD").format("ddd,DD MMM")}
                                                                            </h6>
                                                                            {searchType === ShowProfileType.type3 ?
                                                                                <>
                                                                                    <span className='slotsAvailability'>
                                                                                        {(item.slots.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length
                                                                                        }{
                                                                                            (item.slots.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length <= 1 ?
                                                                                                ' Slot' : ' Slots'
                                                                                        } Available
                                                                                    </span>

                                                                                </> :
                                                                                <span className='slotsAvailability'>
                                                                                    {(item.mornings.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length +
                                                                                        (item.afternoons.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length +
                                                                                        (item.evenings.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length
                                                                                    }{
                                                                                        (item.evenings.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length <= 1 ?
                                                                                            '  Slot' : ' Slots'
                                                                                    }  Available
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='col-md-6'>
                                                            {(indexTo < arrLength - 1) &&
                                                                <button className='rightBtnSlots'
                                                                    onClick={() => nextDaySlots(moment(new Date(item.date)).format("YYYY-MM-DD"))}>
                                                                    <img src={iconArrowRight} alt="Arrow Right" width="12px" />
                                                                </button>
                                                            }
                                                            <div className={activeSlotsDays === "SecondItem" ? "rightSideSlots active" : "rightSideSlots"}
                                                                onClick={() => onClickSlotDate("SecondItem", moment(new Date(item.date)).format("YYYY-MM-DD"))}>
                                                                <div className='text-center'>
                                                                    <div className='row'>
                                                                        <div className='slotsDays'>
                                                                            <h6>{item.date === moment(new Date()).format("YYYY-MM-DD")
                                                                                ? "Today" : item.date === moment(new Date()).add(1, 'day').format("YYYY-MM-DD")
                                                                                    ? "Tomorrow" : moment(item.date, "YYYY-MM-DD").format("ddd,DD MMM")}
                                                                            </h6>
                                                                            <span className='slotsAvailability'>{
                                                                                (item.mornings.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length +
                                                                                (item.afternoons.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length +
                                                                                (item.evenings.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length
                                                                            }{
                                                                                    (item.evenings.filter(itemOfSlot => (itemOfSlot.status === "Available"))).length <= 1 ? ' Slot' : ' Slots'
                                                                                }  Available</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            ))}
                                        </div>
                                        <>

                                            {/* For Nurses */}
                                            {searchType === ShowProfileType.type2 ?
                                                <>
                                                    <div className='nurseSlotsType'>
                                                        <button
                                                            className={nursesButton === "Hourly" ? "btn btn-theme mr-1" : "btn btn-theme-light mr-1"}
                                                            onClick={() => setNursesTabButton("Hourly")}>Hourly</button>

                                                        <button className={nursesButton === "Full Day" ? "btn btn-theme mr-1" : "btn btn-theme-light mr-1"}
                                                            onClick={() => setNursesTabButton("Full Day")}>Full Day</button>
                                                    </div>

                                                    {slotArr.length !== 0 && slotArr.map((item, index) => (
                                                        <>
                                                            {nursesButton === "Hourly" &&
                                                                <>
                                                                    {activeSlotsDays === "FirstItem" && index === 0 &&
                                                                        <div key={index + "slotsData"} className="vc_slots">
                                                                            <div className="vc_block">
                                                                                <h4 className="slots_title">Morning <span>( {item.mornings.length}{item.mornings.length <= 1 ? 'Slot' : <>Slot's</>})</span></h4>
                                                                                <div className="slots_time">
                                                                                    {item.mornings.length > 0 ? item.mornings.map(itemSlotTime => (
                                                                                        <button key={itemSlotTime.start_time}
                                                                                            onClick={() => handelMultipleSelectionSlots(itemSlotTime.id, item.date, Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                            disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                            className={handelClassName(itemSlotTime.status, itemSlotTime.id)}>
                                                                                            {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                        </button>
                                                                                    ))
                                                                                        :
                                                                                        "No slots found"
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            {/* Afternoons */}
                                                                            <div className="vc_block">
                                                                                <h4 className="slots_title">Afternoon <span>({item.afternoons.length}{item.afternoons.length <= 1 ? 'Slot' : <>Slot's</>} )</span></h4>
                                                                                <div className="slots_time">
                                                                                    {item.afternoons.length > 0 ? item.afternoons.map(itemSlotTime => (
                                                                                        <button key={itemSlotTime.id + "itemSlotTime"}
                                                                                            onClick={() => handelMultipleSelectionSlots(itemSlotTime.id, item.date, Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                            disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                            className={handelClassName(itemSlotTime.status, itemSlotTime.id)}>
                                                                                            {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                        </button>
                                                                                    ))
                                                                                        :
                                                                                        "No slots found"
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div className="vc_block">
                                                                                <h4 className="slots_title">Evening <span>({item.evenings.length} Slot's)</span></h4>
                                                                                <div className="slots_time">
                                                                                    {item.evenings.length > 0 ? item.evenings.map(itemSlotTime => (
                                                                                        <button key={itemSlotTime.start_time}
                                                                                            onClick={() => handelMultipleSelectionSlots(itemSlotTime.id, item.date,
                                                                                                Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                            disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                            className={handelClassName(itemSlotTime.status, itemSlotTime.id)}>
                                                                                            {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                        </button>
                                                                                    ))
                                                                                        :
                                                                                        "No slots found"
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        activeSlotsDays === "SecondItem" && index === 1 &&
                                                                        <div key={index + "slotsData"} className="vc_slots">
                                                                            <div className="vc_block">
                                                                                <h4 className="slots_title">Morning <span>( {item.mornings.length} Slot's)</span></h4>
                                                                                <div className="slots_time">
                                                                                    {item.mornings.length > 0 ? item.mornings.map(itemSlotTime => (
                                                                                        <button key={itemSlotTime.start_time}
                                                                                            onClick={() => handelMultipleSelectionSlots(itemSlotTime.id, item.date,
                                                                                                Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                            disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                            className={handelClassName(itemSlotTime.status, itemSlotTime.id)}>
                                                                                            {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                        </button>
                                                                                    ))
                                                                                        :
                                                                                        "No slots found"
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="vc_block">
                                                                                <h4 className="slots_title">Afternoon <span>({item.afternoons.length} Slot's)</span></h4>
                                                                                <div className="slots_time">
                                                                                    {item.afternoons.length > 0 ? item.afternoons.map(itemSlotTime => (
                                                                                        <button key={itemSlotTime.id + "itemSlotTime"}
                                                                                            onClick={() => handelMultipleSelectionSlots(itemSlotTime.id, item.date,
                                                                                                Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                            disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                            className={handelClassName(itemSlotTime.status, itemSlotTime.id)}>
                                                                                            {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                        </button>
                                                                                    ))
                                                                                        :
                                                                                        "No slots found"
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="vc_block">
                                                                                <h4 className="slots_title">Evening <span>({item.evenings.length} Slot's)</span></h4>
                                                                                <div className="slots_time">
                                                                                    {item.evenings.length > 0 ? item.evenings.map(itemSlotTime => (
                                                                                        <button key={itemSlotTime.start_time} onClick={() =>
                                                                                            handelMultipleSelectionSlots(itemSlotTime.id, item.date,
                                                                                                Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                            disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                            className={handelClassName(itemSlotTime.status, itemSlotTime.id)}>
                                                                                            {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                        </button>
                                                                                    ))
                                                                                        :
                                                                                        "No slots found"
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    ))}
                                                    {/* If First Item is active and index will also zero then it will shows */}
                                                </>
                                                // For Ambulances
                                                : searchType === ShowProfileType.type3 ?
                                                    <>
                                                        {slotArr.length !== 0 && slotArr.map((item, index) => (
                                                            <div key={index + "slotsData"} className="vc_slots">
                                                                <div className="vc_block">
                                                                    <div className="slots_time">
                                                                        {item.slots.length !== 0 ? item.slots.map((innerItem) => (
                                                                            <>
                                                                                <button key={innerItem.start_time}
                                                                                    // onClick={() => goToBookDetailsOpenForm([{ slot_id: innerItem.id }], item.date, Change_to_12HourFormat(innerItem.start_time))}
                                                                                    onClick={() => handelMultipleSelectionSlots(innerItem.id, innerItem.date, Change_to_12HourFormat(innerItem.start_time))}
                                                                                    disabled={innerItem.status !== "Available" ? true : false}
                                                                                    className={handelClassName(innerItem.status, innerItem.id)}
                                                                                >
                                                                                    {Change_to_12HourFormat(innerItem.start_time)}
                                                                                </button>
                                                                            </>
                                                                        )

                                                                        )
                                                                            :
                                                                            "No slots found"
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {slotArr.length !== 0 && slotArr.map((item, index) => (
                                                            <>
                                                                {
                                                                    activeSlotsDays === "FirstItem" && index === 0 &&
                                                                    <div key={index + "slotsData"} className="vc_slots">

                                                                        <div className="vc_block">
                                                                            <h4 className="slots_title">Morning <span> {item.mornings.length} Slot(s)</span></h4>
                                                                            <div className="slots_time">
                                                                                {item.mornings.length > 0 ? item.mornings.map(itemSlotTime => (
                                                                                    <button key={itemSlotTime.start_time}
                                                                                        onClick={() => goToBookDetailsOpenForm([{ slot_id: itemSlotTime.id }], item.date, Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                        disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                        className={itemSlotTime.status !== "Available" ? "btn btn-primary disabled" : "btn btn-primary"}>
                                                                                        {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                    </button>
                                                                                ))
                                                                                    :
                                                                                    "No slots found"
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        {/* Afternoons */}
                                                                        <div className="vc_block">
                                                                            <h4 className="slots_title">Afternoon <span>{item.afternoons.length} Slot(s)</span></h4>
                                                                            <div className="slots_time">
                                                                                {item.afternoons.length > 0 ? item.afternoons.map(itemSlotTime => (
                                                                                    <button key={itemSlotTime.id + "itemSlotTime"}
                                                                                        onClick={() => goToBookDetailsOpenForm([{ slot_id: itemSlotTime.id }], item.date, Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                        disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                        className={itemSlotTime.status !== "Available" ? "btn btn-primary disabled" : "btn btn-primary"}>
                                                                                        {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                    </button>
                                                                                ))
                                                                                    :
                                                                                    "No slots found"
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="vc_block">
                                                                            <h4 className="slots_title">Evening <span>{item.evenings.length} Slot(s)</span></h4>
                                                                            <div className="slots_time">
                                                                                {item.evenings.length > 0 ? item.evenings.map(itemSlotTime => (
                                                                                    <button key={itemSlotTime.start_time}
                                                                                        onClick={() => goToBookDetailsOpenForm([{ slot_id: itemSlotTime.id }], item.date,
                                                                                            Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                        disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                        className={itemSlotTime.status !== "Available" ? "btn btn-primary disabled" : "btn btn-primary"}>
                                                                                        {Change_to_12HourFormat(itemSlotTime.start_time)}


                                                                                    </button>
                                                                                ))
                                                                                    :
                                                                                    "No slots found"
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    activeSlotsDays === "SecondItem" && index === 1 &&
                                                                    <div key={index + "slotsData"} className="vc_slots">
                                                                        <div className="vc_block">
                                                                            <h4 className="slots_title">Morning <span> {item.mornings.length} Slot(s)</span></h4>
                                                                            <div className="slots_time">
                                                                                {item.mornings.length > 0 ? item.mornings.map(itemSlotTime => (
                                                                                    <button key={itemSlotTime.start_time}
                                                                                        onClick={() => goToBookDetailsOpenForm([{ slot_id: itemSlotTime.id }], item.date,
                                                                                            Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                        disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                        className={itemSlotTime.status !== "Available" ? "btn btn-primary disabled" : "btn btn-primary"}>
                                                                                        {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                    </button>
                                                                                ))
                                                                                    :
                                                                                    "No slots found"
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="vc_block">
                                                                            <h4 className="slots_title">Afternoon <span>{item.afternoons.length} Slot(s)</span></h4>
                                                                            <div className="slots_time">
                                                                                {item.afternoons.length > 0 ? item.afternoons.map(itemSlotTime => (
                                                                                    <button key={itemSlotTime.id + "itemSlotTime"}
                                                                                        onClick={() => goToBookDetailsOpenForm([{ slot_id: itemSlotTime.id }], item.date,
                                                                                            Change_to_12HourFormat(itemSlotTime.start_time))} disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                        className={itemSlotTime.status !== "Available" ? "btn btn-primary disabled" : "btn btn-primary"}>
                                                                                        {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                    </button>
                                                                                ))
                                                                                    :
                                                                                    "No slots found"
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="vc_block">
                                                                            <h4 className="slots_title">Evening <span>{item.evenings.length} Slot(s)</span></h4>
                                                                            <div className="slots_time">
                                                                                {item.evenings.length > 0 ? item.evenings.map(itemSlotTime => (
                                                                                    <button key={itemSlotTime.start_time} onClick={() =>
                                                                                        goToBookDetailsOpenForm([{ slot_id: itemSlotTime.id }], item.date,
                                                                                            Change_to_12HourFormat(itemSlotTime.start_time))}
                                                                                        disabled={itemSlotTime.status !== "Available" ? true : false}
                                                                                        className={itemSlotTime.status !== "Available" ? "btn btn-primary disabled" : "btn btn-primary"}>
                                                                                        {Change_to_12HourFormat(itemSlotTime.start_time)}
                                                                                    </button>
                                                                                ))
                                                                                    :
                                                                                    "No slots found"
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </>
                                                        ))}
                                                    </>

                                            }
                                        </>
                                        <div className='vc_slots'>
                                            <div className='slots_time'>
                                                {nursesButton === "Full Day" &&
                                                    <button className='btn btn-book' onClick={() => handelBookFullDaySlots()}>Full Day</button>
                                                }
                                            </div>
                                        </div>
                                        <>
                                            <div className='vc_slots'>
                                                <div className='slots_time'>
                                                    {arrMultipleSlotItem.length > 0 ?
                                                        <button onClick={() => goToBookDetailsOpenForm(arrMultipleSlotItem, arrMultipleSlotDate, arrMultipleSlotTime)} className='btn btn-book'>Book</button>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </section>


                <Footer />

                {/* Components */}
                {/* <LoginPopup /> */}
            </div>
        </>
    )
}

export default ShowSlotsProfileAppoinment;