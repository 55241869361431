import React from 'react'
import SquareCard from './cards/SquareCard'
import ServiceOne from "../images/service/service01.png"
import ServiceTwo from "../images/service/service02.png"
import ServiceThree from "../images/service/service03.png"

const ServicesWeOffers = () => {
    return (
        // Services we offer
        <section className="service_sec">
            <div className="container">
                <div className="service_panel">
                    <div className="service_text">
                        <h3 className="heading2">Services We Offer</h3>
                        <p className="para2">Access video consultation with India’s top Doctors, Nurses and Ambulance on the FastTrack Mobile App. Connect with Doctors online, available 24/7, from the comfort of your home.</p>
                    </div>
                    <SquareCard image={ServiceOne} title="Teleconsultation" paragraph="Book Video or Audio Consultation" link="/tele-consultation"/>
                    <SquareCard image={ServiceTwo} title="Homecare" paragraph="Book Home Nursing Services" link="/homecare"/>
                    <SquareCard image={ServiceThree} title="Ambulance" paragraph="24x7 Private Ambulance Services" link="/ambulance"/>
                </div>
            </div>
        </section>

    )
}

export default ServicesWeOffers