import React, { useEffect, useState } from 'react'
import { HomepageHeader } from '../../components'
import VideoIcon from "../../images/images/vc_icon.png"
import userIcon from "../../images/adminpanel/UserIcon.svg"
import moment from 'moment'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { regHtml, regMobile } from '../../helpers/Validate'
import { toast } from 'react-toastify'
import { BookingServices } from '../../services'
import {
    err_10DigitMobile,
    err_NotFullName,
    err_NotMobile,
    HtmlErrorMessage
} from '../../enums/Enums'
import * as process from "process";
// import PayTmGatewayPage from './PayTmGatewayPage'

const NrusingBillingHours = () => {

  
    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />
            <section class="paddtop paddbottom">
    <div class="">
    <div class="booking_flex">
    <div class="right_itme">
        <div class="booking_dtl">
            <h3 class="bk_title">Patient details</h3>
            <h4 class="bk_sub_title">This video consultation is for:</h4>
            <div class="vc_for">
                <div class="vc_rb"><input type="radio" id="" name="radio-group"  /><label for="">Myself</label></div>
                <hr />
                <div class="vc_rb"><input type="radio" id="" name="radio-group" checked="" /><label for="">Someone else</label></div>
            </div>
            <h4 class="bk_sub_title">Please provide following information</h4>
            <form>
                <div>
                    <div class="form-group"><label for="exampleInputEmail1">Full name *</label>
                       <input name="other_name" type="text" class="form-control" placeholder="Enter full name" readonly="" value="Nikunj Gurjar" />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Mobile *</label>
                        <input readonly="" type="text" maxlength="10" name="other_mobile" class="form-control" placeholder="Enter mobile" value="7415487853" />
                    </div>
                    <div class="form-group"><label for="exampleInputEmail1">Address *</label>
                       <input name="other_name" type="text" class="form-control" placeholder="" readonly="" value="" />
                       <input name="other_name" type="text" class="form-control mt-t-3" placeholder="" readonly="" value="" />
                    </div>
                    <div class="form-group">
                        <label>Medical History</label>
                        <div class="gap-3">
                            <input type="checkbox" id="Allergies" /><label class="mx-3-medical">Allergies </label><input type="checkbox" id="Past-Medical-Issue" /><label class="mx-3-medical">Past Medical Issue </label>
                            <input type="checkbox" id="On-Medication" /><label class="mx-3-medical">On Medication </label>
                        </div>
                    </div>
                    <div class="form-group"><label for="exampleInputEmail1">Remark </label><input type="text" maxlength="10" name="short_description" class="form-control" placeholder="Enter remarks" value="" /></div>
                    <div class="go-back">
                    <i class="fa-solid fa-arrow-left"></i> Go Back
                        </div>
                </div>
            </form>
        </div>
    </div>
    <div class="left_itme ">
        <div className='bg_light padding-3'>
        <h4 class="bk_sub_title pt20">Booking details</h4>
        <hr />
        <div class="user-name-booking">
            <p class="">RIYA SHARMA</p>
            <span>Tracheostomy care Specialist</span> <br></br>
            <span>6 years</span> <br></br>
             <span>Bhopal</span>
        </div>
        <hr />
        <div class="belowbtn">
        <div class="cons_fee"><span class="consl">Home Care</span><span class="cons2"> Tracheostomy care</span></div>
        <div class="cons_fee"><span class="consl">Visit Type</span><span class="cons2"> Per visit</span></div>
        <div class="cons_fee"><span class="consl">Required service Type</span><span class="cons2"> Advance Nursing care</span></div>
        <hr />
            <p>First Visit Required on</p>
               <div className='date-time'>
               <div class="date">
               <h5>Date</h5>  <input type="datetime-local" id="birthdaytime" name="birthdaytime"/>
                </div>
             <div className='time'>
                <h5>Preferred Time</h5><input type="time" id="appt" name="appt"/>
             </div>
               </div>
               <div className='service-hours'>
                 <h5>Service Hours Per Day</h5>
                  
                 <button class="btn_fill btn_hours" href="#/partner-with-us">2 Hours</button> <button class="btn_fill btn_hours" href="#/partner-with-us">2 Hours</button>
                 <button class="btn_fill btn_hours" href="#/partner-with-us">2 Hours</button>
                 <button class="btn_fill btn_hours" href="#/partner-with-us">2 Hours</button>
                 <button class="btn_fill btn_hours" href="#/partner-with-us">2 Hours</button>
                 <button class="btn_fill btn_hours" href="#/partner-with-us">2 Hours</button>
                 <button class="btn_fill btn_hours" href="#/partner-with-us">2 Hours</button>
               </div>
               <hr/>
                  <div className='bill-update'>
                     <h6>Bill Detail</h6>
                     <div className='display-flex-flexend'>
                        <div>
                            <span>Rs 400 * 2 day</span>
                        </div>
                         <div>
                             <input type='number' className='increment'/>
                         </div>
                         <div>
                             <span>Rs 800</span>
                         </div>
                     </div>
                  </div>
               <hr/>
                <div className='under-box'>
                    <h5>Apply Coupon</h5>
                    <form class="d-flexsearch" role="search">
                  <input class="form-control me-2" type="search" placeholder="Enter coupon code" aria-label="Search"/>
                  <button class="btn btn-outline-success" type="submit">APPLY</button>
                   
              
               </form>
               <h6>Applicable Coupons</h6>
               <div class="display-flex-t"><input type="radio" id="test1" name="radio-group"/>
               <div className='div-f'>
               <label for="test1" className=' line-height'>Nikunj Gurjar </label> <br></br>
                  <label>
                         Welcome Offer
                  </label>
                </div>
            </div>
               <div class="display-flex-t"><input type="radio" id="test1" name="radio-group"/>
               
               <div className='div-f'>
                  <label for="test1" className='line-height'>saving </label><br></br> 
                  <label >save</label>
               </div>
               </div>
               
                     
                </div>
                <h6 className='under-box-update-email'>Updates will be sent to registered email address</h6>
                <h5>Undertaking</h5>
            <p class="Undertaking">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 

            </p>
            <p>
                <input type="checkbox" /><span> I agree to the <a href="#/terms-and-conditions">Terms and Conditions.</a></span>
            </p>
        </div>
        <div>
            <div class="text-center"><button disabled="" type="submit" class="btn btn_fill btn-block">Book &amp; pay</button></div>
        </div>
        </div>
      
    </div>
</div>

    </div>
</section>

        </div>
    )
}

export default NrusingBillingHours