import React, { useEffect, useRef, useState } from 'react'
import { HomepageHeader, SidebarUserPanel } from '../../../components'
import VinitaIcon from "../../../images/images/vinita.png"
import DownloadPng from "../../../images/images/download.png"
import { BookingServices } from '../../../services'
import ReactPaginate from 'react-paginate';
import moment from 'moment'



const MyCompletedBookings = () => {

    const apiFunction = new BookingServices()
    const [bookingData, setBookingData] = useState([])
    const [bookingDataMain, setBookingDataMain] = useState([])
    const [status, setStatus] = useState("Completed")

    const pagination = useRef();
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const perPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(1);

    const setPage = ({ selected }) => {
        setNumber(selected)
        setOffset(perPage * selected)
    }


    useEffect(() => {
        loadBookingsAllBooking();
    }, [])

    useEffect(() => {
        const slice = bookingDataMain.slice(offset, offset + perPage);
        if (bookingDataMain.length > 0) {
            setBookingData(slice);
            setPageCount(Math.ceil(bookingDataMain.length / perPage));
        }
    }, [number, offset])

    const loadBookingsAllBooking = () => {
        apiFunction.BookingListUserPanel(status).then(result => {
            if (result !== undefined) {
                if (result.success === true) {
                    const myCompletedBooking = result.data
                    const lengthOfdata = myCompletedBooking.length
                    setBookingDataMain(myCompletedBooking)
                    setTotalData(lengthOfdata)
                    const slice = myCompletedBooking.slice(offset, offset + perPage);
                    if (lengthOfdata > 0) {
                        setBookingData(slice);
                        setPageCount(Math.ceil(lengthOfdata / perPage));
                    }
                    // setBookingData(result.data)
                }
            }
        })
    }

    const convertHtmlToPdf = () => {
       
    }

    return (
        <div className="outerhomepage">
            {/*Top Navbar Menu*/}
            <HomepageHeader />

            <section className=" paddbottom">
                <div className="">
                    <div className="userpanel_page bg_light">

                        {/* Side bar */}
                        <SidebarUserPanel />

                        <div className="right_panel table-responsive">
                            <table className="table mytable">
                                <tbody>
                                    <tr>
                                        <th style={{ width: '33.33%' }}>Name</th>
                                        <th style={{ width: '33.33%' }}>Booked at</th>
                                        <th style={{ width: '33.33%' }}>Amount</th>
                                    </tr>
                                    {bookingData.length > 0 ? bookingData.map(item => (
                                        <tr>
                                            <td>
                                                <div className="userdtl">
                                                    <div className="userpic">
                                                        <img src={VinitaIcon} alt />
                                                    </div>
                                                    <div className="userinfo">
                                                        <p>{item.name}</p>
                                                        <span>{item.service_type}</span>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="bookedat">
                                                    <p>{(moment(item.booking_date).utc()).format("DD/MM/YYYY")} </p><span>
                                                        {(moment(item.booking_date).utc()).format("hh:mm a")}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amount_td">
                                                    <div>
                                                        <p>&#x20B9; {item.paid_amount}</p><span>{item.status}</span>
                                                    </div>
                                                    <div>
                                                        <span>Download Prescription</span>
                                                    </div>
                                                    <div>
                                                        <button type='button' onClick={convertHtmlToPdf}><img src={DownloadPng} alt /></button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                        :
                                        <h4 className='text-center'>
                                            No record found
                                        </h4>
                                    }
                                </tbody>
                            </table>
                            <>
                                {totalData > 10 &&
                                    <div className='mypagination'>
                                        <ReactPaginate
                                            ref={pagination}
                                            pageCount={pageCount}
                                            pageRangeDisplayed={4}
                                            marginPagesDisplayed={0}
                                            onPageChange={setPage}
                                            // previousLabel={<i className="fa fa-angle-left"></i>}
                                            activeClassName="active"
                                            containerClassName="pagination_ul"
                                            // nextLabel={<i className="fa fa-angle-right"></i>}
                                            // pageLinkClassName="page-link"
                                            // breakLinkClassName="page-link"
                                            // nextLinkClassName="page-link"
                                            // previousLinkClassName="page-link"
                                            pageClassName="page-item"
                                            breakClassName="page-item"
                                            nextClassName="page-item"
                                            previousClassName="page-item"
                                            breakLabel="..."
                                            // breakClassName={"break-me"}
                                            // pageRangeDisplayed={3}
                                            previousLabel={<>Prev</>}
                                            nextLabel={<>Next</>}
                                        />
                                    </div>
                                }


                            </>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default MyCompletedBookings;