import React from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

const EducationReg = ({incrementBtn,decrementBtn,qualification,completion,College,experience,setExperience,setQualification,setCollege,setCompletion}) => {
    let dates=new Date()
  let year=dates.getFullYear()
  let allYear=[]
  for(let i=0;i<=50;i++){
    allYear.push(year-i)
  }
  const increaseStep=()=>{
    if(qualification!=='' && completion!=='' && experience!=='' && College!==''){
        incrementBtn()
    }else if(qualification===''){
        toast.error("Please select degree")
    }else if(College===''){
        toast.error("Please select College/Institute")
    }else if(completion===''){
        toast.error("Please select year of completion")
    }else if(experience===''){
        toast.error("Please select year of experience")
    }
  }
//   change this
  let exp=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
  const collegeListApi=async()=>{
    try {
        let response = await fetch(`colleges-in.herokuapp.com`);
          console.log(response)
          response.then((res) => {
            console.log(res)
        })
        .catch();
  } catch (error) {}
    
  }
  useEffect(()=>{
    collegeListApi()
  },[])
    return (
        <div className="box box-solid">
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="profile_form1">
                        <div className="row">
                            <div className="col-lg-9">
                                <h3 className="drprofile_title">Educational  Qualification</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label>Select Degree</label>
                                    <select className="form-control"value={qualification} onChange={(e)=>setQualification(e.target.value)}>
                                        <option> Select Degree</option>
                                        <option>MBBS</option>
                                        <option>MS</option>
                                        <option>MD</option>
                                        <option>BAMS</option>
                                        <option>BHMS</option>
                                        <option>BPT</option>
                                        <option>B.VSc</option>
                                        <option>BUMS</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label>Select University/College/Institute</label>
                                    <select className="form-control" value={College} onChange={(e)=>setCollege(e.target.value)}>
                                        <option>Select University/College/Institute</option>
                                        <option>Jawaharlal Nehru Medical College,Aligarh (Aligarh Muslim University), Central University</option>
                                        <option>Institute of Medical Sciences, BHU, Varanasi(Banaras Hindu University),Central University</option>
                                        <option>BRD Medical College, Gorakhpur</option>
                                        <option>Dr.Ram Manohar Lohia Institute of Medical Sciences, Lucknow</option>
                                        <option>Government Allopathic Medical College, Banda</option>
                                        <option>Government Medical College, Badaun</option>
                                        <option>Government Medical College, Faizabad</option>
                                        <option>Government Medical College, Firozabad</option>
                                        <option>Government Institute of Medical Sciences, Kasna, Greater Noida</option>
                                        <option>Government Medical College, Kannauj</option>
                                        <option>Government Medical College, Rampur, Basti</option>
                                        <option>Government Medical College, Shahjahanpur</option>
                                        <option>Government Medical College & Super facility Hospital, Azamgarh</option>
                                        <option>GSVM Medical College, Kanpur</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label>Year of Completion</label>
                                    <select className="form-control" value={completion} onChange={(e)=>setCompletion(e.target.value)}>
                                        <option>Select Year of Completion</option>
                                        {
                                            allYear.map((val,ind)=>{
                                                return(
                                                    <option key={ind}>{val}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="form-group">
                                    <label>Year of Experience</label>
                                    <select className="form-control"value={experience} onChange={(e)=>setExperience(e.target.value)}>
                                        <option>Select Experience</option>
                                        {
                                            exp.map((val,ind)=>{
                                                return(
                                                    <option key={ind}>{val}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="profile_form1">
                        <div className="row">
                            <div className="col-lg-10">
                                <div className="info_box">
                                    <p>On FastTrack, doctors are listed under the relevant specialization based on their academic degrees, education &amp; years of experience, showcase doctor’s expertise and are among the the top 3 things that patients consider while making their decision.</p>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='position-abslute-bottom'>
            <div className="info_box_btn padding-130px ">
        <button onClick={decrementBtn} type="button" className="btnlink">Go back</button>
        <button onClick={increaseStep} type="button"  className="btn btn-theme btn-xs radius " style={{ width: 70 }}>Next</button>
    </div>
    </div>
        </div>
       
    )
}

export default EducationReg