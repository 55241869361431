import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { regEmailValidation, regHtml, regMobile } from '../../helpers/Validate';
import { ProfileService, UploadImageFor } from '../../services'
import { DoctorRegistered } from '../../services/DoctorOnboarding'
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner'
import classNames from 'classnames';
import axios from 'axios';
import chekIsProfileCompleted from '../../helpers/IsProfileCompleted';

import {
    err_10DigitMobile,
    err_NotCity,
    err_NotCountry,
    err_NotDistrict,
    err_NotGender,
    err_NotHouseNo,
    err_NotMobile, err_NotName,
    err_NotSixDigitPinCode, err_NotState,
    err_NotStreetColony,
    err_NotValidEmail, HtmlErrorMessage, uploadImageFor
} from '../../enums/Enums';
import { Label } from '@mui/icons-material';
const baseurl = process.env.REACT_APP_API_BASE_URL

const DoctorProfile = ({ specializationList, hr }) => {
    const token = localStorage.getItem('token')

    const apiFunction = new ProfileService()
    const [check, setCheck] = useState(false)
    const [newSpecilization, setNewSpecilizatio] = useState([])
    const [proofType, setProofType] = useState()
    const [proofTitle, setProofTitle] = useState()
    const [proofFile, setProofFile] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [userProfilePicture, setUserProfilePicture] = useState('')
    const [userSignaturePicture, setUserSignaturePicture] = useState(null)
    const [isLoadingsig, setIsLoadingsig] = useState(false)
    const [specilization_id, setspecilization_id] = useState([])
    const [fromtime, setfromtime] = useState("")
    const [totime, settotime] = useState("")
    const [day, setday] = useState(0)
    const [serviceprovider, setserviceprovider] = useState()
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [userId, setUserId] = useState(0)
    const [proofpath, setproofpath] = useState("")

    const [signpath, setsignpath] = useState(null)
    const [specializationListss, setspecializationListss] = useState([])
    const [availabilitysetlist, setavailabilitysetlist] = useState([])
    const [doccumentsetlists, setdoccumentsetlists] = useState([])
    const [isapplied, setApplied] = useState(false)
    const [item, setItem] = useState(0)
    const [tempslist, setTempsList] = useState([])
    const [tempalist, setTempaList] = useState([])
    const [tempdlist, setTempdList] = useState([])
    const [docUploaded, setDocUploaded] = useState(true)


    const [doctorProfileData, setDoctorProfileData] = useState({
        name: "",
        headline: '',
        mobile: "",
        notes: '',
        email: "",
        specialization: [],
        city: "",
        bio: '',
        address: "",
        availability: [],
        consultation_fees: "",
        degree: '',
        followup_fees: "",
        service_provider_document: [],
        state: "",
        zipcode: '',
        gender: '',
        experience: '',
        spelization_id: '',
        is_approved: 0,
        status: ''

    })
   
    const { name, headline, notes, bio,
        mobile, degree, followup_fees,
        gender,
        email,
        specialization,
        city,
        address, availability, service_provider_document,
        consultation_fees,
        experience, spelization_id, is_approved, status, state, zipcode
    } = doctorProfileData;

    // setting form data------------------------------------------
    const handleinputsdata = (e) => {
        setDoctorProfileData({ ...doctorProfileData, [e.target.name]: e.target.value })
    }
    //setting the days
    const AllDaysList = [
        {
            Dayname: 'All',
            value: -1
        },
        {
            Dayname: 'Sunday',
            value: 0
        },
        {
            Dayname: 'Monday',
            value: 1
        },
        {
            Dayname: 'Tuesday',
            value: 2
        },
        {
            Dayname: 'Wednesday',
            value: 3
        },
        {
            Dayname: 'Thursday',
            value: 4
        },
        {
            Dayname: 'Friday',
            value: 5
        },
        {
            Dayname: 'Saturday',
            value: 6
        }]

    const TimeFrom = [
        { start: '..:..', value: '..:..' },
        { start: '00:00', value: "00:00" },
        { start: '00:30', value: "00:30" },
        { start: '01:00', value: "01:00" },
        { start: '01:30', value: "01:30" },
        { start: '02:00', value: "02:00" },
        { start: '02:30', value: "02:30" },
        { start: '03:00', value: "03:00" },
        { start: '03:30', value: "03:30" },
        { start: '04:00', value: "04:00" },
        { start: '04:30', value: "04:30" },
        { start: '05:00', value: "05:00" },
        { start: '05:30', value: "05:30" },
        { start: '06:00', value: "06:0" },
        { start: '06:30', value: "06:30" },
        { start: '07:00', value: "07:00" },
        { start: '07:30', value: "07:30" },
        { start: '08:00', value: "08:00" },
        { start: '08:30', value: "08:30" },
        { start: '09:00', value: "09:00" },
        { start: '09:30', value: "09:30" },
        { start: '10:00', value: "10:00" },
        { start: '10:30', value: "10:30" },
        { start: '11:00', value: "11:00" },
        { start: '11:30', value: "11:30" },
        { start: '12:00', value: "12:00" },
        { start: '12:30', value: "12:30" },
        { start: '13:00', value: "13:00" },
        { start: '13:30', value: "13:30" },
        { start: '14:00', value: "14:00" },
        { start: '14:30', value: "14:30" },
        { start: '15:00', value: "15:00" },
        { start: '15:30', value: "15:30" },
        { start: '16:00', value: "16:00" },
        { start: '16:30', value: "16:30" },
        { start: '17:00', value: "17:00" },
        { start: '17:30', value: "17:30" },
        { start: '18:00', value: "18:00" },
        { start: '18:30', value: "18:30" },
        { start: '19:00', value: "19:00" },
        { start: '19:30', value: "19:30" },
        { start: '20:00', value: "20:00" },
        { start: '20:30', value: "20:30" },
        { start: '21:00', value: "21:00" },
        { start: '21:30', value: "21:30" },
        { start: '22:00', value: "22:00" },
        { start: '22:30', value: "22:30" },
        { start: '23:00', value: "23:00" },
        { start: '23:30', value: "23:30" }]

    const TimeTo = [
        { start: '..:..', value: '..:..' },
        { start: '00:30', value: "00:30" },
        { start: '01:00', value: "01:00" },
        { start: '01:30', value: "01:30" },
        { start: '02:00', value: "02:00" },
        { start: '02:30', value: "02:30" },
        { start: '03:00', value: "03:00" },
        { start: '03:30', value: "03:30" },
        { start: '04:00', value: "04:00" },
        { start: '04:30', value: "04:30" },
        { start: '05:00', value: "05:00" },
        { start: '05:30', value: "05:30" },
        { start: '06:00', value: "06:0" },
        { start: '06:30', value: "06:30" },
        { start: '07:00', value: "07:00" },
        { start: '07:30', value: "07:30" },
        { start: '08:00', value: "08:00" },
        { start: '08:30', value: "08:30" },
        { start: '09:00', value: "09:00" },
        { start: '09:30', value: "09:30" },
        { start: '10:00', value: "10:00" },
        { start: '10:30', value: "10:30" },
        { start: '11:00', value: "11:00" },
        { start: '11:30', value: "11:30" },
        { start: '12:00', value: "12:00" },
        { start: '12:30', value: "12:30" },
        { start: '13:00', value: "13:00" },
        { start: '13:30', value: "13:30" },
        { start: '14:00', value: "14:00" },
        { start: '14:30', value: "14:30" },
        { start: '15:00', value: "15:00" },
        { start: '15:30', value: "15:30" },
        { start: '16:00', value: "16:00" },
        { start: '16:30', value: "16:30" },
        { start: '17:00', value: "17:00" },
        { start: '17:30', value: "17:30" },
        { start: '18:00', value: "18:00" },
        { start: '18:30', value: "18:30" },
        { start: '19:00', value: "19:00" },
        { start: '19:30', value: "19:30" },
        { start: '20:00', value: "20:00" },
        { start: '20:30', value: "20:30" },
        { start: '21:00', value: "21:00" },
        { start: '21:30', value: "21:30" },
        { start: '22:00', value: "22:00" },
        { start: '22:30', value: "22:30" },
        { start: '23:00', value: "23:00" },
        { start: '23:30', value: "23:30" },
        { start: '00:00', value: '00:00' },]

    const toggle = () => {
        setModal(!modal);
    };

    const openModal = (id) => {

        setItem(id);
        toggle();
    };

    const toggle1 = () => {
        setModal1(!modal);
    };

    const openModal1 = (id) => {

        setItem(id);
        toggle1();
    };


    const toggle2 = () => {
        setModal2(!modal);
    };

    const openModal2 = (id) => {

        setItem(id);
        toggle2();
    };


    const handleProfileImage = (type) => {
        if (type == 'profile') {
            let inPutClick = document.getElementById('profilePic')
            inPutClick.click()
        } else {
            let inPutClick = document.getElementById('signature')
            inPutClick.click()
        }
    }
    //loading teleconsultation profile data------------------------------------------------
    const loadDoctorProfileData = () => {
        let id = localStorage.getItem('userid')
        apiFunction.doctorProfile(id).then(result => {
            if (result.success === true) {

                if (result.data !== undefined && result.data !== null) {
                    setDoctorProfileData(result.data)
                    setserviceprovider(result.data.service_provider_id, "prsefew")
                    setUserSignaturePicture(result.data.signature)
                    setsignpath(result.data.signature)
                    setspecializationListss(result.data.specialization)
                    setavailabilitysetlist(result.data.availability)
                    setdoccumentsetlists(result.data.service_provider_document)
                    if ((result.data.is_applied_for_doctor !== undefined && result.data.is_applied_for_doctor !== null && result.data.is_applied_for_doctor === 1) || result.data.is_approved === 1) {
                        getavilibility();
                        getspelization();
                        getdocument();
                        setApplied(true)
                    }
                }



            }
        })
    }
    // //delte api for specification-------------------------------------------
    const deletespecification = async (id) => {

        var config = {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },

        };
        const result = await axios.delete(`${baseurl}specialization/${id}`, config)
        if (result != undefined && result != null) {
            toast.success("Specification Deleted Sucessfully")
            getspelization()
        }
    }
    //delte api for Avibality-------------------------------------------
    const deleteAvilability = async (id) => {
        var config = {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },

        };
        const result = await axios.delete(`${baseurl}slots/${id}`, config)
        if (result != undefined && result != null) {
            toast.success("Avialibility Deleted Sucessfully");
            getavilibility();
        }
    }
    // //delte api for doccument-------------------------------------------
    const deletedocument = async (id) => {
        var config = {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },

        };
        const result = await axios.delete(`${baseurl}document/${id}`, config)
        if (result != undefined && result != null) {
            toast.success("Document Deleted Sucessfully")
            getdocument()
        }
    }

    useEffect(() => {
        loadDoctorProfileData();


    }, [])
    //uploading profile picture--------------------------------------
    const uploadProfilePicture = (e) => {
        setIsLoading(true)
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, uploadImageFor.profile).then(result => {
            if (result !== null) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    setUserProfilePicture(e.target.result)
                };
                reader.readAsDataURL(selectedFile);

                // document.getElementById("showProfile").innerHTML = '<img src="' + e.target.result + '"/>';
            }
        })
    }
    //uploading signature--------------------------------------------
    const uploadsignaturePicture = (e) => {
        setIsLoadingsig(true)
        const selectedFile = e.target.files[0];
        const selectedFileName = e.target.files[0].name;
        const formData = new FormData();
        formData.append("files", selectedFile, selectedFileName);
        UploadImageFor(formData, uploadImageFor.signature_doc).then(result => {
         if (result !== null && result != undefined) {
                if (result.success) {
                    var reader = new FileReader();
                    setsignpath(result.data.filePath)
                    reader.onload = function (e) {
                        setUserSignaturePicture(e.target.result)
                    };
                    reader.readAsDataURL(selectedFile);
                }
            }

            setIsLoadingsig(false)

            if (result !== null) {
                var temp = userProfileData;
                temp.profile_pic = result.data.filePath;
                setUserProfileData(temp)

                setprofilepath(result.data.filePath)
                var reader = new FileReader();
                reader.onload = function (e) {
                    setUserProfilePicture(e.target.result)
                };
                reader.readAsDataURL(selectedFile);

                // document.getElementById("showProfile").innerHTML = '<img src="' + e.target.result + '"/>';
            }
        })
    }
    const handleUpload = (e) => {
        setDocUploaded(false)

        let a = ""
        if (proofType == 'Identity Proof') {
            a = 'identity'
        } else if (proofType == 'Medical Registration Proof') {
            a = 'medical_doc'
        } else if (proofType == 'Establishment Proof') {
            a = 'establishment_doc'
        }
        if (!a) {
            toast.error("Please select document type")
        }
        else {

            const selectedFile = e.target.files[0];
            const selectedFileName = e.target.files[0].name;
            const formData = new FormData();

            formData.append("files", selectedFile, selectedFileName);
            if ((selectedFile.size) / 1000 > 200) {
                toast.error("Size should be less than 200 kb ")
            }
            else {
                UploadImageFor(formData, a).then(result => {
                    if (result !== null && result != undefined) {
                        setproofpath(result.data.filePath)
                        setDocUploaded(true)

                    }

                })

            }



        }
    }

    useEffect(() => {
        if (tempslist !== null && tempslist.length > 0) {
            setspecializationListss(tempslist)
            setTempsList([])
        }

    }, [tempslist])


    //set specilization
    const Setthespeclization = (e) => {

        if (specilization_id.length > 0) {
            var f = specializationList.filter(x => Number(x.id) === Number(specilization_id))
            // for cheking is specilization is already added or not
            var indexDel = specializationListss.findIndex((element) => element.type == f[0].title)
            if (indexDel > -1) {
                return toast.error("This specialization is already added")
            }
            var temp = specializationListss
            temp.push({
                "type": f[0].title,
                "specialization_id": specilization_id
            })

            setTempsList(temp)
            console.log(temp, "--after add")
        }
        else {
            toast.error("Please select specialization")

        }
    }

    useEffect(() => {
        if (tempalist != null && tempalist.length > 0) {
            setavailabilitysetlist(tempalist)
            setTempaList([])
        }

    }, [tempalist])
    //set avilibility-----------
    const settheavilibility = (e) => {
        if (fromtime !== undefined && fromtime !== null && fromtime !== "" && totime !== undefined && totime !== null && totime !== "") {
            if (totime < fromtime) {
                toast.error("From time shuld be less than to time ")
            }
            else {
                if (day == -1) {
                    for (var i = 0; i < 7; i++) {
                        var temp = availabilitysetlist
                        temp.push({
                            "day": i,
                            "start_time": fromtime,
                            "end_time": totime,
                        })
                        setTempaList(temp)
                    }
                }
                else {
                    var temp = availabilitysetlist
                    temp.push({
                        "day": day,
                        "start_time": fromtime,
                        "end_time": totime,

                    })
                    setTempaList(temp)

                }

            }

        }

        else {
            toast.error("Please enter time")
        }

    }
    useEffect(() => {
        if (tempdlist != null && tempdlist.length > 0) {
            setdoccumentsetlists(tempdlist)
            setTempdList([])

        }

    }, [tempdlist])
    // document settings
    const letsdocument = (e) => {

        if (!proofTitle) {
            toast.error("Please enter title")
        }
        else if (proofType === undefined || proofType === null || proofType === "") {
            toast.error("Please select document type")

        }
        else if (!proofpath) {
            toast.error("Please select file")

        }
        else {
            var temp = doccumentsetlists
            temp.push({
                "document_type": proofType,
                "title": proofTitle,
                "doc_file_path": proofpath
            })
            setTempdList(temp)

        }


    }
    //delete the specilization
    const deletspec = (type) => {
        var temp = specializationListss

        var indexDel = temp.findIndex((element) => element.type == type)
        if (indexDel > -1) {
            temp.splice(indexDel, 1);
        }
        setTempsList(temp)
    }

    //delete the avilibility
    const deletavil = (start_time) => {

        var indexDel = availabilitysetlist.findIndex((element) => element.start_time == start_time)

        if (indexDel > -1) {
            availabilitysetlist.splice(indexDel, 1);
        }

        setTempaList(availabilitysetlist)
    }

    //delete the document
    const deletdocum = (title) => {
        var indexDel = doccumentsetlists.findIndex((element) => element.title == title)
        if (indexDel > -1) {
            doccumentsetlists.splice(indexDel, 1);
        }
        setTempdList(doccumentsetlists)
    }


    //post all form data--------------------------------------------------
    const updateProfileOnsubmit = async (e) => {

        e.preventDefault();       
        chekIsProfileCompleted();
        if (doccumentsetlists.length > 0) {
            var requiredDocTypes = ['Identity Proof', 'Medical Registration Proof'];
            var missingDocTypes = requiredDocTypes.filter(requiredDocType => !doccumentsetlists.some(obj => obj.document_type === requiredDocType && obj.document_type !== "Establishment Proof"));
        }
        if (headline == '' || headline == null) {
            toast.error('Please Enter Hadline')
        }
        else if (bio == '' || bio == null) {
            return toast.error('Plaese Enter Bio')
        }
        else if (experience == null || experience == undefined || experience == '') {
            return toast.error('Please Enter Experiance')
        }
        else if (degree == null || degree == undefined || degree.length <= 0) {
            return toast.error('Please Enter Degree')
        }
        else if (consultation_fees == null || consultation_fees == undefined || consultation_fees.length <= 0 || consultation_fees === "0" || consultation_fees === 0) {
            return toast.error('Please Enter consultation fees')

        }
        else if (followup_fees == null || followup_fees == undefined || followup_fees.length <= 0 || followup_fees === "0" || followup_fees === 0) {
            return toast.error('Please Enter followup fees')
        }

        else if (signpath == undefined || signpath == null) {
            return toast.error('Please upload signature')
        }
        else if (specializationListss[0] === null || specializationListss[0] === undefined || specializationListss[0] === "") {
            return toast.error('Please upload specialization')

        }
        else if (doccumentsetlists[0] === null || doccumentsetlists[0] === undefined || doccumentsetlists[0] === "") {
            return toast.error('Please upload Documents')
        }
        else if (missingDocTypes.length > 0) {
            var missingDocType = missingDocTypes[0];
            return toast.error(`${missingDocType} is required`)
        }
        else {
            setsignpath(userSignaturePicture)
            try {
                var data = {
                    "headline": headline,
                    "degree": degree,
                    "experience": experience,
                    "consultation_fees": consultation_fees,
                    "bio": bio,
                    "followup_fees": followup_fees,
                    "signature": signpath,
                    "specializations": specializationListss,
                    "availability": availabilitysetlist,
                    "documents": doccumentsetlists
                };
                var config = {
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json'
                    },
                };
                const result = await axios.post(`${baseurl}onboarding/doctor`, JSON.stringify(data), config)
                if (result != null && result !== undefined) {
                    if (result.data.success) {
                        toast.success('Your profile has been saved successfully')
                    }
                    else {
                        toast.error(result.data.message)
                    }
                }
                else {
                    toast.error(result.data.message)
                }
            } catch (error) {
                toast.error(error)
            }
        }
    }
    // //adding avibility table-------------------------
    const AddAvibility = async (e) => {
        e.preventDefault();
        if (fromtime !== undefined && fromtime !== null && fromtime !== "" && totime !== undefined && totime !== null && totime !== "") {

            if (totime < fromtime) {
                toast.error("From time shuld be less than to time ")
            }
            else {
                try {
                    if (day == -1) {

                        for (var i = 0; i < 7; i++) {

                            var data = {
                                "day": i,
                                "start_time": fromtime,
                                "end_time": totime,
                                "fee": null,
                                "user_service_id": serviceprovider
                            };

                            var config = {

                                headers: {
                                    'authorization': token,
                                    'Content-Type': 'application/json'
                                },

                            };
                            const result = await axios.post(`${baseurl}slots/masterslot`, JSON.stringify(data), config)



                        }
                        toast.success("Available Added Sucessfully");
                        getavilibility();
                    }
                    else {
                        var data = {
                            "day": day,
                            "start_time": fromtime,
                            "end_time": totime,
                            "fee": null,
                            "user_service_id": serviceprovider
                        };

                        var config = {

                            headers: {
                                'authorization': token,
                                'Content-Type': 'application/json'
                            },

                        };
                        const result = await axios.post(`${baseurl}slots/masterslot`, JSON.stringify(data), config)
                        if (result != null) {

                            toast.success("Available Added Sucessfully");
                            getavilibility();
                        }


                    }


                } catch (error) {
                    toast.error(error)
                }

            }

        }
        else {
            toast.error("Please enter time")
        }

    }
    // //Add specilization---------------------------------------------
    const handleAddSpecilization = async (e) => {
        e.preventDefault();
        if (specilization_id.length > 0) {
            try {

                const tempspec = specializationList.filter(key => (key.id == specilization_id))

                const title = tempspec[0].title;

                // for cheking is specilization is already added or not
                var indexDel = specializationListss.findIndex((element) => element.type == title)
                if (indexDel > -1) {
                    return toast.error("This specialization is already added")
                }

                var data = {
                    "type": title,
                    "specialization_id": specilization_id
                };
                var config = {

                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json'
                    },
                };
                const result = await axios.post(`${baseurl}specialization/user/specs`, JSON.stringify(data), config)
                if (result !== null) {

                    toast.success("Specilization Added Sucessfully")
                    getspelization()

                }
            } catch (error) {
                toast.error(error.response.data.message)
            }
        }
        else {
            toast.error("Please select specilization")
        }
    }

    const Adddocumentlist = async (e) => {

        e.preventDefault();
        if (!proofTitle) {
            toast.error("Please enter title")
        }
        else if (proofType === undefined || proofType === null || proofType === "") {
            toast.error("Please select document type")

        }
        else if (!proofpath) {
            toast.error("Please select file")

        }

        else {

            var data = {
                "document_type": proofType,
                "title": proofTitle,
                "doc_file_path": proofpath
            };

            var config = {

                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                },

            };
            const result = await axios.post(`${baseurl}document`, JSON.stringify(data), config)
            if (result != null && result != undefined) {

                toast.success("Document Added Sucessfully")
                setProofFile()
                setproofpath()
                getdocument()

            }
        }


    }

    // //get api for spelization---------------------------------------
    const getspelization = async () => {
        var config = {
            headers: {
                'authorization': token,
            },
        };

        const result = await axios.get(`${baseurl}specialization/userPerformer`, config)
        if (result != null && result != undefined) {
            setspecializationListss(result.data.data)
        }
    }
    // //get api for avilibility-------------------------------------------
    const getavilibility = async () => {
        var config = {

            headers: {
                'authorization': token,
            },

        };
        const result = await axios.get(`${baseurl}slots`, config)
        if (result != null && result != undefined) {
            setavailabilitysetlist(result.data.data)
        }

    }
    // //get api for documnet--------------------------------------------
    const getdocument = async () => {
        var config = {
            headers: {
                'authorization': token,
            },
        };

        const result = await axios.get(`${baseurl}document`, config)
        if (result != null && result != undefined) {
            setdoccumentsetlists(result.data.data)
        }


    }

    return (
        <div className='teleconsultaion_page'>
            <h4 class="page_title">Status : {((is_approved == 0 && status === "Applied") || (is_approved == undefined && status === "Applied") || is_approved == null) ? "Not Approved" : (is_approved == 0 && status === "Rejected") ? "Rejected" : (is_approved == 1 && status === "Approved") ? "Approved" : "Not Applied"} </h4>

            <div class="">
                <div className='row'>
                    <div class="col-lg-10">
                        <div className='row'>
                            <div class="col-lg-12">
                                <div class="fd_input mb-2">
                                    <div class="form-group">
                                        <label >Headline</label>
                                        <input type="text" value={headline} name='headline' class="form-control" placeholder="" onChange={(e) => handleinputsdata(e)} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="fd_input">
                                    <div class="form-group">
                                        <label>Brief Details</label>
                                        <textarea type="text" name='bio' value={bio} class="form-control breif-details" placeholder="" onChange={(e) => handleinputsdata(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="fd_input mb-2 ">
                            <div class="fd_input mb-2 mt-5">
                                <div class="form-group profile-photo">
                                    <div className='profile-photo-sig heigth-control-auto' onClick={() => handleProfileImage()}>

                                        {(userSignaturePicture) ? <img style={{ 'z-indez': '11111' }} src={userSignaturePicture} alt="Select Signature" className='heigth-control-auto' /> : <p>Select Signature</p>}

                                    </div>



                                    <input id='signature' onChange={(e) => uploadsignaturePicture(e)} type={'file'} />

                                </div>

                            </div>
                            <div className='profile-upload-sign' > <span onClick={() => handleProfileImage()} > Change Signature</span> </div>

                        </div>
                    </div>
                </div>
              {/* start */}
                  <div className='col-lg-12'>
                  <div className='row'>
                    <div class="col-lg-4">
                        <div class="fd_input">
                            <div class="form-group">
                                <label>Total Experience( In Years )</label>
                                <input type="text" name='experience' value={experience} class="form-control" placeholder="" onChange={(e) => handleinputsdata(e)} />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="fd_input">
                            <div class="form-group">
                                <label>Degree</label>
                                <input type="text" name='degree' value={degree} class="form-control" placeholder="" onChange={(e) => handleinputsdata(e)} />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="fd_input">
                            <div class="form-group">
                                <label>Consultation Fee</label>
                                <input type="number" name='consultation_fees' value={consultation_fees} class="form-control" placeholder="" onChange={(e) => handleinputsdata(e)} />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="fd_input">
                            <div class="form-group">
                                <label>Followup Fee</label>
                                <input type="number" name='followup_fees' value={followup_fees} class="form-control" placeholder="" onChange={(e) => handleinputsdata(e)} />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="fd_input">
                            <div class="form-group">
                                <label>Specializations</label>
                                <div className='border-spe'>
                                    <div className='handle-specilization'>
                                        <select name='ddlspecilization' type="email" value={specilization_id} onChange={(e) => setspecilization_id(e.target.value)} class="form-control" placeholder="" >
                                            <option>Select Specialization</option>
                                            {
                                                specializationList.map((val) => {
                                                    return (
                                                        <option value={val.id}>{val.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            isapplied ?
                                                <button className='btn btn-info btn-sm ml-2' onClick={(e) => handleAddSpecilization(e)} data-toggle="tooltip" data-placement="top" title="Click here to Add Specializations"> + Add</button>
                                                :
                                                <button className='btn btn-info btn-sm ml-2' onClick={(e) => Setthespeclization(e) } data-toggle="tooltip" data-placement="top" title="Click here to Add Specializations"> + Add</button>
                                        }

                                    </div>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th >Specialization</th>
                                                <th >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {

                                                (specializationListss != undefined && specializationListss != null) &&
                                                specializationListss.map((val, ind) => {
                                                    return (
                                                        <tr>
                                                            <td>{val.type}</td>
                                                            {isapplied ?
                                                                <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deletespecification(val.id)}>Delete</td>
                                                                :
                                                                <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deletspec(val.type)}>Delete</td>
                                                            }

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="fd_input">
                            <div class="form-group">
                                <label>Availability</label>
                                <div className='border-spe'>

                                    <div className='availability-profile'>
                                        <select type="email" name='email' class="form-control" placeholder="" value={day} onChange={(e) => setday(e.target.value)} >
                                            {
                                                (AllDaysList != null && AllDaysList != undefined) && AllDaysList.map((val, ind) => {
                                                    return (
                                                        <option value={val.value}>{val.Dayname}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* <Label>From Time</Label> */}
                                        <select name='timefrom' class="form-control" placeholder="" value={fromtime} onChange={(e) => setfromtime(e.target.value)} >
                                            {
                                                (TimeFrom != null && TimeFrom != undefined) && TimeFrom.map((val, ind) => {
                                                    return (
                                                        <option value={val.value}>{val.start}</option>
                                                    )
                                                })
                                            }
                                        </select>

                                        <select name='timefrom' class="form-control" placeholder="" value={totime} onChange={(e) => settotime(e.target.value)} >
                                            {
                                                (TimeTo != null && TimeTo != undefined) && TimeTo.map((val, ind) => {
                                                    return (
                                                        <option value={val.value}>{val.start}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* <input 
                                            type="time"
                                            step="1"
                                            value={fromtime}
                                            className="form-control"
                                            placeholder="Time"
                                            onChange={(e) => setfromtime(e.target.value)}

                                        /> */}
                                        {/* <Label>To Time</Label> */}
                                        {/* <input
                                            type="time"
                                            step="1"
                                            value={totime}
                                            className="form-control"
                                            placeholder="Time"
                                            onChange={(e) => settotime(e.target.value)}

                                        /> */}
                                        {
                                            isapplied ?
                                                <button className='btn btn-info btn-sm' onClick={(e) => AddAvibility(e)} data-toggle="tooltip" data-placement="top" title="Click here to Add Avibility"> + Add</button>
                                                :
                                                <button className='btn btn-info btn-sm' onClick={(e) => settheavilibility(e)} data-toggle="tooltip" data-placement="top" title="Click here to Add Avibility"> + Add</button>
                                        }

                                    </div>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Days</th>
                                                <th scope="col">From Time</th>
                                                <th scope="col">To Time</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (availabilitysetlist != null && availabilitysetlist != undefined) &&
                                                availabilitysetlist.map((val, ind) => {
                                                    return (
                                                        <tr>
                                                            <td>{val.day == 0 ? 'sunday' : val.day == 1 ? 'Monday' : val.day == 2 ? 'Tuesday' : val.day == 3 ? 'Wednesday' : val.day == 4 ? 'Thursday' : val.day == 5 ? 'Friday' : val.day == 6 ? 'Saturday' : val.day == 7 ? "All day" : ''}</td>
                                                            <td>{val.start_time}</td>
                                                            <td>{val.end_time}</td>
                                                            {isapplied ?
                                                                <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deleteAvilability(val.id)} >Delete</td>
                                                                :
                                                                <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deletavil(val.start_time)} >Delete</td>
                                                            }

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                    </div>
                 <div className='col-lg-12'>
                 <div className='row'>
                    <div class="col-lg-12">
                        <div class="fd_input">
                            <div class="form-group">

                                <div className='border-spe'>
                                    <div className='documents-profile'>
                                        <select onChange={(e) => setProofType(e.target.value)} value={proofType} class="form-control" placeholder="" >
                                            <option disabled selected>Select Proof</option>
                                            <option value={"Identity Proof"}>Identity Proof</option>
                                            <option value={"Medical Registration Proof"}>Medical Registration Proof</option>
                                            <option value={"Establishment Proof"}>Establishment Proof </option>
                                        </select>
                                        <input type="text" onChange={(e) => setProofTitle(e.target.value)} placeholder='Title' class="form-control" />
                                        <input type="file" onChange={(e) => handleUpload(e)} class="form-control" placeholder='max size 200 kb' />


                                        {
                                            isapplied ?
                                                <button className='btn btn-info btn-sm' onClick={(e) => Adddocumentlist(e)} data-toggle="tooltip" data-placement="top" title="Click here to Add Document">{docUploaded ? "+ Add" : <ThreeDots
                                                    height="30"
                                                    width="30"
                                                    radius="9"
                                                    color="#4fa94d"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />} </button>
                                                :
                                                <button className='btn btn-info btn-sm' onClick={(e) => letsdocument(e)} disabled={!docUploaded} data-toggle="tooltip" data-placement="top" title="Click here to Add Document"> + Add</button>
                                        }

                                    </div>
                                    <div className='size_validation'>
                                        <span>&nbsp;</span>
                                        <span>&nbsp;</span>
                                        <span> Max Size: 200 KB Allow </span>
                                        <span>&nbsp;</span>
                                    </div>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Document Type</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Link</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (doccumentsetlists != null && doccumentsetlists != undefined) && doccumentsetlists.map((val, ind) => {
                                                    return (
                                                        <tr>
                                                            <td>{val.document_type}</td>
                                                            <td>{val.title}</td>
                                                            <td><a href={val.doc_file_path} target="_blank">View</a></td>
                                                            {isapplied ?
                                                                <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deletedocument(val.id)} >Delete</td>
                                                                :
                                                                <td style={{ 'color': 'red', 'cursor': 'pointer' }} onClick={() => deletdocum(val.title)} >Delete</td>
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                     
                 </div>
            </div>

            <hr class="seprator" />
            <div class="save_btn">
                <button class="btn btn_fill btn-xs" onClick={(e) => updateProfileOnsubmit(e)}>Save</button>
            </div>


            <Modal show={modal} onHide={toggle}>
                <Modal.Header
                    onHide={toggle}
                    closeButton
                    className={classNames('modal-colored-header', 'bg-danger')}>
                    <h4 className="modal-title text-light">Delete Specialization</h4>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="mt-0">Are you sure want to delete this item</h5>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggle}>
                        Cancel
                    </Button>{' '}
                    <Button variant="danger" onClick={() => deletespecification(item)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modal1} onHide={toggle1}>
                <Modal.Header
                    onHide={toggle1}
                    closeButton
                    className={classNames('modal-colored-header', 'bg-danger')}>
                    <h4 className="modal-title text-light">Delete Availability</h4>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="mt-0">Are you sure want to delete this item</h5>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggle1}>
                        Cancel
                    </Button>{' '}
                    <Button variant="danger" onClick={() => deleteAvilability(item)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modal2} onHide={toggle2}>
                <Modal.Header
                    onHide={toggle2}
                    closeButton
                    className={classNames('modal-colored-header', 'bg-danger')}>
                    <h4 className="modal-title text-light">Delete Document</h4>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="mt-0">Are you sure want to delete this item</h5>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={toggle2}>
                        Cancel
                    </Button>{' '}
                    <Button variant="danger" onClick={() => deletedocument(item)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default DoctorProfile